@charset "UTF-8";
/*!

* Updated: 06.13.2023
**/

:root,
[data-bs-theme="light"] {
  --bs-blue: #559bfb;
  --bs-indigo: #323f9e;
  --bs-purple: #816bff;
  --bs-pink: #ff63a5;
  --bs-red: #e85347;
  --bs-orange: #ffa353;
  --bs-yellow: #f4bd0e;
  --bs-green: #1ee0ac;
  --bs-teal: #20c997;
  --bs-cyan: #09c2de;
  --bs-white: #fff;
  --bs-gray: #8091a7;
  --bs-gray-dark: #2b3748;
  --bs-gray-100: #ebeef2;
  --bs-gray-200: #e5e9f2;
  --bs-gray-300: #dbdfea;
  --bs-gray-400: #b7c2d0;
  --bs-gray-500: #8091a7;
  --bs-gray-600: #3c4d62;
  --bs-gray-700: #344357;
  --bs-gray-800: #2b3748;
  --bs-gray-900: #1f2b3a;
  --bs-primary: #11c8fc;
  --bs-secondary: #364a63;
  --bs-success: #0fca7a;
  --bs-info: #058efc;
  --bs-warning: #fd9722;
  --bs-danger: #f2426e;
  --bs-light: #e5e9f2;
  --bs-dark: #1f2b3a;
  --bs-gray: #8091a7;
  --bs-lighter: #f5f6fa;
  --bs-primary-rgb: 17, 200, 252;
  --bs-secondary-rgb: 54, 74, 99;
  --bs-success-rgb: 15, 202, 122;
  --bs-info-rgb: 5, 142, 252;
  --bs-warning-rgb: 253, 151, 34;
  --bs-danger-rgb: 242, 66, 110;
  --bs-light-rgb: 229, 233, 242;
  --bs-dark-rgb: 31, 43, 58;
  --bs-gray-rgb: 128, 145, 167;
  --bs-lighter-rgb: 245, 246, 250;
  --bs-primary-text-emphasis: #223e64;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #065131;
  --bs-info-text-emphasis: #023965;
  --bs-warning-text-emphasis: #653c0e;
  --bs-danger-text-emphasis: #611a2c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #ddebfe;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #cff4e4;
  --bs-info-bg-subtle: #cde8fe;
  --bs-warning-bg-subtle: #ffead3;
  --bs-danger-bg-subtle: #fcd9e2;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #bbd7fd;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #9feaca;
  --bs-info-border-subtle: #9bd2fe;
  --bs-warning-border-subtle: #fed5a7;
  --bs-danger-border-subtle: #fab3c5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: Roboto, sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: Roboto, sans-serif;
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.65;
  --bs-body-color: #526484;
  --bs-body-color-rgb: 82, 100, 132;
  --bs-body-bg: #f5f6fa;
  --bs-body-bg-rgb: 245, 246, 250;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(82, 100, 132, 0.75);
  --bs-secondary-color-rgb: 82, 100, 132;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(82, 100, 132, 0.5);
  --bs-tertiary-color-rgb: 82, 100, 132;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: #364a63;
  --bs-link-color: #11c8fc;
  --bs-link-color-rgb: 17, 200, 238;
  --bs-link-decoration: none;
  --bs-link-hover-color: #00aada;
  --bs-link-hover-color-rgb: 0, 136, 199;
  --bs-link-hover-decoration: none;
  --bs-code-color: #ff63a5;
  --bs-highlight-bg: #fcf8e3;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dbdfea;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 4px;
  --bs-border-radius-sm: 3px;
  --bs-border-radius-lg: 5px;
  --bs-border-radius-xl: 8px;
  --bs-border-radius-xxl: 12px;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 3px 12px 1px rgba(43, 55, 72, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(43, 55, 72, 0.15);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(43, 55, 72, 0.25);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(85, 155, 251, 0.25);
  --bs-form-valid-color: #0fca7a;
  --bs-form-valid-border-color: #0fca7a;
  --bs-form-invalid-color: #f2426e;
  --bs-form-invalid-border-color: #f2426e;
}
[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #adb5bd;
  --bs-body-color-rgb: 173, 181, 189;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(173, 181, 189, 0.75);
  --bs-secondary-color-rgb: 173, 181, 189;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(173, 181, 189, 0.5);
  --bs-tertiary-color-rgb: 173, 181, 189;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #99c3fd;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #6fdfaf;
  --bs-info-text-emphasis: #69bbfd;
  --bs-warning-text-emphasis: #fec17a;
  --bs-danger-text-emphasis: #f78ea8;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #111f32;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #032818;
  --bs-info-bg-subtle: #011c32;
  --bs-warning-bg-subtle: #331e07;
  --bs-danger-bg-subtle: #300d16;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #335d97;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #097949;
  --bs-info-border-subtle: #035597;
  --bs-warning-border-subtle: #985b14;
  --bs-danger-border-subtle: #912842;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #99c3fd;
  --bs-link-hover-color: #adcffd;
  --bs-link-color-rgb: 153, 195, 253;
  --bs-link-hover-color-rgb: 0, 136, 199;
  --bs-code-color: #ffa1c9;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #78eccd;
  --bs-form-valid-border-color: #78eccd;
  --bs-form-invalid-color: #f19891;
  --bs-form-invalid-border-color: #f19891;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  /* background-color: var(--bs-body-bg); */
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
hr {
  margin: 1rem 0;
  color: #b7c2d0;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: Nunito, sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: var(--bs-heading-color);
}
h1,
.h1 {
  font-size: 2rem;
}
h2,
.h2 {
  font-size: 1.75rem;
}
h3,
.h3 {
  font-size: 1.5rem;
}
h4,
.h4 {
  font-size: 1.25rem;
}
h5,
.h5 {
  font-size: 1.15rem;
}
h6,
.h6 {
  font-size: 1rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul {
  padding-left: 2rem;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small,
.small {
  font-size: 85%;
}
mark,
.mark {
  padding: 0.2em;
  background-color: var(--bs-highlight-bg);
}
sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
  text-decoration: none;
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 3px;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}
figure {
  margin: 0 0 1rem;
}
img,
svg {
  vertical-align: middle;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
}
caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #8094ae;
  text-align: left;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
label {
  display: inline-block;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}
[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not(
    [type="week"]
  ):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}
::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
textarea {
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
}
legend + * {
  clear: left;
}
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}
::-webkit-inner-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-color-swatch-wrapper {
  padding: 0;
}
::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
iframe {
  border: 0;
}
summary {
  display: list-item;
  cursor: pointer;
}
progress {
  vertical-align: baseline;
}
[hidden] {
  display: none !important;
}
.lead {
  font-size: 1.00625rem;
  font-weight: 400;
}
.display-1 {
  font-size: 5rem;
  font-weight: 300;
  line-height: 1.1;
}
.display-2 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}
.display-3 {
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.1;
}
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}
.display-5 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.1;
}
.display-6 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.1;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}
.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 85%;
  color: #b6c6e3;
}
.blockquote-footer::before {
  content: "— ";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 14px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1540px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1440px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1540px;
}
.row {
  --bs-gutter-x: 28px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.col {
  flex: 1 0 0%;
}
.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}
.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}
.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.offset-1 {
  margin-left: 8.33333333%;
}
.offset-2 {
  margin-left: 16.66666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333333%;
}
.offset-5 {
  margin-left: 41.66666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333333%;
}
.offset-8 {
  margin-left: 66.66666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333333%;
}
.offset-11 {
  margin-left: 91.66666667%;
}
.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}
.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}
.g-1,
.gx-1 {
  --bs-gutter-x: 0.375rem;
}
.g-1,
.gy-1 {
  --bs-gutter-y: 0.375rem;
}
.g-2,
.gx-2 {
  --bs-gutter-x: 0.75rem;
}
.g-2,
.gy-2 {
  --bs-gutter-y: 0.75rem;
}
.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}
.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}
.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}
.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}
.g-5,
.gx-5 {
  --bs-gutter-x: 2.75rem;
}
.g-5,
.gy-5 {
  --bs-gutter-y: 2.75rem;
}
.g-gs,
.gx-gs {
  --bs-gutter-x: 28px;
}
.g-gs,
.gy-gs {
  --bs-gutter-y: 28px;
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 2.75rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 2.75rem;
  }
  .g-sm-gs,
  .gx-sm-gs {
    --bs-gutter-x: 28px;
  }
  .g-sm-gs,
  .gy-sm-gs {
    --bs-gutter-y: 28px;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 2.75rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 2.75rem;
  }
  .g-md-gs,
  .gx-md-gs {
    --bs-gutter-x: 28px;
  }
  .g-md-gs,
  .gy-md-gs {
    --bs-gutter-y: 28px;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 2.75rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 2.75rem;
  }
  .g-lg-gs,
  .gx-lg-gs {
    --bs-gutter-x: 28px;
  }
  .g-lg-gs,
  .gy-lg-gs {
    --bs-gutter-y: 28px;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 2.75rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 2.75rem;
  }
  .g-xl-gs,
  .gx-xl-gs {
    --bs-gutter-x: 28px;
  }
  .g-xl-gs,
  .gy-xl-gs {
    --bs-gutter-y: 28px;
  }
}
@media (min-width: 1540px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 2.75rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 2.75rem;
  }
  .g-xxl-gs,
  .gx-xxl-gs {
    --bs-gutter-x: 28px;
  }
  .g-xxl-gs,
  .gy-xxl-gs {
    --bs-gutter-y: 28px;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: #526484;
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: #dbdfea;
  --bs-table-accent-bg: #fff;
  --bs-table-striped-color: #526484;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #f5f6fa;
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #f5f6fa;
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(
    --bs-table-color-state,
    var(--bs-table-color-type, var(--bs-table-color))
  );
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px
    var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}
.caption-top {
  caption-side: top;
}
.table-sm > :not(caption) > * > * {
  padding: 0.25rem 1.25rem;
}
.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}
.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}
.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}
.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}
.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}
.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #ddebfe;
  --bs-table-border-color: #c7d4e5;
  --bs-table-striped-bg: #d2dff1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c7d4e5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ccd9eb;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #cff4e4;
  --bs-table-border-color: #badccd;
  --bs-table-striped-bg: #c5e8d9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badccd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2d3;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cde8fe;
  --bs-table-border-color: #b9d1e5;
  --bs-table-striped-bg: #c3dcf1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b9d1e5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bed7eb;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #ffead3;
  --bs-table-border-color: #e6d3be;
  --bs-table-striped-bg: #f2dec8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d3be;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecd8c3;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #fcd9e2;
  --bs-table-border-color: #e3c3cb;
  --bs-table-striped-bg: #efced7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e3c3cb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e9c9d1;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #ebeef2;
  --bs-table-border-color: #d4d6da;
  --bs-table-striped-bg: #dfe2e6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d4d6da;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d9dce0;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #1f2b3a;
  --bs-table-border-color: #35404e;
  --bs-table-striped-bg: #2a3644;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #35404e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #303b49;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1539.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}
.col-form-label {
  padding-top: calc(0.4375rem + 1px);
  padding-bottom: calc(0.4375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.25rem;
}
.col-form-label-lg {
  padding-top: calc(0.6875rem + 1px);
  padding-bottom: calc(0.6875rem + 1px);
  font-size: 0.9375rem;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.75rem;
}
.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}
.form-control,
.dual-listbox .dual-listbox__search,
div.dataTables_wrapper div.dataTables_filter input {
  display: block;
  width: 100%;
  padding: 0.4375rem 1rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #3c4d62;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dbdfea;
  appearance: none;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control,
  .dual-listbox .dual-listbox__search,
  div.dataTables_wrapper div.dataTables_filter input {
    transition: none;
  }
}
.form-control[type="file"],
.dual-listbox [type="file"].dual-listbox__search,
div.dataTables_wrapper div.dataTables_filter input[type="file"] {
  overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not([readonly]),
.dual-listbox [type="file"].dual-listbox__search:not(:disabled):not([readonly]),
div.dataTables_wrapper
  div.dataTables_filter
  input[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus,
.dual-listbox .dual-listbox__search:focus,
div.dataTables_wrapper div.dataTables_filter input:focus {
  color: #3c4d62;
  background-color: #fff;
  border-color: #11c8fc;
  outline: 0;
  box-shadow: 0 0 0 3px rgba(115, 58, 234, 0.1);
}
.form-control::-webkit-date-and-time-value,
.dual-listbox .dual-listbox__search::-webkit-date-and-time-value,
div.dataTables_wrapper
  div.dataTables_filter
  input::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.25rem;
  margin: 0;
}
.form-control::-webkit-datetime-edit,
.dual-listbox .dual-listbox__search::-webkit-datetime-edit,
div.dataTables_wrapper div.dataTables_filter input::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder,
.dual-listbox .dual-listbox__search::placeholder,
div.dataTables_wrapper div.dataTables_filter input::placeholder {
  color: #b6c6e3;
  opacity: 1;
}
.form-control:disabled,
.dual-listbox .dual-listbox__search:disabled,
div.dataTables_wrapper div.dataTables_filter input:disabled {
  background-color: #f5f6fa;
  opacity: 1;
}
.form-control::file-selector-button,
.dual-listbox .dual-listbox__search::file-selector-button,
div.dataTables_wrapper div.dataTables_filter input::file-selector-button {
  padding: 0.4375rem 1rem;
  margin: -0.4375rem -1rem;
  margin-inline-end: 1rem;
  color: #3c4d62;
  background-color: #ebeef2;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button,
  .dual-listbox .dual-listbox__search::file-selector-button,
  div.dataTables_wrapper div.dataTables_filter input::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button,
.dual-listbox
  .dual-listbox__search:hover:not(:disabled):not(
    [readonly]
  )::file-selector-button,
div.dataTables_wrapper
  div.dataTables_filter
  input:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.4375rem 0;
  margin-bottom: 0;
  line-height: 1.25rem;
  color: #364a63;
  background-color: rgba(0, 0, 0, 0);
  border: solid rgba(0, 0, 0, 0);
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  min-height: calc(1.75rem + 2px);
  padding: 0.25rem 1rem;
  font-size: 0.75rem;
  border-radius: 3px;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 1rem;
  margin: -0.25rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg {
  min-height: calc(2.625rem + 2px);
  padding: 0.6875rem 1rem;
  font-size: 0.9375rem;
  border-radius: 5px;
}
.form-control-lg::file-selector-button {
  padding: 0.6875rem 1rem;
  margin: -0.6875rem -1rem;
  margin-inline-end: 1rem;
}
textarea.form-control,
.dual-listbox textarea.dual-listbox__search {
  min-height: calc(2.125rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.75rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(2.625rem + 2px);
}
.form-control-color {
  width: 3rem;
  height: calc(2.125rem + 2px);
  padding: 0.4375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 4px;
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: 4px;
}
.form-control-color.form-control-sm {
  height: calc(1.75rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(2.625rem + 2px);
}
.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.4375rem 3rem 0.4375rem 1rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #3c4d62;
  background-color: #fff;
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #11c8fc;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(85, 155, 251, 0.25);
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #f5f6fa;
}
.form-select:-moz-focusring {
  color: rgba(0, 0, 0, 0);
  text-shadow: 0 0 0 #3c4d62;
}
.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  font-size: 0.75rem;
  border-radius: 3px;
}
.form-select-lg {
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem;
  padding-left: 1rem;
  font-size: 0.9375rem;
  border-radius: 5px;
}
[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}
.form-check {
  display: block;
  min-height: 1.44375rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}
.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}
.form-check-input {
  --bs-form-check-bg: #fff;
  width: 1em;
  height: 1em;
  margin-top: 0.325em;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #11c8fc;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(85, 155, 251, 0.25);
}
.form-check-input:checked {
  background-color: #11c8fc;
  border-color: #559bfb;
}
.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #559bfb;
  border-color: #559bfb;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}
.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23733AEA'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}
.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn,
.actions ul li .btn-check[disabled] + a,
.dual-listbox .btn-check[disabled] + .dual-listbox__button,
.btn-check:disabled + .btn,
.actions ul li .btn-check:disabled + a,
.dual-listbox .btn-check:disabled + .dual-listbox__button {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
[data-bs-theme="dark"]
  .form-switch
  .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}
.form-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f5f6fa, 0 0 0 3px rgba(115, 58, 234, 0.1);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f5f6fa, 0 0 0 3px rgba(115, 58, 234, 0.1);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #559bfb;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #cce1fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-color: #dbdfea;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #559bfb;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #cce1fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-color: #dbdfea;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}
.form-floating {
  position: relative;
}
.form-floating > .form-control,
.dual-listbox .form-floating > .dual-listbox__search,
div.dataTables_wrapper div.dataTables_filter .form-floating > input,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  min-height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 1rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid rgba(0, 0, 0, 0);
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.dual-listbox .form-floating > .dual-listbox__search,
div.dataTables_wrapper div.dataTables_filter .form-floating > input,
.form-floating > .form-control-plaintext {
  padding: 1rem 1rem;
}
.form-floating > .form-control::placeholder,
.dual-listbox .form-floating > .dual-listbox__search::placeholder,
div.dataTables_wrapper
  div.dataTables_filter
  .form-floating
  > input::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: rgba(0, 0, 0, 0);
}
.form-floating > .form-control:focus,
.dual-listbox .form-floating > .dual-listbox__search:focus,
div.dataTables_wrapper div.dataTables_filter .form-floating > input:focus,
.form-floating > .form-control:not(:placeholder-shown),
.dual-listbox .form-floating > .dual-listbox__search:not(:placeholder-shown),
div.dataTables_wrapper
  div.dataTables_filter
  .form-floating
  > input:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.dual-listbox .form-floating > .dual-listbox__search:-webkit-autofill,
div.dataTables_wrapper
  div.dataTables_filter
  .form-floating
  > input:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.dual-listbox .form-floating > .dual-listbox__search:focus ~ label,
div.dataTables_wrapper
  div.dataTables_filter
  .form-floating
  > input:focus
  ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.dual-listbox
  .form-floating
  > .dual-listbox__search:not(:placeholder-shown)
  ~ label,
div.dataTables_wrapper
  div.dataTables_filter
  .form-floating
  > input:not(:placeholder-shown)
  ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.dual-listbox .form-floating > .dual-listbox__search:focus ~ label::after,
div.dataTables_wrapper
  div.dataTables_filter
  .form-floating
  > input:focus
  ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.dual-listbox
  .form-floating
  > .dual-listbox__search:not(:placeholder-shown)
  ~ label::after,
div.dataTables_wrapper
  div.dataTables_filter
  .form-floating
  > input:not(:placeholder-shown)
  ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.5rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #fff;
  border-radius: 4px;
}
.form-floating > .form-control:-webkit-autofill ~ label,
.dual-listbox .form-floating > .dual-listbox__search:-webkit-autofill ~ label,
div.dataTables_wrapper
  div.dataTables_filter
  .form-floating
  > input:-webkit-autofill
  ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}
.form-floating > :disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after {
  background-color: #f5f6fa;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.dual-listbox .input-group > .dual-listbox__search,
div.dataTables_wrapper div.dataTables_filter .input-group > input,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.dual-listbox .input-group > .dual-listbox__search:focus,
div.dataTables_wrapper div.dataTables_filter .input-group > input:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn,
.input-group .actions ul li a,
.actions ul li .input-group a,
.input-group .dual-listbox .dual-listbox__button,
.dual-listbox .input-group .dual-listbox__button {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus,
.input-group .actions ul li a:focus,
.actions ul li .input-group a:focus,
.input-group .dual-listbox .dual-listbox__button:focus,
.dual-listbox .input-group .dual-listbox__button:focus {
  z-index: 5;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.4375rem 1rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #3c4d62;
  text-align: center;
  white-space: nowrap;
  background-color: #ebeef2;
  border: 1px solid #dbdfea;
  border-radius: 4px;
}
.input-group-lg > .form-control,
.dual-listbox .input-group-lg > .dual-listbox__search,
div.dataTables_wrapper div.dataTables_filter .input-group-lg > input,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.actions ul li .input-group-lg > a,
.dual-listbox .input-group-lg > .dual-listbox__button {
  padding: 0.6875rem 1rem;
  font-size: 0.9375rem;
  border-radius: 5px;
}
.input-group-sm > .form-control,
.dual-listbox .input-group-sm > .dual-listbox__search,
div.dataTables_wrapper div.dataTables_filter .input-group-sm > input,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.actions ul li .input-group-sm > a,
.dual-listbox .input-group-sm > .dual-listbox__button {
  padding: 0.25rem 1rem;
  font-size: 0.75rem;
  border-radius: 3px;
}
.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.dual-listbox
  .input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .dual-listbox__search,
div.dataTables_wrapper
  div.dataTables_filter
  .input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > input,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-control,
.dual-listbox
  .input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .dual-listbox__search,
div.dataTables_wrapper
  div.dataTables_filter
  .input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > input,
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(1px * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.dual-listbox
  .input-group
  > .form-floating:not(:first-child)
  > .dual-listbox__search,
div.dataTables_wrapper
  div.dataTables_filter
  .input-group
  > .form-floating:not(:first-child)
  > input,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: 3px;
}
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-control:valid,
.was-validated .dual-listbox .dual-listbox__search:valid,
.dual-listbox .was-validated .dual-listbox__search:valid,
.was-validated div.dataTables_wrapper div.dataTables_filter input:valid,
div.dataTables_wrapper div.dataTables_filter .was-validated input:valid,
.form-control.is-valid,
.dual-listbox .is-valid.dual-listbox__search,
div.dataTables_wrapper div.dataTables_filter input.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: 2.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230FCA7A' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.53125rem center;
  background-size: 1.0625rem 1.0625rem;
}
.was-validated .form-control:valid:focus,
.was-validated .dual-listbox .dual-listbox__search:valid:focus,
.dual-listbox .was-validated .dual-listbox__search:valid:focus,
.was-validated div.dataTables_wrapper div.dataTables_filter input:valid:focus,
div.dataTables_wrapper div.dataTables_filter .was-validated input:valid:focus,
.form-control.is-valid:focus,
.dual-listbox .is-valid.dual-listbox__search:focus,
div.dataTables_wrapper div.dataTables_filter input.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.2rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated textarea.form-control:valid,
.was-validated .dual-listbox textarea.dual-listbox__search:valid,
.dual-listbox .was-validated textarea.dual-listbox__search:valid,
textarea.form-control.is-valid,
.dual-listbox textarea.is-valid.dual-listbox__search {
  padding-right: 2.125rem;
  background-position: top 0.53125rem right 0.53125rem;
}
.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230FCA7A' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 5.5rem;
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, 1.0625rem 1.0625rem;
}
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.2rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-control-color:valid,
.form-control-color.is-valid {
  width: 5.125rem;
}
.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}
.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
.was-validated .input-group > .form-control:not(:focus):valid,
.was-validated
  .dual-listbox
  .input-group
  > .dual-listbox__search:not(:focus):valid,
.dual-listbox
  .was-validated
  .input-group
  > .dual-listbox__search:not(:focus):valid,
.was-validated
  div.dataTables_wrapper
  div.dataTables_filter
  .input-group
  > input:not(:focus):valid,
div.dataTables_wrapper
  div.dataTables_filter
  .was-validated
  .input-group
  > input:not(:focus):valid,
.input-group > .form-control:not(:focus).is-valid,
.dual-listbox .input-group > .dual-listbox__search:not(:focus).is-valid,
div.dataTables_wrapper
  div.dataTables_filter
  .input-group
  > input:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: 3px;
}
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-control:invalid,
.was-validated .dual-listbox .dual-listbox__search:invalid,
.dual-listbox .was-validated .dual-listbox__search:invalid,
.was-validated div.dataTables_wrapper div.dataTables_filter input:invalid,
div.dataTables_wrapper div.dataTables_filter .was-validated input:invalid,
.form-control.is-invalid,
.dual-listbox .is-invalid.dual-listbox__search,
div.dataTables_wrapper div.dataTables_filter input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: 2.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F2426E'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F2426E' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.53125rem center;
  background-size: 1.0625rem 1.0625rem;
}
.was-validated .form-control:invalid:focus,
.was-validated .dual-listbox .dual-listbox__search:invalid:focus,
.dual-listbox .was-validated .dual-listbox__search:invalid:focus,
.was-validated div.dataTables_wrapper div.dataTables_filter input:invalid:focus,
div.dataTables_wrapper div.dataTables_filter .was-validated input:invalid:focus,
.form-control.is-invalid:focus,
.dual-listbox .is-invalid.dual-listbox__search:focus,
div.dataTables_wrapper div.dataTables_filter input.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.2rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated textarea.form-control:invalid,
.was-validated .dual-listbox textarea.dual-listbox__search:invalid,
.dual-listbox .was-validated textarea.dual-listbox__search:invalid,
textarea.form-control.is-invalid,
.dual-listbox textarea.is-invalid.dual-listbox__search {
  padding-right: 2.125rem;
  background-position: top 0.53125rem right 0.53125rem;
}
.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F2426E'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F2426E' stroke='none'/%3e%3c/svg%3e");
  padding-right: 5.5rem;
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, 1.0625rem 1.0625rem;
}
.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.2rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-control-color:invalid,
.form-control-color.is-invalid {
  width: 5.125rem;
}
.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}
.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
.was-validated .input-group > .form-control:not(:focus):invalid,
.was-validated
  .dual-listbox
  .input-group
  > .dual-listbox__search:not(:focus):invalid,
.dual-listbox
  .was-validated
  .input-group
  > .dual-listbox__search:not(:focus):invalid,
.was-validated
  div.dataTables_wrapper
  div.dataTables_filter
  .input-group
  > input:not(:focus):invalid,
div.dataTables_wrapper
  div.dataTables_filter
  .was-validated
  .input-group
  > input:not(:focus):invalid,
.input-group > .form-control:not(:focus).is-invalid,
.dual-listbox .input-group > .dual-listbox__search:not(:focus).is-invalid,
div.dataTables_wrapper
  div.dataTables_filter
  .input-group
  > input:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}
.btn,
.actions ul li a,
.dual-listbox .dual-listbox__button {
  --bs-btn-padding-x: 1.125rem;
  --bs-btn-padding-y: 0.4375rem;
  --bs-btn-font-family: Nunito, sans-serif;
  --bs-btn-font-size: 0.8125rem;
  --bs-btn-font-weight: 700;
  --bs-btn-line-height: 1.25rem;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 4px;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(16, 25, 36, 0.075);
  --bs-btn-disabled-opacity: 0.5;
  --bs-btn-focus-box-shadow: 0 0 0 0.2rem
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn,
  .actions ul li a,
  .dual-listbox .dual-listbox__button {
    transition: none;
  }
}
.btn:hover,
.actions ul li a:hover,
.dual-listbox .dual-listbox__button:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover,
.actions ul li .btn-check + a:hover,
.dual-listbox .btn-check + .dual-listbox__button:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible,
.actions ul li a:focus-visible,
.dual-listbox .dual-listbox__button:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn,
.actions ul li .btn-check:focus-visible + a,
.dual-listbox .btn-check:focus-visible + .dual-listbox__button {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn,
.actions ul li .btn-check:checked + a,
.dual-listbox .btn-check:checked + .dual-listbox__button,
:not(.btn-check) + .btn:active,
.actions ul li :not(.btn-check) + a:active,
.dual-listbox :not(.btn-check) + .dual-listbox__button:active,
.btn:first-child:active,
.actions ul li a:first-child:active,
.dual-listbox .dual-listbox__button:first-child:active,
.btn.active,
.actions ul li a.active,
.dual-listbox .active.dual-listbox__button,
.btn.show,
.actions ul li a.show,
.dual-listbox .show.dual-listbox__button {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible,
.actions ul li .btn-check:checked + a:focus-visible,
.dual-listbox .btn-check:checked + .dual-listbox__button:focus-visible,
:not(.btn-check) + .btn:active:focus-visible,
.actions ul li :not(.btn-check) + a:active:focus-visible,
.dual-listbox :not(.btn-check) + .dual-listbox__button:active:focus-visible,
.btn:first-child:active:focus-visible,
.actions ul li a:first-child:active:focus-visible,
.dual-listbox .dual-listbox__button:first-child:active:focus-visible,
.btn.active:focus-visible,
.actions ul li a.active:focus-visible,
.dual-listbox .active.dual-listbox__button:focus-visible,
.btn.show:focus-visible,
.actions ul li a.show:focus-visible,
.dual-listbox .show.dual-listbox__button:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled,
.actions ul li a:disabled,
.dual-listbox .dual-listbox__button:disabled,
.btn.disabled,
.actions ul li a.disabled,
.dual-listbox .disabled.dual-listbox__button,
fieldset:disabled .btn,
fieldset:disabled .actions ul li a,
.actions ul li fieldset:disabled a,
fieldset:disabled .dual-listbox .dual-listbox__button,
.dual-listbox fieldset:disabled .dual-listbox__button {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #11c8fc;
  --bs-btn-border-color: #11c8fc;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #10aad4;
  --bs-btn-hover-border-color: #10aad4;
  --bs-btn-focus-shadow-rgb: 136, 88, 237;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #55b6d1;
  --bs-btn-active-border-color: #11c8fc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #50b1cc;
  --bs-btn-disabled-border-color: #10aad4;
}
.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #364a63;
  --bs-btn-border-color: #364a63;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2e3f54;
  --bs-btn-hover-border-color: #2b3b4f;
  --bs-btn-focus-shadow-rgb: 84, 101, 122;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2b3b4f;
  --bs-btn-active-border-color: #29384a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #364a63;
  --bs-btn-disabled-border-color: #364a63;
}
.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0fca7a;
  --bs-btn-border-color: #0fca7a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0dac68;
  --bs-btn-hover-border-color: #0ca262;
  --bs-btn-focus-shadow-rgb: 51, 210, 142;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0ca262;
  --bs-btn-active-border-color: #0b985c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0fca7a;
  --bs-btn-disabled-border-color: #0fca7a;
}
.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #058efc;
  --bs-btn-border-color: #058efc;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0479d6;
  --bs-btn-hover-border-color: #0472ca;
  --bs-btn-focus-shadow-rgb: 43, 159, 252;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0472ca;
  --bs-btn-active-border-color: #046bbd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #058efc;
  --bs-btn-disabled-border-color: #058efc;
}
.btn-warning {
  --bs-btn-color: #fff;
  --bs-btn-bg: #fd9722;
  --bs-btn-border-color: #fd9722;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d7801d;
  --bs-btn-hover-border-color: #ca791b;
  --bs-btn-focus-shadow-rgb: 253, 167, 67;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ca791b;
  --bs-btn-active-border-color: #be711a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #fd9722;
  --bs-btn-disabled-border-color: #fd9722;
}
.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #f2426e;
  --bs-btn-border-color: #f2426e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ce385e;
  --bs-btn-hover-border-color: #c23558;
  --bs-btn-focus-shadow-rgb: 244, 94, 132;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c23558;
  --bs-btn-active-border-color: #b63253;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #f2426e;
  --bs-btn-disabled-border-color: #f2426e;
}
.btn-light,
.dual-listbox .dual-listbox__button {
  --bs-btn-color: #000;
  --bs-btn-bg: #e5e9f2;
  --bs-btn-border-color: #e5e9f2;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #c3c6ce;
  --bs-btn-hover-border-color: #b7bac2;
  --bs-btn-focus-shadow-rgb: 195, 198, 206;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b7bac2;
  --bs-btn-active-border-color: #acafb6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #e5e9f2;
  --bs-btn-disabled-border-color: #e5e9f2;
}
.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1f2b3a;
  --bs-btn-border-color: #1f2b3a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #414b58;
  --bs-btn-hover-border-color: #35404e;
  --bs-btn-focus-shadow-rgb: 65, 75, 88;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4c5561;
  --bs-btn-active-border-color: #35404e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1f2b3a;
  --bs-btn-disabled-border-color: #1f2b3a;
}
.btn-gray {
  --bs-btn-color: #fff;
  --bs-btn-bg: #8091a7;
  --bs-btn-border-color: #8091a7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6d7b8e;
  --bs-btn-hover-border-color: #667486;
  --bs-btn-focus-shadow-rgb: 147, 162, 180;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #667486;
  --bs-btn-active-border-color: #606d7d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #8091a7;
  --bs-btn-disabled-border-color: #8091a7;
}
.btn-lighter {
  --bs-btn-color: #000;
  --bs-btn-bg: #f5f6fa;
  --bs-btn-border-color: #f5f6fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f7f7fb;
  --bs-btn-hover-border-color: #f6f7fb;
  --bs-btn-focus-shadow-rgb: 208, 209, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f7f8fb;
  --bs-btn-active-border-color: #f6f7fb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f5f6fa;
  --bs-btn-disabled-border-color: #f5f6fa;
}
.btn-outline-primary {
  --bs-btn-color: #11c8fc;
  --bs-btn-border-color: #11c8fc;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #11c8fc;
  --bs-btn-hover-border-color: #11c8fc;
  --bs-btn-focus-shadow-rgb: 115, 58, 234;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #11c8fc;
  --bs-btn-active-border-color: #11c8fc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #11c8fc;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #11c8fc;
  --bs-gradient: none;
}
.btn-outline-secondary {
  --bs-btn-color: #364a63;
  --bs-btn-border-color: #364a63;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #364a63;
  --bs-btn-hover-border-color: #364a63;
  --bs-btn-focus-shadow-rgb: 54, 74, 99;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #364a63;
  --bs-btn-active-border-color: #364a63;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #364a63;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #364a63;
  --bs-gradient: none;
}
.btn-outline-success {
  --bs-btn-color: #0fca7a;
  --bs-btn-border-color: #0fca7a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0fca7a;
  --bs-btn-hover-border-color: #0fca7a;
  --bs-btn-focus-shadow-rgb: 15, 202, 122;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0fca7a;
  --bs-btn-active-border-color: #0fca7a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #0fca7a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0fca7a;
  --bs-gradient: none;
}
.btn-outline-info {
  --bs-btn-color: #058efc;
  --bs-btn-border-color: #058efc;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #058efc;
  --bs-btn-hover-border-color: #058efc;
  --bs-btn-focus-shadow-rgb: 5, 142, 252;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #058efc;
  --bs-btn-active-border-color: #058efc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #058efc;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #058efc;
  --bs-gradient: none;
}
.btn-outline-warning {
  --bs-btn-color: #fd9722;
  --bs-btn-border-color: #fd9722;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #fd9722;
  --bs-btn-hover-border-color: #fd9722;
  --bs-btn-focus-shadow-rgb: 253, 151, 34;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fd9722;
  --bs-btn-active-border-color: #fd9722;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #fd9722;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fd9722;
  --bs-gradient: none;
}
.btn-outline-danger {
  --bs-btn-color: #f2426e;
  --bs-btn-border-color: #f2426e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f2426e;
  --bs-btn-hover-border-color: #f2426e;
  --bs-btn-focus-shadow-rgb: 242, 66, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #f2426e;
  --bs-btn-active-border-color: #f2426e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #f2426e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f2426e;
  --bs-gradient: none;
}
.btn-outline-light,
.dt-buttons .btn-secondary {
  --bs-btn-color: #e5e9f2;
  --bs-btn-border-color: #e5e9f2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e5e9f2;
  --bs-btn-hover-border-color: #e5e9f2;
  --bs-btn-focus-shadow-rgb: 229, 233, 242;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e5e9f2;
  --bs-btn-active-border-color: #e5e9f2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #e5e9f2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e5e9f2;
  --bs-gradient: none;
}
.btn-outline-dark {
  --bs-btn-color: #1f2b3a;
  --bs-btn-border-color: #1f2b3a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1f2b3a;
  --bs-btn-hover-border-color: #1f2b3a;
  --bs-btn-focus-shadow-rgb: 31, 43, 58;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1f2b3a;
  --bs-btn-active-border-color: #1f2b3a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #1f2b3a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1f2b3a;
  --bs-gradient: none;
}
.btn-outline-gray {
  --bs-btn-color: #8091a7;
  --bs-btn-border-color: #8091a7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #8091a7;
  --bs-btn-hover-border-color: #8091a7;
  --bs-btn-focus-shadow-rgb: 128, 145, 167;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #8091a7;
  --bs-btn-active-border-color: #8091a7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #8091a7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #8091a7;
  --bs-gradient: none;
}
.btn-outline-lighter {
  --bs-btn-color: #f5f6fa;
  --bs-btn-border-color: #f5f6fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f5f6fa;
  --bs-btn-hover-border-color: #f5f6fa;
  --bs-btn-focus-shadow-rgb: 245, 246, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f5f6fa;
  --bs-btn-active-border-color: #f5f6fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(16, 25, 36, 0.125);
  --bs-btn-disabled-color: #f5f6fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f5f6fa;
  --bs-gradient: none;
}
.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #f5f6fa;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 159, 121, 241;
  text-decoration: none;
}
.btn-link:hover,
.btn-link:focus-visible {
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}
.btn-lg,
.btn-group-lg > .btn,
.actions ul li .btn-group-lg > a,
.dual-listbox .btn-group-lg > .dual-listbox__button {
  --bs-btn-padding-y: 0.6875rem;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-font-size: 0.9375rem;
  --bs-btn-border-radius: 5px;
}
.btn-sm,
.btn-group-sm > .btn,
.actions ul li .btn-group-sm > a,
.dual-listbox .btn-group-sm > .dual-listbox__button {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-font-size: 0.75rem;
  --bs-btn-border-radius: 3px;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}
.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid rgba(0, 0, 0, 0);
  border-bottom: 0;
  border-left: 0.3em solid rgba(0, 0, 0, 0);
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 180px;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.8125rem;
  --bs-dropdown-color: #526484;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: #e5e9f2;
  --bs-dropdown-border-radius: 4px;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(4px - 1px);
  --bs-dropdown-divider-bg: #e5e9f2;
  --bs-dropdown-divider-margin-y: 12px;
  --bs-dropdown-box-shadow: 0 3px 12px 1px rgba(23, 12, 47, 0.15);
  --bs-dropdown-link-color: #364a63;
  --bs-dropdown-link-hover-color: #11c8fc;
  --bs-dropdown-link-hover-bg: #ebeef2;
  --bs-dropdown-link-active-color: #11c8fc;
  --bs-dropdown-link-active-bg: #dbdfea;
  --bs-dropdown-link-disabled-color: #ebeef2;
  --bs-dropdown-item-padding-x: 14px;
  --bs-dropdown-item-padding-y: 8px;
  --bs-dropdown-header-color: #8091a7;
  --bs-dropdown-header-padding-x: 14px;
  --bs-dropdown-header-padding-y: 0;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}
.dropdown-menu > .dropdown-item:first-child,
.dropdown-menu > li:first-child .dropdown-item {
  border-top-left-radius: var(--bs-dropdown-inner-border-radius);
  border-top-right-radius: var(--bs-dropdown-inner-border-radius);
}
.dropdown-menu > .dropdown-item:last-child,
.dropdown-menu > li:last-child .dropdown-item {
  border-bottom-right-radius: var(--bs-dropdown-inner-border-radius);
  border-bottom-left-radius: var(--bs-dropdown-inner-border-radius);
}
.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}
.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1540px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid rgba(0, 0, 0, 0);
  border-bottom: 0.3em solid;
  border-left: 0.3em solid rgba(0, 0, 0, 0);
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid rgba(0, 0, 0, 0);
  border-right: 0;
  border-bottom: 0.3em solid rgba(0, 0, 0, 0);
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}
.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid rgba(0, 0, 0, 0);
  border-right: 0.3em solid;
  border-bottom: 0.3em solid rgba(0, 0, 0, 0);
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y)
    var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.75rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}
.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: #e5e9f2;
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: #e5e9f2;
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #11c8fc;
  --bs-dropdown-link-active-bg: #dbdfea;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.actions ul li .btn-group > a,
.dual-listbox .btn-group > .dual-listbox__button,
.btn-group-vertical > .btn,
.actions ul li .btn-group-vertical > a,
.dual-listbox .btn-group-vertical > .dual-listbox__button {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.actions ul li .btn-group > .btn-check:checked + a,
.dual-listbox .btn-group > .btn-check:checked + .dual-listbox__button,
.btn-group > .btn-check:focus + .btn,
.actions ul li .btn-group > .btn-check:focus + a,
.dual-listbox .btn-group > .btn-check:focus + .dual-listbox__button,
.btn-group > .btn:hover,
.actions ul li .btn-group > a:hover,
.dual-listbox .btn-group > .dual-listbox__button:hover,
.btn-group > .btn:focus,
.actions ul li .btn-group > a:focus,
.dual-listbox .btn-group > .dual-listbox__button:focus,
.btn-group > .btn:active,
.actions ul li .btn-group > a:active,
.dual-listbox .btn-group > .dual-listbox__button:active,
.btn-group > .btn.active,
.actions ul li .btn-group > a.active,
.dual-listbox .btn-group > .active.dual-listbox__button,
.btn-group-vertical > .btn-check:checked + .btn,
.actions ul li .btn-group-vertical > .btn-check:checked + a,
.dual-listbox .btn-group-vertical > .btn-check:checked + .dual-listbox__button,
.btn-group-vertical > .btn-check:focus + .btn,
.actions ul li .btn-group-vertical > .btn-check:focus + a,
.dual-listbox .btn-group-vertical > .btn-check:focus + .dual-listbox__button,
.btn-group-vertical > .btn:hover,
.actions ul li .btn-group-vertical > a:hover,
.dual-listbox .btn-group-vertical > .dual-listbox__button:hover,
.btn-group-vertical > .btn:focus,
.actions ul li .btn-group-vertical > a:focus,
.dual-listbox .btn-group-vertical > .dual-listbox__button:focus,
.btn-group-vertical > .btn:active,
.actions ul li .btn-group-vertical > a:active,
.dual-listbox .btn-group-vertical > .dual-listbox__button:active,
.btn-group-vertical > .btn.active,
.actions ul li .btn-group-vertical > a.active,
.dual-listbox .btn-group-vertical > .active.dual-listbox__button {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group {
  border-radius: 4px;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.actions ul li .btn-group > :not(.btn-check:first-child) + a,
.dual-listbox .btn-group > :not(.btn-check:first-child) + .dual-listbox__button,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(1px * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.actions ul li .btn-group > a:not(:last-child):not(.dropdown-toggle),
.dual-listbox
  .btn-group
  > .dual-listbox__button:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.actions ul li .btn-group > a.dropdown-toggle-split:first-child,
.dual-listbox
  .btn-group
  > .dropdown-toggle-split.dual-listbox__button:first-child,
.btn-group > .btn-group:not(:last-child) > .btn,
.actions ul li .btn-group > .btn-group:not(:last-child) > a,
.dual-listbox .btn-group > .btn-group:not(:last-child) > .dual-listbox__button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n + 3),
.actions ul li .btn-group > a:nth-child(n + 3),
.dual-listbox .btn-group > .dual-listbox__button:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.actions ul li .btn-group > :not(.btn-check) + a,
.dual-listbox .btn-group > :not(.btn-check) + .dual-listbox__button,
.btn-group > .btn-group:not(:first-child) > .btn,
.actions ul li .btn-group > .btn-group:not(:first-child) > a,
.dual-listbox
  .btn-group
  > .btn-group:not(:first-child)
  > .dual-listbox__button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.84375rem;
  padding-left: 0.84375rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split,
.actions ul li .btn-group-sm > a + .dropdown-toggle-split,
.dual-listbox .btn-group-sm > .dual-listbox__button + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split,
.actions ul li .btn-group-lg > a + .dropdown-toggle-split,
.dual-listbox .btn-group-lg > .dual-listbox__button + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.actions ul li .btn-group-vertical > a,
.dual-listbox .btn-group-vertical > .dual-listbox__button,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.actions ul li .btn-group-vertical > a:not(:first-child),
.dual-listbox .btn-group-vertical > .dual-listbox__button:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(1px * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.actions ul li .btn-group-vertical > a:not(:last-child):not(.dropdown-toggle),
.dual-listbox
  .btn-group-vertical
  > .dual-listbox__button:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.actions ul li .btn-group-vertical > .btn-group:not(:last-child) > a,
.dual-listbox
  .btn-group-vertical
  > .btn-group:not(:last-child)
  > .dual-listbox__button {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.actions ul li .btn-group-vertical > a ~ .btn,
.actions ul li .btn-group-vertical > .btn ~ a,
.actions ul li .btn-group-vertical > a ~ a,
.dual-listbox .btn-group-vertical > .dual-listbox__button ~ .btn,
.dual-listbox .actions ul li .btn-group-vertical > .dual-listbox__button ~ a,
.actions ul li .dual-listbox .btn-group-vertical > .dual-listbox__button ~ a,
.dual-listbox .btn-group-vertical > .btn ~ .dual-listbox__button,
.dual-listbox .actions ul li .btn-group-vertical > a ~ .dual-listbox__button,
.actions ul li .dual-listbox .btn-group-vertical > a ~ .dual-listbox__button,
.dual-listbox
  .btn-group-vertical
  > .dual-listbox__button
  ~ .dual-listbox__button,
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.actions ul li .btn-group-vertical > .btn-group:not(:first-child) > a,
.dual-listbox
  .btn-group-vertical
  > .btn-group:not(:first-child)
  > .dual-listbox__button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover,
.nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(85, 155, 251, 0.25);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg)
    var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color)
    var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid
    var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid rgba(0, 0, 0, 0);
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #559bfb;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}
.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid rgba(0, 0, 0, 0);
}
.nav-underline .nav-link:hover,
.nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}
.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2882, 100, 132, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: 4px;
  --bs-navbar-toggler-focus-width: 0.2rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}
.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}
.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: rgba(0, 0, 0, 0);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1540px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}
.navbar-dark,
.navbar[data-bs-theme="dark"] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.card {
  --bs-card-spacer-y: 0.75rem;
  --bs-card-spacer-x: 1.25rem;
  --bs-card-title-spacer-y: 0.75rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: 0;
  --bs-card-border-color: rgba(0, 0, 0, 0.125);
  --bs-card-border-radius: 4px;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: 3px;
  --bs-card-cap-padding-y: 0.75rem;
  --bs-card-cap-padding-x: 1.25rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.07);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1.25rem;
  --bs-card-group-margin: 14px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}
.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}
.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}
.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius)
    var(--bs-card-inner-border-radius) 0 0;
}
.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius)
    var(--bs-card-inner-border-radius);
}
.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}
.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}
.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}
.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: #dbdfea;
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 4px;
  --bs-accordion-inner-border-radius: 3px;
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: #fff;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23526484'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23223e64'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #11c8fc;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 3px rgba(115, 58, 234, 0.1);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.875rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}
.accordion-header {
  margin-bottom: 0;
}
.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid
    var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}
.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}
[data-bs-theme="dark"] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2399c3fd'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2399c3fd'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0.25rem;
  --bs-breadcrumb-margin-bottom: 0;
  --bs-breadcrumb-font-size: 0.6875rem;
  --bs-breadcrumb-bg: transparent;
  --bs-breadcrumb-border-radius: 4px;
  --bs-breadcrumb-divider-color: #8091a7;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #b7c2d0;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(
    --bs-breadcrumb-divider
  
  ); /* rtl: var(--bs-breadcrumb-divider, "/") */
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}
.pagination {
  --bs-pagination-padding-x: 0.625rem;
  --bs-pagination-padding-y: 0.5625rem;
  --bs-pagination-font-size: 0.8125rem;
  --bs-pagination-color: #526484;
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #e5e9f2;
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: #6d33e9;
  --bs-pagination-hover-bg: #ebeef2;
  --bs-pagination-hover-border-color: #e5e9f2;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: none;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #11c8fc;
  --bs-pagination-active-border-color: #11c8fc;
  --bs-pagination-disabled-color: #dbdfea;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #e5e9f2;
  display: flex;
  padding-left: 0;
  list-style: none;
}
.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid
    var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active,
.active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled,
.disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}
.page-item:not(:first-child) .page-link {
  margin-left: calc(1px * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}
.pagination-lg {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.5625rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}
.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.4375rem;
  --bs-pagination-font-size: 0.75rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}
.badge {
  --bs-badge-padding-x: 0.375rem;
  --bs-badge-padding-y: 0;
  --bs-badge-font-size: 0.675rem;
  --bs-badge-font-weight: 500;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 3px;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}
.btn .badge,
.actions ul li a .badge,
.dual-listbox .dual-listbox__button .badge {
  position: relative;
  top: -1px;
}
.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1.25rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 4px;
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}
.alert-dismissible {
  padding-right: 3.75rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1.25rem;
}
.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}
.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}
.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}
.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}
.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}
.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}
.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}
.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}
.alert-gray {
  --bs-alert-color: var(--bs-gray-text-emphasis);
  --bs-alert-bg: var(--bs-gray-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-border-subtle);
  --bs-alert-link-color: var(--bs-gray-text-emphasis);
}
.alert-lighter {
  --bs-alert-color: var(--bs-lighter-text-emphasis);
  --bs-alert-bg: var(--bs-lighter-bg-subtle);
  --bs-alert-border-color: var(--bs-lighter-border-subtle);
  --bs-alert-link-color: var(--bs-lighter-text-emphasis);
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: 0.5rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 0.5rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #f5f6fa;
  --bs-progress-border-radius: 2px;
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #11c8fc;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}
.progress-stacked > .progress {
  overflow: visible;
}
.progress-stacked > .progress > .progress-bar {
  width: 100%;
}
.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}
.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1.25rem;
  --bs-list-group-item-padding-y: 0.75rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #559bfb;
  --bs-list-group-active-border-color: #559bfb;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}
.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}
.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}
.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y)
    var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid
    var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1540px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}
.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}
.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}
.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}
.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}
.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}
.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}
.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}
.list-group-item-gray {
  --bs-list-group-color: var(--bs-gray-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-text-emphasis);
}
.list-group-item-lighter {
  --bs-list-group-color: var(--bs-lighter-text-emphasis);
  --bs-list-group-bg: var(--bs-lighter-bg-subtle);
  --bs-list-group-border-color: var(--bs-lighter-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-lighter-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-lighter-border-subtle);
  --bs-list-group-active-color: var(--bs-lighter-bg-subtle);
  --bs-list-group-active-bg: var(--bs-lighter-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-lighter-text-emphasis);
}
.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(85, 155, 251, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: rgba(0, 0, 0, 0) var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 4px;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}
.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}
[data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}
.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.25rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: transparent;
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}
.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}
.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid
    var(--bs-toast-header-border-color);
  border-top-left-radius: calc(
    var(--bs-toast-border-radius) - var(--bs-toast-border-width)
  );
  border-top-right-radius: calc(
    var(--bs-toast-border-radius) - var(--bs-toast-border-width)
  );
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}
.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}
.modal {
  --bs-modal-zindex: 1051;
  --bs-modal-width: 520px;
  --bs-modal-padding: 1.25rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: rgba(0, 0, 0, 0);
  --bs-modal-border-width: 0;
  --bs-modal-border-radius: 5px;
  --bs-modal-box-shadow: 0px 0px 1px 0px rgba(82, 100, 132, 0.2),
    0px 8px 15.52px 0.48px rgba(28, 43, 70, 0.15);
  --bs-modal-inner-border-radius: calc(
    var(--bs-border-radius-lg) - (var(--bs-border-width))
  );
  --bs-modal-header-padding-x: 1.25rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1.25rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -30px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none !important;
}
.modal.modal-static .modal-dialog {
  transform: scale(0.95);
}
.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}
.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #364a63;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid
    var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5)
    calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y))
    calc(-0.5 * var(--bs-modal-header-padding-x))
    calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}
.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid
    var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}
@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0px 0px 1px 0px rgba(82, 100, 132, 0.2),
      0px 8px 15.52px 0.48px rgba(28, 43, 70, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 360px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 720px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 980px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1539.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.75rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.8125rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: #1f2b3a;
  --bs-tooltip-border-radius: 3px;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.65;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: rgba(0, 0, 0, 0);
  border-style: solid;
}
.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height)
    calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5)
    var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5)
    var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0
    calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}
.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: rgba(0, 0, 0, 0.2);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(
    var(--bs-border-radius-lg) - var(--bs-border-width)
  );
  --bs-popover-box-shadow: 0 3px 12px 1px rgba(43, 55, 72, 0.15);
  --bs-popover-header-padding-x: 0.75rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: #f7f7f7;
  --bs-popover-body-padding-x: 0.75rem;
  --bs-popover-body-padding-y: 0.5rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.65;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 0;
}
.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before,
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height)
    calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}
.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before,
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5)
    var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}
.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5)
    var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid
    var(--bs-popover-header-bg);
}
.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(
    -1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)
  );
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before,
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0
    calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid
    var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}
.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}
[data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
[data-bs-theme="dark"] .carousel .carousel-control-next-icon,
[data-bs-theme="dark"].carousel .carousel-control-prev-icon,
[data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
[data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme="dark"] .carousel .carousel-caption,
[data-bs-theme="dark"].carousel .carousel-caption {
  color: #000;
}
.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite
    var(--bs-spinner-animation-name);
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg); /* rtl:ignore */
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: rgba(0, 0, 0, 0);
}
.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}
.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas,
.offcanvas-xxl,
.offcanvas-xl,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1.25rem;
  --bs-offcanvas-padding-y: 1.25rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(43, 55, 72, 0.15);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}
@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing,
  .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing,
  .offcanvas-sm.hiding,
  .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing,
  .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing,
  .offcanvas-md.hiding,
  .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing,
  .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing,
  .offcanvas-lg.hiding,
  .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing,
  .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing,
  .offcanvas-xl.hiding,
  .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
@media (max-width: 1539.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1539.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1539.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing,
  .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing,
  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1540px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing,
.offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing,
.offcanvas.hiding,
.offcanvas.show {
  visibility: visible;
}
.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}
.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5)
    calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}
.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}
.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}
.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before,
.actions ul li a.placeholder::before,
.dual-listbox .placeholder.dual-listbox__button::before {
  display: inline-block;
  content: "";
}
.placeholder-xs {
  min-height: 0.6em;
}
.placeholder-sm {
  min-height: 0.8em;
}
.placeholder-lg {
  min-height: 1.2em;
}
.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}
@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}
@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(115, 58, 234, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(54, 74, 99, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-success {
  color: #fff !important;
  background-color: RGBA(15, 202, 122, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-info {
  color: #fff !important;
  background-color: RGBA(5, 142, 252, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-warning {
  color: #fff !important;
  background-color: RGBA(253, 151, 34, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(242, 66, 110, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-light {
  color: #000 !important;
  background-color: RGBA(229, 233, 242, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(31, 43, 58, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-gray {
  color: #fff !important;
  background-color: RGBA(128, 145, 167, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-lighter {
  color: #000 !important;
  background-color: RGBA(245, 246, 250, var(--bs-bg-opacity, 1)) !important;
}
.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-primary-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-primary:hover,
.link-primary:focus {
  
  color: RGBA(19, 95, 238, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    92,
    46,
    187,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-secondary-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-secondary:hover,
.link-secondary:focus {
  color: RGBA(43, 59, 79, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    43,
    59,
    79,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-success-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-success:hover,
.link-success:focus {
  color: RGBA(12, 162, 98, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    12,
    162,
    98,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-info-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-info:hover,
.link-info:focus {
  color: RGBA(4, 114, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    4,
    114,
    202,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-warning-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-warning:hover,
.link-warning:focus {
  color: RGBA(202, 121, 27, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    202,
    121,
    27,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-danger-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-danger:hover,
.link-danger:focus {
  color: RGBA(194, 53, 88, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    194,
    53,
    88,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-light-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-light:hover,
.link-light:focus {
  color: RGBA(234, 237, 245, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    234,
    237,
    245,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-dark-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-dark:hover,
.link-dark:focus {
  color: RGBA(25, 34, 46, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    25,
    34,
    46,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-gray {
  color: RGBA(var(--bs-gray-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-gray-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-gray:hover,
.link-gray:focus {
  color: RGBA(102, 116, 134, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    102,
    116,
    134,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-lighter {
  color: RGBA(var(--bs-lighter-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    var(--bs-lighter-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-lighter:hover,
.link-lighter:focus {
  color: RGBA(247, 248, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(
    247,
    248,
    251,
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-body-emphasis {
  color: RGBA(
    var(--bs-emphasis-color-rgb),
    var(--bs-link-opacity, 1)
  ) !important;
  text-decoration-color: RGBA(
    var(--bs-emphasis-color-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-body-emphasis:hover,
.link-body-emphasis:focus {
  color: RGBA(
    var(--bs-emphasis-color-rgb),
    var(--bs-link-opacity, 0.75)
  ) !important;
  text-decoration-color: RGBA(
    var(--bs-emphasis-color-rgb),
    var(--bs-link-underline-opacity, 0.75)
  ) !important;
}
.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0)
    var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width)
    var(--bs-focus-ring-color);
}
.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(
    var(--bs-link-color-rgb),
    var(--bs-link-opacity, 0.5)
  );
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}
.icon-link-hover:hover > .bi,
.icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}
.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}
.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}
.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}
.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}
.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}
@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1540px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}
.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.float-start {
  float: left !important;
}
.float-end {
  float: right !important;
}
.float-none {
  float: none !important;
}
.object-fit-contain {
  object-fit: contain !important;
}
.object-fit-cover {
  object-fit: cover !important;
}
.object-fit-fill {
  object-fit: fill !important;
}
.object-fit-scale {
  object-fit: scale-down !important;
}
.object-fit-none {
  object-fit: none !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-25 {
  opacity: 0.25 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.opacity-75 {
  opacity: 0.75 !important;
}
.opacity-100 {
  opacity: 1 !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-scroll {
  overflow: scroll !important;
}
.overflow-x-auto {
  overflow-x: auto !important;
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}
.overflow-x-visible {
  overflow-x: visible !important;
}
.overflow-x-scroll {
  overflow-x: scroll !important;
}
.overflow-y-auto {
  overflow-y: auto !important;
}
.overflow-y-hidden {
  overflow-y: hidden !important;
}
.overflow-y-visible {
  overflow-y: visible !important;
}
.overflow-y-scroll {
  overflow-y: scroll !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-grid {
  display: grid !important;
}
.d-inline-grid {
  display: inline-grid !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex,
.stretch,
.center,
.between-center,
.between-start,
.justify-around,
.justify-between,
.justify-center,
.justify-end,
.justify-start,
.align-center,
.align-end,
.align-start {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-none {
  display: none !important;
}
.shadow {
  box-shadow: 0 3px 12px 1px rgba(43, 55, 72, 0.15) !important;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(43, 55, 72, 0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(43, 55, 72, 0.25) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.focus-ring-primary {
  --bs-focus-ring-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-focus-ring-opacity)
  );
}
.focus-ring-secondary {
  --bs-focus-ring-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-focus-ring-opacity)
  );
}
.focus-ring-success {
  --bs-focus-ring-color: rgba(
    var(--bs-success-rgb),
    var(--bs-focus-ring-opacity)
  );
}
.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}
.focus-ring-warning {
  --bs-focus-ring-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-focus-ring-opacity)
  );
}
.focus-ring-danger {
  --bs-focus-ring-color: rgba(
    var(--bs-danger-rgb),
    var(--bs-focus-ring-opacity)
  );
}
.focus-ring-light {
  --bs-focus-ring-color: rgba(
    var(--bs-light-rgb),
    var(--bs-focus-ring-opacity)
  );
}
.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}
.focus-ring-gray {
  --bs-focus-ring-color: rgba(var(--bs-gray-rgb), var(--bs-focus-ring-opacity));
}
.focus-ring-lighter {
  --bs-focus-ring-color: rgba(
    var(--bs-lighter-rgb),
    var(--bs-focus-ring-opacity)
  );
}
.position-static {
  position: static !important;
}
.position-relative,
.pos-rel {
  position: relative !important;
}
.position-absolute,
.pos-abs {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
.top-0 {
  top: 0 !important;
}
.top-50 {
  top: 50% !important;
}
.top-100 {
  top: 100% !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.bottom-50 {
  bottom: 50% !important;
}
.bottom-100 {
  bottom: 100% !important;
}
.start-0 {
  left: 0 !important;
}
.start-50 {
  left: 50% !important;
}
.start-100 {
  left: 100% !important;
}
.end-0 {
  right: 0 !important;
}
.end-50 {
  right: 50% !important;
}
.end-100 {
  right: 100% !important;
}
.translate-middle {
  transform: translate(-50%, -50%) !important;
}
.translate-middle-x {
  transform: translateX(-50%) !important;
}
.translate-middle-y {
  transform: translateY(-50%) !important;
}
.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.border-0,
.no-bdr {
  border: 0 !important;
}
.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-top-0,
.no-bdr-y,
.no-bdr-t {
  border-top: 0 !important;
}
.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-end-0,
.no-bdr-x,
.no-bdr-r {
  border-right: 0 !important;
}
.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-bottom-0,
.no-bdr-y,
.no-bdr-b {
  border-bottom: 0 !important;
}
.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-start-0,
.no-bdr-x,
.no-bdr-l {
  border-left: 0 !important;
}
.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-success-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}
.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}
.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}
.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}
.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}
.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}
.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}
.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}
.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}
.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}
.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}
.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}
.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}
.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}
.border-1 {
  border-width: 1px !important;
}
.border-2 {
  border-width: 2px !important;
}
.border-3 {
  border-width: 3px !important;
}
.border-4 {
  border-width: 4px !important;
}
.border-5 {
  border-width: 5px !important;
}
.border-opacity-10 {
  --bs-border-opacity: 0.1;
}
.border-opacity-25 {
  --bs-border-opacity: 0.25;
}
.border-opacity-50 {
  --bs-border-opacity: 0.5;
}
.border-opacity-75 {
  --bs-border-opacity: 0.75;
}
.border-opacity-100 {
  --bs-border-opacity: 1;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.vw-100 {
  width: 100vw !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mh-100 {
  max-height: 100% !important;
}
.vh-100 {
  height: 100vh !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.justify-content-start,
.justify-start {
  justify-content: flex-start !important;
}
.justify-content-end,
.justify-end {
  justify-content: flex-end !important;
}
.justify-content-center,
.center,
.justify-center {
  justify-content: center !important;
}
.justify-content-between,
.between-center,
.between-start,
.justify-between {
  justify-content: space-between !important;
}
.justify-content-around,
.justify-around {
  justify-content: space-around !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.align-items-start,
.between-start,
.align-start {
  align-items: flex-start !important;
}
.align-items-end,
.align-end {
  align-items: flex-end !important;
}
.align-items-center,
.center,
.between-center,
.align-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch,
.stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
.order-first {
  order: -1 !important;
}
.order-0 {
  order: 0 !important;
}
.order-1 {
  order: 1 !important;
}
.order-2 {
  order: 2 !important;
}
.order-3 {
  order: 3 !important;
}
.order-4 {
  order: 4 !important;
}
.order-5 {
  order: 5 !important;
}
.order-last {
  order: 6 !important;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.375rem !important;
}
.m-2 {
  margin: 0.75rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.m-5 {
  margin: 2.75rem !important;
}
.m-gs {
  margin: 28px !important;
}
.m-auto {
  margin: auto !important;
}
.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.mx-1 {
  margin-right: 0.375rem !important;
  margin-left: 0.375rem !important;
}
.mx-2 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}
.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.mx-5 {
  margin-right: 2.75rem !important;
  margin-left: 2.75rem !important;
}
.mx-gs {
  margin-right: 28px !important;
  margin-left: 28px !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-1 {
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
}
.my-2 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-5 {
  margin-top: 2.75rem !important;
  margin-bottom: 2.75rem !important;
}
.my-gs {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.375rem !important;
}
.mt-2 {
  margin-top: 0.75rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mt-5 {
  margin-top: 2.75rem !important;
}
.mt-gs {
  margin-top: 28px !important;
}
.mt-auto {
  margin-top: auto !important;
}
.me-0 {
  margin-right: 0 !important;
}
.me-1 {
  margin-right: 0.375rem !important;
}
.me-2 {
  margin-right: 0.75rem !important;
}
.me-3 {
  margin-right: 1rem !important;
}
.me-4 {
  margin-right: 1.5rem !important;
}
.me-5 {
  margin-right: 2.75rem !important;
}
.me-gs {
  margin-right: 28px !important;
}
.me-auto {
  margin-right: auto !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.375rem !important;
}
.mb-2 {
  margin-bottom: 0.75rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mb-5 {
  margin-bottom: 2.75rem !important;
}
.mb-gs {
  margin-bottom: 28px !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ms-0 {
  margin-left: 0 !important;
}
.ms-1 {
  margin-left: 0.375rem !important;
}
.ms-2 {
  margin-left: 0.75rem !important;
}
.ms-3 {
  margin-left: 1rem !important;
}
.ms-4 {
  margin-left: 1.5rem !important;
}
.ms-5 {
  margin-left: 2.75rem !important;
}
.ms-gs {
  margin-left: 28px !important;
}
.ms-auto {
  margin-left: auto !important;
}
.m-n1 {
  margin: -0.375rem !important;
}
.m-n2 {
  margin: -0.75rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.m-n5 {
  margin: -2.75rem !important;
}
.m-ngs {
  margin: -28px !important;
}
.mx-n1 {
  margin-right: -0.375rem !important;
  margin-left: -0.375rem !important;
}
.mx-n2 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}
.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}
.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}
.mx-n5 {
  margin-right: -2.75rem !important;
  margin-left: -2.75rem !important;
}
.mx-ngs {
  margin-right: -28px !important;
  margin-left: -28px !important;
}
.my-n1 {
  margin-top: -0.375rem !important;
  margin-bottom: -0.375rem !important;
}
.my-n2 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}
.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}
.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}
.my-n5 {
  margin-top: -2.75rem !important;
  margin-bottom: -2.75rem !important;
}
.my-ngs {
  margin-top: -28px !important;
  margin-bottom: -28px !important;
}
.mt-n1 {
  margin-top: -0.375rem !important;
}
.mt-n2 {
  margin-top: -0.75rem !important;
}
.mt-n3 {
  margin-top: -1rem !important;
}
.mt-n4 {
  margin-top: -1.5rem !important;
}
.mt-n5 {
  margin-top: -2.75rem !important;
}
.mt-ngs {
  margin-top: -28px !important;
}
.me-n1 {
  margin-right: -0.375rem !important;
}
.me-n2 {
  margin-right: -0.75rem !important;
}
.me-n3 {
  margin-right: -1rem !important;
}
.me-n4 {
  margin-right: -1.5rem !important;
}
.me-n5 {
  margin-right: -2.75rem !important;
}
.me-ngs {
  margin-right: -28px !important;
}
.mb-n1 {
  margin-bottom: -0.375rem !important;
}
.mb-n2 {
  margin-bottom: -0.75rem !important;
}
.mb-n3 {
  margin-bottom: -1rem !important;
}
.mb-n4 {
  margin-bottom: -1.5rem !important;
}
.mb-n5 {
  margin-bottom: -2.75rem !important;
}
.mb-ngs {
  margin-bottom: -28px !important;
}
.ms-n1 {
  margin-left: -0.375rem !important;
}
.ms-n2 {
  margin-left: -0.75rem !important;
}
.ms-n3 {
  margin-left: -1rem !important;
}
.ms-n4 {
  margin-left: -1.5rem !important;
}
.ms-n5 {
  margin-left: -2.75rem !important;
}
.ms-ngs {
  margin-left: -28px !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.375rem !important;
}
.p-2 {
  padding: 0.75rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.p-5 {
  padding: 2.75rem !important;
}
.p-gs {
  padding: 28px !important;
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.px-1 {
  padding-right: 0.375rem !important;
  padding-left: 0.375rem !important;
}
.px-2 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}
.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.px-5 {
  padding-right: 2.75rem !important;
  padding-left: 2.75rem !important;
}
.px-gs {
  padding-right: 28px !important;
  padding-left: 28px !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}
.py-2 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.py-5 {
  padding-top: 2.75rem !important;
  padding-bottom: 2.75rem !important;
}
.py-gs {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 0.375rem !important;
}
.pt-2 {
  padding-top: 0.75rem !important;
}
.pt-3 {
  padding-top: 1rem !important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.pt-5 {
  padding-top: 2.75rem !important;
}
.pt-gs {
  padding-top: 28px !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.pe-1 {
  padding-right: 0.375rem !important;
}
.pe-2 {
  padding-right: 0.75rem !important;
}
.pe-3 {
  padding-right: 1rem !important;
}
.pe-4 {
  padding-right: 1.5rem !important;
}
.pe-5 {
  padding-right: 2.75rem !important;
}
.pe-gs {
  padding-right: 28px !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 0.375rem !important;
}
.pb-2 {
  padding-bottom: 0.75rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.pb-5 {
  padding-bottom: 2.75rem !important;
}
.pb-gs {
  padding-bottom: 28px !important;
}
.ps-0 {
  padding-left: 0 !important;
}
.ps-1 {
  padding-left: 0.375rem !important;
}
.ps-2 {
  padding-left: 0.75rem !important;
}
.ps-3 {
  padding-left: 1rem !important;
}
.ps-4 {
  padding-left: 1.5rem !important;
}
.ps-5 {
  padding-left: 2.75rem !important;
}
.ps-gs {
  padding-left: 28px !important;
}
.gap-0 {
  gap: 0 !important;
}
.gap-1 {
  gap: 0.375rem !important;
}
.gap-2 {
  gap: 0.75rem !important;
}
.gap-3 {
  gap: 1rem !important;
}
.gap-4 {
  gap: 1.5rem !important;
}
.gap-5 {
  gap: 2.75rem !important;
}
.gap-gs {
  gap: 28px !important;
}
.row-gap-0 {
  row-gap: 0 !important;
}
.row-gap-1 {
  row-gap: 0.375rem !important;
}
.row-gap-2 {
  row-gap: 0.75rem !important;
}
.row-gap-3 {
  row-gap: 1rem !important;
}
.row-gap-4 {
  row-gap: 1.5rem !important;
}
.row-gap-5 {
  row-gap: 2.75rem !important;
}
.row-gap-gs {
  row-gap: 28px !important;
}
.column-gap-0 {
  column-gap: 0 !important;
}
.column-gap-1 {
  column-gap: 0.375rem !important;
}
.column-gap-2 {
  column-gap: 0.75rem !important;
}
.column-gap-3 {
  column-gap: 1rem !important;
}
.column-gap-4 {
  column-gap: 1.5rem !important;
}
.column-gap-5 {
  column-gap: 2.75rem !important;
}
.column-gap-gs {
  column-gap: 28px !important;
}
.font-monospace,
.ff-mono {
  font-family: var(--bs-font-monospace) !important;
}
.fs-1 {
  font-size: 2rem !important;
}
.fs-2 {
  font-size: 1.75rem !important;
}
.fs-3 {
  font-size: 1.5rem !important;
}
.fs-4 {
  font-size: 1.25rem !important;
}
.fs-5 {
  font-size: 1.15rem !important;
}
.fs-6 {
  font-size: 1rem !important;
}
.fst-italic,
.ff-italic {
  font-style: italic !important;
}
.fst-normal {
  font-style: normal !important;
}
.fw-lighter {
  font-weight: lighter !important;
}
.fw-light {
  font-weight: 300 !important;
}
.fw-normal {
  font-weight: 400 !important;
}
.fw-medium {
  font-weight: 500 !important;
}
.fw-semibold {
  font-weight: 600 !important;
}
.fw-bold {
  font-weight: 700 !important;
}
.fw-bolder {
  font-weight: bolder !important;
}
.lh-1 {
  line-height: 1 !important;
}
.lh-sm {
  line-height: 1.5 !important;
}
.lh-base {
  line-height: 1.65 !important;
}
.lh-lg {
  line-height: 1.5 !important;
}
.text-start {
  text-align: left !important;
}
.text-end {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.text-decoration-line-through {
  text-decoration: line-through !important;
}
.text-lowercase,
.lcap {
  text-transform: lowercase !important;
}
.text-uppercase,
.ucap {
  text-transform: uppercase !important;
}
.text-capitalize,
.ccap {
  text-transform: capitalize !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}
.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}
.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}
.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}
.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}
.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}
.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}
.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}
.text-gray {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-rgb), var(--bs-text-opacity)) !important;
}
.text-lighter {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-lighter-rgb), var(--bs-text-opacity)) !important;
}
.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}
.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}
.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}
.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}
.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}
.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}
.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}
.text-opacity-25 {
  --bs-text-opacity: 0.25;
}
.text-opacity-50 {
  --bs-text-opacity: 0.5;
}
.text-opacity-75 {
  --bs-text-opacity: 0.75;
}
.text-opacity-100 {
  --bs-text-opacity: 1;
}
.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}
.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}
.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}
.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}
.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}
.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}
.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}
.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}
.link-opacity-10 {
  --bs-link-opacity: 0.1;
}
.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}
.link-opacity-25 {
  --bs-link-opacity: 0.25;
}
.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}
.link-opacity-50 {
  --bs-link-opacity: 0.5;
}
.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}
.link-opacity-75 {
  --bs-link-opacity: 0.75;
}
.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}
.link-opacity-100 {
  --bs-link-opacity: 1;
}
.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}
.link-offset-1 {
  text-underline-offset: 0.125em !important;
}
.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}
.link-offset-2 {
  text-underline-offset: 0.25em !important;
}
.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}
.link-offset-3 {
  text-underline-offset: 0.375em !important;
}
.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}
.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-success-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-info-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-danger-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-light-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-dark-rgb),
    var(--bs-link-underline-opacity)
  ) !important;
}
.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--bs-link-color-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}
.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}
.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}
.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}
.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}
.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}
.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}
.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}
.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}
.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}
.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}
.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}
.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-success-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}
.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}
.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}
.bg-gray {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-rgb), var(--bs-bg-opacity)) !important;
}
.bg-lighter,
.dual-listbox .dual-listbox__item:hover {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-lighter-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}
.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}
.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-body-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: rgba(0, 0, 0, 0) !important;
}
.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-secondary-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-tertiary-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}
.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}
.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}
.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}
.bg-opacity-100 {
  --bs-bg-opacity: 1;
}
.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}
.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}
.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}
.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}
.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}
.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}
.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}
.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}
.bg-gradient {
  background-image: var(--bs-gradient) !important;
}
.user-select-all {
  user-select: all !important;
}
.user-select-auto {
  user-select: auto !important;
}
.user-select-none {
  user-select: none !important;
}
.pe-none {
  pointer-events: none !important;
}
.pe-auto {
  pointer-events: auto !important;
}
.rounded {
  border-radius: var(--bs-border-radius) !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}
.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}
.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}
.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}
.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}
.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}
.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}
.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}
.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}
.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}
.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}
.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}
.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}
.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}
.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}
.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}
.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}
.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}
.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}
.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}
.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}
.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}
.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}
.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}
.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}
.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}
.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}
.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}
.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}
.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}
.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}
.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}
.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}
.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}
.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
.z-n1 {
  z-index: -1 !important;
}
.z-0 {
  z-index: 0 !important;
}
.z-1 {
  z-index: 1 !important;
}
.z-2 {
  z-index: 2 !important;
}
.z-3 {
  z-index: 3 !important;
}
@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.375rem !important;
  }
  .m-sm-2 {
    margin: 0.75rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2.75rem !important;
  }
  .m-sm-gs {
    margin: 28px !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }
  .mx-sm-gs {
    margin-right: 28px !important;
    margin-left: 28px !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-sm-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
  .my-sm-gs {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.375rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.75rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 2.75rem !important;
  }
  .mt-sm-gs {
    margin-top: 28px !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.375rem !important;
  }
  .me-sm-2 {
    margin-right: 0.75rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 2.75rem !important;
  }
  .me-sm-gs {
    margin-right: 28px !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 2.75rem !important;
  }
  .mb-sm-gs {
    margin-bottom: 28px !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.375rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.75rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 2.75rem !important;
  }
  .ms-sm-gs {
    margin-left: 28px !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.375rem !important;
  }
  .m-sm-n2 {
    margin: -0.75rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2.75rem !important;
  }
  .m-sm-ngs {
    margin: -28px !important;
  }
  .mx-sm-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }
  .mx-sm-ngs {
    margin-right: -28px !important;
    margin-left: -28px !important;
  }
  .my-sm-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }
  .my-sm-ngs {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }
  .mt-sm-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -2.75rem !important;
  }
  .mt-sm-ngs {
    margin-top: -28px !important;
  }
  .me-sm-n1 {
    margin-right: -0.375rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.75rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -2.75rem !important;
  }
  .me-sm-ngs {
    margin-right: -28px !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -2.75rem !important;
  }
  .mb-sm-ngs {
    margin-bottom: -28px !important;
  }
  .ms-sm-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -2.75rem !important;
  }
  .ms-sm-ngs {
    margin-left: -28px !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.375rem !important;
  }
  .p-sm-2 {
    padding: 0.75rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2.75rem !important;
  }
  .p-sm-gs {
    padding: 28px !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-sm-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }
  .px-sm-gs {
    padding-right: 28px !important;
    padding-left: 28px !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-sm-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }
  .py-sm-gs {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.375rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.75rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 2.75rem !important;
  }
  .pt-sm-gs {
    padding-top: 28px !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.375rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.75rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 2.75rem !important;
  }
  .pe-sm-gs {
    padding-right: 28px !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 2.75rem !important;
  }
  .pb-sm-gs {
    padding-bottom: 28px !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.375rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.75rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 2.75rem !important;
  }
  .ps-sm-gs {
    padding-left: 28px !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.375rem !important;
  }
  .gap-sm-2 {
    gap: 0.75rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 2.75rem !important;
  }
  .gap-sm-gs {
    gap: 28px !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 2.75rem !important;
  }
  .row-gap-sm-gs {
    row-gap: 28px !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 2.75rem !important;
  }
  .column-gap-sm-gs {
    column-gap: 28px !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.375rem !important;
  }
  .m-md-2 {
    margin: 0.75rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2.75rem !important;
  }
  .m-md-gs {
    margin: 28px !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-md-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }
  .mx-md-gs {
    margin-right: 28px !important;
    margin-left: 28px !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-md-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
  .my-md-gs {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.375rem !important;
  }
  .mt-md-2 {
    margin-top: 0.75rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 2.75rem !important;
  }
  .mt-md-gs {
    margin-top: 28px !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.375rem !important;
  }
  .me-md-2 {
    margin-right: 0.75rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 2.75rem !important;
  }
  .me-md-gs {
    margin-right: 28px !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 2.75rem !important;
  }
  .mb-md-gs {
    margin-bottom: 28px !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.375rem !important;
  }
  .ms-md-2 {
    margin-left: 0.75rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 2.75rem !important;
  }
  .ms-md-gs {
    margin-left: 28px !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.375rem !important;
  }
  .m-md-n2 {
    margin: -0.75rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2.75rem !important;
  }
  .m-md-ngs {
    margin: -28px !important;
  }
  .mx-md-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }
  .mx-md-ngs {
    margin-right: -28px !important;
    margin-left: -28px !important;
  }
  .my-md-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-md-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }
  .my-md-ngs {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }
  .mt-md-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -2.75rem !important;
  }
  .mt-md-ngs {
    margin-top: -28px !important;
  }
  .me-md-n1 {
    margin-right: -0.375rem !important;
  }
  .me-md-n2 {
    margin-right: -0.75rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -2.75rem !important;
  }
  .me-md-ngs {
    margin-right: -28px !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -2.75rem !important;
  }
  .mb-md-ngs {
    margin-bottom: -28px !important;
  }
  .ms-md-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -2.75rem !important;
  }
  .ms-md-ngs {
    margin-left: -28px !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.375rem !important;
  }
  .p-md-2 {
    padding: 0.75rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2.75rem !important;
  }
  .p-md-gs {
    padding: 28px !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-md-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }
  .px-md-gs {
    padding-right: 28px !important;
    padding-left: 28px !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-md-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }
  .py-md-gs {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.375rem !important;
  }
  .pt-md-2 {
    padding-top: 0.75rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 2.75rem !important;
  }
  .pt-md-gs {
    padding-top: 28px !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.375rem !important;
  }
  .pe-md-2 {
    padding-right: 0.75rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 2.75rem !important;
  }
  .pe-md-gs {
    padding-right: 28px !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 2.75rem !important;
  }
  .pb-md-gs {
    padding-bottom: 28px !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.375rem !important;
  }
  .ps-md-2 {
    padding-left: 0.75rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 2.75rem !important;
  }
  .ps-md-gs {
    padding-left: 28px !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.375rem !important;
  }
  .gap-md-2 {
    gap: 0.75rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 2.75rem !important;
  }
  .gap-md-gs {
    gap: 28px !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 2.75rem !important;
  }
  .row-gap-md-gs {
    row-gap: 28px !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 2.75rem !important;
  }
  .column-gap-md-gs {
    column-gap: 28px !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.375rem !important;
  }
  .m-lg-2 {
    margin: 0.75rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2.75rem !important;
  }
  .m-lg-gs {
    margin: 28px !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }
  .mx-lg-gs {
    margin-right: 28px !important;
    margin-left: 28px !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-lg-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
  .my-lg-gs {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.375rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.75rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 2.75rem !important;
  }
  .mt-lg-gs {
    margin-top: 28px !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.375rem !important;
  }
  .me-lg-2 {
    margin-right: 0.75rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 2.75rem !important;
  }
  .me-lg-gs {
    margin-right: 28px !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 2.75rem !important;
  }
  .mb-lg-gs {
    margin-bottom: 28px !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.375rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.75rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 2.75rem !important;
  }
  .ms-lg-gs {
    margin-left: 28px !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.375rem !important;
  }
  .m-lg-n2 {
    margin: -0.75rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2.75rem !important;
  }
  .m-lg-ngs {
    margin: -28px !important;
  }
  .mx-lg-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }
  .mx-lg-ngs {
    margin-right: -28px !important;
    margin-left: -28px !important;
  }
  .my-lg-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }
  .my-lg-ngs {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }
  .mt-lg-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -2.75rem !important;
  }
  .mt-lg-ngs {
    margin-top: -28px !important;
  }
  .me-lg-n1 {
    margin-right: -0.375rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.75rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -2.75rem !important;
  }
  .me-lg-ngs {
    margin-right: -28px !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -2.75rem !important;
  }
  .mb-lg-ngs {
    margin-bottom: -28px !important;
  }
  .ms-lg-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -2.75rem !important;
  }
  .ms-lg-ngs {
    margin-left: -28px !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.375rem !important;
  }
  .p-lg-2 {
    padding: 0.75rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2.75rem !important;
  }
  .p-lg-gs {
    padding: 28px !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-lg-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }
  .px-lg-gs {
    padding-right: 28px !important;
    padding-left: 28px !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-lg-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }
  .py-lg-gs {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.375rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.75rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 2.75rem !important;
  }
  .pt-lg-gs {
    padding-top: 28px !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.375rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.75rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 2.75rem !important;
  }
  .pe-lg-gs {
    padding-right: 28px !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 2.75rem !important;
  }
  .pb-lg-gs {
    padding-bottom: 28px !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.375rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.75rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 2.75rem !important;
  }
  .ps-lg-gs {
    padding-left: 28px !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.375rem !important;
  }
  .gap-lg-2 {
    gap: 0.75rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 2.75rem !important;
  }
  .gap-lg-gs {
    gap: 28px !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 2.75rem !important;
  }
  .row-gap-lg-gs {
    row-gap: 28px !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 2.75rem !important;
  }
  .column-gap-lg-gs {
    column-gap: 28px !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.375rem !important;
  }
  .m-xl-2 {
    margin: 0.75rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2.75rem !important;
  }
  .m-xl-gs {
    margin: 28px !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }
  .mx-xl-gs {
    margin-right: 28px !important;
    margin-left: 28px !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-xl-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
  .my-xl-gs {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.375rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.75rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 2.75rem !important;
  }
  .mt-xl-gs {
    margin-top: 28px !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.375rem !important;
  }
  .me-xl-2 {
    margin-right: 0.75rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 2.75rem !important;
  }
  .me-xl-gs {
    margin-right: 28px !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 2.75rem !important;
  }
  .mb-xl-gs {
    margin-bottom: 28px !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.375rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.75rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 2.75rem !important;
  }
  .ms-xl-gs {
    margin-left: 28px !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.375rem !important;
  }
  .m-xl-n2 {
    margin: -0.75rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2.75rem !important;
  }
  .m-xl-ngs {
    margin: -28px !important;
  }
  .mx-xl-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }
  .mx-xl-ngs {
    margin-right: -28px !important;
    margin-left: -28px !important;
  }
  .my-xl-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }
  .my-xl-ngs {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }
  .mt-xl-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -2.75rem !important;
  }
  .mt-xl-ngs {
    margin-top: -28px !important;
  }
  .me-xl-n1 {
    margin-right: -0.375rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.75rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -2.75rem !important;
  }
  .me-xl-ngs {
    margin-right: -28px !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -2.75rem !important;
  }
  .mb-xl-ngs {
    margin-bottom: -28px !important;
  }
  .ms-xl-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -2.75rem !important;
  }
  .ms-xl-ngs {
    margin-left: -28px !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.375rem !important;
  }
  .p-xl-2 {
    padding: 0.75rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2.75rem !important;
  }
  .p-xl-gs {
    padding: 28px !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-xl-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }
  .px-xl-gs {
    padding-right: 28px !important;
    padding-left: 28px !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-xl-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }
  .py-xl-gs {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.375rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.75rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 2.75rem !important;
  }
  .pt-xl-gs {
    padding-top: 28px !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.375rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.75rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 2.75rem !important;
  }
  .pe-xl-gs {
    padding-right: 28px !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 2.75rem !important;
  }
  .pb-xl-gs {
    padding-bottom: 28px !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.375rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.75rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 2.75rem !important;
  }
  .ps-xl-gs {
    padding-left: 28px !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.375rem !important;
  }
  .gap-xl-2 {
    gap: 0.75rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 2.75rem !important;
  }
  .gap-xl-gs {
    gap: 28px !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 2.75rem !important;
  }
  .row-gap-xl-gs {
    row-gap: 28px !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 2.75rem !important;
  }
  .column-gap-xl-gs {
    column-gap: 28px !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1540px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.375rem !important;
  }
  .m-xxl-2 {
    margin: 0.75rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 2.75rem !important;
  }
  .m-xxl-gs {
    margin: 28px !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }
  .mx-xxl-gs {
    margin-right: 28px !important;
    margin-left: 28px !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }
  .my-xxl-gs {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.375rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.75rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 2.75rem !important;
  }
  .mt-xxl-gs {
    margin-top: 28px !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.375rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.75rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 2.75rem !important;
  }
  .me-xxl-gs {
    margin-right: 28px !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 2.75rem !important;
  }
  .mb-xxl-gs {
    margin-bottom: 28px !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.375rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.75rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 2.75rem !important;
  }
  .ms-xxl-gs {
    margin-left: 28px !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.375rem !important;
  }
  .m-xxl-n2 {
    margin: -0.75rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -2.75rem !important;
  }
  .m-xxl-ngs {
    margin: -28px !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }
  .mx-xxl-ngs {
    margin-right: -28px !important;
    margin-left: -28px !important;
  }
  .my-xxl-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }
  .my-xxl-ngs {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -2.75rem !important;
  }
  .mt-xxl-ngs {
    margin-top: -28px !important;
  }
  .me-xxl-n1 {
    margin-right: -0.375rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.75rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -2.75rem !important;
  }
  .me-xxl-ngs {
    margin-right: -28px !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -2.75rem !important;
  }
  .mb-xxl-ngs {
    margin-bottom: -28px !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -2.75rem !important;
  }
  .ms-xxl-ngs {
    margin-left: -28px !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.375rem !important;
  }
  .p-xxl-2 {
    padding: 0.75rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 2.75rem !important;
  }
  .p-xxl-gs {
    padding: 28px !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }
  .px-xxl-gs {
    padding-right: 28px !important;
    padding-left: 28px !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }
  .py-xxl-gs {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.375rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.75rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 2.75rem !important;
  }
  .pt-xxl-gs {
    padding-top: 28px !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.375rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.75rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 2.75rem !important;
  }
  .pe-xxl-gs {
    padding-right: 28px !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 2.75rem !important;
  }
  .pb-xxl-gs {
    padding-bottom: 28px !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.375rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.75rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 2.75rem !important;
  }
  .ps-xxl-gs {
    padding-left: 28px !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.375rem !important;
  }
  .gap-xxl-2 {
    gap: 0.75rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 2.75rem !important;
  }
  .gap-xxl-gs {
    gap: 28px !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.375rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 2.75rem !important;
  }
  .row-gap-xxl-gs {
    row-gap: 28px !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.375rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.75rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 2.75rem !important;
  }
  .column-gap-xxl-gs {
    column-gap: 28px !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
html {
  font-size: 16px;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  transition: color 0.4s, background-color 0.4s, border 0.4s, box-shadow 0.4s;
}
a:focus {
  outline: none;
}
img {
  max-width: 100%;
}
strong {
  font-weight: 500;
}
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
.h1:last-child,
.h2:last-child,
.h3:last-child,
.h4:last-child,
.h5:last-child,
.h6:last-child,
p:last-child {
  margin-bottom: 0;
}
h1,
.h1,
h2,
.h2 {
  letter-spacing: -0.03em;
}
h3,
.h3,
h4,
.h4 {
  letter-spacing: -0.02em;
}
h5,
.h5,
h6,
.h6 {
  letter-spacing: -0.01em;
}
@media (min-width: 992px) {
  h1,
  .h1 {
    font-size: 3.75rem;
    font-weight: 400;
    letter-spacing: -0.04em;
  }
  h2,
  .h2 {
    font-size: 2.5rem;
    letter-spacing: -0.03em;
  }
  h3,
  .h3 {
    font-size: 2rem;
    letter-spacing: -0.03em;
  }
  h4,
  .h4 {
    font-size: 1.5rem;
  }
  h5,
  .h5 {
    font-size: 1.25rem;
  }
  h6,
  .h6 {
    font-size: 1.05rem;
  }
}
.list-inline li {
  display: inline-block;
}
.list-inline li:not(:last-child) {
  margin-right: 1rem;
}
.ellipsis,
.nk-msg-text .title,
.attach-item span,
.tb-odr-item .tb-odr-info,
.tb-tnx-item .tb-tnx-desc,
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ff-base {
  font-family: Roboto, sans-serif !important;
}
.ff-alt {
  font-family: Nunito, sans-serif !important;
}
.fw-medium {
  font-weight: 500 !important;
}
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white .h1,
.text-white .h2,
.text-white .h3,
.text-white .h4,
.text-white .h5,
.text-white .h6 {
  color: #fff;
}
@media (min-width: 992px) {
  .lead {
    font-size: 1.09375rem;
  }
}
small,
.small {
  font-weight: 400 !important;
}
.text-soft {
  color: #8094ae !important;
}
.text-base {
  color: #526484 !important;
}
.text-head {
  color: #364a63 !important;
}
.circle {
  border-radius: 50%;
}
.round {
  border-radius: 4px;
}
.round-sm {
  border-radius: 3px;
}
.round-lg {
  border-radius: 5px;
}
.round-xl {
  border-radius: 10px;
}
svg {
  height: 100%;
  max-width: 100%;
}
.sq {
  height: 40px;
  width: 40px;
}
.sq-xs {
  height: 18px;
  width: 18px;
}
.sq-sm {
  height: 24px;
  width: 24px;
}
.sq-md {
  height: 32px;
  width: 32px;
}
.sq-lg {
  height: 60px;
  width: 60px;
}
.sq-xl {
  height: 80px;
  width: 80px;
}
.divider {
  border-top: 1px solid #e5e9f2 !important;
  margin: 28px 0;
  display: block;
}
.divider.xs {
  margin: 0.5rem 0;
}
.divider.sm {
  margin: 1rem 0;
}
.divider.md {
  margin: 1.5rem 0;
}
.divider.lg {
  margin: 2.25rem 0;
}
.divider.xl {
  margin: 2.75rem 0;
}
.bg-blue {
  background-color: #559bfb !important;
}
.text-blue {
  color: #559bfb !important;
}
.bg-azure {
  background-color: #1676fb !important;
}
.text-azure {
  color: #1676fb !important;
}
.bg-indigo {
  background-color: #323f9e !important;
}
.text-indigo {
  color: #323f9e !important;
}
.bg-purple {
  background-color: #816bff !important;
}
.text-purple {
  color: #816bff !important;
}
.bg-pink {
  background-color: #ff63a5 !important;
}
.text-pink {
  color: #ff63a5 !important;
}
.bg-orange {
  background-color: #ffa353 !important;
}
.text-orange {
  color: #ffa353 !important;
}
.bg-teal {
  background-color: #20c997 !important;
}
.text-teal {
  color: #20c997 !important;
}
.bg-blue-dim {
  background-color: #e9f2fe !important;
}
.text-blue-dim {
  color: #e9f2fe !important;
}
.bg-azure-dim {
  background-color: #e1edfe !important;
}
.text-azure-dim {
  color: #e1edfe !important;
}
.bg-indigo-dim {
  background-color: #e4e6f2 !important;
}
.text-indigo-dim {
  color: #e4e6f2 !important;
}
.bg-purple-dim {
  background-color: #efecff !important;
}
.text-purple-dim {
  color: #efecff !important;
}
.bg-pink-dim {
  background-color: #ffebf3 !important;
}
.text-pink-dim {
  color: #ffebf3 !important;
}
.bg-orange-dim {
  background-color: #fff3e9 !important;
}
.text-orange-dim {
  color: #fff3e9 !important;
}
.bg-teal-dim {
  background-color: #e2f8f1 !important;
}
.text-teal-dim {
  color: #e2f8f1 !important;
}
.bg-primary-dim,
.dual-listbox .dual-listbox__item:active,
.dual-listbox .dual-listbox__item.dual-listbox__item--selected {
  background-color: #d5f5fe !important;
}
.text-primary-dim {
  color: #d5f5fe !important;
}
.bg-success-dim {
  background-color: #e0f8ee !important;
}
.text-success-dim {
  color: #e0f8ee !important;
}
.bg-info-dim {
  background-color: #dff0ff !important;
}
.text-info-dim {
  color: #dff0ff !important;
}
.bg-warning-dim {
  background-color: #fff1e2 !important;
}
.text-warning-dim {
  color: #fff1e2 !important;
}
.bg-danger-dim {
  background-color: #fde6ec !important;
}
.text-danger-dim {
  color: #fde6ec !important;
}
.bg-secondary-dim {
  background-color: #e5ecf5 !important;
}
.text-secondary-dim {
  color: #e5ecf5 !important;
}
.bg-dark-dim {
  background-color: #d9e1ef !important;
}
.text-dark-dim {
  color: #d9e1ef !important;
}
.bg-gray-dim {
  background-color: #edf2f9 !important;
}
.text-gray-dim {
  color: #edf2f9 !important;
}
.bg-bitcoin {
  background-color: #f9841e !important;
}
.text-bitcoin {
  color: #f9841e !important;
}
.bg-ethereum {
  background-color: #6174b9 !important;
}
.text-ethereum {
  color: #6174b9 !important;
}
.bg-facebook {
  background-color: #337fff !important;
}
.text-facebook {
  color: #337fff !important;
}
.bg-google {
  background-color: #de5246 !important;
}
.text-google {
  color: #de5246 !important;
}
.bg-instagram {
  background-color: #8d3bd0 !important;
}
.text-instagram {
  color: #8d3bd0 !important;
}
.bg-linkedin {
  background-color: #3cf !important;
}
.text-linkedin {
  color: #3cf !important;
}
.bg-twitter {
  background-color: #0178b6 !important;
}
.text-twitter {
  color: #0178b6 !important;
}
.bg-youtube {
  background-color: red !important;
}
.text-youtube {
  color: red !important;
}
.bg-gray-100 {
  background-color: #ebeef2 !important;
}
.bg-gray-200 {
  background-color: #e5e9f2 !important;
}
.bg-gray-300 {
  background-color: #dbdfea !important;
}
.bg-gray-400 {
  background-color: #b7c2d0 !important;
}
.bg-gray-500 {
  background-color: #8091a7 !important;
}
.bg-gray-600 {
  background-color: #3c4d62 !important;
}
.bg-gray-700 {
  background-color: #344357 !important;
}
.bg-gray-800 {
  background-color: #2b3748 !important;
}
.bg-gray-900 {
  background-color: #1f2b3a !important;
}
.bg-abstract {
  background-image: linear-gradient(
    to right,
    #2c3782 calc(60% - 150px),
    #39469f calc(60% - 150px),
    #39469f 60%,
    #4856b5 60%,
    #4856b5 calc(60% + 150px),
    #5b6ac6 calc(60% + 150px),
    #5b6ac6 100%
  );
}
.bg-white-1 {
  background: rgba(255, 255, 255, 0.1) !important;
}
.bg-white-2 {
  background: rgba(255, 255, 255, 0.2) !important;
}
.bg-white-3 {
  background: rgba(255, 255, 255, 0.3) !important;
}
.bg-white-4 {
  background: rgba(255, 255, 255, 0.4) !important;
}
.bg-white-5 {
  background: rgba(255, 255, 255, 0.5) !important;
}
.bg-white-6 {
  background: rgba(255, 255, 255, 0.6) !important;
}
.bg-white-7 {
  background: rgba(255, 255, 255, 0.7) !important;
}
.bg-white-8 {
  background: rgba(255, 255, 255, 0.8) !important;
}
.bg-white-9 {
  background: rgba(255, 255, 255, 0.9) !important;
}
.border-transparent {
  border-color: rgba(0, 0, 0, 0);
}
.border-dashed {
  border-style: dashed !important;
}
.border-dotted {
  border-style: dotted !important;
}
.w-40px {
  width: 40px !important;
}
.w-min-40px {
  min-width: 40px !important;
}
.w-max-40px {
  max-width: 40px !important;
}
.w-80px {
  width: 80px !important;
}
.w-min-80px {
  min-width: 80px !important;
}
.w-max-80px {
  max-width: 80px !important;
}
.w-90px {
  width: 90px !important;
}
.w-min-90px {
  min-width: 90px !important;
}
.w-max-90px {
  max-width: 90px !important;
}
.w-100px {
  width: 100px !important;
}
.w-min-100px {
  min-width: 100px !important;
}
.w-max-100px {
  max-width: 100px !important;
}
.w-110px {
  width: 110px !important;
}
.w-min-110px {
  min-width: 110px !important;
}
.w-max-110px {
  max-width: 110px !important;
}
.w-120px {
  width: 120px !important;
}
.w-min-120px {
  min-width: 120px !important;
}
.w-max-120px {
  max-width: 120px !important;
}
.w-125px {
  width: 125px !important;
}
.w-min-125px {
  min-width: 125px !important;
}
.w-max-125px {
  max-width: 125px !important;
}
.w-130px {
  width: 130px !important;
}
.w-min-130px {
  min-width: 130px !important;
}
.w-max-130px {
  max-width: 130px !important;
}
.w-140px {
  width: 140px !important;
}
.w-min-140px {
  min-width: 140px !important;
}
.w-max-140px {
  max-width: 140px !important;
}
.w-150px {
  width: 150px !important;
}
.w-min-150px {
  min-width: 150px !important;
}
.w-max-150px {
  max-width: 150px !important;
}
.w-175px {
  width: 175px !important;
}
.w-min-175px {
  min-width: 175px !important;
}
.w-max-175px {
  max-width: 175px !important;
}
.w-200px {
  width: 200px !important;
}
.w-min-200px {
  min-width: 200px !important;
}
.w-max-200px {
  max-width: 200px !important;
}
.w-220px {
  width: 220px !important;
}
.w-min-220px {
  min-width: 220px !important;
}
.w-max-220px {
  max-width: 220px !important;
}
.w-225px {
  width: 225px !important;
}
.w-min-225px {
  min-width: 225px !important;
}
.w-max-225px {
  max-width: 225px !important;
}
.w-250px {
  width: 250px !important;
}
.w-min-250px {
  min-width: 250px !important;
}
.w-max-250px {
  max-width: 250px !important;
}
.w-300px {
  width: 300px !important;
}
.w-min-300px {
  min-width: 300px !important;
}
.w-max-300px {
  max-width: 300px !important;
}
.w-350px {
  width: 350px !important;
}
.w-min-350px {
  min-width: 350px !important;
}
.w-max-350px {
  max-width: 350px !important;
}
.w-400px {
  width: 400px !important;
}
.w-min-400px {
  min-width: 400px !important;
}
.w-max-400px {
  max-width: 400px !important;
}
.w-550px {
  width: 550px !important;
}
.w-min-550px {
  min-width: 550px !important;
}
.w-max-550px {
  max-width: 550px !important;
}
.w-max-100,
.wide-xs-fix,
.wide-sm-fix,
.wide-md-fix,
.wide-lg-fix {
  max-width: 100% !important;
}
.w-min-100 {
  min-width: 100% !important;
}
.w-max-auto {
  max-width: inherit !important;
}
.w-min-auto {
  min-width: auto !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-30 {
  width: 30% !important;
}
.w-35 {
  width: 35% !important;
}
.w-40 {
  width: 40% !important;
}
.w-45 {
  width: 45% !important;
}
.w-55 {
  width: 55% !important;
}
.w-60 {
  width: 60% !important;
}
.w-65 {
  width: 65% !important;
}
.w-70 {
  width: 70% !important;
}
.w-80 {
  width: 80% !important;
}
.w-85 {
  width: 85% !important;
}
.w-90 {
  width: 90% !important;
}
.w-95 {
  width: 95% !important;
}
@media (min-width: 576px) {
  .wide-xs {
    max-width: 520px !important;
  }
  .wide-xs-fix {
    width: 520px !important;
  }
}
@media (min-width: 768px) {
  .wide-sm {
    max-width: 720px !important;
  }
  .wide-sm-fix {
    width: 720px !important;
  }
}
@media (min-width: 992px) {
  .wide-md {
    max-width: 960px !important;
  }
  .wide-md-fix {
    width: 960px !important;
  }
}
@media (min-width: 1200px) {
  .wide-lg {
    max-width: 1140px !important;
  }
  .wide-lg-fix {
    width: 1140px !important;
  }
}
@media (min-width: 1280px) {
  .wide-xl {
    max-width: 1240px !important;
  }
  .wide-xl-fix {
    width: 1240px !important;
  }
}
.wide-xs-fix {
  width: 520px !important;
}
.wide-sm-fix {
  width: 720px !important;
}
.wide-md-fix {
  width: 960px !important;
}
.wide-lg-fix {
  width: 1140px !important;
}
.wide-xl-fix {
  width: 1240px !important;
}
@media (min-width: 1200px) and (max-width: 1660px) {
  .wide-fit {
    max-width: 1140px;
  }
}
.h-1rem {
  height: 1rem !important;
}
.h-min-1rem {
  min-height: 1rem !important;
}
.h-max-1rem {
  max-height: 1rem !important;
}
.h-2rem {
  height: 2rem !important;
}
.h-min-2rem {
  min-height: 2rem !important;
}
.h-max-2rem {
  max-height: 2rem !important;
}
.h-1rem {
  height: 1rem !important;
}
.h-min-1rem {
  min-height: 1rem !important;
}
.h-max-1rem {
  max-height: 1rem !important;
}
.h-2rem {
  height: 2rem !important;
}
.h-min-2rem {
  min-height: 2rem !important;
}
.h-max-2rem {
  max-height: 2rem !important;
}
.h-100px {
  height: 100px !important;
}
.h-min-100px {
  min-height: 100px !important;
}
.h-max-100px {
  max-height: 100px !important;
}
.h-150px {
  height: 150px !important;
}
.h-min-150px {
  min-height: 150px !important;
}
.h-max-150px {
  max-height: 150px !important;
}
.h-175px {
  height: 175px !important;
}
.h-min-175px {
  min-height: 175px !important;
}
.h-max-175px {
  max-height: 175px !important;
}
.h-200px {
  height: 200px !important;
}
.h-min-200px {
  min-height: 200px !important;
}
.h-max-200px {
  max-height: 200px !important;
}
.h-225px {
  height: 225px !important;
}
.h-min-225px {
  min-height: 225px !important;
}
.h-max-225px {
  max-height: 225px !important;
}
.h-250px {
  height: 250px !important;
}
.h-min-250px {
  min-height: 250px !important;
}
.h-max-250px {
  max-height: 250px !important;
}
.h-275px {
  height: 275px !important;
}
.h-min-275px {
  min-height: 275px !important;
}
.h-max-275px {
  max-height: 275px !important;
}
.h-300px {
  height: 300px !important;
}
.h-min-300px {
  min-height: 300px !important;
}
.h-max-300px {
  max-height: 300px !important;
}
.h-325px {
  height: 325px !important;
}
.h-min-325px {
  min-height: 325px !important;
}
.h-max-325px {
  max-height: 325px !important;
}
.h-350px {
  height: 350px !important;
}
.h-min-350px {
  min-height: 350px !important;
}
.h-max-350px {
  max-height: 350px !important;
}
.h-375px {
  height: 375px !important;
}
.h-min-375px {
  min-height: 375px !important;
}
.h-max-375px {
  max-height: 375px !important;
}
.h-400px {
  height: 400px !important;
}
.h-min-400px {
  min-height: 400px !important;
}
.h-max-400px {
  max-height: 400px !important;
}
.h-425px {
  height: 425px !important;
}
.h-min-425px {
  min-height: 425px !important;
}
.h-max-425px {
  max-height: 425px !important;
}
.h-450px {
  height: 450px !important;
}
.h-min-450px {
  min-height: 450px !important;
}
.h-max-450px {
  max-height: 450px !important;
}
.h-475px {
  height: 475px !important;
}
.h-min-475px {
  min-height: 475px !important;
}
.h-max-475px {
  max-height: 475px !important;
}
.h-500px {
  height: 500px !important;
}
.h-min-500px {
  min-height: 500px !important;
}
.h-max-500px {
  max-height: 500px !important;
}
.absolute-top-left {
  position: absolute;
  top: 0;
  left: 0;
}
.absolute-top-right {
  position: absolute;
  top: 0;
  right: 0;
}
.absolute-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}
.absolute-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}
.shrink-0 {
  flex-shrink: 0;
}
.badge {
  position: relative;
  border: 1px solid currentColor;
  line-height: 1.125rem;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.01em;
  vertical-align: middle;
  display: inline-flex;
}
.badge .icon + span,
.badge span + .icon {
  padding-left: 4px;
}
.badge.rounded-pill {
  border-radius: 10rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.badge.badge-pill {
  border-radius: 10rem;
}
.badge-sm {
  padding: 0.3125rem 0.75rem;
  font-size: 0.75rem;
}
.badge-sm.rounded-pill {
  padding: 0.3125rem 0.875rem;
}
.badge-md {
  padding: 0.47rem 1rem;
  font-size: 0.8125rem !important;
}
.badge-md.rounded-pill {
  padding: 0.47rem 1rem;
}
.badge-lg {
  padding: 0.75rem 1.25rem;
  font-size: 0.93rem !important;
}
.badge-lg.rounded-pill {
  padding: 0.75rem 1.25rem;
}
.badge-xl {
  padding: 0.875rem 1.5rem;
  font-size: 1.125rem !important;
}
.badge-xl.rounded-pill {
  padding: 0.875rem 1.5rem;
}
.badge.bg-light {
  color: #364a63;
}
.badge-lighter {
  color: #526484;
}
.badge .icon {
  font-size: 1rem;
}
.badge.bg-primary {
  border-color: #11c8fc;
  background: #11c8fc;
}
.badge.bg-secondary {
  border-color: #364a63;
  background: #364a63;
}
.badge.bg-success {
  border-color: #0fca7a;
  background: #0fca7a;
}
.badge.bg-info {
  border-color: #058efc;
  background: #058efc;
}
.badge.bg-warning {
  border-color: #fd9722;
  background: #fd9722;
}
.badge.bg-danger {
  border-color: #f2426e;
  background: #f2426e;
}
.badge.bg-light {
  border-color: #e5e9f2;
  background: #e5e9f2;
}
.badge.bg-dark {
  border-color: #1f2b3a;
  background: #1f2b3a;
}
.badge.bg-gray {
  border-color: #8091a7;
  background: #8091a7;
}
.badge.bg-lighter,
.dual-listbox .badge.dual-listbox__item:hover {
  border-color: #f5f6fa;
  background: #f5f6fa;
}
.bg-outline-primary {
  color: #11c8fc;
  border-color: #ab89f2;
}
.bg-outline-secondary {
  color: #364a63;
  border-color: #8692a1;
}
.bg-outline-success {
  color: #0fca7a;
  border-color: #6fdfaf;
}
.bg-outline-info {
  color: #058efc;
  border-color: #69bbfd;
}
.bg-outline-warning {
  color: #fd9722;
  border-color: #fec17a;
}
.bg-outline-danger {
  color: #f2426e;
  border-color: #f78ea8;
}
.bg-outline-light {
  color: #b7c2d0;
  border-color: #d4dae3;
}
.bg-outline-dark {
  color: #1f2b3a;
  border-color: #798089;
}
.bg-outline-gray {
  color: #8091a7;
  border-color: #b3bdca;
}
.bg-outline-lighter {
  color: #e5e9f2;
  border-color: #eff2f7;
}
.badge-dim.bg-primary {
  color: #11c8fc;
  background-color: #d2f5ff !important;
  border-color: #d2f5ff;
}
.badge-dim.bg-outline-primary {
  color: #11c8fc;
  background-color: #d2f5ff;
  border-color: #11c8fc2e;
}
.badge-dim.bg-secondary {
  color: #364a63;
  background-color: #e9ebee !important;
  border-color: #e9ebee;
}
.badge-dim.bg-outline-secondary {
  color: #364a63;
  background-color: #e9ebee;
  border-color: #afb7c1;
}
.badge-dim.bg-success {
  color: #0fca7a;
  background-color: #e5f9f0 !important;
  border-color: #e5f9f0;
}
.badge-dim.bg-outline-success {
  color: #0fca7a;
  background-color: #e5f9f0;
  border-color: #9feaca;
}
.badge-dim.bg-info {
  color: #058efc;
  background-color: #e4f3ff !important;
  border-color: #e4f3ff;
}
.badge-dim.bg-outline-info {
  color: #058efc;
  background-color: #e4f3ff;
  border-color: #9bd2fe;
}
.badge-dim.bg-warning {
  color: #fd9722;
  background-color: #fff4e7 !important;
  border-color: #fff4e7;
}
.badge-dim.bg-outline-warning {
  color: #fd9722;
  background-color: #fff4e7;
  border-color: #fed5a7;
}
.badge-dim.bg-danger {
  color: #f2426e;
  background-color: #feeaef !important;
  border-color: #feeaef;
}
.badge-dim.bg-outline-danger {
  color: #f2426e;
  background-color: #feeaef;
  border-color: #fab3c5;
}
.badge-dim.bg-light {
  color: #b7c2d0;
  background-color: #f7f8fa !important;
  border-color: #f7f8fa;
}
.badge-dim.bg-outline-light {
  color: #b7c2d0;
  background-color: #f7f8fa;
  border-color: #e2e7ec;
}
.badge-dim.bg-dark {
  color: #1f2b3a;
  background-color: #e6e8e9 !important;
  border-color: #e6e8e9;
}
.badge-dim.bg-outline-dark {
  color: #1f2b3a;
  background-color: #e6e8e9;
  border-color: #a5aab0;
}
.badge-dim.bg-gray {
  color: #8091a7;
  background-color: #f1f3f5 !important;
  border-color: #f1f3f5;
}
.badge-dim.bg-outline-gray {
  color: #8091a7;
  background-color: #f1f3f5;
  border-color: #ccd3dc;
}
.badge-dim.bg-lighter,
.dual-listbox .badge-dim.dual-listbox__item:hover {
  color: #e5e9f2;
  background-color: #fcfdfe !important;
  border-color: #fcfdfe;
}
.badge-dim.bg-outline-lighter {
  color: #e5e9f2;
  background-color: #fcfdfe;
  border-color: #f5f6fa;
}
.badge-dot {
  display: inline-flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0) !important;
  border: none;
  padding-left: 12px;
  padding-right: 0;
  font-size: 12px;
}
.badge-dot:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  height: 6px;
  width: 6px;
  margin-top: -1px;
  border-radius: 50%;
  background: currentColor;
}
.badge-dot.badge-sm,
.badge-dot.badge-md {
  padding-left: 16px;
}
.badge-dot.badge-sm:before,
.badge-dot.badge-md:before {
  height: 8px;
  width: 8px;
  margin-top: 0;
}
.badge-dot.badge-lg,
.badge-dot.badge-xl {
  padding-left: 24px;
}
.badge-dot.badge-lg:before,
.badge-dot.badge-xl:before {
  height: 12px;
  width: 12px;
  margin-top: 0;
}
.badge-dot.bg-primary {
  color: #11c8fc;
}
.badge-dot.bg-secondary {
  color: #364a63;
}
.badge-dot.bg-success {
  color: #0fca7a;
}
.badge-dot.bg-info {
  color: #058efc;
}
.badge-dot.bg-warning {
  color: #fd9722;
}
.badge-dot.bg-danger {
  color: #f2426e;
}
.badge-dot.bg-light {
  color: #b7c2d0;
}
.badge-dot.bg-dark {
  color: #1f2b3a;
}
.badge-dot.bg-gray {
  color: #8091a7;
}
.badge-dot.bg-lighter,
.dual-listbox .badge-dot.dual-listbox__item:hover {
  color: #e5e9f2;
}
@media (max-width: 767.98px) {
  .badge-dot-sm {
    width: 0;
    overflow: hidden;
  }
}
@media (max-width: 413px) {
  .badge-dot-mb {
    width: 0;
    overflow: hidden;
  }
}
@media (max-width: 369px) {
  .badge-dot-xs {
    width: 0;
    overflow: hidden;
  }
}
.alert:last-child {
  margin-bottom: 0;
}
.alert-link {
  box-shadow: 0 1px 0 currentColor;
}
.alert-link:hover {
  box-shadow: none;
}
.alert h5,
.alert .h5,
.alert h6,
.alert .h6,
.alert .title {
  font-size: 1.05rem;
}
.alert.alert-icon {
  padding-left: 3.25rem;
}
.alert.alert-icon > .icon {
  position: absolute;
  font-size: 1.25rem;
  line-height: 1;
  width: 2rem;
  top: 1rem;
  left: 1.25rem;
  margin-left: -1px;
}
.alert-dismissible .close {
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s ease;
  margin-top: -1px;
  position: absolute;
  right: 0;
  padding: 1rem 1.25rem;
  color: inherit;
}
.alert-dismissible .close:focus {
  outline: none;
}
.alert-dismissible .close:hover {
  color: currentColor;
  opacity: 0.75;
}
.alert-dismissible .close:after {
  font-family: "Nioicon";
  content: "";
  font-size: 1.1rem;
}
.alert-light,
.alert-lighter,
.alert-gray {
  border-color: #e5e9f2;
  color: #526484;
}
.alert-light .alert-link,
.alert-lighter .alert-link,
.alert-gray .alert-link {
  color: #526484;
}
.alert-alt {
  position: relative;
  padding: 0.875rem;
  border-radius: 12px;
}
.alert-alt.alert-icon {
  padding-left: 4rem;
}
.alert-alt.alert-icon > .icon {
  position: absolute;
  font-size: 2rem;
  line-height: 1;
  width: 4rem;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-align: center;
  opacity: 0.5;
}
a.alert:hover {
  color: currentColor;
}
.alert-fill.alert-primary {
  color: #fff;
  background: #11c8fc;
}
.alert-fill.alert-secondary {
  color: #fff;
  background: #364a63;
}
.alert-fill.alert-success {
  color: #fff;
  background: #0fca7a;
}
.alert-fill.alert-info {
  color: #fff;
  background: #058efc;
}
.alert-fill.alert-warning {
  color: #fff;
  background: #fd9722;
}
.alert-fill.alert-danger {
  color: #fff;
  background: #f2426e;
}
.alert-fill.alert-light {
  color: #000;
  background: #e5e9f2;
}
.alert-fill.alert-dark {
  color: #fff;
  background: #1f2b3a;
}
.alert-fill.alert-gray {
  color: #fff;
  background: #8091a7;
}
.alert-fill.alert-lighter {
  color: #000;
  background: #ebeef2;
}
.alert-fill {
  border-width: 0;
}
.alert-fill .alert-link,
.alert-fill .icon,
.alert-fill h4,
.alert-fill .h4,
.alert-fill h5,
.alert-fill .h5,
.alert-fill h6,
.alert-fill .h6 {
  color: currentColor;
}
.alert-fill.alert-light {
  color: #364a63;
}
.alert-fill.alert-lighter {
  color: #526484;
}
.alert-pro {
  box-shadow: 0 4px 15px 0 rgba(31, 43, 58, 0.1);
  border: none;
  border-left: 4px solid rgba(0, 0, 0, 0);
  color: #526484;
  line-height: 1.5;
  background: #fff;
}
.alert-pro.no-shadow {
  box-shadow: none;
}
.alert-pro.no-border {
  border-left: 0;
}
.alert-pro.alert-icon {
  padding-left: 4rem;
}
.alert-pro.alert-icon > .icon {
  font-size: 1.75rem;
  top: 50%;
  transform: translateY(-50%);
}
.alert-pro.alert-primary {
  border-color: #11c8fc;
}
.alert-pro.alert-primary > .icon {
  color: #11c8fc;
}
.alert-pro.alert-secondary {
  border-color: #364a63;
}
.alert-pro.alert-secondary > .icon {
  color: #364a63;
}
.alert-pro.alert-success {
  border-color: #0fca7a;
}
.alert-pro.alert-success > .icon {
  color: #0fca7a;
}
.alert-pro.alert-info {
  border-color: #058efc;
}
.alert-pro.alert-info > .icon {
  color: #058efc;
}
.alert-pro.alert-warning {
  border-color: #fd9722;
}
.alert-pro.alert-warning > .icon {
  color: #fd9722;
}
.alert-pro.alert-danger {
  border-color: #f2426e;
}
.alert-pro.alert-danger > .icon {
  color: #f2426e;
}
.alert-pro.alert-light {
  border-color: #b7c2d0;
}
.alert-pro.alert-light > .icon {
  color: #b7c2d0;
}
.alert-pro.alert-dark {
  border-color: #1f2b3a;
}
.alert-pro.alert-dark > .icon {
  color: #1f2b3a;
}
.alert-pro.alert-gray {
  border-color: #8091a7;
}
.alert-pro.alert-gray > .icon {
  color: #8091a7;
}
.alert-pro.alert-lighter {
  border-color: #b7c2d0;
}
.alert-pro.alert-lighter > .icon {
  color: #b7c2d0;
}
.breadcrumb-item {
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}
.breadcrumb-item a:not(:hover) {
  color: #8094ae;
}
.breadcrumb-pipe .breadcrumb-item + .breadcrumb-item::before {
  content: "|";
}
.breadcrumb-arrow .breadcrumb-item + .breadcrumb-item::before {
  font-family: "Nioicon";
  content: "";
}
.breadcrumb-item.active:not(:only-child) {
  font-weight: 400;
}
.breadcrumb-alt .breadcrumb-item {
  text-transform: unset;
  letter-spacing: 0;
  color: #8094ae;
}
.breadcrumb-xs .breadcrumb-item + .breadcrumb-item {
  padding-left: 0.25rem;
}
.breadcrumb-xs .breadcrumb-item + .breadcrumb-item:before {
  padding-right: 0.25rem;
  font-size: 0.8em;
}
.breadcrumb-sm .breadcrumb-item {
  font-size: 13px;
}
.link {
  display: inline-flex;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  line-height: 1;
  border: none;
  background: rgba(0, 0, 0, 0);
  transition: all 0.3s ease;
  white-space: nowrap;
}
.link:focus {
  box-shadow: none;
  outline: none;
}
.link .icon {
  font-size: 1.3em;
}
.link span,
.link .icon {
  display: inline-block;
}
.link .icon-circle {
  display: inline-flex;
}
.link .icon + span,
.link span + .icon {
  padding-left: 10px;
}
.link-md {
  font-size: 1.25rem;
}
.link-sm {
  font-size: 0.75rem;
}
.link-dim {
  opacity: 0.7;
}
.link-dim:hover {
  opacity: 1;
}
.link-block {
  display: flex;
}
.link-between {
  justify-content: space-between;
}
.link-primary {
  color: #11c8fc !important;
}
.link-on-primary:hover {
  color: #11c8fc !important;
}
.link-secondary {
  color: #364a63 !important;
}
.link-on-secondary:hover {
  color: #364a63 !important;
}
.link-success {
  color: #0fca7a !important;
}
.link-on-success:hover {
  color: #0fca7a !important;
}
.link-info {
  color: #058efc !important;
}
.link-on-info:hover {
  color: #058efc !important;
}
.link-warning {
  color: #fd9722 !important;
}
.link-on-warning:hover {
  color: #fd9722 !important;
}
.link-danger {
  color: #f2426e !important;
}
.link-on-danger:hover {
  color: #f2426e !important;
}
.link-light {
  color: #8094ae !important;
}
.link-on-light:hover {
  color: #8094ae !important;
}
.link-dark {
  color: #1f2b3a !important;
}
.link-on-dark:hover {
  color: #1f2b3a !important;
}
.link-gray {
  color: #8091a7 !important;
}
.link-on-gray:hover {
  color: #8091a7 !important;
}
.link-lighter {
  color: #b7c2d0 !important;
}
.link-on-lighter:hover {
  color: #b7c2d0 !important;
}
.link-white {
  color: #fff !important;
}
.link-on-white:hover {
  color: #fff !important;
}
.link-text {
  color: #526484 !important;
}
.link-on-text:hover {
  color: #526484 !important;
}
.link-danger:hover,
.link-warning:hover,
.link-success:hover,
.link-info:hover {
  color: #526484 !important;
}
.btn,
.actions ul li a,
.dual-listbox .dual-listbox__button {
  position: relative;
  letter-spacing: 0.02em;
  display: inline-flex;
  align-items: center;
}
.btn-xl {
  --bs-btn-padding-y: 0.6875rem;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-font-size: 0.9375rem;
  --bs-btn-border-radius: 5px;
  line-height: 1.25rem;
}
.btn-xs {
  --bs-btn-padding-y: 0.125rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.6875rem;
  --bs-btn-border-radius: 3px;
  line-height: 1rem;
}
.btn .icon,
.actions ul li a .icon,
.dual-listbox .dual-listbox__button .icon {
  font-size: 1.4em;
  line-height: inherit;
}
.btn > span,
.actions ul li a > span,
.dual-listbox .dual-listbox__button > span {
  display: inline-block;
  white-space: nowrap;
}
.btn > span:only-child,
.actions ul li a > span:only-child,
.dual-listbox .dual-listbox__button > span:only-child {
  width: 100%;
}
.btn .icon + span,
.actions ul li a .icon + span,
.dual-listbox .dual-listbox__button .icon + span,
.btn span + .icon,
.actions ul li a span + .icon,
.dual-listbox .dual-listbox__button span + .icon {
  padding-left: 8px;
}
.btn .dd-indc,
.actions ul li a .dd-indc,
.dual-listbox .dual-listbox__button .dd-indc {
  transform: translateX(-8px);
}
.btn span + .dd-indc,
.actions ul li a span + .dd-indc,
.dual-listbox .dual-listbox__button span + .dd-indc {
  transform: translateX(8px);
}
.btn:active,
.actions ul li a:active,
.dual-listbox .dual-listbox__button:active,
.btn.active,
.actions ul li a.active,
.dual-listbox .active.dual-listbox__button {
  border-color: rgba(0, 0, 0, 0) !important;
}
.btn-lg .icon + span,
.btn-group-lg > .btn .icon + span,
.actions ul li .btn-group-lg > a .icon + span,
.dual-listbox .btn-group-lg > .dual-listbox__button .icon + span,
.btn-lg span + .icon,
.btn-group-lg > .btn span + .icon,
.actions ul li .btn-group-lg > a span + .icon,
.dual-listbox .btn-group-lg > .dual-listbox__button span + .icon {
  padding-left: 12px;
}
.btn-round {
  border-radius: 2.125rem;
}
.btn-block {
  justify-content: center;
  width: 100%;
}
.btn-ucap,
.btn.ucap,
.actions ul li a.ucap,
.dual-listbox .ucap.dual-listbox__button {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.05em;
}
.btn-icon:not([class*="btn-icon-break"]),
.dt-buttons .btn-secondary:not([class*="btn-icon-break"]) {
  padding-left: 0;
  padding-right: 0;
}
.btn-icon .icon,
.dt-buttons .btn-secondary .icon {
  width: 2.125rem;
}
.btn-icon.btn-xl .icon,
.dt-buttons .btn-xl.btn-secondary .icon {
  width: 2.625rem;
}
.btn-icon.btn-lg .icon,
.dt-buttons .btn-lg.btn-secondary .icon,
.btn-group-lg > .btn-icon.btn .icon,
.actions ul li .btn-group-lg > a.btn-icon .icon,
.dual-listbox .btn-group-lg > .btn-icon.dual-listbox__button .icon,
.dt-buttons .btn-group-lg > .btn.btn-secondary .icon,
.dt-buttons .actions ul li .btn-group-lg > a.btn-secondary .icon,
.actions ul li .dt-buttons .btn-group-lg > a.btn-secondary .icon,
.dt-buttons
  .dual-listbox
  .btn-group-lg
  > .btn-secondary.dual-listbox__button
  .icon,
.dual-listbox
  .dt-buttons
  .btn-group-lg
  > .btn-secondary.dual-listbox__button
  .icon {
  width: 2.625rem;
}
.btn-icon.btn-sm .icon,
.dt-buttons .btn-sm.btn-secondary .icon,
.btn-group-sm > .btn-icon.btn .icon,
.actions ul li .btn-group-sm > a.btn-icon .icon,
.dual-listbox .btn-group-sm > .btn-icon.dual-listbox__button .icon,
.dt-buttons .btn-group-sm > .btn.btn-secondary .icon,
.dt-buttons .actions ul li .btn-group-sm > a.btn-secondary .icon,
.actions ul li .dt-buttons .btn-group-sm > a.btn-secondary .icon,
.dt-buttons
  .dual-listbox
  .btn-group-sm
  > .btn-secondary.dual-listbox__button
  .icon,
.dual-listbox
  .dt-buttons
  .btn-group-sm
  > .btn-secondary.dual-listbox__button
  .icon {
  width: 1.75rem;
}
.btn-icon.btn-xs .icon,
.dt-buttons .btn-xs.btn-secondary .icon {
  width: 1.25rem;
  font-size: 1.1em;
}
.btn-icon .dot,
.dt-buttons .btn-secondary .dot {
  top: 0.35rem;
  position: absolute;
  right: 0.35rem;
  transform: translate(50%, -50%);
}
.btn-icon .badge,
.dt-buttons .btn-secondary .badge {
  top: 0.25rem;
  position: absolute;
  right: 0.25rem;
  transform: translate(50%, -50%);
}
.btn-icon .badge-circle,
.dt-buttons .btn-secondary .badge-circle {
  border-radius: 50%;
  height: 1.125rem;
  width: 1.125rem;
  padding: 0;
  font-weight: 700;
  font-size: 11px;
  text-align: center;
  justify-content: center;
}
.btn-mw {
  min-width: 120px;
  justify-content: center;
}
.btn-wrap {
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
}
.btn-extext {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-top: 0.25rem;
  color: #8094ae;
}
.btn-wider {
  display: flex;
}
.btn-wider .icon + span,
.btn-wider span + .icon {
  margin-left: auto;
}
.btn-auto {
  min-width: auto;
}
.btn-pd-auto {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.btn .spinner-border,
.actions ul li a .spinner-border,
.dual-listbox .dual-listbox__button .spinner-border,
.btn .spinner-grow,
.actions ul li a .spinner-grow,
.dual-listbox .dual-listbox__button .spinner-grow {
  margin: 0.125rem;
}
.btn .spinner-border + span,
.actions ul li a .spinner-border + span,
.dual-listbox .dual-listbox__button .spinner-border + span,
.btn .spinner-grow + span,
.actions ul li a .spinner-grow + span,
.dual-listbox .dual-listbox__button .spinner-grow + span {
  margin-left: 0.25rem;
}
.btn-indc {
  width: 100%;
}
.btn-indc .icon {
  font-size: 1.43em;
}
.btn-indc .indc {
  opacity: 0.6;
  margin-left: -8px;
  margin-right: auto;
}
.btn-indc span + .indc {
  margin-left: auto;
  margin-right: -8px;
}
@media (min-width: 768px) {
  .btn-xl {
    --bs-btn-padding-y: 0.625rem;
    --bs-btn-padding-x: 2rem;
    --bs-btn-font-size: 1.125rem;
    --bs-btn-border-radius: 5px;
    line-height: 2rem;
  }
  .btn-xl.btn-round {
    border-radius: 3.25rem;
  }
  .btn-icon.btn-xl .icon,
  .dt-buttons .btn-xl.btn-secondary .icon {
    width: 3.25rem;
  }
}
.btn-trigger {
  position: relative;
  z-index: 1;
  color: #526484;
}
.is-dark .btn-trigger {
  color: #fff;
}
.btn-trigger:active,
.btn-trigger.show {
  border-color: rgba(0, 0, 0, 0) !important;
}
.btn-trigger:focus {
  box-shadow: none;
}
.btn-trigger:before {
  position: absolute;
  z-index: -1;
  height: 20px;
  width: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  content: "";
  background-color: #e5e9f2;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.3s;
}
.is-dark .btn-trigger:before {
  background-color: rgba(0, 0, 0, 0.2);
}
.show > .btn-trigger:before {
  opacity: 1;
  height: 120%;
  width: 120%;
}
.btn-trigger:hover:before,
.btn-trigger:focus:before,
.btn-trigger.active:not(.revarse):before {
  opacity: 1;
  height: 120%;
  width: 120%;
}
.btn-trigger.active:hover:before {
  background-color: #dbdfea;
}
a:hover .btn-trigger:before {
  opacity: 1;
  height: 120%;
  width: 120%;
}
.btn-trigger.on-dark {
  color: #fff !important;
}
.btn-trigger.on-dark:focus {
  color: #fff !important;
}
.btn-trigger.on-dark:before {
  background-color: rgba(0, 0, 0, 0.2);
}
.btn-dim.btn-outline-primary {
  color: #11c8fc;
  background-color: #d2f5ff;
  border-color: #11c8fc2e;
}
.btn-dim.btn-outline-primary:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #11c8fc;
  border-color: #11c8fc;
}
.btn-white.btn-outline-primary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dt-buttons
  .btn-outline-primary.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.btn-trans.btn-outline-primary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover {
  color: #11c8fc;
  background: #d2f5ff;
}
.btn-dim.btn-outline-success {
  color: #0fca7a;
  background-color: #e5f9f0;
  border-color: #9feaca;
}
.btn-dim.btn-outline-success:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #0fca7a;
  border-color: #0fca7a;
}
.btn-white.btn-outline-success:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dt-buttons
  .btn-outline-success.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.btn-trans.btn-outline-success:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover {
  color: #0fca7a;
  background: #e5f9f0;
}
.btn-dim.btn-outline-warning {
  color: #fd9722;
  background-color: #fff4e7;
  border-color: #fed5a7;
}
.btn-dim.btn-outline-warning:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #fd9722;
  border-color: #fd9722;
}
.btn-white.btn-outline-warning:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dt-buttons
  .btn-outline-warning.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.btn-trans.btn-outline-warning:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover {
  color: #fd9722;
  background: #fff4e7;
}
.btn-dim.btn-outline-info {
  color: #058efc;
  background-color: #e4f3ff;
  border-color: #9bd2fe;
}
.btn-dim.btn-outline-info:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #058efc;
  border-color: #058efc;
}
.btn-white.btn-outline-info:not(.btn-dim):not(:disabled):not(.disabled):hover,
.dt-buttons
  .btn-outline-info.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.btn-trans.btn-outline-info:not(.btn-dim):not(:disabled):not(.disabled):hover {
  color: #058efc;
  background: #e4f3ff;
}
.btn-dim.btn-outline-danger {
  color: #f2426e;
  background-color: #feeaef;
  border-color: #fab3c5;
}
.btn-dim.btn-outline-danger:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #f2426e;
  border-color: #f2426e;
}
.btn-white.btn-outline-danger:not(.btn-dim):not(:disabled):not(.disabled):hover,
.dt-buttons
  .btn-outline-danger.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.btn-trans.btn-outline-danger:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover {
  color: #f2426e;
  background: #feeaef;
}
.btn-dim.btn-outline-secondary {
  color: #364a63;
  background-color: #e9ebee;
  border-color: #b9c0c8;
}
.btn-dim.btn-outline-secondary:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #364a63;
  border-color: #364a63;
}
.btn-white.btn-outline-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dt-buttons
  .btn-outline-secondary.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.btn-trans.btn-outline-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover {
  color: #364a63;
  background: #e9ebee;
}
.btn-dim.btn-outline-gray {
  color: #3c4d62;
  background-color: #f1f3f5;
  border-color: #d3d9e0;
}
.btn-dim.btn-outline-gray:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #3c4d62;
  border-color: #3c4d62;
}
.btn-white.btn-outline-gray:not(.btn-dim):not(:disabled):not(.disabled):hover,
.dt-buttons
  .btn-outline-gray.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.btn-trans.btn-outline-gray:not(.btn-dim):not(:disabled):not(.disabled):hover {
  color: #3c4d62;
  background: #f1f3f5;
}
.btn-dim.btn-outline-dark {
  color: #1f2b3a;
  background-color: #e6e8e9;
  border-color: #b1b5ba;
}
.btn-dim.btn-outline-dark:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #1f2b3a;
  border-color: #1f2b3a;
}
.btn-white.btn-outline-dark:not(.btn-dim):not(:disabled):not(.disabled):hover,
.dt-buttons
  .btn-outline-dark.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.btn-trans.btn-outline-dark:not(.btn-dim):not(:disabled):not(.disabled):hover {
  color: #1f2b3a;
  background: #e6e8e9;
}
.btn-dim.btn-outline-light,
.dt-buttons .btn-dim.btn-secondary {
  color: #526484;
  background-color: #f5f6fa;
  border-color: #dbdfea;
}
.btn-dim.btn-outline-light:not(:disabled):not(.disabled):hover,
.dt-buttons .btn-dim.btn-secondary:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #526484;
  border-color: #526484;
}
.btn-white.btn-outline-light:not(.btn-dim):not(:disabled):not(.disabled):hover,
.dt-buttons .btn-secondary:not(.btn-dim):not(:disabled):not(.disabled):hover,
.btn-trans.btn-outline-light:not(.btn-dim):not(:disabled):not(.disabled):hover {
  color: #526484;
  background: #f5f6fa;
}
.btn-dim.btn-primary {
  color: #11c8fc;
  background-color: #d2f5ff;
  border-color: rgba(0, 0, 0, 0);
}
.btn-dim.btn-primary:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #11c8fc;
  border-color: #11c8fc;
}
.btn-dim.btn-secondary {
  color: #364a63;
  background-color: #e9ebee;
  border-color: rgba(0, 0, 0, 0);
}
.btn-dim.btn-secondary:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #364a63;
  border-color: #364a63;
}
.btn-dim.btn-success {
  color: #0fca7a;
  background-color: #e5f9f0;
  border-color: rgba(0, 0, 0, 0);
}
.btn-dim.btn-success:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #0fca7a;
  border-color: #0fca7a;
}
.btn-dim.btn-warning {
  color: #fd9722;
  background-color: #fff4e7;
  border-color: rgba(0, 0, 0, 0);
}
.btn-dim.btn-warning:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #fd9722;
  border-color: #fd9722;
}
.btn-dim.btn-info {
  color: #058efc;
  background-color: #e4f3ff;
  border-color: rgba(0, 0, 0, 0);
}
.btn-dim.btn-info:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #058efc;
  border-color: #058efc;
}
.btn-dim.btn-danger {
  color: #f2426e;
  background-color: #feeaef;
  border-color: rgba(0, 0, 0, 0);
}
.btn-dim.btn-danger:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #f2426e;
  border-color: #f2426e;
}
.btn-dim.btn-gray {
  color: #3c4d62;
  background-color: #eaebee;
  border-color: rgba(0, 0, 0, 0);
}
.btn-dim.btn-gray:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #3c4d62;
  border-color: #3c4d62;
}
.btn-dim.btn-dark {
  color: #1f2b3a;
  background-color: #e6e8e9;
  border-color: rgba(0, 0, 0, 0);
}
.btn-dim.btn-dark:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #1f2b3a;
  border-color: #1f2b3a;
}
.btn-dim.btn-light,
.dual-listbox .btn-dim.dual-listbox__button {
  color: #8091a7;
  background-color: #f1f3f5;
  border-color: rgba(0, 0, 0, 0);
}
.btn-dim.btn-light:not(:disabled):not(.disabled):hover,
.dual-listbox
  .btn-dim.dual-listbox__button:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #8091a7;
  border-color: #8091a7;
}
.btn-dim.btn-lighter {
  color: #b7c2d0;
  background-color: #f7f8fa;
  border-color: rgba(0, 0, 0, 0);
}
.btn-dim.btn-lighter:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #b7c2d0;
  border-color: #b7c2d0;
}
.btn-trans.btn,
.actions ul li a.btn-trans,
.dual-listbox .btn-trans.dual-listbox__button {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.btn-outline-light,
.dt-buttons .btn-secondary {
  border-color: #dbdfea;
}
.btn-outline-lighter {
  border-color: #e5e9f2;
}
.btn-outline-light,
.dt-buttons .btn-secondary,
.btn-outline-lighter {
  color: #526484;
}
.btn-white,
.dt-buttons .btn-secondary,
.btn-white.btn-dim {
  background: #fff;
}
.btn-white.btn-outline-light:not(.btn-dim):not(:disabled):not(.disabled):hover,
.dt-buttons .btn-secondary:not(.btn-dim):not(:disabled):not(.disabled):hover {
  color: #fff;
  background: #526484;
  border-color: #526484;
}
.btn-light:focus,
.dual-listbox .dual-listbox__button:focus {
  color: #101924;
  background: #b7c2d0;
}
.btn-toolbar-sep {
  border-right: 1px solid #e5e9f2;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.card-tools .btn-toolbar-sep {
  padding: 0 !important;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.btn-group.is-tags .btn-xs:first-child {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.btn-group.is-tags .btn-xs:last-child {
  margin-left: 0;
}
.btn-group.is-tags .btn-xs .icon {
  width: 1rem;
}
.btn-group.is-tags .btn-dim:not(:disabled):not(.disabled):hover {
  border-color: rgba(0, 0, 0, 0);
}
.dropup,
.dropright,
.dropdown,
.dropleft {
  display: inline-flex;
}
.dropdown-toggle {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0;
}
.dropdown-toggle:not([class*="indicator"]):after {
  display: none;
}
.clickable * {
  pointer-events: none;
}
.dropdown-menu {
  overflow: hidden;
  border: 1px solid #e5e9f2;
  box-shadow: 0 3px 12px 1px rgba(23, 12, 47, 0.15);
}
.dropdown-inner {
  padding: 0 1.75rem;
}
.dropdown-inner + .dropdown-inner {
  border-top: 1px solid #e5e9f2;
}
.dropdown-head,
.dropdown-foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #e5e9f2;
}
.dropdown-head.center,
.dropdown-foot.center {
  justify-content: center;
}
.dropdown-head {
  border-bottom: 1px solid #e5e9f2;
}
.dropdown-foot {
  border-top: 1px solid #e5e9f2;
}
.dropdown-body-sm {
  padding: 0.75rem;
}
.dropdown-body-rg {
  padding: 1.25rem;
}
.dropdown-body-md {
  padding: 1.5rem;
}
.dropdown-body-lg {
  padding: 2.25rem;
}
.dropdown-title {
  font-weight: 500;
  color: #364a63;
}
[class*="dropdown-indicator"]:after {
  border: none !important;
  font-family: "Nioicon";
  vertical-align: middle;
  content: "";
  margin-left: 0.25rem;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
}
.dropup [class*="dropdown-indicator"]:after {
  content: "";
}
.dropdown-indicator-caret:after {
  content: "";
}
.dropup .dropdown-indicator-caret:after {
  content: "";
}
.dropdown-indicator-caret-up:after {
  content: "";
}
.dropup .dropdown-indicator-caret-up:after {
  content: "";
}
.dropdown-indicator-up:after {
  content: "";
}
.dropup .dropdown-indicator-up:after {
  content: "";
}
.dropdown-indicator-down:after {
  content: "";
}
.dropup .dropdown-indicator-down:after {
  content: "";
}
.dropdown-indicator-dubble:after {
  content: "";
}
.dropup .dropdown-indicator-dubble:after {
  content: "";
}
.dropdown-indicator-unfold:after {
  content: "";
}
.dropup .dropdown-indicator-unfold:after {
  content: "";
}
.dropdown-menu-center {
  left: 50% !important;
}
.dropdown-menu-bottom {
  bottom: 0 !important;
}
.dropdown-menu-xxs {
  min-width: 70px;
  max-width: 70px;
}
.dropdown-menu-xxs.dropdown-menu-center {
  margin-left: -35px;
}
.dropdown-menu-xs {
  min-width: 120px;
  max-width: 120px;
}
.dropdown-menu-xs.dropdown-menu-center {
  margin-left: -60px;
}
.dropdown-menu-md {
  min-width: 280px;
  max-width: 280px;
}
.dropdown-menu-md.dropdown-menu-center {
  margin-left: -140px;
}
.dropdown-menu-sm {
  min-width: 140px;
  max-width: 140px;
}
.dropdown-menu-sm.dropdown-menu-center {
  margin-left: -70px;
}
.dropdown-menu-lg {
  min-width: 320px;
  max-width: 320px;
}
.dropdown-menu-lg.dropdown-menu-center {
  margin-left: -160px;
}
.dropdown-menu-xl {
  min-width: 360px;
  max-width: 360px;
}
.dropdown-menu-xl.dropdown-menu-center {
  margin-left: -180px;
}
.dropdown-menu-auto {
  min-width: 100%;
  max-width: 100%;
}
.dropdown-menu-mxh {
  max-height: 400px;
  overflow: auto;
}
@media (max-width: 420px) {
  .dropdown-menu-sm {
    margin-left: 14px !important;
  }
}
.nk-quick-nav .dropdown-menu {
  margin-top: 9px !important;
  margin-right: -6px !important;
}
@media (max-width: 420px) {
  .dropdown-menu-xl,
  .dropdown-menu-lg,
  .dropdown-menu-md {
    min-width: calc(100vw - 40px);
    max-width: calc(100vw - 40px);
  }
  .nk-quick-nav .dropdown-menu-xl,
  .nk-quick-nav .dropdown-menu-lg,
  .nk-quick-nav .dropdown-menu-md {
    margin-right: -21px !important;
  }
  .nk-quick-nav li:last-child .dropdown-menu-xl,
  .nk-quick-nav li:last-child .dropdown-menu-lg,
  .nk-quick-nav li:last-child .dropdown-menu-md {
    margin-right: 3px !important;
  }
}
.dropdown-menu-s1 {
  border-top: 3px solid #11c8fc;
}
.dropdown-toggle-split > .icon {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.card {
  box-shadow: 0 0 25px -12px rgba(31, 43, 58, 0.15);
}
.card .card {
  box-shadow: 0 0 4px rgba(31, 43, 58, 0.15);
}
.card + .card:not(.card .card + .card) {
  margin-top: 28px;
}
.card + .nk-block-head {
  padding-top: 2.5rem;
}
.card-full {
  height: 100%;
}
.card.is-dark {
  background: #170c2f;
}
.card-bordered {
  border: 1px solid #dbdfea;
}
.card-bordered.is-dark {
  border-color: #170c2f;
}
.card-bordered.dashed {
  border-style: dashed;
}
.card-inner {
  padding: 1.25rem;
}
.card-inner-sm {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.card-inner-group .card-inner:not(:last-child) {
  border-bottom: 1px solid #dbdfea;
}
.card-head {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -0.25rem -0.5rem 1rem;
}
.card-head > * {
  padding: 0.25rem 0.5rem;
}
.card-head > .title:only-child {
  width: 100%;
}
.card-title-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.card-title-group .card-title {
  margin-bottom: 0;
}
.card-title-group:only-child,
.card-title-group .card-search {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}
.card-title h6.title:not(:last-child),
.card-title .title.h6:not(:last-child) {
  margin-bottom: 0.25rem;
}
.card-title p {
  font-size: 12px;
  color: #8094ae;
}
.card-title p .icon {
  line-height: inherit;
  vertical-align: middle;
}
.card-title-sm .title {
  font-size: 14px;
}
.card-title small,
.card-title .small {
  font-weight: 500;
  color: #8094ae;
}
.card-subtitle {
  color: #8094ae;
  font-family: Roboto, sans-serif;
  font-weight: normal;
}
.card-tools-nav {
  display: flex;
  margin: -6px -10px -5px;
}
.card-tools-nav li {
  padding: 0 10px;
}
.card-tools-nav li a {
  position: relative;
  color: #8094ae;
  padding: 10px 0;
  display: block;
  line-height: 1;
}
.card-tools-nav li a:before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  content: "";
  background: #11c8fc;
  opacity: 0;
}
.card-tools-nav li a:hover,
.card-tools-nav li a.active {
  color: #526484;
}
.card-tools-nav li a.active::before {
  opacity: 1;
}
.card-tools-nav li.active a {
  color: #526484;
}
.card-tools-nav li.active a::before {
  opacity: 1;
}
.card-tools.me-n1 {
  margin-right: -0.5rem !important;
}
.card-hint {
  font-size: 1rem;
  color: #dbdfea;
}
@media (max-width: 575.98px) {
  .card-head.ui-v2 .card-tools {
    width: calc(100% + 20px);
    margin: 0 -10px;
    padding-top: 0.5rem;
  }
  .card-head.ui-v2 .card-tools-nav {
    padding: 0 0.75rem;
    border-bottom: 1px solid #e5e9f2;
  }
  .card-head.ui-v2 .card-tools-nav li a {
    padding-bottom: 1rem;
  }
  .card-stretch {
    margin-left: -18px;
    margin-right: -18px;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
  }
}
@media (min-width: 576px) {
  .card-inner {
    padding: 1.5rem;
  }
  .card-inner-sm {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .card-inner-md {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .card-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (min-width: 768px) {
  .card-inner-lg {
    padding: 2.5rem;
  }
  .card-inner-xl {
    padding: 3.75rem;
  }
}
.form-icon {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: calc(1rem + 24px);
  height: calc(2.125rem + 2px);
}
.form-icon.sm {
  height: calc(1.75rem + 2px);
}
.form-icon.lg {
  height: calc(2.625rem + 2px);
}
.form-icon.xl {
  height: calc(3.25rem + 2px);
}
.form-icon .icon {
  font-size: 16px;
  color: #8094ae;
}
.form-icon + .form-control,
.dual-listbox .form-icon + .dual-listbox__search,
div.dataTables_wrapper div.dataTables_filter .form-icon + input {
  padding-left: calc(1rem + 24px);
}
.form-icon-right {
  left: auto;
  right: -1px;
}
.form-icon-right + .form-control,
.dual-listbox .form-icon-right + .dual-listbox__search,
div.dataTables_wrapper div.dataTables_filter .form-icon-right + input {
  padding-left: 1rem;
  padding-right: calc(1rem + 24px);
}
.form-info {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #dbdfea;
  right: 0;
  top: 50%;
  padding: 0.125rem 1.25rem;
  transform: translateY(-50%);
  color: #8094ae;
}
.form-info + .form-control,
.dual-listbox .form-info + .dual-listbox__search,
div.dataTables_wrapper div.dataTables_filter .form-info + input {
  padding-right: calc(1rem + 24px);
}
.form-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #344357;
  margin-bottom: 0.5rem;
}
.form-label-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.form-label-group .form-label {
  margin-bottom: 0;
}
.form-label + .form-note {
  margin-top: -0.35rem;
}
.form-label-outlined {
  position: absolute;
  top: 0.4375rem;
  left: 0.6666666667rem;
  transform-origin: left;
  padding: 0 0.4rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #3c4d62;
  background-color: #fff;
  z-index: 1;
  transition: all 0.3s ease;
  margin-bottom: 0;
  font-size: 0.8125rem;
}
.form-control-lg ~ .form-label-outlined {
  top: 0.6875rem;
  padding: 0 0.3773584906rem;
  font-size: 0.9375rem;
}
.form-control-sm ~ .form-label-outlined {
  font-size: 0.75rem;
  top: 0.25rem;
  left: 0.4rem;
}
.focused .form-control-sm ~ .form-label-outlined,
.js-select2.form-control-sm ~ .form-label-outlined {
  font-size: 11px;
}
.focused .form-label-outlined,
.js-select2 ~ .form-label-outlined {
  top: calc(-0.4375rem + -2px);
  font-size: 11px;
}
.form-control-xl ~ .form-label-outlined {
  font-size: 0.9375rem;
  line-height: 2rem;
  top: 0.625rem;
  padding: 0 0.5rem;
  left: 0.8333333333rem;
}
.focused .form-control-xl ~ .form-label-outlined,
.js-select2.form-control-xl ~ .form-label-outlined {
  top: calc(-0.625rem + -5px);
}
.focused .form-control-lg ~ .form-label-outlined,
.focused .form-control-xl ~ .form-label-outlined,
.js-select2.form-control-lg ~ .form-label-outlined,
.js-select2.form-control-xl ~ .form-label-outlined {
  font-size: 12px;
}
.form-note {
  font-size: 12px;
  color: #8094ae;
  font-style: italic;
  display: block;
}
.form-note-alt {
  font-size: 12px;
  color: #8094ae;
}
.form-note-group {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-bottom: -0.25rem;
}
.form-control-xl {
  height: calc(3.25rem + 2px) !important;
  padding: 0.625rem 1.25rem;
  font-size: 0.9375rem;
  line-height: 2rem;
  border-radius: 5px;
}
.form-control-outlined {
  cursor: pointer;
}
.focused .form-control-outlined {
  cursor: auto;
}
.form-control-wrap,
.form-control-group {
  position: relative;
}
.form-control-wrap + .form-note,
.form-control-group + .form-note {
  margin-top: 0.5rem;
}
.form-control-noborder {
  border-color: rgba(0, 0, 0, 0);
}
.form-control-noborder:focus {
  border-color: #dbdfea;
  box-shadow: none;
}
.form-control-solid {
  border-color: #fff;
}
.form-control-solid:focus {
  box-shadow: none;
  border-color: #dbdfea;
}
.form-control-number {
  font-size: 20px;
  padding: 20px;
  height: 74px;
}
.form-control-password-big {
  font-size: 44px;
  height: calc(1rem * 2 + 1.25rem + 1px);
  padding: 1rem 1rem;
}
.form-control.error,
.dual-listbox .error.dual-listbox__search,
div.dataTables_wrapper div.dataTables_filter input.error {
  border-color: #f2426e;
}
.form-control.error:focus,
.dual-listbox .error.dual-listbox__search:focus,
div.dataTables_wrapper div.dataTables_filter input.error:focus {
  box-shadow: 0 0 0 3px rgba(242, 66, 110, 0.1);
}
.form-control.focus,
.dual-listbox .focus.dual-listbox__search,
div.dataTables_wrapper div.dataTables_filter input.focus {
  border-color: #11c8fc;
  box-shadow: 0 0 0 3px rgba(115, 58, 234, 0.1);
}
.form-control-simple {
  border: none;
  padding: 0;
}
.form-control-simple:focus {
  box-shadow: none;
}
.form-control-plaintext {
  font-size: 0.8125rem;
  color: #3c4d62;
}
.form-control-plaintext:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0);
}
.form-control-plaintext.form-control-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.form-control-plaintext.form-control-lg {
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem;
}
.form-control-plaintext.form-control-xl {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.form-control-plaintext::placeholder {
  color: #b6c6e3;
  opacity: 1;
}
.form-group {
  position: relative;
  margin-bottom: 1.25rem;
}
.form-group:last-child {
  margin-bottom: 0;
}
.form-focus-none:focus {
  border-color: rgba(0, 0, 0, 0);
  box-shadow: none;
}
.form-action .btn + .btn,
.form-action .actions ul li a + .btn,
.actions ul li .form-action a + .btn,
.form-action .actions ul li .btn + a,
.actions ul li .form-action .btn + a,
.form-action .actions ul li a + a,
.actions ul li .form-action a + a,
.form-action .dual-listbox .dual-listbox__button + .btn,
.form-action .dual-listbox .actions ul li .dual-listbox__button + a,
.actions ul li .form-action .dual-listbox .dual-listbox__button + a,
.dual-listbox .form-action .dual-listbox__button + .btn,
.dual-listbox .form-action .actions ul li .dual-listbox__button + a,
.actions ul li .dual-listbox .form-action .dual-listbox__button + a,
.form-action .dual-listbox .btn + .dual-listbox__button,
.form-action .dual-listbox .actions ul li a + .dual-listbox__button,
.actions ul li .form-action .dual-listbox a + .dual-listbox__button,
.dual-listbox .form-action .btn + .dual-listbox__button,
.dual-listbox .form-action .actions ul li a + .dual-listbox__button,
.actions ul li .dual-listbox .form-action a + .dual-listbox__button,
.form-action .dual-listbox .dual-listbox__button + .dual-listbox__button,
.dual-listbox .form-action .dual-listbox__button + .dual-listbox__button {
  margin-left: 1.25rem;
}
.form-round {
  border-radius: 100px;
}
.form-select {
  appearance: none;
  -webkit-appearance: none;
}
.form-select option {
  border-radius: 3px;
  padding: 5px 0.5rem;
  margin: 2px -0.5rem;
  cursor: pointer;
  color: #526484;
}
.form-select option:last-child {
  margin-bottom: -0.5rem;
}
.form-control-select {
  position: relative;
}
.form-control-select .form-control,
.form-control-select .dual-listbox .dual-listbox__search,
.dual-listbox .form-control-select .dual-listbox__search,
.form-control-select div.dataTables_wrapper div.dataTables_filter input,
div.dataTables_wrapper div.dataTables_filter .form-control-select input {
  appearance: none;
  -webkit-appearance: none;
}
.form-control-select-multiple .form-select {
  padding-top: 0.375rem;
  padding-bottom: 0.875rem;
}
.form-control-select-multiple .form-select option {
  border-radius: 3px;
  padding: 5px 0.5rem;
  margin: 2px -0.5rem;
  cursor: pointer;
  color: #526484;
}
.form-control-select-multiple .form-select option:last-child {
  margin-bottom: -0.5rem;
}
.form-control-select:after {
  font-family: "Nioicon";
  content: "";
  pointer-events: none;
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: calc(1rem + 12px);
  height: calc(1rem + 12px);
}
textarea.no-resize {
  resize: none;
}
textarea.form-control,
.dual-listbox textarea.dual-listbox__search {
  min-height: 122px;
  padding-top: 0.65625rem;
}
textarea.textarea-sm {
  min-height: 92px;
}
textarea.textarea-lg {
  min-height: 208px;
}
textarea.textarea-auto {
  min-height: 0;
}
.custom-file-input ~ .error,
.custom-file-input ~ .invalid,
.form-control ~ .error,
.dual-listbox .dual-listbox__search ~ .error,
div.dataTables_wrapper div.dataTables_filter input ~ .error,
.form-control ~ .invalid,
.dual-listbox .dual-listbox__search ~ .invalid,
div.dataTables_wrapper div.dataTables_filter input ~ .invalid,
.input-group ~ .error,
.input-group ~ .invalid,
.js-select2 ~ .error,
.js-select2 ~ .invalid,
.form-select ~ .error,
.form-select ~ .invalid {
  color: #f2426e;
  font-size: 11px;
  font-style: italic;
}
.custom-btn-check {
  position: relative;
}
.custom-btn-check input {
  position: absolute;
  opacity: 0;
  height: 1px;
  width: 1px;
}
.custom-btn-check input:checked ~ label {
  color: #fff;
  border-color: #11c8fc;
  background-color: #11c8fc;
}
.custom-btn-check label {
  display: inline-block;
  border: 2px solid #e5e9f2;
  background-color: #e5e9f2;
  font-weight: 500;
  color: #526484;
  text-align: center;
  padding: 0.4375rem 1.125rem;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  border-radius: 4px;
  transition: all 0.3s;
  margin-bottom: 0 !important;
}
.custom-btn-sm label {
  padding: 0.25rem 1rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
}
.custom-btn-round label {
  border-radius: 60px;
}
.custom-btn-outline label {
  background-color: rgba(0, 0, 0, 0);
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-control,
.dual-listbox .dual-listbox__search,
div.dataTables_wrapper div.dataTables_filter input {
  min-height: calc(2.125rem + 2px);
}
.custom-control {
  position: relative;
  z-index: 1;
  display: inline-flex;
  min-height: 1.5rem;
  padding-left: 2.25rem;
}
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.5rem;
  height: 1.471875rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #11c8fc;
  background-color: #11c8fc;
  box-shadow: none;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 3px rgba(115, 58, 234, 0.1);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #11c8fc;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #11c8fc;
  border-color: #11c8fc;
  box-shadow: none;
}
.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #3c4d62;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label:before,
.custom-control-label:after {
  z-index: 1;
}
.custom-control-label::before {
  position: absolute;
  top: -0.028125rem;
  left: -2.25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #dbdfea solid 2px;
  box-shadow: inset 0 1px 1px rgba(16, 25, 36, 0.075);
  border-radius: 4px;
}
.custom-control-label::after {
  position: absolute;
  top: -0.028125rem;
  left: -2.25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}
.custom-control {
  display: inline-flex;
  min-height: 1.5rem;
}
.custom-control.color-control {
  padding: 4px !important;
}
.custom-control.color-control .custom-control-label::before {
  opacity: 0;
  display: block;
  left: -4px;
  right: -4px;
  top: -4px;
  bottom: -4px;
  border-radius: 50%;
  border: 2px solid #11c8fc !important;
  background: rgba(0, 0, 0, 0) !important;
  transition: 0.3s ease;
}
.custom-control.color-control .custom-control-label::after {
  display: none;
}
.custom-control.color-control
  .custom-control-input:checked
  ~ .custom-control-label::before {
  opacity: 1;
}
.custom-control.notext {
  margin-left: -0.75rem;
}
.custom-control.notext .custom-control-label {
  position: relative;
  left: 0.75rem;
}
.custom-control-label {
  font-size: 14px;
  line-height: 1.25rem;
  padding-top: 0.125rem;
}
.custom-control-label::before,
.custom-control-label::after {
  top: 0;
}
.custom-control-label:after {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-image: none !important;
  font-family: "Nioicon";
  color: #fff;
  opacity: 0;
}
.custom-checkbox .custom-control-label:after {
  content: "";
}
.custom-radio .custom-control-label:after {
  content: "";
}
.custom-control .custom-control-input:checked ~ .custom-control-label::after {
  opacity: 1;
}
.custom-control .custom-control-input[disabled] ~ .custom-control-label,
.custom-control .custom-control-input:disabled ~ .custom-control-label {
  opacity: 0.7;
  color: #364a63;
}
.custom-control-sm {
  min-height: 1.125rem;
  padding-left: 1.625rem;
}
.custom-control-sm.notext {
  margin-left: -0.5rem;
}
.custom-control-sm.notext .custom-control-label {
  left: 0.5rem;
}
.custom-control-sm .custom-control-label {
  font-size: 12px;
  line-height: 1.125rem;
  padding-top: 0;
}
.custom-control-sm .custom-control-label::before,
.custom-control-sm .custom-control-label::after {
  left: -1.625rem;
  width: 1.125rem;
  height: 1.125rem;
}
.custom-control-sm .custom-control-label:after {
  font-size: 0.9em;
}
.custom-control-lg {
  min-height: 2rem;
  padding-left: 3rem;
}
.custom-control-lg.notext {
  margin-left: -1rem;
}
.custom-control-lg.notext .custom-control-label {
  left: 1rem;
}
.custom-control-lg .custom-control-label {
  font-size: 15px;
  line-height: 1.375rem;
  padding-top: 0.3125rem;
}
.custom-control-lg .custom-control-label::before,
.custom-control-lg .custom-control-label::after {
  left: -3rem;
  width: 2rem;
  height: 2rem;
}
.custom-control-lg .custom-control-label:after {
  font-size: 1.2em;
}
.custom-control-input {
  position: absolute;
  height: 1px;
  width: 1px;
  opacity: 0;
}
.custom-control.is-right {
  padding-left: 0;
  padding-right: 2.25rem;
}
.custom-control.is-right .custom-control-label:before,
.custom-control.is-right .custom-control-label:after {
  left: auto;
  right: -2.25rem;
}
.custom-control.is-right.custom-control-sm {
  padding-right: 1.625rem;
}
.custom-control.is-right.custom-control-sm .custom-control-label:before,
.custom-control.is-right.custom-control-sm .custom-control-label:after {
  right: -1.625rem;
}
.custom-control.is-right.custom-control-lg {
  padding-right: 3rem;
}
.custom-control.is-right.custom-control-lg .custom-control-label:before,
.custom-control.is-right.custom-control-lg .custom-control-label:after {
  right: -3rem;
}
.custom-file-label {
  white-space: nowrap;
  overflow: hidden;
}
.custom-select,
div.dataTables_wrapper div.dataTables_length select {
  background: #fff;
}
.custom-control-group {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -0.375rem;
}
.custom-control-group > * {
  padding: 0.375rem;
}
.custom-control-stacked {
  margin: 0;
}
.custom-control-stacked > * {
  padding: 0;
  margin: -1px;
}
.custom-control-stacked:not(.custom-control-vertical)
  > *:not(:first-child)
  .custom-control-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control-stacked:not(.custom-control-vertical)
  > *:not(:last-child)
  .custom-control-label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.custom-control-vertical {
  flex-direction: column;
  align-items: start;
}
.custom-control-vertical > * {
  width: 100%;
}
.custom-control-vertical .custom-control {
  display: flex;
}
.custom-control-vertical .custom-control .custom-control-label {
  width: 100%;
}
.custom-control-vertical.custom-control-stacked
  > *:not(:first-child)
  .custom-control-label {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.custom-control-vertical.custom-control-stacked
  > *:not(:last-child)
  .custom-control-label {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.custom-control-pro {
  padding-left: 0;
  position: relative;
}
.custom-control-pro.no-control.checked {
  z-index: 3;
}
.custom-control-pro.no-control.focused {
  z-index: 2;
}
.custom-control-pro.no-control .custom-control-label {
  padding: 0.375rem 1.125rem;
  border-width: 2px;
}
.custom-control-pro.no-control
  .custom-control-input:checked
  ~ .custom-control-label {
  border-color: #11c8fc;
  box-shadow: none;
  z-index: 2;
}
.custom-control-pro.no-control
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label {
  border-color: #11c8fc;
  box-shadow: none;
}
.custom-control-pro.no-control
  .custom-control-input[disabled]
  ~ .custom-control-label,
.custom-control-pro.no-control
  .custom-control-input:disabled
  ~ .custom-control-label {
  color: #3c4d62;
}
.custom-control-pro-block.custom-control {
  display: flex;
}
.custom-control-pro-block .custom-control-label {
  width: 100%;
}
.custom-control-pro .custom-control-label {
  border: 1px solid #e5e9f2;
  padding: 1.125rem 1.125rem 1.125rem 3.375rem;
  font-size: 13px;
  line-height: 1.25rem;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: inline-flex;
  align-items: center;
  background-color: #fff;
}
.custom-control-pro .custom-control-label::before,
.custom-control-pro .custom-control-label::after {
  top: 50%;
  transform: translateY(-50%);
  left: 1.125rem;
}
.custom-control-pro .custom-control-label .icon {
  font-size: 1.4em;
  line-height: inherit;
}
.custom-control-pro .custom-control-label .icon-lg {
  font-size: 2.2em;
}
.custom-control-pro .custom-control-label > span {
  white-space: nowrap;
}
.custom-control-pro .custom-control-label > span:only-child {
  width: 100%;
}
.custom-control-pro .custom-control-label .icon + span,
.custom-control-pro .custom-control-label span + .icon {
  padding-left: 8px;
}
.custom-control-pro.custom-control-sm {
  padding-left: 0;
}
.custom-control-pro.custom-control-sm .custom-control-label {
  padding: 0.6875rem 1.125rem 0.6875rem 3rem;
  font-size: 13px;
  line-height: 1.25rem;
  border-radius: 4px;
}
.custom-control-pro.custom-control-sm .custom-control-label::before,
.custom-control-pro.custom-control-sm .custom-control-label::after {
  top: 50%;
  transform: translateY(-50%);
  left: 1.125rem;
}
.custom-control-pro-icon.custom-control-pro .custom-control-label {
  padding-left: 0;
  padding-right: 0;
}
.custom-control-pro-icon.custom-control-pro .custom-control-label .icon {
  text-align: center;
  width: 2.125rem;
}
.pro-control.custom-control {
  padding-left: 0;
}
.pro-control .custom-control-label {
  padding: 1.5rem;
  border-radius: 4px;
  border: 1px solid #e5e9f2;
  overflow: hidden;
  transition: 0.3s ease;
}
.pro-control .custom-control-label:before,
.pro-control .custom-control-label:after {
  z-index: 9;
}
.pro-control .custom-control-label::before {
  border-width: 1px;
  top: 1rem;
  left: 1rem;
}
.pro-control .custom-control-label::after {
  top: 1rem;
  left: 1rem;
}
.pro-control .custom-control-label .icon-wrap {
  height: 36px;
}
.pro-control .custom-control-label .icon-wrap img {
  height: 100%;
}
.pro-control .custom-control-label .icon-wrap.lg {
  height: 48px;
}
.pro-control .custom-control-label .icon-wrap.xl {
  height: 60px;
}
.pro-control .custom-control-label .text-center .icon {
  margin-left: auto;
  margin-right: auto;
}
.pro-control .custom-control-input:checked ~ .custom-control-label {
  border-color: #11c8fc;
}
.custom-control-full {
  width: 100%;
  height: 100%;
}
.custom-control-full .custom-control-label {
  width: 100%;
  height: 100%;
}
.no-control {
  padding-left: 0;
}
.no-control .custom-control-label::before,
.no-control .custom-control-label::after {
  display: none;
}
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: #11c8fc;
}
.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: #11c8fc;
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: #11c8fc;
}
.image-control.custom-control {
  padding-left: 0;
}
.image-control .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #fff;
}
.image-control .custom-control-input:checked ~ .custom-control-label img {
  opacity: 0.8;
}
.image-control .custom-control-label {
  padding-top: 0;
  border-radius: 4px;
  overflow: hidden;
  background-color: #1f2b3a;
}
.image-control .custom-control-label img {
  transition: opacity 0.3s ease;
}
.image-control .custom-control-label:before,
.image-control .custom-control-label:after {
  z-index: 9;
}
.image-control .custom-control-label::before {
  border-width: 1px;
  top: 1rem;
  left: 1rem;
}
.image-control .custom-control-label::after {
  top: 1rem;
  left: 1rem;
}
.image-control:hover .custom-control-label img {
  opacity: 0.8;
}
.custom-switch {
  padding-left: 3.75rem;
}
.custom-switch .custom-control-label::before {
  left: -3.75rem;
  width: 3rem;
  border-radius: 1.5rem;
  pointer-events: all;
}
.custom-switch .custom-control-label::after {
  opacity: 1;
  top: 0.25rem;
  left: -3.5rem;
  width: 1rem;
  height: 1rem;
  border-radius: 1.5rem;
  background-color: #dbdfea;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: #11c8fc;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(calc(2rem - 8px));
}
.custom-switch.custom-control-sm {
  padding-left: 2.46875rem;
}
.custom-switch.custom-control-sm .custom-control-label::before {
  left: -2.46875rem;
  width: 1.96875rem;
  border-radius: 0.984375rem;
}
.custom-switch.custom-control-sm .custom-control-label::after {
  top: 0.2375rem;
  left: calc(-2.46875rem + 4px);
  width: 0.65rem;
  height: 0.65rem;
  border-radius: 0.984375rem;
}
.custom-switch.custom-control-sm
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(calc(1.31875rem - 8px));
}
.custom-switch.custom-control-lg {
  padding-left: 5rem;
}
.custom-switch.custom-control-lg .custom-control-label::before {
  left: -5rem;
  width: 4rem;
  border-radius: 2rem;
}
.custom-switch.custom-control-lg .custom-control-label::after {
  top: 0.375rem;
  left: calc(-5rem + 6px);
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 2rem;
}
.custom-switch.custom-control-lg
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(calc(2.75rem - 12px));
}
.custom-switch.is-right {
  padding-left: 0;
  padding-right: 3.75rem;
}
.custom-switch.is-right .custom-control-label:before,
.custom-switch.is-right .custom-control-label:after {
  left: auto;
  right: -2.25rem;
}
.custom-switch.is-right .custom-control-label::before {
  right: -3.75rem;
}
.custom-switch.is-right .custom-control-label::after {
  right: calc(-3.75rem + 4px);
}
.custom-switch.is-right
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(calc(-2rem + 8px));
}
.custom-switch.is-right.custom-control-sm {
  padding-right: 2.46875rem;
}
.custom-switch.is-right.custom-control-sm .custom-control-label::before {
  right: -2.46875rem;
}
.custom-switch.is-right.custom-control-sm .custom-control-label::after {
  right: calc(-2.46875rem + 4px);
}
.custom-switch.is-right.custom-control-sm
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(calc(-1.31875rem + 8px));
}
.custom-switch.is-right.custom-control-lg {
  padding-right: 5rem;
}
.custom-switch.is-right.custom-control-lg .custom-control-label::before {
  right: -5rem;
}
.custom-switch.is-right.custom-control-lg .custom-control-label::after {
  right: calc(-5rem + 6px);
}
.custom-switch.is-right.custom-control-lg
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(calc(-2.75rem + 12px));
}
label {
  cursor: pointer;
}
input[type="radio"]:checked ~ label {
  cursor: default;
}
.is-alter .form-control ~ .invalid,
.is-alter .dual-listbox .dual-listbox__search ~ .invalid,
.dual-listbox .is-alter .dual-listbox__search ~ .invalid,
.is-alter div.dataTables_wrapper div.dataTables_filter input ~ .invalid,
div.dataTables_wrapper div.dataTables_filter .is-alter input ~ .invalid,
.is-alter .input-group ~ .invalid,
.custom-control .invalid {
  position: absolute;
  left: 0;
  color: #fff;
  font-size: 11px;
  line-height: 1;
  bottom: calc(100% + 4px);
  background: #f5688b;
  padding: 0.3rem 0.5rem;
  z-index: 1;
  border-radius: 3px;
  white-space: nowrap;
}
.is-alter .form-control ~ .invalid:before,
.is-alter .dual-listbox .dual-listbox__search ~ .invalid:before,
.dual-listbox .is-alter .dual-listbox__search ~ .invalid:before,
.is-alter div.dataTables_wrapper div.dataTables_filter input ~ .invalid:before,
div.dataTables_wrapper div.dataTables_filter .is-alter input ~ .invalid:before,
.is-alter .input-group ~ .invalid:before,
.custom-control .invalid:before {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  border-left: 6px solid #f5688b;
  border-right: 6px solid rgba(0, 0, 0, 0);
  left: 10px;
  border-bottom: 6px solid rgba(0, 0, 0, 0);
  bottom: -6px;
}
.is-alter .form-control ~ .invalid,
.is-alter .dual-listbox .dual-listbox__search ~ .invalid,
.dual-listbox .is-alter .dual-listbox__search ~ .invalid,
.is-alter div.dataTables_wrapper div.dataTables_filter input ~ .invalid,
div.dataTables_wrapper div.dataTables_filter .is-alter input ~ .invalid,
.is-alter .input-group ~ .invalid {
  left: auto;
  right: 0;
}
.is-alter .form-control ~ .invalid::before,
.is-alter .dual-listbox .dual-listbox__search ~ .invalid::before,
.dual-listbox .is-alter .dual-listbox__search ~ .invalid::before,
.is-alter div.dataTables_wrapper div.dataTables_filter input ~ .invalid::before,
div.dataTables_wrapper div.dataTables_filter .is-alter input ~ .invalid::before,
.is-alter .input-group ~ .invalid::before {
  left: auto;
  right: 10px;
  border-right-color: #f5688b;
  border-left-color: rgba(0, 0, 0, 0);
  bottom: -4px;
}
.form-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.125rem + 2px);
  margin-bottom: 0;
}
.form-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.125rem + 2px);
  margin: 0;
  opacity: 0;
}
.form-file-input:focus ~ .form-file-label {
  border-color: #11c8fc;
  box-shadow: 0 0 0 3px rgba(115, 58, 234, 0.1);
}
.form-file-input[disabled] ~ .form-file-label,
.form-file-input:disabled ~ .form-file-label {
  background-color: #f5f6fa;
}
.form-file-input:lang(en) ~ .form-file-label::after {
  content: "Browse";
}
.form-file-input ~ .form-file-label[data-browse]::after {
  content: attr(data-browse);
}
.form-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.125rem + 2px);
  padding: 0.4375rem 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #3c4d62;
  background-color: #fff;
  border: 1px solid #dbdfea;
  border-radius: 4px;
}
.form-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.125rem;
  padding: 0.4375rem 1rem;
  line-height: 1.25rem;
  color: #3c4d62;
  content: "Browse";
  background-color: #ebeef2;
  border-left: inherit;
  border-radius: 0 4px 4px 0;
}
.input-group > .form-control,
.dual-listbox .input-group > .dual-listbox__search,
div.dataTables_wrapper div.dataTables_filter .input-group > input,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
div.dataTables_wrapper div.dataTables_length .input-group > select,
.input-group > .form-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.dual-listbox .input-group > .dual-listbox__search + .form-control,
.dual-listbox .input-group > .form-control + .dual-listbox__search,
.dual-listbox .input-group > .dual-listbox__search + .dual-listbox__search,
div.dataTables_wrapper
  div.dataTables_filter
  .input-group
  > input
  + .form-control,
div.dataTables_wrapper
  div.dataTables_filter
  .dual-listbox
  .input-group
  > input
  + .dual-listbox__search,
.dual-listbox
  div.dataTables_wrapper
  div.dataTables_filter
  .input-group
  > input
  + .dual-listbox__search,
div.dataTables_wrapper
  div.dataTables_filter
  .input-group
  > .form-control
  + input,
div.dataTables_wrapper
  div.dataTables_filter
  .dual-listbox
  .input-group
  > .dual-listbox__search
  + input,
.dual-listbox
  div.dataTables_wrapper
  div.dataTables_filter
  .input-group
  > .dual-listbox__search
  + input,
div.dataTables_wrapper div.dataTables_filter .input-group > input + input,
.input-group > .form-control + .custom-select,
.dual-listbox .input-group > .dual-listbox__search + .custom-select,
div.dataTables_wrapper
  div.dataTables_filter
  .input-group
  > input
  + .custom-select,
div.dataTables_wrapper
  div.dataTables_length
  .input-group
  > .form-control
  + select,
div.dataTables_wrapper
  div.dataTables_length
  .dual-listbox
  .input-group
  > .dual-listbox__search
  + select,
.dual-listbox
  div.dataTables_wrapper
  div.dataTables_length
  .input-group
  > .dual-listbox__search
  + select,
div.dataTables_wrapper
  div.dataTables_length
  div.dataTables_filter
  .input-group
  > input
  + select,
div.dataTables_wrapper
  div.dataTables_filter
  div.dataTables_length
  .input-group
  > input
  + select,
.input-group > .form-control + .form-file,
.dual-listbox .input-group > .dual-listbox__search + .form-file,
div.dataTables_wrapper div.dataTables_filter .input-group > input + .form-file,
.input-group > .form-control-plaintext + .form-control,
.dual-listbox .input-group > .form-control-plaintext + .dual-listbox__search,
div.dataTables_wrapper
  div.dataTables_filter
  .input-group
  > .form-control-plaintext
  + input,
.input-group > .form-control-plaintext + .custom-select,
div.dataTables_wrapper
  div.dataTables_length
  .input-group
  > .form-control-plaintext
  + select,
.input-group > .form-control-plaintext + .form-file,
.input-group > .custom-select + .form-control,
.dual-listbox .input-group > .custom-select + .dual-listbox__search,
div.dataTables_wrapper
  div.dataTables_filter
  .input-group
  > .custom-select
  + input,
div.dataTables_wrapper
  div.dataTables_length
  .input-group
  > select
  + .form-control,
div.dataTables_wrapper
  div.dataTables_length
  .dual-listbox
  .input-group
  > select
  + .dual-listbox__search,
.dual-listbox
  div.dataTables_wrapper
  div.dataTables_length
  .input-group
  > select
  + .dual-listbox__search,
div.dataTables_wrapper
  div.dataTables_length
  div.dataTables_filter
  .input-group
  > select
  + input,
div.dataTables_wrapper
  div.dataTables_filter
  div.dataTables_length
  .input-group
  > select
  + input,
.input-group > .custom-select + .custom-select,
div.dataTables_wrapper
  div.dataTables_length
  .input-group
  > select
  + .custom-select,
div.dataTables_wrapper
  div.dataTables_length
  .input-group
  > .custom-select
  + select,
div.dataTables_wrapper div.dataTables_length .input-group > select + select,
.input-group > .custom-select + .form-file,
div.dataTables_wrapper div.dataTables_length .input-group > select + .form-file,
.input-group > .form-file + .form-control,
.dual-listbox .input-group > .form-file + .dual-listbox__search,
div.dataTables_wrapper div.dataTables_filter .input-group > .form-file + input,
.input-group > .form-file + .custom-select,
div.dataTables_wrapper div.dataTables_length .input-group > .form-file + select,
.input-group > .form-file + .form-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.dual-listbox .input-group > .dual-listbox__search:focus,
div.dataTables_wrapper div.dataTables_filter .input-group > input:focus,
.input-group > .custom-select:focus,
div.dataTables_wrapper div.dataTables_length .input-group > select:focus,
.input-group > .form-file .form-file-input:focus ~ .form-file-label {
  z-index: 3;
}
.input-group > .form-file .form-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.dual-listbox .input-group > .dual-listbox__search:not(:last-child),
div.dataTables_wrapper
  div.dataTables_filter
  .input-group
  > input:not(:last-child),
.input-group > .custom-select:not(:last-child),
div.dataTables_wrapper
  div.dataTables_length
  .input-group
  > select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.dual-listbox .input-group > .dual-listbox__search:not(:first-child),
div.dataTables_wrapper
  div.dataTables_filter
  .input-group
  > input:not(:first-child),
.input-group > .custom-select:not(:first-child),
div.dataTables_wrapper
  div.dataTables_length
  .input-group
  > select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-file {
  display: flex;
  align-items: center;
}
.input-group > .form-file:not(:last-child) .form-file-label,
.input-group > .form-file:not(:last-child) .form-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-file:not(:first-child) .form-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-prepend .actions ul li a,
.actions ul li .input-group-prepend a,
.input-group-prepend .dual-listbox .dual-listbox__button,
.dual-listbox .input-group-prepend .dual-listbox__button,
.input-group-append .btn,
.input-group-append .actions ul li a,
.actions ul li .input-group-append a,
.input-group-append .dual-listbox .dual-listbox__button,
.dual-listbox .input-group-append .dual-listbox__button {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-prepend .actions ul li a:focus,
.actions ul li .input-group-prepend a:focus,
.input-group-prepend .dual-listbox .dual-listbox__button:focus,
.dual-listbox .input-group-prepend .dual-listbox__button:focus,
.input-group-append .btn:focus,
.input-group-append .actions ul li a:focus,
.actions ul li .input-group-append a:focus,
.input-group-append .dual-listbox .dual-listbox__button:focus,
.dual-listbox .input-group-append .dual-listbox__button:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .actions ul li a + .btn,
.actions ul li .input-group-prepend a + .btn,
.input-group-prepend .actions ul li .btn + a,
.actions ul li .input-group-prepend .btn + a,
.input-group-prepend .actions ul li a + a,
.actions ul li .input-group-prepend a + a,
.input-group-prepend .dual-listbox .dual-listbox__button + .btn,
.input-group-prepend .dual-listbox .actions ul li .dual-listbox__button + a,
.actions ul li .input-group-prepend .dual-listbox .dual-listbox__button + a,
.dual-listbox .input-group-prepend .dual-listbox__button + .btn,
.dual-listbox .input-group-prepend .actions ul li .dual-listbox__button + a,
.actions ul li .dual-listbox .input-group-prepend .dual-listbox__button + a,
.input-group-prepend .dual-listbox .btn + .dual-listbox__button,
.input-group-prepend .dual-listbox .actions ul li a + .dual-listbox__button,
.actions ul li .input-group-prepend .dual-listbox a + .dual-listbox__button,
.dual-listbox .input-group-prepend .btn + .dual-listbox__button,
.dual-listbox .input-group-prepend .actions ul li a + .dual-listbox__button,
.actions ul li .dual-listbox .input-group-prepend a + .dual-listbox__button,
.input-group-prepend
  .dual-listbox
  .dual-listbox__button
  + .dual-listbox__button,
.dual-listbox
  .input-group-prepend
  .dual-listbox__button
  + .dual-listbox__button,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .actions ul li a + .input-group-text,
.actions ul li .input-group-prepend a + .input-group-text,
.input-group-prepend .dual-listbox .dual-listbox__button + .input-group-text,
.dual-listbox .input-group-prepend .dual-listbox__button + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .actions ul li .input-group-text + a,
.actions ul li .input-group-prepend .input-group-text + a,
.input-group-prepend .dual-listbox .input-group-text + .dual-listbox__button,
.dual-listbox .input-group-prepend .input-group-text + .dual-listbox__button,
.input-group-append .btn + .btn,
.input-group-append .actions ul li a + .btn,
.actions ul li .input-group-append a + .btn,
.input-group-append .actions ul li .btn + a,
.actions ul li .input-group-append .btn + a,
.input-group-append .actions ul li a + a,
.actions ul li .input-group-append a + a,
.input-group-append .dual-listbox .dual-listbox__button + .btn,
.input-group-append .dual-listbox .actions ul li .dual-listbox__button + a,
.actions ul li .input-group-append .dual-listbox .dual-listbox__button + a,
.dual-listbox .input-group-append .dual-listbox__button + .btn,
.dual-listbox .input-group-append .actions ul li .dual-listbox__button + a,
.actions ul li .dual-listbox .input-group-append .dual-listbox__button + a,
.input-group-append .dual-listbox .btn + .dual-listbox__button,
.input-group-append .dual-listbox .actions ul li a + .dual-listbox__button,
.actions ul li .input-group-append .dual-listbox a + .dual-listbox__button,
.dual-listbox .input-group-append .btn + .dual-listbox__button,
.dual-listbox .input-group-append .actions ul li a + .dual-listbox__button,
.actions ul li .dual-listbox .input-group-append a + .dual-listbox__button,
.input-group-append .dual-listbox .dual-listbox__button + .dual-listbox__button,
.dual-listbox .input-group-append .dual-listbox__button + .dual-listbox__button,
.input-group-append .btn + .input-group-text,
.input-group-append .actions ul li a + .input-group-text,
.actions ul li .input-group-append a + .input-group-text,
.input-group-append .dual-listbox .dual-listbox__button + .input-group-text,
.dual-listbox .input-group-append .dual-listbox__button + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .actions ul li .input-group-text + a,
.actions ul li .input-group-append .input-group-text + a,
.input-group-append .dual-listbox .input-group-text + .dual-listbox__button,
.dual-listbox .input-group-append .input-group-text + .dual-listbox__button {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-lg > .form-control,
.dual-listbox .input-group-lg > .dual-listbox__search,
div.dataTables_wrapper div.dataTables_filter .input-group-lg > input,
.input-group-lg > .custom-select,
div.dataTables_wrapper div.dataTables_length .input-group-lg > select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.actions ul li .input-group-lg > .input-group-prepend > a,
.dual-listbox .input-group-lg > .input-group-prepend > .dual-listbox__button,
.input-group-lg > .input-group-append > .btn,
.actions ul li .input-group-lg > .input-group-append > a,
.dual-listbox .input-group-lg > .input-group-append > .dual-listbox__button {
  font-size: 15px;
}
.input-group > .input-group-prepend > .btn,
.actions ul li .input-group > .input-group-prepend > a,
.dual-listbox .input-group > .input-group-prepend > .dual-listbox__button,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.actions ul li .input-group > .input-group-append:not(:last-child) > a,
.dual-listbox
  .input-group
  > .input-group-append:not(:last-child)
  > .dual-listbox__button,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.actions
  ul
  li
  .input-group
  > .input-group-append:last-child
  > a:not(:last-child):not(.dropdown-toggle),
.dual-listbox
  .input-group
  > .input-group-append:last-child
  > .dual-listbox__button:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.actions ul li .input-group > .input-group-append > a,
.dual-listbox .input-group > .input-group-append > .dual-listbox__button,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.actions ul li .input-group > .input-group-prepend:not(:first-child) > a,
.dual-listbox
  .input-group
  > .input-group-prepend:not(:first-child)
  > .dual-listbox__button,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.actions
  ul
  li
  .input-group
  > .input-group-prepend:first-child
  > a:not(:first-child),
.dual-listbox
  .input-group
  > .input-group-prepend:first-child
  > .dual-listbox__button:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-select,
div.dataTables_wrapper div.dataTables_length select {
  height: calc(2.125rem + 2px);
  padding: 0.4375rem 2rem 0.4375rem 1rem !important;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #3c4d62;
  vertical-align: middle;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  appearance: none;
}
.col-sep > * + * {
  border-top: 1px solid #e5e9f2;
}
.col-sep-vr > * + * {
  border-left: 1px solid #e5e9f2;
}
@media (min-width: 576px) {
  .col-sep-sm > * + * {
    border-top: none;
    border-left: 1px solid #e5e9f2;
  }
  .col-sep-sm-vr > * + * {
    border-left: none;
    border-top: 1px solid #e5e9f2;
  }
}
@media (min-width: 768px) {
  .col-sep-md > * + * {
    border-top: none;
    border-left: 1px solid #e5e9f2;
  }
  .col-sep-md-vr > * + * {
    border-left: none;
    border-top: 1px solid #e5e9f2;
  }
}
@media (min-width: 992px) {
  .col-sep-lg > * + * {
    border-top: none;
    border-left: 1px solid #e5e9f2;
  }
  .col-sep-lg-vr > * + * {
    border-left: none;
    border-top: 1px solid #e5e9f2;
  }
}
@media (min-width: 1200px) {
  .col-sep-xl > * + * {
    border-top: none;
    border-left: 1px solid #e5e9f2;
  }
  .col-sep-xl-vr > * + * {
    border-left: none;
    border-top: 1px solid #e5e9f2;
  }
}
@media (min-width: 1540px) {
  .col-sep-xxl > * + * {
    border-top: none;
    border-left: 1px solid #e5e9f2;
  }
  .col-sep-xxl-vr > * + * {
    border-left: none;
    border-top: 1px solid #e5e9f2;
  }
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f5f6fa;
}
.table > :not(caption) > * > * {
  border-top: 1px solid #dbdfea;
  border-bottom: 0;
  background-color: #fff;
}
.table-hover tbody tr:hover {
  color: #526484;
  background-color: #f5f6fa;
}
.table-head {
  background: #f5f6fa;
}
.table > :not(:first-child) {
  border-top: none;
}
.table thead tr:last-child th {
  border-bottom: var(--bs-border-width) solid #dbdfea;
}
.table-dark thead tr:last-child th,
.table thead.table-dark tr:last-child th {
  border-color: #3c4d62;
}
.table td:first-child,
.table th:first-child {
  padding-left: 1.25rem;
}
.table td:last-child,
.table th:last-child {
  padding-right: 1.25rem;
}
.table th {
  line-height: 1.1;
}
.table th.overline-title {
  line-height: 1.25rem;
  font-weight: 500;
}
.table caption {
  font-style: italic;
  padding-left: 1.25rem;
}
.table-bordered td,
.table-bordered th {
  padding-left: 1rem;
  padding-right: 1rem;
}
.table-plain td:first-child,
.table-plain th:first-child {
  padding-left: 0;
}
.table-plain td:last-child,
.table-plain th:last-child {
  padding-right: 0;
}
.table-lg td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.table-lg th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.table-vm td,
.table-vm th,
.table td.vm,
.table th.vm {
  vertical-align: middle;
}
@media (min-width: 576px) {
  .table-lg td:first-child,
  .table-lg th:first-child {
    padding-left: 1.5rem;
  }
  .table-lg td:last-child,
  .table-lg th:last-child {
    padding-right: 1.5rem;
  }
}
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}
.table > :not(caption) > * > * {
  box-shadow: none;
}
.table-sm th,
.table-sm td {
  padding: 0.25rem;
}
.table .table-light th {
  color: #364a63 !important;
  background-color: #f5f6fa;
  border: #dbdfea;
}
.media {
  display: flex;
}
.media-center {
  align-items: center;
}
.media-object {
  display: inline-flex;
}
.media > *:first-child {
  flex-shrink: 0;
}
.media > *:last-child {
  flex-grow: 1;
  max-width: 100%;
}
.nav {
  margin: -0.5rem -1rem;
}
.nav .nav-link {
  display: inline-flex;
  align-items: center;
}
.nav .nav-link .icon + span,
.nav .nav-link span + .icon {
  margin-left: 0.75rem;
}
.nav .nav-link .icon {
  font-size: 1.3em;
}
.nav-tabs {
  font-family: Nunito, sans-serif;
  margin: 0;
  font-size: 0.8125rem;
  border-bottom: 1px solid #dbdfea;
}
.nav-tabs-s2 {
  border-bottom: none;
  justify-content: center;
}
.nav-tabs + .tab-content {
  margin-top: 1.25rem;
}
.nav-tabs .nav-item {
  padding-right: 1.25rem;
}
.nav-tabs .nav-item:last-child {
  padding-right: 0;
}
.nav-tabs .nav-item.active .nav-link {
  color: #11c8fc;
}
.nav-tabs .nav-item.active .nav-link:after {
  opacity: 1;
}
.nav-tabs .nav-link {
  padding: 1rem 0;
  font-weight: 700;
  color: #526484;
  position: relative;
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center;
}
.nav-tabs .nav-link .icon {
  font-size: 1.4em;
}
.nav-tabs .nav-link .icon + span {
  margin-left: 0.5rem;
}
.nav-tabs .nav-link .badge {
  margin-left: 0.25rem;
}
.nav-tabs .nav-link:hover {
  color: #364a63;
}
.nav-tabs .nav-link:focus {
  color: #11c8fc;
}
.nav-tabs .nav-link:before,
.nav-tabs .nav-link:after {
  position: absolute;
  content: "";
}
.nav-tabs .nav-link:after {
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background: #11c8fc;
  opacity: 0;
}
.nav-tabs .nav-link.active {
  color: #11c8fc;
  border: none;
  background-color: rgba(0, 0, 0, 0);
}
.nav-tabs .nav-link.active:after {
  opacity: 1;
}
.nav-sm {
  font-size: 0.8125rem;
}
@media (min-width: 768px) {
  .nav-tabs .nav-item {
    padding-right: 1.5rem;
  }
  .nav-tabs-sm .nav-item {
    padding-right: 1.25rem;
  }
}
@media (min-width: 992px) {
  .nav-tabs .nav-item {
    padding-right: 1.75rem;
  }
  .nav-tabs-sm .nav-item {
    padding-right: 1.25rem;
  }
  .nav-tabs .nav-link {
    font-size: 0.875rem;
  }
}
@media (min-width: 1200px) {
  .nav-tabs .nav-item {
    padding-right: 2.25rem;
  }
  .nav-tabs-sm .nav-item {
    padding-right: 1.25rem;
  }
}
@media (max-width: 575.98px) {
  .nav-tabs.is-stretch {
    margin-left: -18px;
    margin-right: -18px;
    padding-left: 18px;
    padding-right: 18px;
  }
}
.nav-item-trigger {
  display: inline-flex;
  align-items: center;
  margin: -1px -0.5rem 0 auto;
}
.nav-tabs-card {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-family: Roboto, sans-serif;
}
@media (min-width: 576px) {
  .nav-tabs-card {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .nav-tabs-mb-icon .nav-item {
    padding-right: 0.75rem;
  }
  .nav-tabs-mb-icon .nav-link .icon {
    font-size: 1.25rem;
    padding: 0 0.5rem;
  }
  .nav-tabs-mb-icon .nav-link .icon + span {
    display: none;
  }
}
@media (max-width: 359px) {
  .nav-tabs-mb-icon .nav-item {
    padding-right: 0.35rem;
  }
}
.is-medium.nav-tabs .nav-link {
  padding: 0.75rem 0;
}
.modal-content {
  position: relative;
  min-height: 40px;
  box-shadow: 0px 0px 1px 0px rgba(82, 100, 132, 0.2),
    0px 8px 15.52px 0.48px rgba(28, 43, 70, 0.15);
}
.modal-content > .close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  height: 2.25rem;
  width: 2.25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #526484;
  z-index: 1;
  transition: all 0.3s;
}
.modal-body .stretched {
  border-radius: 0;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.modal-body .stretched.alert {
  border: none;
}
.modal-body .stretched.alert-icon {
  padding-left: 3.25rem;
}
.modal-body-md {
  padding: 1.75rem 1.25rem;
}
.modal-body-md .stretched {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.modal-body-md .stretched.alert-icon {
  padding-left: 4.5rem;
}
.modal-body-lg {
  padding: 2.25rem 1.25rem;
}
.modal-body-lg .stretched {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.modal-body-lg .stretched.alert-icon {
  padding-left: 4.5rem;
}
.modal-footer-stretch {
  justify-content: stretch;
}
.modal-footer-between {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: -0.25rem 0;
}
.modal-footer-between > div {
  padding: 0.25rem 0;
}
.modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog::before {
  height: calc(100vh - 1rem);
}
.modal.fade .modal-dialog {
  transform: translate(0, -10px);
}
.modal.zoom .modal-dialog {
  transform: scale(0.95);
  transition: transform 0.3s ease-out;
}
.modal-dialog-top {
  align-items: flex-start;
}
.modal.fade .modal-dialog-top {
  transform: translate(0, -30px);
}
.modal-dialog-bottom {
  align-items: flex-end;
}
@media (min-width: 576px) {
  .modal-dialog {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-body {
    padding: 1.5rem 1.5rem;
  }
  .modal-body .stretched {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .modal-body .stretched.alert-icon {
    padding-left: 3.5rem;
  }
  .modal-body-sm {
    padding: 1.25rem 1.25rem;
  }
  .modal-body-sm .stretched {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .modal-body-sm .stretched.alert-icon {
    padding-left: 3.25rem;
  }
  .modal-body-md {
    padding: 2.25rem 2.5rem;
  }
  .modal-body-md .stretched {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .modal-body-md .stretched.alert-icon {
    padding-left: 4.5rem;
  }
  .modal-body-md .stretched.alert-icon > .icon {
    left: 2.5rem;
  }
  .modal-body-lg {
    padding: 3.75rem 3.75rem;
  }
  .modal-body-lg .stretched {
    margin-left: -3.75rem;
    margin-right: -3.75rem;
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
  .modal-body-lg .stretched.alert-icon {
    padding-left: 5.75rem;
  }
  .modal-header {
    padding: 1rem 1.5rem;
  }
  .modal-header-sm {
    padding: 0.75rem 1.25rem;
  }
  .modal-footer {
    padding: 1rem 1.5rem;
  }
  .modal-footer-sm {
    padding: 0.75rem 1.25rem;
  }
}
@media (min-width: 768px) {
  .modal-md {
    max-width: 600px;
  }
}
.pagination-goto {
  display: inline-flex;
  align-items: center;
  font-size: 0.8125rem;
  text-transform: uppercase;
}
.page-link {
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1rem;
  min-width: calc(1rem + 1.125rem + 2px);
}
.pagination-sm .page-link {
  line-height: 0.875rem;
  min-width: calc(0.875rem + 0.875rem + 2px);
}
.pagination-lg .page-link {
  line-height: 1.5rem;
  min-width: calc(1.5rem + 1.125rem + 2px);
}
.pagination-xl .page-link {
  padding: 0.875rem 0.75rem;
  font-size: 1.38125rem;
  line-height: 1.5rem;
  min-width: calc(1.5rem + 1.75rem + 2px);
}
.page-link .icon {
  font-size: 0.8125rem;
  line-height: inherit;
}
.page-link:not([href]) {
  background-color: rgba(0, 0, 0, 0);
  color: inherit;
}
.page-link:not([href]) .icon:before {
  position: relative;
  top: 1px;
}
.page-link-prev span,
.page-link-next span {
  display: none;
}
@media (min-width: 576px) {
  .page-link-prev span,
  .page-link-next span {
    display: block;
  }
  .page-link-prev .icon {
    padding-right: 0.25rem;
  }
  .page-link-next .icon {
    padding-left: 0.25rem;
  }
}
.progress-bar {
  height: 100%;
  line-height: 1;
}
.progress-sm {
  height: 0.25rem;
}
.progress-md {
  height: 0.375rem;
}
.progress-lg {
  height: 1rem;
}
.progress-pill,
.progress-pill .progress-bar {
  border-radius: 100px;
}
.progress-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #8094ae;
  margin-bottom: 0.25rem;
}
.progress-label {
  font-size: 0.875rem;
}
.progress-amount {
  font-size: 12px;
  padding-left: 0.5rem;
}
.progress-alt {
  display: flex;
  align-items: center;
  overflow: visible;
}
.progress-alt .progress-bar {
  border-radius: 2px;
}
.progress-reverse {
  flex-direction: row-reverse;
}
.progress-bordered .progress-bar {
  margin-top: -2px;
  border: 2px solid #fff;
  box-sizing: content-box;
}
.progress-bordered .progress-bar:not(:first-child) {
  margin-left: -4px;
}
.progress-bordered.progress-reverse .progress-bar {
  margin-left: 0;
}
.progress-bordered.progress-reverse .progress-bar:not(:last-child) {
  margin-left: -5px;
}
.progress-rating {
  display: flex;
  align-items: center;
}
.progress-rating-title {
  width: 48px;
  flex-grow: 0;
  line-height: 1.25rem;
}
.progress-rating-percent {
  width: 40px;
  line-height: 1.25rem;
  text-align: right;
}
.progress-rating .progress {
  flex-grow: 1;
}
.toast .close {
  display: flex;
  border: none;
  background: rgba(0, 0, 0, 0);
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  margin-right: -0.5rem;
  margin-left: 0.5rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.ni {
  font-family: "Nioicon" !important;

  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ni-bugs-alt-fill:before {
  content: "";
}
.ni-bugs-alt:before {
  content: "";
}
.ni-bulb-alt-fill:before {
  content: "";
}
.ni-bulb-alt:before {
  content: "";
}
.ni-chat-msg-fill:before {
  content: "";
}
.ni-chat-msg:before {
  content: "";
}
.ni-diamond-fill:before {
  content: "";
}
.ni-diamond:before {
  content: "";
}
.ni-file-code-fill:before {
  content: "";
}
.ni-google-play-store-alt:before {
  content: "";
}
.ni-laptop-fill:before {
  content: "";
}
.ni-mail-alt:before {
  content: "";
}
.ni-newspaper-fill:before {
  content: "";
}
.ni-newspaper:before {
  content: "";
}
.ni-pie-2-fill:before {
  content: "";
}
.ni-pie-2:before {
  content: "";
}
.ni-presentation-fill:before {
  content: "";
}
.ni-presentation:before {
  content: "";
}
.ni-projector-screen-fill:before {
  content: "";
}
.ni-projector-screen:before {
  content: "";
}
.ni-quote-sq-left-fill:before {
  content: "";
}
.ni-quote-sq-left:before {
  content: "";
}
.ni-quote-sq-right-fill:before {
  content: "";
}
.ni-quote-sq-right:before {
  content: "";
}
.ni-send-fill:before {
  content: "";
}
.ni-setting-question-fill:before {
  content: "";
}
.ni-setting-question:before {
  content: "";
}
.ni-support-fill:before {
  content: "";
}
.ni-support:before {
  content: "";
}
.ni-traffic-signal-fill:before {
  content: "";
}
.ni-traffic-signal:before {
  content: "";
}
.ni-tree-structure-fill:before {
  content: "";
}
.ni-tree-structure:before {
  content: "";
}
.ni-trophy-fill:before {
  content: "";
}
.ni-trophy:before {
  content: "";
}
.ni-user-group-fill:before {
  content: "";
}
.ni-user-group:before {
  content: "";
}
.ni-user-switch-fill:before {
  content: "";
}
.ni-user-switch:before {
  content: "";
}
.ni-nioboard:before {
  content: "";
}
.ni-graphql:before {
  content: "";
}
.ni-node-js:before {
  content: "";
}
.ni-node:before {
  content: "";
}
.ni-svelte:before {
  content: "";
}
.ni-typescript:before {
  content: "";
}
.ni-vue:before {
  content: "";
}
.ni-centos:before {
  content: "";
}
.ni-covid:before {
  content: "";
}
.ni-fedora:before {
  content: "";
}
.ni-hot-fill:before {
  content: "";
}
.ni-hot:before {
  content: "";
}
.ni-linux-server:before {
  content: "";
}
.ni-linux:before {
  content: "";
}
.ni-note-add-fill:before {
  content: "";
}
.ni-repeat-fill:before {
  content: "";
}
.ni-tranx-fill:before {
  content: "";
}
.ni-ubuntu:before {
  content: "";
}
.ni-virus:before {
  content: "";
}
.ni-b-chrome:before {
  content: "";
}
.ni-b-edge:before {
  content: "";
}
.ni-b-firefox:before {
  content: "";
}
.ni-b-ie:before {
  content: "";
}
.ni-b-opera:before {
  content: "";
}
.ni-b-safari:before {
  content: "";
}
.ni-b-si:before {
  content: "";
}
.ni-b-uc:before {
  content: "";
}
.ni-brick-fill:before {
  content: "";
}
.ni-brick:before {
  content: "";
}
.ni-col-3s:before {
  content: "";
}
.ni-col-4s:before {
  content: "";
}
.ni-col-2s:before {
  content: "";
}
.ni-comments:before {
  content: "";
}
.ni-dot-sq:before {
  content: "";
}
.ni-dot:before {
  content: "";
}
.ni-footer:before {
  content: "";
}
.ni-header:before {
  content: "";
}
.ni-heading:before {
  content: "";
}
.ni-layout-alt-fill:before {
  content: "";
}
.ni-layout-alt:before {
  content: "";
}
.ni-layout-fill1:before {
  content: "";
}
.ni-layout1:before {
  content: "";
}
.ni-list-index-fill:before {
  content: "";
}
.ni-list-index:before {
  content: "";
}
.ni-list-thumb-alt-fill:before {
  content: "";
}
.ni-list-thumb-alt:before {
  content: "";
}
.ni-list-thumb-fill:before {
  content: "";
}
.ni-list-thumb:before {
  content: "";
}
.ni-masonry-fill:before {
  content: "";
}
.ni-masonry:before {
  content: "";
}
.ni-menu-circled:before {
  content: "";
}
.ni-menu-squared:before {
  content: "";
}
.ni-notice:before {
  content: "";
}
.ni-pen2:before {
  content: "";
}
.ni-propert-blank:before {
  content: "";
}
.ni-property-add:before {
  content: "";
}
.ni-property-alt:before {
  content: "";
}
.ni-property-remove:before {
  content: "";
}
.ni-property:before {
  content: "";
}
.ni-puzzle-fill:before {
  content: "";
}
.ni-puzzle:before {
  content: "";
}
.ni-quote-left:before {
  content: "";
}
.ni-quote-right:before {
  content: "";
}
.ni-row-mix:before {
  content: "";
}
.ni-row-view1:before {
  content: "";
}
.ni-sidebar-r:before {
  content: "";
}
.ni-text2:before {
  content: "";
}
.ni-tile-thumb-fill:before {
  content: "";
}
.ni-tile-thumb:before {
  content: "";
}
.ni-view-col-fill:before {
  content: "";
}
.ni-view-col-sq:before {
  content: "";
}
.ni-view-col:before {
  content: "";
}
.ni-view-col2:before {
  content: "";
}
.ni-view-col3:before {
  content: "";
}
.ni-view-cols-fill:before {
  content: "";
}
.ni-view-cols-sq:before {
  content: "";
}
.ni-view-cols:before {
  content: "";
}
.ni-view-grid-fill:before {
  content: "";
}
.ni-view-grid-sq:before {
  content: "";
}
.ni-view-grid-wd:before {
  content: "";
}
.ni-view-grid:before {
  content: "";
}
.ni-view-grid2-wd:before {
  content: "";
}
.ni-view-grid3-wd:before {
  content: "";
}
.ni-view-group-fill:before {
  content: "";
}
.ni-view-group-wd:before {
  content: "";
}
.ni-view-list-fill:before {
  content: "";
}
.ni-view-list-sq:before {
  content: "";
}
.ni-view-list-wd:before {
  content: "";
}
.ni-view-list:before {
  content: "";
}
.ni-view-panel-fill:before {
  content: "";
}
.ni-view-panel-sq:before {
  content: "";
}
.ni-view-panel:before {
  content: "";
}
.ni-view-row-fill:before {
  content: "";
}
.ni-view-row-sq:before {
  content: "";
}
.ni-view-row-wd:before {
  content: "";
}
.ni-view-row:before {
  content: "";
}
.ni-view-x1:before {
  content: "";
}
.ni-view-x2:before {
  content: "";
}
.ni-view-x3:before {
  content: "";
}
.ni-view-x4:before {
  content: "";
}
.ni-view-x5:before {
  content: "";
}
.ni-view-x6:before {
  content: "";
}
.ni-view-x7:before {
  content: "";
}
.ni-PostMonkey:before {
  content: "";
}
.ni-PostMonkey-circle:before {
  content: "";
}
.ni-PostMonkey-alt:before {
  content: "";
}
.ni-master-card:before {
  content: "";
}
.ni-paypal:before {
  content: "";
}
.ni-visa-alt:before {
  content: "";
}
.ni-coin-eur:before {
  content: "";
}
.ni-coin-gbp:before {
  content: "";
}
.ni-sign-ada-alt:before {
  content: "";
}
.ni-sign-bch-alt:before {
  content: "";
}
.ni-sign-bgp-alt:before {
  content: "";
}
.ni-sign-bnb-alt:before {
  content: "";
}
.ni-sign-brl-alt:before {
  content: "";
}
.ni-sign-btc-alt:before {
  content: "";
}
.ni-sign-cc-alt:before {
  content: "";
}
.ni-sign-cc-alt2:before {
  content: "";
}
.ni-sign-chf-alt:before {
  content: "";
}
.ni-sign-cny-alt:before {
  content: "";
}
.ni-sign-czk-alt:before {
  content: "";
}
.ni-sign-dash-alt:before {
  content: "";
}
.ni-sign-dkk-alt:before {
  content: "";
}
.ni-sign-eos-alt:before {
  content: "";
}
.ni-sign-eth-alt:before {
  content: "";
}
.ni-sign-eur-alt2:before {
  content: "";
}
.ni-sign-euro-alt:before {
  content: "";
}
.ni-sign-gbp-alt2:before {
  content: "";
}
.ni-sign-hkd-alt:before {
  content: "";
}
.ni-sign-idr-alt:before {
  content: "";
}
.ni-sign-inr-alt:before {
  content: "";
}
.ni-sign-jpy-alt:before {
  content: "";
}
.ni-sign-kr-alt:before {
  content: "";
}
.ni-sign-ltc-alt:before {
  content: "";
}
.ni-sign-ltc:before {
  content: "";
}
.ni-sign-mxn-alt:before {
  content: "";
}
.ni-sign-mxr-alt:before {
  content: "";
}
.ni-sign-myr-alt:before {
  content: "";
}
.ni-sign-paypal-alt:before {
  content: "";
}
.ni-sign-paypal-full:before {
  content: "";
}
.ni-sign-php-alt:before {
  content: "";
}
.ni-sign-pln-alt:before {
  content: "";
}
.ni-sign-rub-alt:before {
  content: "";
}
.ni-sign-sek-alt:before {
  content: "";
}
.ni-sign-sgd-alt:before {
  content: "";
}
.ni-sign-kobo-alt:before {
  content: "";
}
.ni-sign-steem-alt:before {
  content: "";
}
.ni-sign-steller-alt:before {
  content: "";
}
.ni-sign-stripe-fulll:before {
  content: "";
}
.ni-sign-thb-alt:before {
  content: "";
}
.ni-sign-trx-alt:before {
  content: "";
}
.ni-sign-try-alt:before {
  content: "";
}
.ni-sign-usd-alt:before {
  content: "";
}
.ni-sign-usd-alt2:before {
  content: "";
}
.ni-sign-usdc-alt:before {
  content: "";
}
.ni-sign-usdt-alt:before {
  content: "";
}
.ni-sign-visa-alt:before {
  content: "";
}
.ni-sign-vnd-alt:before {
  content: "";
}
.ni-sign-waves-alt:before {
  content: "";
}
.ni-sign-xem-alt:before {
  content: "";
}
.ni-sign-xrp-new-alt:before {
  content: "";
}
.ni-sign-xrp-old-alt:before {
  content: "";
}
.ni-sign-zcash-alt:before {
  content: "";
}
.ni-chevron-left:before {
  content: "";
}
.ni-chevron-right:before {
  content: "";
}
.ni-chevron-up:before {
  content: "";
}
.ni-chevron-down:before {
  content: "";
}
.ni-chevron-left-round:before {
  content: "";
}
.ni-chevron-right-round:before {
  content: "";
}
.ni-chevron-up-round:before {
  content: "";
}
.ni-chevron-down-round:before {
  content: "";
}
.ni-chevron-left-round-fill:before {
  content: "";
}
.ni-chevron-right-round-fill:before {
  content: "";
}
.ni-chevron-up-round-fill:before {
  content: "";
}
.ni-chevron-down-round-fill:before {
  content: "";
}
.ni-chevron-left-c:before {
  content: "";
}
.ni-chevron-right-c:before {
  content: "";
}
.ni-chevron-up-c:before {
  content: "";
}
.ni-chevron-down-c:before {
  content: "";
}
.ni-chevron-left-fill-c:before {
  content: "";
}
.ni-chevron-right-fill-c:before {
  content: "";
}
.ni-chevron-up-fill-c:before {
  content: "";
}
.ni-chevron-down-fill-c:before {
  content: "";
}
.ni-chevron-left-circle:before {
  content: "";
}
.ni-chevron-right-circle:before {
  content: "";
}
.ni-chevron-up-circle:before {
  content: "";
}
.ni-chevron-down-circle:before {
  content: "";
}
.ni-chevron-left-circle-fill:before {
  content: "";
}
.ni-chevron-right-circle-fill:before {
  content: "";
}
.ni-chevron-up-circle-fill:before {
  content: "";
}
.ni-chevron-down-circle-fill:before {
  content: "";
}
.ni-caret-left:before {
  content: "";
}
.ni-caret-right:before {
  content: "";
}
.ni-caret-up:before {
  content: "";
}
.ni-caret-down:before {
  content: "";
}
.ni-caret-left-fill:before {
  content: "";
}
.ni-caret-right-fill:before {
  content: "";
}
.ni-caret-up-fill:before {
  content: "";
}
.ni-caret-down-fill:before {
  content: "";
}
.ni-sort:before {
  content: "";
}
.ni-sort-up:before {
  content: "";
}
.ni-sort-down:before {
  content: "";
}
.ni-sort-fill:before {
  content: "";
}
.ni-sort-up-fill:before {
  content: "";
}
.ni-sort-down-fill:before {
  content: "";
}
.ni-sort-v:before {
  content: "";
}
.ni-swap-v:before {
  content: "";
}
.ni-swap:before {
  content: "";
}
.ni-arrow-left-round:before {
  content: "";
}
.ni-arrow-right-round:before {
  content: "";
}
.ni-arrow-up-round:before {
  content: "";
}
.ni-arrow-down-round:before {
  content: "";
}
.ni-arrow-left-round-fill:before {
  content: "";
}
.ni-arrow-right-round-fill:before {
  content: "";
}
.ni-arrow-up-round-fill:before {
  content: "";
}
.ni-arrow-down-round-fill:before {
  content: "";
}
.ni-arrow-left-c:before {
  content: "";
}
.ni-arrow-right-c:before {
  content: "";
}
.ni-arrow-up-c:before {
  content: "";
}
.ni-arrow-down-c:before {
  content: "";
}
.ni-arrow-left-fill-c:before {
  content: "";
}
.ni-arrow-right-fill-c:before {
  content: "";
}
.ni-arrow-up-fill-c:before {
  content: "";
}
.ni-arrow-down-fill-c:before {
  content: "";
}
.ni-arrow-left-circle:before {
  content: "";
}
.ni-arrow-right-circle:before {
  content: "";
}
.ni-arrow-up-circle:before {
  content: "";
}
.ni-arrow-down-circle:before {
  content: "";
}
.ni-arrow-left-circle-fill:before {
  content: "";
}
.ni-arrow-up-circle-fill:before {
  content: "";
}
.ni-arrow-down-circle-fill:before {
  content: "";
}
.ni-arrow-right-circle-fill:before {
  content: "";
}
.ni-chevrons-left:before {
  content: "";
}
.ni-chevrons-right:before {
  content: "";
}
.ni-chevrons-up:before {
  content: "";
}
.ni-chevrons-down:before {
  content: "";
}
.ni-first:before {
  content: "";
}
.ni-last:before {
  content: "";
}
.ni-back-ios:before {
  content: "";
}
.ni-forward-ios:before {
  content: "";
}
.ni-upword-ios:before {
  content: "";
}
.ni-downward-ios:before {
  content: "";
}
.ni-back-alt:before {
  content: "";
}
.ni-forward-alt:before {
  content: "";
}
.ni-upword-alt:before {
  content: "";
}
.ni-downward-alt:before {
  content: "";
}
.ni-back-alt-fill:before {
  content: "";
}
.ni-forward-alt-fill:before {
  content: "";
}
.ni-upword-alt-fill:before {
  content: "";
}
.ni-downward-alt-fill:before {
  content: "";
}
.ni-arrow-long-left:before {
  content: "";
}
.ni-arrow-long-right:before {
  content: "";
}
.ni-arrow-long-up:before {
  content: "";
}
.ni-arrow-long-down:before {
  content: "";
}
.ni-arrow-left:before {
  content: "";
}
.ni-arrow-right:before {
  content: "";
}
.ni-arrow-up:before {
  content: "";
}
.ni-arrow-down:before {
  content: "";
}
.ni-arrow-up-left:before {
  content: "";
}
.ni-arrow-up-right:before {
  content: "";
}
.ni-arrow-down-left:before {
  content: "";
}
.ni-arrow-down-right:before {
  content: "";
}
.ni-arrow-to-left:before {
  content: "";
}
.ni-arrow-to-right:before {
  content: "";
}
.ni-arrow-to-up:before {
  content: "";
}
.ni-arrow-to-down:before {
  content: "";
}
.ni-arrow-from-left:before {
  content: "";
}
.ni-arrow-from-right:before {
  content: "";
}
.ni-arrow-from-up:before {
  content: "";
}
.ni-arrow-from-down:before {
  content: "";
}
.ni-curve-down-left:before {
  content: "";
}
.ni-curve-up-right:before {
  content: "";
}
.ni-curve-up-left:before {
  content: "";
}
.ni-curve-down-right:before {
  content: "";
}
.ni-curve-left-up:before {
  content: "";
}
.ni-curve-right-up:before {
  content: "";
}
.ni-curve-left-down:before {
  content: "";
}
.ni-curve-right-down:before {
  content: "";
}
.ni-back-arrow:before {
  content: "";
}
.ni-forward-arrow:before {
  content: "";
}
.ni-back-arrow-fill:before {
  content: "";
}
.ni-forward-arrow-fill:before {
  content: "";
}
.ni-navigate:before {
  content: "";
}
.ni-navigate-up:before {
  content: "";
}
.ni-navigate-fill:before {
  content: "";
}
.ni-navigate-up-fill:before {
  content: "";
}
.ni-send:before {
  content: "";
}
.ni-send-alt:before {
  content: "";
}
.ni-unfold-less:before {
  content: "";
}
.ni-unfold-more:before {
  content: "";
}
.ni-exchange-v:before {
  content: "";
}
.ni-exchange:before {
  content: "";
}
.ni-expand:before {
  content: "";
}
.ni-shrink:before {
  content: "";
}
.ni-focus:before {
  content: "";
}
.ni-maximize:before {
  content: "";
}
.ni-minimize:before {
  content: "";
}
.ni-maximize-alt:before {
  content: "";
}
.ni-minimize-alt:before {
  content: "";
}
.ni-shuffle:before {
  content: "";
}
.ni-cross-sm:before {
  content: "";
}
.ni-cross:before {
  content: "";
}
.ni-cross-round:before {
  content: "";
}
.ni-cross-circle:before {
  content: "";
}
.ni-cross-c:before {
  content: "";
}
.ni-cross-round-fill:before {
  content: "";
}
.ni-cross-circle-fill:before {
  content: "";
}
.ni-cross-fill-c:before {
  content: "";
}
.ni-na:before {
  content: "";
}
.ni-check:before {
  content: "";
}
.ni-check-thick:before {
  content: "";
}
.ni-done:before {
  content: "";
}
.ni-check-round:before {
  content: "";
}
.ni-check-circle:before {
  content: "";
}
.ni-check-c:before {
  content: "";
}
.ni-check-round-fill:before {
  content: "";
}
.ni-check-circle-fill:before {
  content: "";
}
.ni-check-fill-c:before {
  content: "";
}
.ni-check-circle-cut:before {
  content: "";
}
.ni-check-round-cut:before {
  content: "";
}
.ni-bullet:before {
  content: "";
}
.ni-circle:before {
  content: "";
}
.ni-square:before {
  content: "";
}
.ni-square-c:before {
  content: "";
}
.ni-bullet-fill:before {
  content: "";
}
.ni-circle-fill:before {
  content: "";
}
.ni-square-fill:before {
  content: "";
}
.ni-square-fill-c:before {
  content: "";
}
.ni-plus-sm:before {
  content: "";
}
.ni-minus-sm:before {
  content: "";
}
.ni-plus:before {
  content: "";
}
.ni-minus:before {
  content: "";
}
.ni-plus-round:before {
  content: "";
}
.ni-minus-round:before {
  content: "";
}
.ni-plus-circle:before {
  content: "";
}
.ni-minus-circle:before {
  content: "";
}
.ni-plus-c:before {
  content: "";
}
.ni-minus-c:before {
  content: "";
}
.ni-plus-round-fill:before {
  content: "";
}
.ni-plus-circle-fill:before {
  content: "";
}
.ni-minus-round-fill:before {
  content: "";
}
.ni-minus-circle-fill:before {
  content: "";
}
.ni-plus-fill-c:before {
  content: "";
}
.ni-minus-fill-c:before {
  content: "";
}
.ni-plus-medi:before {
  content: "";
}
.ni-plus-medi-fill:before {
  content: "";
}
.ni-equal-sm:before {
  content: "";
}
.ni-equal:before {
  content: "";
}
.ni-calc:before {
  content: "";
}
.ni-search:before {
  content: "";
}
.ni-zoom-out:before {
  content: "";
}
.ni-zoom-in:before {
  content: "";
}
.ni-play:before {
  content: "";
}
.ni-play-fill:before {
  content: "";
}
.ni-play-circle:before {
  content: "";
}
.ni-play-circle-fill:before {
  content: "";
}
.ni-pause:before {
  content: "";
}
.ni-pause-fill:before {
  content: "";
}
.ni-pause-circle:before {
  content: "";
}
.ni-pause-circle-fill:before {
  content: "";
}
.ni-stop:before {
  content: "";
}
.ni-stop-fill:before {
  content: "";
}
.ni-stop-circle:before {
  content: "";
}
.ni-stop-circle-fill:before {
  content: "";
}
.ni-rewind:before {
  content: "";
}
.ni-forward:before {
  content: "";
}
.ni-rewind-fill:before {
  content: "";
}
.ni-forward-fill:before {
  content: "";
}
.ni-step-back:before {
  content: "";
}
.ni-step-forward:before {
  content: "";
}
.ni-vol-off:before {
  content: "";
}
.ni-vol-no:before {
  content: "";
}
.ni-vol-half:before {
  content: "";
}
.ni-vol:before {
  content: "";
}
.ni-mic:before {
  content: "";
}
.ni-mic-off:before {
  content: "";
}
.ni-video:before {
  content: "";
}
.ni-video-off:before {
  content: "";
}
.ni-video-fill:before {
  content: "";
}
.ni-loader:before {
  content: "";
}
.ni-power:before {
  content: "";
}
.ni-signout:before {
  content: "";
}
.ni-signin:before {
  content: "";
}
.ni-upload:before {
  content: "";
}
.ni-download:before {
  content: "";
}
.ni-alert-circle:before {
  content: "";
}
.ni-alert:before {
  content: "";
}
.ni-caution:before {
  content: "";
}
.ni-report:before {
  content: "";
}
.ni-alert-c:before {
  content: "";
}
.ni-alert-circle-fill:before {
  content: "";
}
.ni-alert-fill:before {
  content: "";
}
.ni-caution-fill:before {
  content: "";
}
.ni-report-fill:before {
  content: "";
}
.ni-alert-fill-c:before {
  content: "";
}
.ni-info-i:before {
  content: "";
}
.ni-info:before {
  content: "";
}
.ni-info-fill:before {
  content: "";
}
.ni-help:before {
  content: "";
}
.ni-help-fill:before {
  content: "";
}
.ni-archived:before {
  content: "";
}
.ni-archive:before {
  content: "";
}
.ni-unarchive:before {
  content: "";
}
.ni-archived-fill:before {
  content: "";
}
.ni-archive-fill:before {
  content: "";
}
.ni-unarchive-fill:before {
  content: "";
}
.ni-bag:before {
  content: "";
}
.ni-bag-fill:before {
  content: "";
}
.ni-bell:before {
  content: "";
}
.ni-bell-off:before {
  content: "";
}
.ni-bell-fill:before {
  content: "";
}
.ni-bell-off-fill:before {
  content: "";
}
.ni-wifi:before {
  content: "";
}
.ni-wifi-off:before {
  content: "";
}
.ni-live:before {
  content: "";
}
.ni-signal:before {
  content: "";
}
.ni-bluetooth:before {
  content: "";
}
.ni-blank-alt:before {
  content: "";
}
.ni-blank:before {
  content: "";
}
.ni-blankf-fill:before {
  content: "";
}
.ni-block-over:before {
  content: "";
}
.ni-book-read:before {
  content: "";
}
.ni-book:before {
  content: "";
}
.ni-book-fill:before {
  content: "";
}
.ni-bulb-fill:before {
  content: "";
}
.ni-bulb:before {
  content: "";
}
.ni-calendar-alt-fill:before {
  content: "";
}
.ni-calendar-alt:before {
  content: "";
}
.ni-calendar-booking-fill:before {
  content: "";
}
.ni-calendar-booking:before {
  content: "";
}
.ni-calendar-check-fill:before {
  content: "";
}
.ni-calendar-check:before {
  content: "";
}
.ni-calendar-fill:before {
  content: "";
}
.ni-calendar:before {
  content: "";
}
.ni-calender-date-fill:before {
  content: "";
}
.ni-calender-date:before {
  content: "";
}
.ni-call:before {
  content: "";
}
.ni-call-alt:before {
  content: "";
}
.ni-call-alt-fill:before {
  content: "";
}
.ni-call-fill:before {
  content: "";
}
.ni-camera-fill:before {
  content: "";
}
.ni-camera:before {
  content: "";
}
.ni-capsule:before {
  content: "";
}
.ni-capsule-fill:before {
  content: "";
}
.ni-cards:before {
  content: "";
}
.ni-cards-fill:before {
  content: "";
}
.ni-cart:before {
  content: "";
}
.ni-cart-fill:before {
  content: "";
}
.ni-cc:before {
  content: "";
}
.ni-cc-alt:before {
  content: "";
}
.ni-cc-alt2:before {
  content: "";
}
.ni-cc-secure:before {
  content: "";
}
.ni-cc-new:before {
  content: "";
}
.ni-cc-off:before {
  content: "";
}
.ni-cc-fill:before {
  content: "";
}
.ni-cc-alt-fill:before {
  content: "";
}
.ni-cc-alt2-fill:before {
  content: "";
}
.ni-cc-secure-fill:before {
  content: "";
}
.ni-msg-circle:before {
  content: "";
}
.ni-chat-circle:before {
  content: "";
}
.ni-msg:before {
  content: "";
}
.ni-chat:before {
  content: "";
}
.ni-question-alt:before {
  content: "";
}
.ni-question:before {
  content: "";
}
.ni-msg-circle-fill:before {
  content: "";
}
.ni-chat-circle-fill:before {
  content: "";
}
.ni-msg-fill:before {
  content: "";
}
.ni-chat-fill:before {
  content: "";
}
.ni-clip-h:before {
  content: "";
}
.ni-clip-v:before {
  content: "";
}
.ni-clip:before {
  content: "";
}
.ni-link-alt:before {
  content: "";
}
.ni-unlink:before {
  content: "";
}
.ni-unlink-alt:before {
  content: "";
}
.ni-link-h:before {
  content: "";
}
.ni-link-v:before {
  content: "";
}
.ni-link:before {
  content: "";
}
.ni-clipboard:before {
  content: "";
}
.ni-clipboad-check:before {
  content: "";
}
.ni-clipboard-fill:before {
  content: "";
}
.ni-clipboad-check-fill:before {
  content: "";
}
.ni-clock:before {
  content: "";
}
.ni-clock-fill:before {
  content: "";
}
.ni-cloud:before {
  content: "";
}
.ni-upload-cloud:before {
  content: "";
}
.ni-download-cloud:before {
  content: "";
}
.ni-cloud-fill:before {
  content: "";
}
.ni-contact:before {
  content: "";
}
.ni-contact-fill:before {
  content: "";
}
.ni-coffee:before {
  content: "";
}
.ni-coffee-fill:before {
  content: "";
}
.ni-box-view:before {
  content: "";
}
.ni-col-view:before {
  content: "";
}
.ni-sidebar:before {
  content: "";
}
.ni-layout:before {
  content: "";
}
.ni-table-view:before {
  content: "";
}
.ni-layout2:before {
  content: "";
}
.ni-row-view:before {
  content: "";
}
.ni-dot-box:before {
  content: "";
}
.ni-layout-fill:before {
  content: "";
}
.ni-box-view-fill:before {
  content: "";
}
.ni-sidebar-fill:before {
  content: "";
}
.ni-table-view-fill:before {
  content: "";
}
.ni-dot-box-fill:before {
  content: "";
}
.ni-template:before {
  content: "";
}
.ni-browser:before {
  content: "";
}
.ni-toolbar:before {
  content: "";
}
.ni-browser-fill:before {
  content: "";
}
.ni-toolbar-fill:before {
  content: "";
}
.ni-template-fill:before {
  content: "";
}
.ni-box:before {
  content: "";
}
.ni-package:before {
  content: "";
}
.ni-layer:before {
  content: "";
}
.ni-layers:before {
  content: "";
}
.ni-panel:before {
  content: "";
}
.ni-server:before {
  content: "";
}
.ni-layer-fill:before {
  content: "";
}
.ni-layers-fill:before {
  content: "";
}
.ni-package-fill:before {
  content: "";
}
.ni-panel-fill:before {
  content: "";
}
.ni-server-fill:before {
  content: "";
}
.ni-color-palette:before {
  content: "";
}
.ni-color-palette-fill:before {
  content: "";
}
.ni-copy:before {
  content: "";
}
.ni-copy-fill:before {
  content: "";
}
.ni-crop-alt:before {
  content: "";
}
.ni-crop:before {
  content: "";
}
.ni-target:before {
  content: "";
}
.ni-crosshair:before {
  content: "";
}
.ni-crosshair-fill:before {
  content: "";
}
.ni-db-fill:before {
  content: "";
}
.ni-db:before {
  content: "";
}
.ni-hard-drive:before {
  content: "";
}
.ni-cpu:before {
  content: "";
}
.ni-disk:before {
  content: "";
}
.ni-pen:before {
  content: "";
}
.ni-edit-alt:before {
  content: "";
}
.ni-pen-fill:before {
  content: "";
}
.ni-edit-alt-fill:before {
  content: "";
}
.ni-pen-alt-fill:before {
  content: "";
}
.ni-edit-fill:before {
  content: "";
}
.ni-edit:before {
  content: "";
}
.ni-external-alt:before {
  content: "";
}
.ni-external:before {
  content: "";
}
.ni-eye-alt:before {
  content: "";
}
.ni-eye-alt-fill:before {
  content: "";
}
.ni-eye:before {
  content: "";
}
.ni-eye-fill:before {
  content: "";
}
.ni-eye-off:before {
  content: "";
}
.ni-eye-off-fill:before {
  content: "";
}
.ni-file:before {
  content: "";
}
.ni-file-minus:before {
  content: "";
}
.ni-file-plus:before {
  content: "";
}
.ni-file-remove:before {
  content: "";
}
.ni-file-check:before {
  content: "";
}
.ni-file-code:before {
  content: "";
}
.ni-file-docs:before {
  content: "";
}
.ni-file-img:before {
  content: "";
}
.ni-file-doc:before {
  content: "";
}
.ni-file-pdf:before {
  content: "";
}
.ni-file-xls:before {
  content: "";
}
.ni-file-zip:before {
  content: "";
}
.ni-file-download:before {
  content: "";
}
.ni-file-text:before {
  content: "";
}
.ni-files:before {
  content: "";
}
.ni-file-fill:before {
  content: "";
}
.ni-file-minus-fill:before {
  content: "";
}
.ni-file-plus-fill:before {
  content: "";
}
.ni-file-remove-fill:before {
  content: "";
}
.ni-file-check-fill:before {
  content: "";
}
.ni-file-text-fill:before {
  content: "";
}
.ni-files-fill:before {
  content: "";
}
.ni-folder:before {
  content: "";
}
.ni-folder-minus:before {
  content: "";
}
.ni-folder-plus:before {
  content: "";
}
.ni-folder-remove:before {
  content: "";
}
.ni-folder-check:before {
  content: "";
}
.ni-folder-list:before {
  content: "";
}
.ni-folders:before {
  content: "";
}
.ni-folder-fill:before {
  content: "";
}
.ni-folders-fill:before {
  content: "";
}
.ni-filter-alt:before {
  content: "";
}
.ni-sort-line:before {
  content: "";
}
.ni-filter-fill:before {
  content: "";
}
.ni-filter:before {
  content: "";
}
.ni-flag:before {
  content: "";
}
.ni-flag-fill:before {
  content: "";
}
.ni-notify:before {
  content: "";
}
.ni-dashboard:before {
  content: "";
}
.ni-dashboard-fill:before {
  content: "";
}
.ni-grid-sq:before {
  content: "";
}
.ni-grid:before {
  content: "";
}
.ni-grid-c:before {
  content: "";
}
.ni-grid-alt:before {
  content: "";
}
.ni-grid-plus:before {
  content: "";
}
.ni-grid-add-c:before {
  content: "";
}
.ni-grid-fill:before {
  content: "";
}
.ni-grid-fill-c:before {
  content: "";
}
.ni-grid-alt-fill:before {
  content: "";
}
.ni-grid-plus-fill:before {
  content: "";
}
.ni-grid-add-fill-c:before {
  content: "";
}
.ni-grid-box-alt-fill:before {
  content: "";
}
.ni-grid-box-alt:before {
  content: "";
}
.ni-grid-box:before {
  content: "";
}
.ni-grid-box-fill:before {
  content: "";
}
.ni-grid-line:before {
  content: "";
}
.ni-menu-alt-left:before {
  content: "";
}
.ni-menu-alt-r:before {
  content: "";
}
.ni-menu-alt:before {
  content: "";
}
.ni-menu-center:before {
  content: "";
}
.ni-menu-left:before {
  content: "";
}
.ni-menu-right:before {
  content: "";
}
.ni-menu:before {
  content: "";
}
.ni-trend-up:before {
  content: "";
}
.ni-trend-down:before {
  content: "";
}
.ni-line-chart-down:before {
  content: "";
}
.ni-line-chart-up:before {
  content: "";
}
.ni-line-chart:before {
  content: "";
}
.ni-bar-chart:before {
  content: "";
}
.ni-bar-chart-alt:before {
  content: "";
}
.ni-chart-up:before {
  content: "";
}
.ni-chart-down:before {
  content: "";
}
.ni-growth:before {
  content: "";
}
.ni-growth-fill:before {
  content: "";
}
.ni-bar-chart-fill:before {
  content: "";
}
.ni-bar-c:before {
  content: "";
}
.ni-bar-fill-c:before {
  content: "";
}
.ni-pie:before {
  content: "";
}
.ni-pie-alt:before {
  content: "";
}
.ni-pie-fill:before {
  content: "";
}
.ni-activity:before {
  content: "";
}
.ni-activity-alt:before {
  content: "";
}
.ni-activity-round:before {
  content: "";
}
.ni-activity-round-fill:before {
  content: "";
}
.ni-meter:before {
  content: "";
}
.ni-speed:before {
  content: "";
}
.ni-happy:before {
  content: "";
}
.ni-sad:before {
  content: "";
}
.ni-meh:before {
  content: "";
}
.ni-happyf-fill:before {
  content: "";
}
.ni-sad-fill:before {
  content: "";
}
.ni-meh-fill:before {
  content: "";
}
.ni-home:before {
  content: "";
}
.ni-home-alt:before {
  content: "";
}
.ni-home-fill:before {
  content: "";
}
.ni-img:before {
  content: "";
}
.ni-img-fill:before {
  content: "";
}
.ni-inbox:before {
  content: "";
}
.ni-inbox-in:before {
  content: "";
}
.ni-inbox-out:before {
  content: "";
}
.ni-inbox-fill:before {
  content: "";
}
.ni-inbox-in-fill:before {
  content: "";
}
.ni-inbox-out-fill:before {
  content: "";
}
.ni-link-group:before {
  content: "";
}
.ni-lock:before {
  content: "";
}
.ni-lock-alt:before {
  content: "";
}
.ni-lock-fill:before {
  content: "";
}
.ni-lock-alt-fill:before {
  content: "";
}
.ni-unlock:before {
  content: "";
}
.ni-unlock-fill:before {
  content: "";
}
.ni-mail:before {
  content: "";
}
.ni-emails:before {
  content: "";
}
.ni-mail-fill:before {
  content: "";
}
.ni-emails-fill:before {
  content: "";
}
.ni-map-pin:before {
  content: "";
}
.ni-location:before {
  content: "";
}
.ni-map:before {
  content: "";
}
.ni-map-pin-fill:before {
  content: "";
}
.ni-list:before {
  content: "";
}
.ni-list-ol:before {
  content: "";
}
.ni-align-center:before {
  content: "";
}
.ni-align-justify:before {
  content: "";
}
.ni-align-left:before {
  content: "";
}
.ni-align-right:before {
  content: "";
}
.ni-list-check:before {
  content: "";
}
.ni-list-round:before {
  content: "";
}
.ni-card-view:before {
  content: "";
}
.ni-list-fill:before {
  content: "";
}
.ni-save:before {
  content: "";
}
.ni-save-fill:before {
  content: "";
}
.ni-move:before {
  content: "";
}
.ni-scissor:before {
  content: "";
}
.ni-text:before {
  content: "";
}
.ni-text-a:before {
  content: "";
}
.ni-bold:before {
  content: "";
}
.ni-italic:before {
  content: "";
}
.ni-underline:before {
  content: "";
}
.ni-percent:before {
  content: "";
}
.ni-at:before {
  content: "";
}
.ni-hash:before {
  content: "";
}
.ni-code:before {
  content: "";
}
.ni-code-download:before {
  content: "";
}
.ni-terminal:before {
  content: "";
}
.ni-cmd:before {
  content: "";
}
.ni-sun:before {
  content: "";
}
.ni-sun-fill:before {
  content: "";
}
.ni-moon-fill:before {
  content: "";
}
.ni-moon:before {
  content: "";
}
.ni-light:before {
  content: "";
}
.ni-light-fill:before {
  content: "";
}
.ni-more-v:before {
  content: "";
}
.ni-more-h:before {
  content: "";
}
.ni-more-h-alt:before {
  content: "";
}
.ni-more-v-alt:before {
  content: "";
}
.ni-music:before {
  content: "";
}
.ni-movie:before {
  content: "";
}
.ni-offer:before {
  content: "";
}
.ni-offer-fill:before {
  content: "";
}
.ni-opt-alt:before {
  content: "";
}
.ni-opt:before {
  content: "";
}
.ni-opt-dot-alt:before {
  content: "";
}
.ni-opt-dot:before {
  content: "";
}
.ni-opt-dot-fill:before {
  content: "";
}
.ni-opt-alt-fill:before {
  content: "";
}
.ni-user-alt:before {
  content: "";
}
.ni-user-alt-fill:before {
  content: "";
}
.ni-user:before {
  content: "";
}
.ni-users:before {
  content: "";
}
.ni-user-add:before {
  content: "";
}
.ni-user-remove:before {
  content: "";
}
.ni-user-check:before {
  content: "";
}
.ni-user-cross:before {
  content: "";
}
.ni-account-setting:before {
  content: "";
}
.ni-account-setting-alt:before {
  content: "";
}
.ni-user-list:before {
  content: "";
}
.ni-user-fill:before {
  content: "";
}
.ni-users-fill:before {
  content: "";
}
.ni-user-add-fill:before {
  content: "";
}
.ni-user-remove-fill:before {
  content: "";
}
.ni-user-check-fill:before {
  content: "";
}
.ni-user-cross-fill:before {
  content: "";
}
.ni-account-setting-fill:before {
  content: "";
}
.ni-user-list-fill:before {
  content: "";
}
.ni-user-circle:before {
  content: "";
}
.ni-user-circle-fill:before {
  content: "";
}
.ni-user-c:before {
  content: "";
}
.ni-user-fill-c:before {
  content: "";
}
.ni-user-round:before {
  content: "";
}
.ni-printer:before {
  content: "";
}
.ni-printer-fill:before {
  content: "";
}
.ni-laptop:before {
  content: "";
}
.ni-monitor:before {
  content: "";
}
.ni-tablet:before {
  content: "";
}
.ni-mobile:before {
  content: "";
}
.ni-undo:before {
  content: "";
}
.ni-redo:before {
  content: "";
}
.ni-reload-alt:before {
  content: "";
}
.ni-reload:before {
  content: "";
}
.ni-regen-alt:before {
  content: "";
}
.ni-regen:before {
  content: "";
}
.ni-invest:before {
  content: "";
}
.ni-history:before {
  content: "";
}
.ni-update:before {
  content: "";
}
.ni-repeat:before {
  content: "";
}
.ni-repeat-v:before {
  content: "";
}
.ni-tranx:before {
  content: "";
}
.ni-reply-all:before {
  content: "";
}
.ni-reply:before {
  content: "";
}
.ni-reply-fill:before {
  content: "";
}
.ni-reply-all-fill:before {
  content: "";
}
.ni-notes:before {
  content: "";
}
.ni-note-add:before {
  content: "";
}
.ni-notes-alt:before {
  content: "";
}
.ni-article:before {
  content: "";
}
.ni-text-rich:before {
  content: "";
}
.ni-todo:before {
  content: "";
}
.ni-report-profit:before {
  content: "";
}
.ni-reports-alt:before {
  content: "";
}
.ni-reports:before {
  content: "";
}
.ni-task:before {
  content: "";
}
.ni-note-add-c:before {
  content: "";
}
.ni-task-c:before {
  content: "";
}
.ni-todo-fill:before {
  content: "";
}
.ni-note-add-fill-c:before {
  content: "";
}
.ni-task-fill-c:before {
  content: "";
}
.ni-scan-fill:before {
  content: "";
}
.ni-scan:before {
  content: "";
}
.ni-qr:before {
  content: "";
}
.ni-money:before {
  content: "";
}
.ni-coins:before {
  content: "";
}
.ni-coin:before {
  content: "";
}
.ni-coin-alt:before {
  content: "";
}
.ni-coin-alt-fill:before {
  content: "";
}
.ni-setting-alt-fill:before {
  content: "";
}
.ni-setting-alt:before {
  content: "";
}
.ni-setting-fill:before {
  content: "";
}
.ni-setting:before {
  content: "";
}
.ni-share-alt:before {
  content: "";
}
.ni-share-fill:before {
  content: "";
}
.ni-share:before {
  content: "";
}
.ni-network:before {
  content: "";
}
.ni-rss:before {
  content: "";
}
.ni-shield:before {
  content: "";
}
.ni-shield-star:before {
  content: "";
}
.ni-shield-check:before {
  content: "";
}
.ni-shield-alert:before {
  content: "";
}
.ni-shield-off:before {
  content: "";
}
.ni-security:before {
  content: "";
}
.ni-policy:before {
  content: "";
}
.ni-shield-alert-fill:before {
  content: "";
}
.ni-shield-check-fill:before {
  content: "";
}
.ni-shield-fill:before {
  content: "";
}
.ni-shield-half:before {
  content: "";
}
.ni-shield-star-fill:before {
  content: "";
}
.ni-policy-fill:before {
  content: "";
}
.ni-spark:before {
  content: "";
}
.ni-spark-off:before {
  content: "";
}
.ni-spark-fill:before {
  content: "";
}
.ni-spark-off-fill:before {
  content: "";
}
.ni-wallet:before {
  content: "";
}
.ni-wallet-alt:before {
  content: "";
}
.ni-wallet-in:before {
  content: "";
}
.ni-wallet-out:before {
  content: "";
}
.ni-wallet-saving:before {
  content: "";
}
.ni-wallet-fill:before {
  content: "";
}
.ni-star:before {
  content: "";
}
.ni-star-half:before {
  content: "";
}
.ni-star-half-fill:before {
  content: "";
}
.ni-star-fill:before {
  content: "";
}
.ni-star-round:before {
  content: "";
}
.ni-heart:before {
  content: "";
}
.ni-heart-fill:before {
  content: "";
}
.ni-swap-alt-fill:before {
  content: "";
}
.ni-swap-alt:before {
  content: "";
}
.ni-thumbs-down:before {
  content: "";
}
.ni-thumbs-up:before {
  content: "";
}
.ni-tag:before {
  content: "";
}
.ni-tag-alt:before {
  content: "";
}
.ni-tags:before {
  content: "";
}
.ni-tag-fill:before {
  content: "";
}
.ni-tag-alt-fill:before {
  content: "";
}
.ni-tags-fill:before {
  content: "";
}
.ni-bookmark:before {
  content: "";
}
.ni-bookmark-fill:before {
  content: "";
}
.ni-label:before {
  content: "";
}
.ni-label-fill:before {
  content: "";
}
.ni-piority:before {
  content: "";
}
.ni-piority-fill:before {
  content: "";
}
.ni-label-alt:before {
  content: "";
}
.ni-label-alt-fill:before {
  content: "";
}
.ni-ticket-alt:before {
  content: "";
}
.ni-ticket:before {
  content: "";
}
.ni-ticket-minus:before {
  content: "";
}
.ni-ticket-plus:before {
  content: "";
}
.ni-ticket-alt-fill:before {
  content: "";
}
.ni-ticket-fill:before {
  content: "";
}
.ni-ticket-minus-fill:before {
  content: "";
}
.ni-ticket-plus-fill:before {
  content: "";
}
.ni-toggle-off:before {
  content: "";
}
.ni-toggle-on:before {
  content: "";
}
.ni-trash-alt:before {
  content: "";
}
.ni-trash-empty:before {
  content: "";
}
.ni-trash:before {
  content: "";
}
.ni-trash-fill:before {
  content: "";
}
.ni-trash-empty-fill:before {
  content: "";
}
.ni-delete-fill:before {
  content: "";
}
.ni-delete:before {
  content: "";
}
.ni-alarm-alt:before {
  content: "";
}
.ni-alarm:before {
  content: "";
}
.ni-bugs:before {
  content: "";
}
.ni-building:before {
  content: "";
}
.ni-building-fill:before {
  content: "";
}
.ni-headphone:before {
  content: "";
}
.ni-headphone-fill:before {
  content: "";
}
.ni-aperture:before {
  content: "";
}
.ni-help-alt:before {
  content: "";
}
.ni-award:before {
  content: "";
}
.ni-briefcase:before {
  content: "";
}
.ni-gift:before {
  content: "";
}
.ni-globe:before {
  content: "";
}
.ni-umbrela:before {
  content: "";
}
.ni-truck:before {
  content: "";
}
.ni-sign-usd:before {
  content: "";
}
.ni-sign-dollar:before {
  content: "";
}
.ni-sign-mxn:before {
  content: "";
}
.ni-sign-sgd:before {
  content: "";
}
.ni-sign-euro:before {
  content: "";
}
.ni-sign-eur:before {
  content: "";
}
.ni-sign-gbp:before {
  content: "";
}
.ni-sign-pound:before {
  content: "";
}
.ni-sign-thb:before {
  content: "";
}
.ni-sign-inr:before {
  content: "";
}
.ni-sign-jpy:before {
  content: "";
}
.ni-sign-yen:before {
  content: "";
}
.ni-sign-cny:before {
  content: "";
}
.ni-sign-kobo:before {
  content: "";
}
.ni-sign-chf:before {
  content: "";
}
.ni-sign-vnd:before {
  content: "";
}
.ni-sign-php:before {
  content: "";
}
.ni-sign-brl:before {
  content: "";
}
.ni-sign-idr:before {
  content: "";
}
.ni-sign-czk:before {
  content: "";
}
.ni-sign-hkd:before {
  content: "";
}
.ni-sign-kr:before {
  content: "";
}
.ni-sign-dkk:before {
  content: "";
}
.ni-sign-nok:before {
  content: "";
}
.ni-sign-sek:before {
  content: "";
}
.ni-sign-rub:before {
  content: "";
}
.ni-sign-myr:before {
  content: "";
}
.ni-sign-pln:before {
  content: "";
}
.ni-sign-try:before {
  content: "";
}
.ni-sign-waves:before {
  content: "";
}
.ni-waves:before {
  content: "";
}
.ni-sign-trx:before {
  content: "";
}
.ni-tron:before {
  content: "";
}
.ni-sign-xem:before {
  content: "";
}
.ni-nem:before {
  content: "";
}
.ni-sign-mxr:before {
  content: "";
}
.ni-monero:before {
  content: "";
}
.ni-sign-usdc:before {
  content: "";
}
.ni-sign-steller:before {
  content: "";
}
.ni-sign-steem:before {
  content: "";
}
.ni-sign-usdt:before {
  content: "";
}
.ni-tether:before {
  content: "";
}
.ni-sign-btc:before {
  content: "";
}
.ni-bitcoin:before {
  content: "";
}
.ni-sign-bch:before {
  content: "";
}
.ni-bitcoin-cash:before {
  content: "";
}
.ni-sign-bnb:before {
  content: "";
}
.ni-binance:before {
  content: "";
}
.ni-sign-ada:before {
  content: "";
}
.ni-sign-zcash:before {
  content: "";
}
.ni-sign-eth:before {
  content: "";
}
.ni-ethereum:before {
  content: "";
}
.ni-sign-dash:before {
  content: "";
}
.ni-dash:before {
  content: "";
}
.ni-sign-xrp-old:before {
  content: "";
}
.ni-ripple-old:before {
  content: "";
}
.ni-sign-eos:before {
  content: "";
}
.ni-eos:before {
  content: "";
}
.ni-sign-xrp:before {
  content: "";
}
.ni-ripple:before {
  content: "";
}
.ni-american-express:before {
  content: "";
}
.ni-jcb:before {
  content: "";
}
.ni-cc-jcb:before {
  content: "";
}
.ni-mc:before {
  content: "";
}
.ni-cc-mc:before {
  content: "";
}
.ni-discover:before {
  content: "";
}
.ni-cc-discover:before {
  content: "";
}
.ni-visa:before {
  content: "";
}
.ni-cc-visa:before {
  content: "";
}
.ni-cc-paypal:before {
  content: "";
}
.ni-cc-stripe:before {
  content: "";
}
.ni-amazon-pay:before {
  content: "";
}
.ni-amazon-pay-fill:before {
  content: "";
}
.ni-google-pay:before {
  content: "";
}
.ni-google-pay-fill:before {
  content: "";
}
.ni-apple-pay:before {
  content: "";
}
.ni-apple-pay-fill:before {
  content: "";
}
.ni-angular:before {
  content: "";
}
.ni-react:before {
  content: "";
}
.ni-laravel:before {
  content: "";
}
.ni-html5:before {
  content: "";
}
.ni-css3-fill:before {
  content: "";
}
.ni-css3:before {
  content: "";
}
.ni-js:before {
  content: "";
}
.ni-php:before {
  content: "";
}
.ni-python:before {
  content: "";
}
.ni-bootstrap:before {
  content: "";
}
.ni-ebay:before {
  content: "";
}
.ni-google-wallet:before {
  content: "";
}
.ni-google-drive:before {
  content: "";
}
.ni-google-play-store:before {
  content: "";
}
.ni-android:before {
  content: "";
}
.ni-blogger-fill:before {
  content: "";
}
.ni-blogger:before {
  content: "";
}
.ni-hangout:before {
  content: "";
}
.ni-apple-store:before {
  content: "";
}
.ni-apple-store-ios:before {
  content: "";
}
.ni-stripe:before {
  content: "";
}
.ni-apple:before {
  content: "";
}
.ni-microsoft:before {
  content: "";
}
.ni-windows:before {
  content: "";
}
.ni-amazon:before {
  content: "";
}
.ni-paypal-alt:before {
  content: "";
}
.ni-airbnb:before {
  content: "";
}
.ni-adobe:before {
  content: "";
}
.ni-mailchimp:before {
  content: "";
}
.ni-dropbox:before {
  content: "";
}
.ni-digital-ocean:before {
  content: "";
}
.ni-slack:before {
  content: "";
}
.ni-slack-hash:before {
  content: "";
}
.ni-stack-overflow:before {
  content: "";
}
.ni-soundcloud:before {
  content: "";
}
.ni-blackberry:before {
  content: "";
}
.ni-spotify:before {
  content: "";
}
.ni-kickstarter:before {
  content: "";
}
.ni-houzz:before {
  content: "";
}
.ni-vine:before {
  content: "";
}
.ni-yelp:before {
  content: "";
}
.ni-yoast:before {
  content: "";
}
.ni-envato:before {
  content: "";
}
.ni-wordpress:before {
  content: "";
}
.ni-wp:before {
  content: "";
}
.ni-wordpress-fill:before {
  content: "";
}
.ni-elementor:before {
  content: "";
}
.ni-joomla:before {
  content: "";
}
.ni-megento:before {
  content: "";
}
.ni-git:before {
  content: "";
}
.ni-github:before {
  content: "";
}
.ni-github-round:before {
  content: "";
}
.ni-github-circle:before {
  content: "";
}
.ni-dribbble:before {
  content: "";
}
.ni-dribbble-round:before {
  content: "";
}
.ni-behance:before {
  content: "";
}
.ni-behance-fill:before {
  content: "";
}
.ni-flickr:before {
  content: "";
}
.ni-flickr-round:before {
  content: "";
}
.ni-medium:before {
  content: "";
}
.ni-medium-round:before {
  content: "";
}
.ni-reddit:before {
  content: "";
}
.ni-reddit-round:before {
  content: "";
}
.ni-reddit-circle:before {
  content: "";
}
.ni-google:before {
  content: "";
}
.ni-facebook-f:before {
  content: "";
}
.ni-facebook-fill:before {
  content: "";
}
.ni-facebook-circle:before {
  content: "";
}
.ni-instagram:before {
  content: "";
}
.ni-instagram-round:before {
  content: "";
}
.ni-linkedin:before {
  content: "";
}
.ni-linkedin-round:before {
  content: "";
}
.ni-twitter:before {
  content: "";
}
.ni-twitter-round:before {
  content: "";
}
.ni-pinterest:before {
  content: "";
}
.ni-pinterest-round:before {
  content: "";
}
.ni-pinterest-circle:before {
  content: "";
}
.ni-tumblr:before {
  content: "";
}
.ni-tumblr-round:before {
  content: "";
}
.ni-skype:before {
  content: "";
}
.ni-viber:before {
  content: "";
}
.ni-whatsapp:before {
  content: "";
}
.ni-whatsapp-round:before {
  content: "";
}
.ni-snapchat:before {
  content: "";
}
.ni-snapchat-fill:before {
  content: "";
}
.ni-telegram:before {
  content: "";
}
.ni-telegram-circle:before {
  content: "";
}
.ni-youtube-line:before {
  content: "";
}
.ni-youtube:before {
  content: "";
}
.ni-youtube-fill:before {
  content: "";
}
.ni-youtube-round:before {
  content: "";
}
.ni-vimeo:before {
  content: "";
}
.ni-vimeo-fill:before {
  content: "";
}
.com {
  color: #93a1a1;
}
.lit {
  color: #195f91;
}
.pun,
.opn,
.clo {
  color: #93a1a1;
}
.fun {
  color: #dc322f;
}
.str,
.atv {
  color: #d14;
}
.kwd,
.linenums .tag {
  color: #1e347b;
}
.typ,
.atn,
.dec,
.var {
  color: teal;
}
.pln {
  color: #48484c;
}
.prettyprint {
  padding: 8px;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
}
.prettyprint.linenums {
  -webkit-box-shadow: inset 40px 0 0 #fbfbfc, inset 41px 0 0 #ececf0;
  -moz-box-shadow: inset 40px 0 0 #fbfbfc, inset 41px 0 0 #ececf0;
  box-shadow: inset 40px 0 0 #fbfbfc, inset 41px 0 0 #ececf0;
}
ol.linenums {
  margin: 0 0 0 33px;
}
ol.linenums li {
  padding-left: 12px;
  color: #bebec5;
  line-height: 18px;
  text-shadow: 0 1px 0 #fff;
}
.select2 {
  width: 100% !important;
  display: inline-block;
}
.select2-container {
  box-sizing: border-box;
  margin: 0;
  position: relative;
  vertical-align: middle;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: calc(2.125rem + 2px);
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container.select2-lg .select2-selection--multiple {
  min-height: calc(2.625rem + 2px);
}
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}
.select2-container
  .select2-search--inline
  .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-dropdown {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #dbdfea;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: 400;
  display: block;
  position: absolute;
  left: -100000px;
  z-index: 1051;
  padding: 6px;
}
.select2-dropdown--below {
  margin-top: -1px;
  border-radius: 0 0 4px 4px;
}
.select2-dropdown--above {
  margin-top: 1px;
  border-radius: 4px 4px 0 0;
}
.select2-results {
  display: block;
}
.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 4px 4px;
}
.select2-results__option {
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-results__option:not(:last-child) {
  margin-bottom: 0.125rem;
}
.select2-results__option[aria-selected] {
  cursor: pointer;
}
.select2-lg .select2-results__option {
  padding: 0.5rem 0.75rem;
}
.select2-container--open .select2-dropdown {
  left: 0;
  border-color: #b4bdd3;
}
.select2-search--dropdown {
  display: block;
  padding: 0;
}
.select2-search--dropdown .select2-search__field {
  padding: 0.375rem 0.75rem;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 6px;
  color: #526484;
}
.select2-search--dropdown .select2-search__field:focus {
  outline: none;
  border-color: #11c8fc;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-lg .select2-search--dropdown .select2-search__field {
  padding: 0.5rem 0.75rem;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}
.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}
.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
.select2-container--default .select2-selection--single {
  height: calc(2.125rem + 2px);
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: 400;
  background-color: #fff;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  box-shadow: none;
  transition: all 0.3s;
}
.select2-sm .select2-container--default .select2-selection--single {
  font-size: 12px;
}
.select2-lg .select2-container--default .select2-selection--single {
  font-size: 15px;
}
.select2-xl .select2-container--default .select2-selection--single {
  font-size: 15px;
}
.select2-container--default .select2-selection--single:focus {
  box-shadow: 0 0 0 3px rgba(115, 58, 234, 0.1);
  border-color: #11c8fc;
  outline: none;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #526484;
  line-height: 1.25rem;
  padding: 0.4375rem calc(2.125rem + 2px) 0.4375rem 1rem;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered:focus {
  outline: none;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: #8094ae;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: calc(2.125rem + 2px);
  position: absolute;
  top: 0;
  right: 0;
  width: calc(2.125rem + 2px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow:after {
  font-family: "Nioicon";
  content: "";
  line-height: 1;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  display: none;
}
.select2-container--default.select2-sm .select2-selection--single {
  height: calc(1.75rem + 2px);
  font-size: 12px;
}
.select2-container--default.select2-sm
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 1.25rem;
  padding: 0.25rem calc(1.75rem + 2px) 0.25rem 1rem;
}
.select2-container--default.select2-sm
  .select2-selection--single
  .select2-selection__arrow {
  height: calc(1.75rem + 2px);
  width: calc(1.75rem + 2px);
}
.select2-container--default.select2-lg .select2-selection--single {
  height: calc(2.625rem + 2px);
  font-size: 15px;
}
.select2-container--default.select2-lg
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 1.25rem;
  padding: 0.6875rem calc(2.625rem + 2px) 0.6875rem 1rem;
}
.select2-container--default.select2-lg
  .select2-selection--single
  .select2-selection__arrow {
  height: calc(2.625rem + 2px);
  width: calc(2.625rem + 2px);
}
.select2-container--default.select2-xl .select2-selection--single {
  height: calc(3.25rem + 2px);
  font-size: 15px;
}
.select2-container--default.select2-xl
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 2rem;
  padding: 0.625rem calc(3.25rem + 2px) 0.625rem 1.25rem;
}
.select2-container--default.select2-xl
  .select2-selection--single
  .select2-selection__arrow {
  height: calc(3.25rem + 2px);
  width: calc(3.25rem + 2px);
}
.select2-container--default.select2-container--below.select2-container--open
  .select2-selection--single {
  border-radius: 4px 4px 0 0;
}
.select2-container--default.select2-container--above.select2-container--open
  .select2-selection--single {
  border-radius: 0 0 4px 4px;
}
.select2-container--default.select2-container--disabled
  .select2-selection--single {
  border-color: #dbdfea;
  background-color: rgba(219, 223, 234, 0.3);
  cursor: default;
}
.select2-container--default.select2-container--disabled
  .select2-selection--single
  .select2-selection__clear {
  display: none;
}
.select2-container--default.select2-container--open .select2-selection--single {
  border-color: #b4bdd3 !important;
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow:after {
  content: "";
}
.select2-container--default .select2-selection--multiple {
  background-color: #fff;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: 400;
  cursor: text;
}
.select2-container--default
  .select2-selection--multiple
  .select2-search--inline
  input::placeholder {
  color: #8094ae;
}
.select2-container--default
  .select2-selection--multiple
  .select2-search--inline
  .select2-search__field {
  padding-left: 0.8125rem !important;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0.125rem 0.1875rem;
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  li {
  list-style: none;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__clear {
  cursor: pointer;
  font-weight: bold;
  margin-top: 5px;
  float: right;
  margin-right: 10px;
  padding: 1px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #e5e9f2;
  border-radius: 3px;
  cursor: default;
  line-height: 1.5rem;
  margin: 0.1875rem 0.1875rem;
  padding: 0 0.5rem;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #526484;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 0.5rem;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #526484;
}
.select2-container--default.select2-lg .select2-selection--multiple {
  font-size: 15px;
}
.select2-container--default.select2-lg
  .select2-selection--multiple
  .select2-selection__choice {
  border-radius: 4px;
  padding: 0.25rem 0.75rem;
}
.select2-container--default.select2-container--below.select2-container--open
  .select2-selection--multiple {
  border-radius: 4px 4px 0 0;
}
.select2-container--default.select2-container--above.select2-container--open
  .select2-selection--multiple {
  border-radius: 0 0 4px 4px;
}
.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: 1px solid #11c8fc;
  outline: 0;
}
.select2-container--default.select2-container--disabled
  .select2-selection--multiple {
  border-color: #dbdfea;
  background-color: rgba(219, 223, 234, 0.3);
  cursor: default;
}
.select2-container--default.select2-container--disabled
  .select2-selection__choice__remove {
  display: none;
}
.select2-container--default.select2-container--open
  .select2-selection--multiple {
  border-color: #b4bdd3;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #dbdfea;
}
.select2-container--default .select2-search--inline .select2-search__field {
  background: rgba(0, 0, 0, 0);
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}
.select2-container--default .select2-results > .select2-results__options {
  max-height: 210px;
  overflow-y: auto;
}
.select2-container--default .select2-results__option[role="group"] {
  padding: 0;
}
.select2-container--default .select2-results__option[aria-disabled="true"] {
  color: rgba(128, 148, 174, 0.7);
}
.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #e5e9f2;
}
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__group {
  padding-left: 0;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #e5e9f2;
}
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}
.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}
.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}
.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0 !important;
  bottom: 0;
  right: 0 !important;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}
.simplebar-content-wrapper,
.simplebar-scroll-content {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  width: auto;
  visibility: visible;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: hidden scroll;
}
.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-scroll-content::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}
.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}
.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}
.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}
.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}
[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}
[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}
.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}
.simplebar-scrollbar .hidden-scrollbar {
  visibility: hidden;
}
.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #8094ae;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s linear;
}
.simplebar-scrollbar.simplebar-visible:before {
  opacity: 0.5;
  transition: opacity 0s linear;
}
.simplebar-track.simplebar-vertical {
  top: 0;
  width: 8px !important;
  transition: width 0.1s;
}
.simplebar-track.simplebar-vertical.simplebar-hover {
  width: 10px !important;
}
.simplebar-track.simplebar-vertical .nk-sidebar {
  visibility: hidden !important;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}
.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 8px !important;
  transition: height 0.1s;
}
.simplebar-track.simplebar-horizontal.simplebar-hover {
  height: 10px !important;
}
.nk-sidebar .simplebar-track.simplebar-horizontal,
.nk-sidebar-fat .simplebar-track.simplebar-horizontal,
.nk-apps-sidebar .simplebar-track.simplebar-horizontal {
  visibility: hidden !important;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}
[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}
.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}
.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0.25rem 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0);
}
.slick-slide:focus {
  outline: none;
  border: none;
}
.slick-slide.col {
  padding-left: 14px;
  padding-right: 14px;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-dots {
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
}
.slick-dots li {
  display: inline-flex;
  padding: 0 6px;
}
.slick-dots li button {
  background-color: #dbdfea;
  border: none;
  border-radius: 20px;
  text-indent: -99999px;
  height: 8px;
  width: 8px;
  padding: 0;
  overflow: hidden;
  transition: all 0.4s linear;
}
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li.slick-active button {
  background-color: #8094ae;
}
.slick-next,
.slick-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 44px;
  width: 44px;
  z-index: 9;
  font-size: 24px;
  color: #8094ae;
  background: #fff;
  transition: all 0.4s linear;
  border: 1px solid #e5e9f2;
}
.slick-next:hover,
.slick-prev:hover {
  color: #526484;
  background: #f5f6fa;
  border-color: #dbdfea;
  opacity: 1;
}
.slick-disabled .slick-next,
.slick-disabled .slick-prev {
  cursor: default;
  color: #dbdfea;
}
.slick-disabled .slick-next:hover,
.slick-disabled .slick-prev:hover {
  color: #dbdfea;
  background: #fff;
  border-color: #e5e9f2;
}
.slick-next {
  right: 5px;
}
.slick-prev {
  left: 5px;
}
@media (min-width: 576px) {
  .slick-next {
    right: -10px;
  }
  .slick-prev {
    left: -10px;
  }
}
.datepicker {
  padding: 10px;
  border-radius: 4px;
  direction: ltr;
  border: 1px solid #dbdfea;
  box-shadow: none;
  min-width: 240px;
}
.datepicker .table-condensed {
  width: 100%;
}
.datepicker-inline {
  width: 220px;
}
.datepicker-rtl {
  direction: rtl;
}
.datepicker-rtl.dropdown-menu {
  left: auto;
}
.datepicker-rtl table tr td span {
  float: right;
}
.datepicker-dropdown {
  top: 0;
  left: 0;
}
.datepicker-dropdown:before {
  content: "";
  display: inline-block;
  border-left: 7px solid rgba(0, 0, 0, 0);
  border-right: 7px solid rgba(0, 0, 0, 0);
  border-bottom: 7px solid #999;
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}
.datepicker-dropdown:after {
  content: "";
  display: inline-block;
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  border-bottom: 6px solid #fff;
  border-top: 0;
  position: absolute;
}
.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}
.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}
.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}
.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}
.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}
.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}
.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999;
}
.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}
.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.datepicker td,
.datepicker th {
  text-align: center;
  width: 34px;
  height: 24px;
  padding: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none;
  transition: all 0.3s;
}
.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: rgba(0, 0, 0, 0);
}
.datepicker table tr td.day:hover,
.datepicker table tr td.day.focused {
  background: #ebeef2;
  cursor: pointer;
}
.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #dbdfea;
}
.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #dbdfea;
  cursor: default;
}
.datepicker table tr td.highlighted {
  background: #ebeef2;
  border-radius: 0;
}
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #11c8fc2e;
  color: #11c8fc;
}
.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
  background-color: #11c8fc;
}
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
  background-color: #11c8fc;
}
.datepicker table tr td.today:hover:hover {
  color: #fff;
}
.datepicker table tr td.today.active:hover {
  color: #fff;
}
.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
  background: #dbdfea;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
  background-color: #9c73f5;
  border-radius: 0;
}
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled] {
  background-color: #11c8fc;
}
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active {
  background-color: #11c8fc \9;
}
.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
  background-color: #3c4d62;
  color: #fff;
}
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected[disabled],
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected.disabled:hover[disabled] {
  background-color: #344357;
}
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active {
  background-color: #2b3748 \9;
}
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-color: #11c8fc;
  color: #fff;
}
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
  background-color: #11c8fc;
}
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
  background-color: #11c8fc;
}
.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  transition: all 0.3s;
}
.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  background: #11c8fc;
  color: #fff;
}
.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #dbdfea;
  cursor: default;
}
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-color: #11c8fc;
  color: #fff;
}
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: #11c8fc;
}
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
  background-color: #11c8fc \9;
}
.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #dbdfea;
}
.datepicker .datepicker-switch {
  width: 145px;
}
.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th {
  cursor: pointer;
}
.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  background: #ebeef2;
}
.datepicker .prev.disabled,
.datepicker .next.disabled {
  visibility: hidden;
}
.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}
.input-append.date .add-on,
.input-prepend.date .add-on {
  cursor: pointer;
}
.input-append.date .add-on i,
.input-prepend.date .add-on i {
  margin-top: 3px;
}
.input-daterange input {
  text-align: center;
}
.input-daterange input:first-child {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.input-daterange input:last-child {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.input-daterange .add-on {
  display: inline-block;
  width: auto;
  min-width: 16px;
  height: 18px;
  padding: 4px 5px;
  font-weight: normal;
  line-height: 18px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  vertical-align: middle;
  background-color: #ebeef2;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px;
}
.datepicker.dropdown-menu {
  z-index: 99999 !important;
}
.ui-timepicker-container {
  position: absolute;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 1052 !important;
}
.ui-timepicker {
  box-sizing: content-box;
  display: block;
  height: 205px;
  list-style: none outside none;
  margin: 0;
  padding: 0 1px;
  text-align: center;
}
.ui-timepicker-viewport {
  box-sizing: content-box;
  display: block;
  height: 205px;
  margin: 0;
  padding: 0;
  overflow: auto;
  overflow-x: hidden;
}
.ui-timepicker-standard {
  font-size: 1.1em;
  background-color: #fff;
  border: 1px solid #dbdfea;
  color: #526484;
  margin: 0;
  padding: 6px;
}
.ui-timepicker-standard a {
  border: none;
  color: #526484;
  display: block;
  padding: 0.375rem 0.75rem;
  text-decoration: none;
}
.ui-timepicker-standard .ui-state-hover {
  background-color: #ebeef2;
  border: none;
  font-weight: normal;
  color: #364a63;
  cursor: pointer;
  border-radius: 4px;
}
.ui-timepicker-standard .ui-menu-item {
  margin: 0;
  padding: 0;
}
.ui-timepicker-corners,
.ui-timepicker-corners .ui-corner-all {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.ui-timepicker-hidden {
  display: none;
}
.ui-timepicker-no-scrollbar .ui-timepicker {
  border: none;
}
@-webkit-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@-moz-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-moz-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.dropzone,
.dropzone * {
  box-sizing: border-box;
}
.dropzone {
  min-height: 150px;
  border: 1px dashed #e5e9f2;
  border-radius: 4px;
  background: #fff;
  padding: 20px 20px;
}
.dropzone.small {
  min-height: 86px;
}
.dropzone.dz-clickable {
  cursor: pointer;
}
.dropzone.dz-clickable * {
  cursor: default;
}
.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  cursor: pointer;
}
.dropzone.dz-started .dz-message {
  display: none;
}
.dropzone.dz-drag-hover {
  border-style: solid;
}
.dropzone.dz-drag-hover .dz-message {
  opacity: 0.5;
}
.dropzone .dz-message {
  text-align: center;
  margin: 1em 0;
}
.dropzone .dz-message span {
  display: block;
  color: rgba(82, 100, 132, 0.6);
}
.dropzone .dz-message-text {
  font-size: 13px;
}
.dropzone .dz-message-text span {
  display: inline-block;
  color: #11c8fc;
}
.dropzone .dz-message-or {
  font-size: 16px;
  margin-bottom: 4px;
  text-transform: uppercase;
}
.dropzone .dz-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 16px;
  min-height: 100px;
}
.dropzone .dz-preview:hover {
  z-index: 1000;
}
.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}
.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 20px;
  background: #999;
  background: linear-gradient(to bottom, #eee, #ddd);
}
.dropzone .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}
.dropzone .dz-preview.dz-image-preview {
  background: #fff;
}
.dropzone .dz-preview.dz-image-preview .dz-details {
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.dropzone .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
}
.dropzone .dz-preview .dz-remove:hover {
  text-decoration: underline;
}
.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}
.dropzone .dz-preview .dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
}
.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}
.dropzone .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
}
.dropzone .dz-preview .dz-details .dz-filename:hover span {
  border: 1px solid rgba(200, 200, 200, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
}
.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid rgba(0, 0, 0, 0);
}
.dropzone .dz-preview .dz-details .dz-filename span,
.dropzone .dz-preview .dz-details .dz-size span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}
.dropzone .dz-preview:hover .dz-image img {
  -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-filter: blur(8px);
  filter: blur(8px);
}
.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
}
.dropzone .dz-preview .dz-image img {
  display: block;
}
.dropzone .dz-preview.dz-success .dz-success-mark {
  -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}
.dropzone .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}
.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -27px;
}
.dropzone .dz-preview .dz-success-mark svg,
.dropzone .dz-preview .dz-error-mark svg {
  display: block;
  width: 54px;
  height: 54px;
}
.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in;
  -moz-transition: opacity 0.4s ease-in;
  -ms-transition: opacity 0.4s ease-in;
  -o-transition: opacity 0.4s ease-in;
  transition: opacity 0.4s ease-in;
}
.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse 6s ease infinite;
  -moz-animation: pulse 6s ease infinite;
  -ms-animation: pulse 6s ease infinite;
  -o-animation: pulse 6s ease infinite;
  animation: pulse 6s ease infinite;
}
.dropzone .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  width: 80px;
  margin-left: -40px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-transform: scale(1);
  border-radius: 8px;
  overflow: hidden;
}
.dropzone .dz-preview .dz-progress .dz-upload {
  background: #333;
  background: linear-gradient(to bottom, #666, #444);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  -webkit-transition: width 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out;
  -ms-transition: width 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out;
  transition: width 300ms ease-in-out;
}
.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}
.dropzone .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}
.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  display: block;
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  font-size: 13px;
  top: 130px;
  left: -10px;
  width: 140px;
  background: #be2626;
  background: linear-gradient(to bottom, #be2626, #a92222);
  padding: 0.5em 1.2em;
  color: #fff;
}
.dropzone .dz-preview .dz-error-message:after {
  content: "";
  position: absolute;
  top: -6px;
  left: 64px;
  width: 0;
  height: 0;
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  border-bottom: 6px solid #be2626;
}
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
}
.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}
.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}
.noUi-connect {
  height: 100%;
  width: 100%;
}
.noUi-origin {
  height: 10%;
  width: 10%;
}
.noUi-vertical .noUi-origin {
  width: 100%;
}
.noUi-horizontal .noUi-origin {
  height: 100%;
}
.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}
.noUi-handle:focus {
  outline: none;
}
.noUi-touch-area {
  height: 100%;
  width: 100%;
}
.noUi-touch-area:focus {
  outline: none;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
.noUi-horizontal {
  height: 8px;
}
.noUi-horizontal .noUi-handle {
  width: 28px;
  height: 28px;
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
}
.noUi-vertical {
  width: 8px;
  height: 200px;
}
.noUi-vertical .noUi-handle {
  width: 28px;
  height: 28px;
  left: 50%;
  top: -14px;
  transform: translateX(-50%);
}
.noUi-target {
  background: #dbdfea;
  border-radius: 4px;
}
.noUi-connects {
  border-radius: 3px;
}
.noUi-connect {
  background: #11c8fc;
}
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-handle {
  border: 1px solid #dbdfea;
  border-radius: 50%;
  background: #fff;
  cursor: default;
  box-shadow: 0 3px 12px 1px rgba(43, 55, 72, 0.15);
}
.noUi-active {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 10px;
  width: 1px;
  background: #dbdfea;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.noUi-horizontal .noUi-handle:before {
  margin-left: -2px;
}
.noUi-horizontal .noUi-handle:after {
  margin-left: 2px;
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 10px;
  height: 1px;
}
.noUi-vertical .noUi-handle:before {
  margin-top: -2px;
}
.noUi-vertical .noUi-handle:after {
  margin-top: 2px;
}
[disabled] .noUi-connect {
  background: #e5e9f2;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: #999;
}
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}
.noUi-marker {
  position: absolute;
  background: #ccc;
}
.noUi-marker-sub {
  background: #aaa;
}
.noUi-marker-large {
  background: #aaa;
}
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #1f2b3a;
  color: var(--bs-body-bg);
  padding: 0.25rem 0.75rem;
  text-align: center;
  white-space: nowrap;
  font-size: 13px;
  opacity: 0;
  transition: all 0.3s ease;
}
.noUi-handle:hover .noUi-tooltip {
  opacity: 0.9;
}
.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}
#toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
  padding: 1.25rem;
  width: 100%;
}
#toast-container[class*="-center"] .toastr {
  margin-left: auto;
  margin-right: auto;
}
#toast-container[class*="-left"] .toastr {
  margin-right: auto;
}
#toast-container[class*="-right"] .toastr {
  margin-left: auto;
}
#toast-container[class*="-full"] .toastr {
  width: 100%;
}
#toast-container[class*="toast-top"] {
  top: 0;
}
#toast-container[class*="toast-bottom"] {
  bottom: 0;
}
.toastr {
  position: relative;
  pointer-events: auto;
  background: #fff;
  box-shadow: 0 3px 12px 1px rgba(43, 55, 72, 0.15);
  border-radius: 4px;
  opacity: 1;
  border-left: 4px solid rgba(0, 0, 0, 0);
  padding: 1.25rem 4.5rem 1.25rem 1.25rem;
  width: 430px;
  max-width: 100%;
  font-size: 0.875rem;
  line-height: 1.3rem;
}
.is-dark .toastr {
  background: #101924;
  color: #fff;
}
.toastr:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toastr.toast-success {
  border-color: #0fca7a;
}
.toastr.toast-success .icon {
  color: #0fca7a;
}
.toastr.toast-error {
  border-color: #f2426e;
}
.toastr.toast-error .icon {
  color: #f2426e;
}
.toastr.toast-info {
  border-color: #058efc;
}
.toastr.toast-info .icon {
  color: #058efc;
}
.toastr.toast-warning {
  border-color: #fd9722;
}
.toastr.toast-warning .icon {
  color: #fd9722;
}
.toastr-icon {
  position: absolute;
  left: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  line-height: 1;
}
.toastr-icon + .toastr-text {
  padding-left: 2.35rem;
}
.toastr h4,
.toastr .h4,
.toastr h5,
.toastr .h5,
.toastr h6,
.toastr .h6 {
  margin-bottom: 0.35rem;
  font-size: 1rem;
}
.is-dark .toastr h4,
.is-dark .toastr .h4,
.is-dark .toastr h5,
.is-dark .toastr .h5,
.is-dark .toastr h6,
.is-dark .toastr .h6 {
  color: #fff;
}
.toast-close-button {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  height: 32px;
  width: 32px;
  text-indent: -9999px;
  cursor: pointer;
}
.toast-close-button:after {
  position: absolute;
  top: 0;
  left: 0;
  text-indent: 0;
  color: #8094ae;
  font-family: "Nioicon";
  content: "";
  font-size: 18px;
  line-height: 32px;
  width: 100%;
  text-align: center;
}
.is-dark .toast-close-button:after {
  color: #dbdfea;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}
.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #526484;
}
.toast-message a:hover {
  color: #ccc;
  text-decoration: none;
}
.swal2-popup.swal2-toast {
  flex-direction: row;
  align-items: center;
  width: auto;
  padding: 0.625em;
  overflow-y: hidden;
  background: #fff;
  box-shadow: 0 0 0.625em #d9d9d9;
}
.swal2-popup.swal2-toast .swal2-header {
  flex-direction: row;
}
.swal2-popup.swal2-toast .swal2-title {
  flex-grow: 1;
  justify-content: flex-start;
  margin: 0 0.6em;
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-footer {
  margin: 0.5em 0 0;
  padding: 0.5em 0 0;
  font-size: 0.8em;
}
.swal2-popup.swal2-toast .swal2-close {
  position: static;
  width: 0.8em;
  height: 0.8em;
  line-height: 0.8;
}
.swal2-popup.swal2-toast .swal2-content {
  justify-content: flex-start;
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-icon {
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0;
}
.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 1.8em;
  font-weight: bold;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
    font-size: 0.25em;
  }
}
.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  top: 0.875em;
  width: 1.375em;
}
.swal2-popup.swal2-toast
  .swal2-icon.swal2-error
  [class^="swal2-x-mark-line"][class$="left"] {
  left: 0.3125em;
}
.swal2-popup.swal2-toast
  .swal2-icon.swal2-error
  [class^="swal2-x-mark-line"][class$="right"] {
  right: 0.3125em;
}
.swal2-popup.swal2-toast .swal2-actions {
  flex-basis: auto !important;
  width: auto;
  height: auto;
  margin: 0 0.3125em;
}
.swal2-popup.swal2-toast .swal2-actions .btn,
.swal2-popup.swal2-toast .swal2-actions .actions ul li a,
.actions ul li .swal2-popup.swal2-toast .swal2-actions a,
.swal2-popup.swal2-toast .swal2-actions .dual-listbox .dual-listbox__button,
.dual-listbox .swal2-popup.swal2-toast .swal2-actions .dual-listbox__button {
  margin: 0 0.5em;
}
.swal2-popup.swal2-toast .swal2-styled {
  margin: 0 0.5em;
  padding: 0.3125em 0.625em;
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(115, 58, 234, 0.4);
}
.swal2-popup.swal2-toast .swal2-success {
  border-color: #0fca7a;
}
.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-circular-line"] {
  position: absolute;
  width: 1.6em;
  height: 3em;
  transform: rotate(45deg);
  border-radius: 50%;
}
.swal2-popup.swal2-toast
  .swal2-success
  [class^="swal2-success-circular-line"][class$="left"] {
  top: -0.8em;
  left: -0.5em;
  border-radius: 4em 0 0 4em;
  transform: rotate(-45deg);
  transform-origin: 2em 2em;
}
.swal2-popup.swal2-toast
  .swal2-success
  [class^="swal2-success-circular-line"][class$="right"] {
  top: -0.25em;
  left: 0.9375em;
  border-radius: 0 4em 4em 0;
  transform-origin: 0 1.5em;
}
.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}
.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  top: 0;
  left: 0.4375em;
  width: 0.4375em;
  height: 2.6875em;
}
.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-line"] {
  height: 0.3125em;
}
.swal2-popup.swal2-toast
  .swal2-success
  [class^="swal2-success-line"][class$="tip"] {
  top: 1.125em;
  left: 0.1875em;
  width: 0.75em;
}
.swal2-popup.swal2-toast
  .swal2-success
  [class^="swal2-success-line"][class$="long"] {
  top: 0.9375em;
  right: 0.1875em;
  width: 1.375em;
}
.swal2-popup.swal2-toast
  .swal2-success.swal2-icon-show
  .swal2-success-line-tip {
  animation: swal2-toast-animate-success-line-tip 0.75s;
}
.swal2-popup.swal2-toast
  .swal2-success.swal2-icon-show
  .swal2-success-line-long {
  animation: swal2-toast-animate-success-line-long 0.75s;
}
.swal2-popup.swal2-toast.swal2-show {
  animation: swal2-toast-show 0.5s;
}
.swal2-popup.swal2-toast.swal2-hide {
  animation: swal2-toast-hide 0.1s forwards;
}
.swal2-container {
  display: flex;
  position: fixed;
  z-index: 1060;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.625em;
  overflow-x: hidden;
  transition: background-color 0.1s;
  -webkit-overflow-scrolling: touch;
}
.swal2-container.swal2-backdrop-show {
  background: rgba(28, 43, 70, 0.4);
}
.swal2-container.swal2-backdrop-hide {
  background: rgba(0, 0, 0, 0) !important;
}
.swal2-container.swal2-top {
  align-items: flex-start;
}
.swal2-container.swal2-top-start,
.swal2-container.swal2-top-left {
  align-items: flex-start;
  justify-content: flex-start;
}
.swal2-container.swal2-top-end,
.swal2-container.swal2-top-right {
  align-items: flex-start;
  justify-content: flex-end;
}
.swal2-container.swal2-center {
  align-items: center;
}
.swal2-container.swal2-center-start,
.swal2-container.swal2-center-left {
  align-items: center;
  justify-content: flex-start;
}
.swal2-container.swal2-center-end,
.swal2-container.swal2-center-right {
  align-items: center;
  justify-content: flex-end;
}
.swal2-container.swal2-bottom {
  align-items: flex-end;
}
.swal2-container.swal2-bottom-start,
.swal2-container.swal2-bottom-left {
  align-items: flex-end;
  justify-content: flex-start;
}
.swal2-container.swal2-bottom-end,
.swal2-container.swal2-bottom-right {
  align-items: flex-end;
  justify-content: flex-end;
}
.swal2-container.swal2-bottom > :first-child,
.swal2-container.swal2-bottom-start > :first-child,
.swal2-container.swal2-bottom-left > :first-child,
.swal2-container.swal2-bottom-end > :first-child,
.swal2-container.swal2-bottom-right > :first-child {
  margin-top: auto;
}
.swal2-container.swal2-grow-fullscreen > .swal2-modal {
  display: flex !important;
  flex: 1;
  align-self: stretch;
  justify-content: center;
}
.swal2-container.swal2-grow-row > .swal2-modal {
  display: flex !important;
  flex: 1;
  align-content: center;
  justify-content: center;
}
.swal2-container.swal2-grow-column {
  flex: 1;
  flex-direction: column;
}
.swal2-container.swal2-grow-column.swal2-top,
.swal2-container.swal2-grow-column.swal2-center,
.swal2-container.swal2-grow-column.swal2-bottom {
  align-items: center;
}
.swal2-container.swal2-grow-column.swal2-top-start,
.swal2-container.swal2-grow-column.swal2-center-start,
.swal2-container.swal2-grow-column.swal2-bottom-start,
.swal2-container.swal2-grow-column.swal2-top-left,
.swal2-container.swal2-grow-column.swal2-center-left,
.swal2-container.swal2-grow-column.swal2-bottom-left {
  align-items: flex-start;
}
.swal2-container.swal2-grow-column.swal2-top-end,
.swal2-container.swal2-grow-column.swal2-center-end,
.swal2-container.swal2-grow-column.swal2-bottom-end,
.swal2-container.swal2-grow-column.swal2-top-right,
.swal2-container.swal2-grow-column.swal2-center-right,
.swal2-container.swal2-grow-column.swal2-bottom-right {
  align-items: flex-end;
}
.swal2-container.swal2-grow-column > .swal2-modal {
  display: flex !important;
  flex: 1;
  align-content: center;
  justify-content: center;
}
.swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(
    .swal2-top-left
  ):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(
    .swal2-center-left
  ):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(
    .swal2-bottom-end
  ):not(.swal2-bottom-left):not(.swal2-bottom-right):not(.swal2-grow-fullscreen)
  > .swal2-modal {
  margin: auto;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-container .swal2-modal {
    margin: 0 !important;
  }
}
.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  width: 480px;
  max-width: 100%;
  padding: 2.75rem 2.5rem;
  border: none;
  border-radius: 4px;
  background: #fff;
  font-family: inherit;
  font-size: 1rem;
}
.swal2-popup:focus {
  outline: none;
}
.swal2-popup.swal2-loading {
  overflow-y: hidden;
}
.swal2-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: #364a63;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}
.swal2-html-container {
  margin-bottom: 1rem;
}
.swal2-actions {
  display: flex;
  z-index: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1rem auto 0;
}
.swal2-actions .btn,
.swal2-actions .actions ul li a,
.actions ul li .swal2-actions a,
.swal2-actions .dual-listbox .dual-listbox__button,
.dual-listbox .swal2-actions .dual-listbox__button {
  margin: 0 0.75rem;
}
.swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: 0.4;
}
.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(
    rgba(31, 43, 58, 0.1),
    rgba(31, 43, 58, 0.1)
  );
}
.swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background-image: linear-gradient(
    rgba(31, 43, 58, 0.2),
    rgba(31, 43, 58, 0.2)
  );
}
.swal2-actions.swal2-loading .swal2-styled.swal2-confirm {
  box-sizing: border-box;
  width: 2.5em;
  height: 2.5em;
  margin: 0.46875em;
  padding: 0;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  border: 0.25em solid rgba(0, 0, 0, 0);
  border-radius: 100%;
  border-color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0) !important;
  color: rgba(0, 0, 0, 0);
  cursor: default;
  user-select: none;
}
.swal2-actions.swal2-loading .swal2-styled.swal2-cancel {
  margin-right: 30px;
  margin-left: 30px;
}
.swal2-actions.swal2-loading :not(.swal2-styled).swal2-confirm::after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  border: 3px solid #b4c4d7;
  border-radius: 50%;
  margin-left: 5px;
  border-right-color: rgba(0, 0, 0, 0);
  box-shadow: 1px 1px 1px #fff;
}
.swal2-styled {
  margin: 0.5rem;
  box-shadow: none;
  padding: 0.5rem 1rem;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  border-radius: 5px;
  min-width: 90px;
}
.swal2-loading .swal2-styled {
  min-width: 0;
}
.swal2-styled:not([disabled]) {
  cursor: pointer;
}
.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #0fca7a;
  color: #fff;
}
.swal2-styled.swal2-confirm:focus {
  outline: none;
}
.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #f2426e;
  color: #fff;
}
.swal2-styled.swal2-cancel:focus {
  outline: none;
}
.swal2-styled::-moz-focus-inner {
  border: 0;
}
.swal2-footer {
  justify-content: center;
  margin: 1.25em 0 0;
  padding: 1em 0 0;
  border-top: 1px solid #eee;
  color: #5a7ca7;
  font-size: 1em;
}
.swal2-timer-progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.25em;
  background: rgba(31, 43, 58, 0.2);
}
.swal2-image {
  max-width: 100%;
  margin: 0 auto 1.5rem;
}
.swal2-close {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  padding: 0;
  overflow: hidden;
  transition: color 0.1s ease-out;
  border: none;
  border-radius: 0;
  outline: initial;
  background: rgba(0, 0, 0, 0);
  color: #f7f8fa;
  font-family: serif;
  font-size: 2.5em;
  line-height: 1.2;
  cursor: pointer;
}
.swal2-close:hover {
  transform: none;
  background: rgba(0, 0, 0, 0);
  color: #f2426e;
}
.swal2-close::-moz-focus-inner {
  border: 0;
}
.swal2-content {
  z-index: 1;
  justify-content: center;
  margin: 0;
  padding: 0;
  color: #8094ae;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
}
.swal2-input,
.swal2-file,
.swal2-textarea,
.swal2-select,
.swal2-radio,
.swal2-checkbox {
  margin: 1rem auto;
}
.swal2-input,
.swal2-file,
.swal2-textarea {
  box-sizing: border-box;
  width: 100%;
  transition: border-color 0.3s, box-shadow 0.3s;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  background: inherit;
  box-shadow: none;
  color: inherit;
  font-size: 0.875rem;
}
.swal2-input.swal2-inputerror,
.swal2-file.swal2-inputerror,
.swal2-textarea.swal2-inputerror {
  border-color: #f2426e !important;
  box-shadow: 0 0 2px #f2426e !important;
}
.swal2-input:focus,
.swal2-file:focus,
.swal2-textarea:focus {
  border: 1px solid #11c8fc;
  outline: none;
  box-shadow: 0 0 0 3px rgba(115, 58, 234, 0.1);
}
.swal2-input::placeholder,
.swal2-file::placeholder,
.swal2-textarea::placeholder {
  color: #f7f8fa;
}
.swal2-range {
  margin: 1rem auto;
  background: #fff;
}
.swal2-range input {
  width: 80%;
}
.swal2-range output {
  width: 20%;
  color: inherit;
  font-weight: 600;
  text-align: center;
}
.swal2-range input,
.swal2-range output {
  height: 2.75rem;
  padding: 0;
  font-size: 0.875rem;
  line-height: 2.75rem;
}
.swal2-input {
  height: 2.75rem;
  padding: 0 1rem;
}
.swal2-input[type="number"] {
  max-width: 10em;
}
.swal2-file {
  background: inherit;
  font-size: 0.875rem;
}
.swal2-textarea {
  height: 6.75em;
  padding: 0.75em;
}
.swal2-select {
  min-width: 50%;
  max-width: 100%;
  padding: 0.375em 0.625em;
  background: inherit;
  color: inherit;
  font-size: 0.875rem;
}
.swal2-radio,
.swal2-checkbox {
  align-items: center;
  justify-content: center;
  background: #fff;
  color: inherit;
}
.swal2-radio label,
.swal2-checkbox label {
  margin: 0 0.6em;
  font-size: 0.875rem;
}
.swal2-radio input,
.swal2-checkbox input {
  margin: 0 0.4em;
}
.swal2-validation-message {
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0.625em;
  overflow: hidden;
  background: #fff;
  color: #728fb3;
  font-size: 1em;
  font-weight: 300;
}
.swal2-validation-message::before {
  content: "!";
  display: inline-block;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 0.625em;
  border-radius: 50%;
  background-color: #f2426e;
  color: #fff;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center;
}
.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: -0.5rem auto 1.5rem;
  border: 0.25em solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;
  transform: scale(0.8);
}
.swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 3.75em;
}
.swal2-icon.swal2-error {
  border-color: #f2426e;
  color: #f2426e;
}
.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #f2426e;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="left"] {
  left: 1.0625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="right"] {
  right: 1em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-error.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
  animation: swal2-animate-error-x-mark 0.5s;
}
.swal2-icon.swal2-warning {
  border-color: #fda845;
  color: #fd9722;
}
.swal2-icon.swal2-info {
  border-color: #6abcfd;
  color: #058efc;
}
.swal2-icon.swal2-question {
  border-color: #8798b5;
  color: #526484;
}
.swal2-icon.swal2-success {
  border-color: #0fca7a;
  color: #0fca7a;
}
.swal2-icon.swal2-success [class^="swal2-success-circular-line"] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  transform: rotate(45deg);
  border-radius: 50%;
}
.swal2-icon.swal2-success
  [class^="swal2-success-circular-line"][class$="left"] {
  top: -0.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
}
.swal2-icon.swal2-success
  [class^="swal2-success-circular-line"][class$="right"] {
  top: -0.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0;
}
.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 2;
  top: -0.25em;
  left: -0.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(15, 202, 122, 0.3);
  border-radius: 50%;
}
.swal2-icon.swal2-success .swal2-success-fix {
  position: absolute;
  z-index: 1;
  top: 0.5em;
  left: 1.625em;
  width: 0.4375em;
  height: 5.625em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success [class^="swal2-success-line"] {
  display: block;
  position: absolute;
  z-index: 2;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #0fca7a;
}
.swal2-icon.swal2-success [class^="swal2-success-line"][class$="tip"] {
  top: 2.875em;
  left: 0.875em;
  width: 1.5625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-success [class^="swal2-success-line"][class$="long"] {
  top: 2.375em;
  right: 0.5em;
  width: 2.9375em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: swal2-animate-success-line-tip 0.75s;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: swal2-animate-success-line-long 0.75s;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  animation: swal2-rotate-success-circular-line 4.25s ease-in;
}
.swal2-progress-steps {
  align-items: center;
  margin: 0 0 1.25em;
  padding: 0;
  background: inherit;
  font-weight: 600;
}
.swal2-progress-steps li {
  display: inline-block;
  position: relative;
}
.swal2-progress-steps .swal2-progress-step {
  z-index: 20;
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background: #3085d6;
  color: #fff;
  line-height: 2em;
  text-align: center;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #3085d6;
}
.swal2-progress-steps
  .swal2-progress-step.swal2-active-progress-step
  ~ .swal2-progress-step {
  background: #add8e6;
  color: #fff;
}
.swal2-progress-steps
  .swal2-progress-step.swal2-active-progress-step
  ~ .swal2-progress-step-line {
  background: #add8e6;
}
.swal2-progress-steps .swal2-progress-step-line {
  z-index: 10;
  width: 2.5em;
  height: 0.4em;
  margin: 0 -1px;
  background: #3085d6;
}
[class^="swal2"] {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.swal2-show {
  animation: swal2-show 0.3s;
}
.swal2-hide {
  animation: swal2-hide 0.15s forwards;
}
.swal2-noanimation {
  transition: none;
}
.swal2-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
.swal2-rtl .swal2-close {
  right: auto;
  left: 0;
}
.swal2-rtl .swal2-timer-progress-bar {
  right: 0;
  left: auto;
}
@supports (-ms-accelerator: true) {
  .swal2-range input {
    width: 100% !important;
  }
  .swal2-range output {
    display: none;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-range input {
    width: 100% !important;
  }
  .swal2-range output {
    display: none;
  }
}
@-moz-document url-prefix() {
  .swal2-close:focus {
    outline: 2px solid rgba(115, 58, 234, 0.4);
  }
}
@keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg);
  }
  33% {
    transform: translateY(0) rotateZ(-2deg);
  }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg);
  }
  100% {
    transform: translateY(0) rotateZ(0deg);
  }
}
@keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0;
  }
}
@keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: 0.5625em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 0.125em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 0.625em;
    left: -0.25em;
    width: 1.625em;
  }
  84% {
    top: 1.0625em;
    left: 0.75em;
    width: 0.5em;
  }
  100% {
    top: 1.125em;
    left: 0.1875em;
    width: 0.75em;
  }
}
@keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0;
  }
  65% {
    top: 1.25em;
    right: 0.9375em;
    width: 0;
  }
  84% {
    top: 0.9375em;
    right: 0;
    width: 1.125em;
  }
  100% {
    top: 0.9375em;
    right: 0.1875em;
    width: 1.375em;
  }
}
@keyframes swal2-show {
  0% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  40% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.7);
    opacity: 0;
  }
}
@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 1.0625em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 2.1875em;
    left: -0.375em;
    width: 3.125em;
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }
  100% {
    top: 2.8125em;
    left: 0.875em;
    width: 1.5625em;
  }
}
@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }
  100% {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
  }
}
@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}
@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  80% {
    margin-top: -0.375em;
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes swal2-animate-error-icon {
  0% {
    transform: scale(0.8) rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: scale(0.8) rotateX(0deg);
    opacity: 1;
  }
}
@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden;
}
body.swal2-height-auto {
  height: auto !important;
}
body.swal2-no-backdrop .swal2-container {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  max-width: calc(100% - 0.625em * 2);
  background-color: rgba(0, 0, 0, 0) !important;
}
body.swal2-no-backdrop .swal2-container > .swal2-modal {
  box-shadow: 0 0 10px rgba(28, 43, 70, 0.4);
}
body.swal2-no-backdrop .swal2-container.swal2-top {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-top-start,
body.swal2-no-backdrop .swal2-container.swal2-top-left {
  top: 0;
  left: 0;
}
body.swal2-no-backdrop .swal2-container.swal2-top-end,
body.swal2-no-backdrop .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
}
body.swal2-no-backdrop .swal2-container.swal2-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body.swal2-no-backdrop .swal2-container.swal2-center-start,
body.swal2-no-backdrop .swal2-container.swal2-center-left {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-center-end,
body.swal2-no-backdrop .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-bottom {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-bottom-start,
body.swal2-no-backdrop .swal2-container.swal2-bottom-left {
  bottom: 0;
  left: 0;
}
body.swal2-no-backdrop .swal2-container.swal2-bottom-end,
body.swal2-no-backdrop .swal2-container.swal2-bottom-right {
  right: 0;
  bottom: 0;
}
@media print {
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: scroll !important;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown)
    > [aria-hidden="true"] {
    display: none;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown)
    .swal2-container {
    position: static !important;
  }
}
body.swal2-toast-shown .swal2-container {
  background-color: rgba(0, 0, 0, 0);
}
body.swal2-toast-shown .swal2-container.swal2-top {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-top-end,
body.swal2-toast-shown .swal2-container.swal2-top-right {
  top: 0;
  bottom: auto;
  right: 0;
  left: auto;
}
body.swal2-toast-shown .swal2-container.swal2-top-start,
body.swal2-toast-shown .swal2-container.swal2-top-left {
  top: 0;
  bottom: auto;
  left: 0;
  right: auto;
}
body.swal2-toast-shown .swal2-container.swal2-center-start,
body.swal2-toast-shown .swal2-container.swal2-center-left {
  top: 50%;
  bottom: auto;
  right: auto;
  left: 0;
  transform: translateY(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-center {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%);
}
body.swal2-toast-shown .swal2-container.swal2-center-end,
body.swal2-toast-shown .swal2-container.swal2-center-right {
  top: 50%;
  bottom: auto;
  right: 0;
  left: auto;
  transform: translateY(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-bottom-start,
body.swal2-toast-shown .swal2-container.swal2-bottom-left {
  top: auto;
  bottom: 0;
  right: auto;
  left: 0;
}
body.swal2-toast-shown .swal2-container.swal2-bottom {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-bottom-end,
body.swal2-toast-shown .swal2-container.swal2-bottom-right {
  top: auto;
  bottom: 0;
  right: 0;
  left: auto;
}
body.swal2-toast-column .swal2-toast {
  flex-direction: column;
  align-items: stretch;
}
body.swal2-toast-column .swal2-toast .swal2-actions {
  flex: 1;
  align-self: stretch;
  height: 2.2em;
  margin-top: 0.3125em;
}
body.swal2-toast-column .swal2-toast .swal2-loading {
  justify-content: center;
}
body.swal2-toast-column .swal2-toast .swal2-input {
  height: 2em;
  margin: 0.3125em auto;
  font-size: 1em;
}
body.swal2-toast-column .swal2-toast .swal2-validation-message {
  font-size: 1em;
}
table.dataTable {
  clear: both;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}
table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  text-align: center;
}
table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_length select {
  width: auto;
  display: inline-block;
}
div.dataTables_wrapper div.dataTables_length label {
  display: inline-flex;
  align-items: center;
}
div.dataTables_wrapper div.dataTables_length span {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_filter input {
  display: inline-block;
}
div.dataTables_wrapper div.dataTables_info {
  padding: 0.5rem 0;
  white-space: nowrap;
  font-size: 0.75rem;
}
div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  justify-content: center;
}
div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  width: 200px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0;
}
div.dataTables_wrapper label {
  margin-bottom: 0;
}
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 30px;
}
table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  opacity: 0.3;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  content: "↑";
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "↓";
}
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1;
}
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0;
}
div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}
div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
div.dataTables_scrollBody table thead .sorting:before,
div.dataTables_scrollBody table thead .sorting_asc:before,
div.dataTables_scrollBody table thead .sorting_desc:before,
div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none;
}
div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: none;
}
div.dataTables_scrollFoot > .dataTables_scrollFootInner {
  box-sizing: content-box;
}
div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
  margin-top: 0 !important;
  border-top: none;
}
@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
}
table.dataTable.table-sm > thead > tr > th {
  padding-right: 20px;
}
table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
  top: 5px;
  right: 0.85em;
}
table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
  top: 5px;
}
table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0;
}
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
  border-right-width: 0;
}
table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
}
div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}
div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}
div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^="col-"]:first-child {
  padding-left: 0;
}
div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^="col-"]:last-child {
  padding-right: 0;
}
@media (min-width: 768px) {
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: flex-start;
  }
}
table.dataTable.dtr-inline.collapsed > tbody > tr:not(.child) > td:first-child,
table.dataTable.dtr-inline.collapsed > tbody > tr:not(.child) > th:first-child {
  cursor: pointer;
}
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr:not(.child)
  > td:first-child:before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr:not(.child)
  > th:first-child:before {
  content: "";
  font-family: "Nioicon";
  margin-right: 0.4rem;
}
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > td:first-child:before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > th:first-child:before {
  content: "";
}
@media (max-width: 575px) {
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: flex-start;
  }
  div.dataTables_wrapper
    div.dataTables_paginate
    ul.pagination
    li:not(.previous):not(.next) {
    display: none;
  }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination .page-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  div.dataTables_wrapper div.dataTables_info {
    text-align: right !important;
  }
}
.dataTable td:focus {
  outline: none;
}
.datatable-wrap {
  border: 1px solid #e5e9f2;
  border-radius: 4px;
}
.dtr-details {
  padding-left: 0.25rem;
}
.dtr-details .dtr-title {
  font-weight: 700;
  margin-right: 0.25rem;
}
.nk-tb-col-check,
.nk-tb-col-tools {
  pointer-events: none;
}
.nk-tb-col-check > *,
.nk-tb-col-tools > * {
  pointer-events: auto;
}
.nk-tb-col-check:before,
.nk-tb-col-check:after,
.nk-tb-col-tools:before,
.nk-tb-col-tools:after {
  display: none !important;
}
.nk-tb-col-check {
  padding-right: 0.5rem !important;
}
@keyframes dtb-spinner {
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes dtb-spinner {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes dtb-spinner {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dtb-spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes dtb-spinner {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: #f5f6fa;
  box-shadow: 0 0 0 200vh rgba(31, 43, 58, 0.3);
  border-radius: 4px;
  text-align: center;
  z-index: 2020;
  overflow: hidden;
  border: 1px solid #e5e9f2;
}
div.dt-button-info h2,
div.dt-button-info .h2 {
  padding: 2rem;
  margin: 0;
  font-weight: normal;
  border-bottom: 1px solid #e5e9f2;
  background-color: #fff;
  font-size: 1.75rem;
}
div.dt-button-info > div {
  padding: 1.25rem;
}
div.dt-button-collection-title {
  text-align: center;
  padding: 0.3em 0 0.5em;
  font-size: 0.9em;
}
div.dt-button-collection-title:empty {
  display: none;
}
div.dt-button-collection {
  position: absolute;
  z-index: 1000;
  width: 100%;
}
div.dt-button-collection div.dropdown-menu {
  display: block;
  z-index: 1001;
  min-width: 100px;
  top: 10px;
}
.dropdown-position-right div.dt-button-collection div.dropdown-menu {
  right: 0;
  left: auto;
}
div.dt-button-collection div.dt-button-collection-title {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
div.dt-button-collection.fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  border-radius: 0;
}
div.dt-button-collection.fixed.two-column {
  margin-left: -200px;
}
div.dt-button-collection.fixed.three-column {
  margin-left: -225px;
}
div.dt-button-collection.fixed.four-column {
  margin-left: -300px;
}
div.dt-button-collection > :last-child {
  display: block !important;
  -webkit-column-gap: 8px;
  -moz-column-gap: 8px;
  -ms-column-gap: 8px;
  -o-column-gap: 8px;
  column-gap: 8px;
}
div.dt-button-collection > :last-child > * {
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
}
div.dt-button-collection.two-column {
  width: 400px;
}
div.dt-button-collection.two-column > :last-child {
  padding-bottom: 1px;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  -ms-column-count: 2;
  -o-column-count: 2;
  column-count: 2;
}
div.dt-button-collection.three-column {
  width: 450px;
}
div.dt-button-collection.three-column > :last-child {
  padding-bottom: 1px;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  -ms-column-count: 3;
  -o-column-count: 3;
  column-count: 3;
}
div.dt-button-collection.four-column {
  width: 600px;
}
div.dt-button-collection.four-column > :last-child {
  padding-bottom: 1px;
  -webkit-column-count: 4;
  -moz-column-count: 4;
  -ms-column-count: 4;
  -o-column-count: 4;
  column-count: 4;
}
div.dt-button-collection .dt-button {
  border-radius: 0;
}
div.dt-button-collection.fixed {
  max-width: none;
}
div.dt-button-collection.fixed:before,
div.dt-button-collection.fixed:after {
  display: none;
}
div.dt-button-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(31, 43, 58, 0.3);
  display: none !important;
}
@media screen and (max-width: 767px) {
  div.dt-buttons {
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 0.5em;
  }
  div.dt-buttons a.btn,
  div.dt-buttons .actions ul li a,
  .actions ul li div.dt-buttons a,
  div.dt-buttons .dual-listbox a.dual-listbox__button,
  .dual-listbox div.dt-buttons a.dual-listbox__button {
    float: none;
  }
}
div.dt-buttons button.btn.processing,
div.dt-buttons .dual-listbox button.processing.dual-listbox__button,
.dual-listbox div.dt-buttons button.processing.dual-listbox__button,
div.dt-buttons div.btn.processing,
div.dt-buttons .dual-listbox div.processing.dual-listbox__button,
.dual-listbox div.dt-buttons div.processing.dual-listbox__button,
div.dt-buttons a.btn.processing,
div.dt-buttons .actions ul li a.processing,
.actions ul li div.dt-buttons a.processing,
div.dt-buttons .dual-listbox a.processing.dual-listbox__button,
.dual-listbox div.dt-buttons a.processing.dual-listbox__button {
  color: rgba(0, 0, 0, 0.2);
}
div.dt-buttons button.btn.processing:after,
div.dt-buttons .dual-listbox button.processing.dual-listbox__button:after,
.dual-listbox div.dt-buttons button.processing.dual-listbox__button:after,
div.dt-buttons div.btn.processing:after,
div.dt-buttons .dual-listbox div.processing.dual-listbox__button:after,
.dual-listbox div.dt-buttons div.processing.dual-listbox__button:after,
div.dt-buttons a.btn.processing:after,
div.dt-buttons .actions ul li a.processing:after,
.actions ul li div.dt-buttons a.processing:after,
div.dt-buttons .dual-listbox a.processing.dual-listbox__button:after,
.dual-listbox div.dt-buttons a.processing.dual-listbox__button:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  box-sizing: border-box;
  display: block;
  content: " ";
  border: 2px solid #282828;
  border-radius: 50%;
  border-left-color: rgba(0, 0, 0, 0);
  border-right-color: rgba(0, 0, 0, 0);
  animation: dtb-spinner 1500ms infinite linear;
  -o-animation: dtb-spinner 1500ms infinite linear;
  -ms-animation: dtb-spinner 1500ms infinite linear;
  -webkit-animation: dtb-spinner 1500ms infinite linear;
  -moz-animation: dtb-spinner 1500ms infinite linear;
}
.dt-buttons .btn-secondary span {
  display: none;
}
.dt-buttons .btn-secondary:before {
  font-size: 1.125rem;
  font-weight: normal;
  font-style: normal;
  width: 2.125rem;
  font-family: "Nioicon";
}
.dt-export-title {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.buttons-copy:before {
  content: "";
}
.buttons-pdf:before {
  content: "";
}
.buttons-excel:before {
  content: "";
}
.buttons-csv:before {
  content: "";
}
.buttons-print:before {
  content: "";
}
.buttons-colvis:before {
  content: "";
}
.buttons-columnVisibility {
  position: relative;
  padding-right: 42px;
  font-size: 12px;
  font-weight: 500;
  color: #526484;
  transition: all 0.4s;
}
.buttons-columnVisibility.active:not(:hover) {
  color: #526484;
  background-color: rgba(0, 0, 0, 0);
}
.buttons-columnVisibility.active:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  font-family: "Nioicon";
}
@media (max-width: 600px) {
  .with-export > .col-5,
  .with-export > .col-7,
  .with-export > .col-sm-4,
  .with-export > .col-sm-8 {
    width: 100% !important;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .with-export .datatable-filter .justify-content-end,
  .with-export .datatable-filter .justify-end {
    justify-content: space-between !important;
  }
  .with-export .dataTables_filter label {
    display: block;
  }
  .with-export .dataTables_filter .form-control,
  .with-export .dataTables_filter .dual-listbox .dual-listbox__search,
  .dual-listbox .with-export .dataTables_filter .dual-listbox__search,
  .with-export div.dataTables_wrapper div.dataTables_filter input,
  div.dataTables_wrapper .with-export div.dataTables_filter input {
    width: 100% !important;
  }
}
.jqvmap-label {
  position: absolute;
  display: none;
  border-radius: 4px;
  background: rgba(31, 43, 58, 0.9);
  color: #fff;
  padding: 0.25rem 0.75rem;
  pointer-events: none;
  font-size: 12px;
}
.jqvmap-pin {
  pointer-events: none;
}
.jqvmap-zoomin,
.jqvmap-zoomout {
  position: absolute;
  left: 10px;
  border-radius: 3px;
  background: rgba(31, 43, 58, 0.9);
  padding: 0.125rem;
  color: #fff;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  line-height: 0.75rem;
  text-align: center;
}
.jqvmap-zoomin {
  top: 10px;
}
.jqvmap-zoomout {
  top: 30px;
}
.jqvmap-region {
  cursor: pointer;
}
.jqvmap-ajax_response {
  width: 100%;
  height: 500px;
}
.tagify {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border: 1px solid #e5e9f2;
  padding: 3px;
  line-height: 1.1;
  cursor: text;
  outline: none;
  position: relative;
  transition: 0.1s;
  font-size: 14px;
}
@keyframes tags--bump {
  30% {
    transform: scale(1.2);
  }
}
@keyframes rotateLoader {
  to {
    transform: rotate(1turn);
  }
}
@media (max-width: 576px) {
  .tagify {
    padding: 4px;
    font-size: 12px;
  }
}
.tagify:hover {
  border-color: #dbdfea;
}
.tagify.tagify--focus {
  transition: 0s;
  border-color: #11c8fc;
}
.tagify[readonly] {
  cursor: default;
}
.tagify[readonly] > .tagify__input {
  visibility: hidden;
  width: 0;
  margin: 5px 0;
}
.tagify[readonly] .tagify__tag__removeBtn {
  display: none;
}
.tagify[readonly] .tagify__tag > div {
  padding: 0.25rem 0.5rem 0.25rem 0.675rem;
}
.tagify[readonly] .tagify__tag > div::before {
  background: linear-gradient(
      45deg,
      #fff 25%,
      transparent 25%,
      transparent 50%,
      #fff 50%,
      #fff 75%,
      transparent 75%,
      transparent
    )
    0/5px 5px;
  box-shadow: none;
  filter: brightness(0.95);
}
.tagify--loading .tagify__input::before {
  content: none;
}
.tagify--loading .tagify__input::after {
  content: "";
  vertical-align: middle;
  margin: -2px 0 -2px 0.5em;
  opacity: 1;
  width: 0.7em;
  height: 0.7em;
  border: 3px solid;
  border-color: #eee #bbb #888 rgba(0, 0, 0, 0);
  border-radius: 50%;
  animation: rotateLoader 0.4s infinite linear;
}
.tagify--loading .tagify__input:empty::after {
  margin-left: 0;
}
.tagify + input,
.tagify + textarea {
  visibility: hidden !important;
  position: absolute !important;
  pointer-events: none;
}
.tagify__tag {
  display: inline-flex;
  align-items: center;
  margin: 2px;
  position: relative;
  z-index: 1;
  outline: none;
  cursor: default;
  transition: 0.13s ease-out;
}
.tagify__tag > div {
  vertical-align: top;
  box-sizing: border-box;
  max-width: 100%;
  padding: 0.25rem 0.5rem 0.25rem 0.675rem;
  color: #526484;
  line-height: inherit;
  border-radius: 15px;
  user-select: none;
  transition: 0.13s ease-out;
}
.tagify__tag > div > * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: top;
  min-width: var(--tag--min-width);
  max-width: var(--tag--max-width);
  transition: 0.8s ease, 0.1s color;
}
.tagify__tag > div > *[contenteditable] {
  outline: none;
  user-select: text;
  cursor: text;
  margin: -2px;
  padding: 2px;
  max-width: 350px;
}
.tagify__tag > div::before {
  content: "";
  position: absolute;
  border-radius: inherit;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  pointer-events: none;
  transition: 120ms ease;
  box-shadow: 0 0 0 1.1em #fff inset;
  border: 1px solid #e5e9f2;
}
.tagify__tag:hover:not([readonly]) div::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 0 1.1em #f5f6fa inset;
}
.tagify__tag.tagify--noAnim > div::before {
  animation: none;
}
.tagify__tag.tagify--hide {
  width: 0 !important;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  opacity: 0;
  transform: scale(0);
  transition: 0.3s;
  pointer-events: none;
}
.tagify__tag.tagify--mark div::before {
  animation: none;
}
.tagify__tag.tagify--notAllowed:not(.tagify__tag--editable) div > span {
  opacity: 0.5;
}
.tagify__tag.tagify--notAllowed:not(.tagify__tag--editable) div::before {
  box-shadow: 0 0 0 1.1em rgba(114, 128, 146, 0.5) inset !important;
  transition: 0.2s;
}
.tagify__tag[readonly] .tagify__tag__removeBtn {
  display: none;
}
.tagify__tag[readonly] > div::before {
  background: linear-gradient(
      45deg,
      #fff 25%,
      transparent 25%,
      transparent 50%,
      #fff 50%,
      #fff 75%,
      transparent 75%,
      transparent
    )
    0/5px 5px;
  box-shadow: none;
  filter: brightness(0.95);
  border-color: none;
}
.tagify__tag--editable > div {
  color: #526484;
}
.tagify__tag--editable > div::before {
  box-shadow: 0 0 0 2px #f5f6fa inset !important;
}
.tagify__tag--editable.tagify--invalid > div::before {
  box-shadow: 0 0 0 2px #728092 inset !important;
}
.tagify__tag__removeBtn {
  order: 5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  cursor: pointer;
  font: 11px "Nioicon";
  background: none;
  color: #364a63;
  width: 14px;
  height: 14px;
  margin-right: 3.6666666667px;
  margin-left: -3.6666666667px;
  transition: 0.2s ease-out;
}
.tagify__tag__removeBtn::after {
  position: relative;
  content: "";
  top: 0;
}
.tagify__tag__removeBtn:hover {
  color: #fff;
  background: #606c7c;
}
.tagify__tag__removeBtn:hover + div::before {
  transition: 0.2s;
}
.tagify:not(.tagify--mix) .tagify__input br {
  display: none;
}
.tagify:not(.tagify--mix) .tagify__input * {
  display: inline;
  white-space: nowrap;
}
.tagify__input {
  display: block;
  min-width: 60px;
  margin: 2px;
  padding: 0.25rem 0.5rem 0.25rem 0.675rem;
  line-height: inherit;
  position: relative;
  white-space: pre-line;
}
.tagify__input::before {
  display: inline-block;
  width: 0;
}
.tagify__input:empty {
  display: flex;
}
.tagify__input:empty::before {
  transition: 0.2s ease-out;
  opacity: 0.7;
  transform: none;
  width: auto;
}
.tagify__input:focus {
  outline: none;
}
.tagify__input:focus::before {
  transition: 0.2s ease-out;
  opacity: 0;
  transform: translatex(6px);
}
@supports (-moz-appearance: none) {
  .tagify__input:focus::before {
    display: none;
  }
}
.tagify__input:focus:empty::before {
  transition: 0.2s ease-out;
  opacity: 0.5;
  transform: none;
}
@supports (-moz-appearance: none) {
  .tagify__input:focus:empty::before {
    display: inline-block;
  }
}
.tagify__input::before {
  content: attr(data-placeholder);
  line-height: 1.8;
  position: absolute;
  top: 0;
  z-index: 1;
  color: #526484;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
}
.tagify--mix .tagify__input::before {
  position: static;
  line-height: inherit;
}
@supports (-moz-appearance: none) {
  .tagify__input::before {
    line-height: inherit;
    position: relative;
  }
}
.tagify__input::after {
  content: attr(data-suggest);
  display: inline-block;
  white-space: pre;
  color: #526484;
  opacity: 0.3;
  pointer-events: none;
  max-width: 100px;
}
.tagify__input .tagify__tag {
  margin: 0;
}
.tagify__input .tagify__tag > div {
  padding-top: 0;
  padding-bottom: 0;
}
.tagify--mix {
  line-height: 1.7;
}
.tagify--mix .tagify__input {
  padding: 5px;
  margin: 0;
  width: 100%;
  height: 100%;
  line-height: inherit;
}
.tagify--mix .tagify__input::after {
  content: none;
}
.tagify--select::after {
  content: ">";
  opacity: 0.5;
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  font: 16px monospace;
  line-height: 8px;
  height: 8px;
  pointer-events: none;
  transform: translate(-150%, -50%) scaleX(1.2) rotate(90deg);
  transition: 0.2s ease-in-out;
}
.tagify--select[aria-expanded="true"]::after {
  transform: translate(-150%, -50%) rotate(270deg) scaleY(1.2);
}
.tagify--select .tagify__tag {
  position: absolute;
  top: 0;
  right: 1.8em;
  bottom: 0;
}
.tagify--select .tagify__tag div {
  display: none;
}
.tagify--select .tagify__input {
  width: 100%;
}
.tagify--invalid {
  border-color: #728092;
}
.tagify__dropdown {
  position: absolute;
  z-index: 9999;
  transform: translateY(4px);
  overflow: hidden;
  box-shadow: 0 1px 4px rgba(54, 74, 99, 0.1);
  border-radius: 4px;
}
.tagify__dropdown[placement="top"] {
  margin-top: 0;
  transform: translateY(-4px);
  box-shadow: 0 -1px 4px rgba(54, 74, 99, 0.1);
}
.tagify__dropdown--text {
  box-shadow: 0 0 0 3px rgba(115, 58, 234, 0.1);
  font-size: 0.9em;
}
.tagify__dropdown--text .tagify__dropdown__wrapper {
  border-width: 1px;
}
.tagify__dropdown__wrapper {
  max-height: 300px;
  overflow: hidden;
  background: #fff;
  border: 1px solid #e5e9f2;
  padding: 0.25rem;
  border-radius: 4px;
  transition: 0.25s cubic-bezier(0, 1, 0.5, 1);
}
.tagify__dropdown__wrapper:hover {
  overflow: auto;
}
.tagify__dropdown--initial .tagify__dropdown__wrapper {
  max-height: 20px;
  transform: translateY(-1em);
}
.tagify__dropdown--initial[placement="top"] .tagify__dropdown__wrapper {
  transform: translateY(2em);
}
.tagify__dropdown__item {
  box-sizing: inherit;
  padding: 0.25rem 0.5rem 0.25rem 0.675rem;
  margin: 1px;
  cursor: pointer;
  border-radius: 2px;
  position: relative;
  outline: none;
}
.tagify__dropdown__item--active {
  background: #11c8fc;
  color: #fff;
}
.tagify__dropdown__item:active {
  filter: brightness(105%);
}
.tagify__dropdown__createTagBtn {
  width: 100%;
  background: #11c8fc;
  color: #fff;
  border: none;
}
.fc-not-allowed,
.fc-not-allowed .fc-event {
  cursor: not-allowed;
}
.fc-unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.fc {
  display: flex;
  flex-direction: column;
  font-size: 1em;
}
.fc,
.fc *,
.fc *:before,
.fc *:after {
  box-sizing: border-box;
}
.fc table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
  border-radius: 4px;
}
.fc table th {
  border: 1px solid #dbdfea;
}
.fc table td {
  border: 1px solid #dbdfea;
}
.fc table thead th,
.fc table thead td {
  border-bottom-width: 0;
}
.fc table thead > tr > th,
.fc table thead > tr > td {
  border-bottom-width: 1px;
}
.fc table thead tr:first-child td:first-child,
.fc table thead tr:first-child th:first-child {
  border-radius: 4px 0 0 0;
}
.fc table thead tr:first-child td:last-child,
.fc table thead tr:first-child th:last-child {
  border-radius: 0 4px 0 0;
}
.fc table tbody tr:last-child td:first-child,
.fc table tbody tr:last-child th:first-child {
  border-radius: 0 0 0 4px;
}
.fc table tbody tr:last-child td:last-child,
.fc table tbody tr:last-child th:last-child {
  border-radius: 0 0 4px 0;
}
.fc th {
  text-align: center;
}
.fc th,
.fc td {
  vertical-align: top;
  padding: 0;
}
.fc a[data-navlink] {
  cursor: pointer;
}
.fc a[data-navlink]:hover {
  text-decoration: underline;
}
.fc-direction-ltr {
  direction: ltr;
  text-align: left;
  background-color: white;
  padding: 10px 15px;
  border-radius: 5px;
}
.fc-direction-rtl {
  direction: rtl;
  text-align: right;
}
.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #ddd;
  border: 1px solid var(--fc-border-color, #ddd);
}
.fc-liquid-hack td,
.fc-liquid-hack th {
  position: relative;
}

.fc-icon,
.fc .bi {
  display: inline-block;
  width: 1em;
  height: 1em;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "fcicons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* .fc-icon-chevron-left:before,
.fc .bi-chevron-left:before {
  content: "";
} */
/* .fc-icon-chevron-right:before,
.fc .bi-chevron-right:before {
  content: "";
} */
.fc-icon-chevrons-left:before,
.fc .bi-chevrons-left:before {
  content: "";
}
.fc-icon-chevrons-right:before,
.fc .bi-chevrons-right:before {
  content: "";
}
.fc-icon-minus-square:before,
.fc .bi-minus-square:before {
  content: "";
}
.fc-icon-plus-square:before,
.fc .bi-plus-square:before {
  content: "";
}
.fc-icon-x:before,
.fc .bi-x-lg:before {
  content: "";
}
.fc .fc-button {
  border-radius: 0;
  overflow: visible;
  text-transform: none;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.fc .fc-button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
.fc .fc-button {
  -webkit-appearance: button;
}
.fc .fc-button:not(:disabled) {
  cursor: pointer;
}
.fc .fc-button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
.fc .fc-button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 0.4em 0.65em;
  font-size: 1em;
  line-height: 1.5;
  border-radius: 0.25em;
}
.fc .fc-button:hover {
  text-decoration: none;
}
.fc .fc-button:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.25);
}
.fc .fc-button:disabled {
  opacity: 0.65;
}
.fc .fc-button-primary {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #2c3e50;
  background-color: var(--fc-button-bg-color, #2c3e50);
  border-color: #2c3e50;
  border-color: var(--fc-button-border-color, #2c3e50);
}
.fc .fc-button-primary:hover {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #1e2b37;
  background-color: var(--fc-button-hover-bg-color, #1e2b37);
  border-color: #1a252f;
  border-color: var(--fc-button-hover-border-color, #1a252f);
}
.fc .fc-button-primary:disabled {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #2c3e50;
  background-color: var(--fc-button-bg-color, #2c3e50);
  border-color: #2c3e50;
  border-color: var(--fc-button-border-color, #2c3e50);
}
.fc .fc-button-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 91, 106, 0.5);
}
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #1a252f;
  background-color: var(--fc-button-active-bg-color, #1a252f);
  border-color: #151e27;
  border-color: var(--fc-button-active-border-color, #151e27);
}
.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 91, 106, 0.5);
}
.fc .fc-button .fc-icon {
  vertical-align: middle;
  font-size: 1.5em;
}
.fc .fc-button-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.fc .fc-button-group > .fc-button {
  position: relative;
  flex: 1 1 auto;
}
.fc .fc-button-group > .fc-button:hover {
  z-index: 1;
}
.fc .fc-button-group > .fc-button:focus,
.fc .fc-button-group > .fc-button:active,
.fc .fc-button-group > .fc-button.fc-button-active {
  z-index: 1;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.fc-direction-rtl .fc-button-group > .fc-button:not(:first-child) {
  margin-right: -1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.fc-direction-rtl .fc-button-group > .fc-button:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.fc .fc-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767.98px) {
  .fc .fc-toolbar {
    flex-direction: column;
  }
}
.fc .fc-toolbar.fc-header-toolbar {
  margin: -0.5rem 0 0.75rem;
}
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
}
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:empty {
  display: none;
}
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .btn,
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .actions ul li a,
.actions ul li .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk a,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox
  .dual-listbox__button,
.dual-listbox
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox__button {
  position: relative;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 12px;
  background: rgba(0, 0, 0, 0);
  color: #8094ae;
  border: 1px solid #dbdfea;
  text-transform: capitalize;
}
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .btn:disabled,
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .actions ul li a:disabled,
.actions ul li .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk a:disabled,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox
  .dual-listbox__button:disabled,
.dual-listbox
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox__button:disabled {
  background: rgba(0, 0, 0, 0);
  color: #8094ae;
  border: 1px solid #dbdfea;
}
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .btn:hover,
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .actions ul li a:hover,
.actions ul li .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk a:hover,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox
  .dual-listbox__button:hover,
.dual-listbox
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox__button:hover,
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .btn:focus,
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .actions ul li a:focus,
.actions ul li .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk a:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox
  .dual-listbox__button:focus,
.dual-listbox
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox__button:focus {
  color: #526484;
  background: #f5f6fa;
  border: 1px solid #dbdfea;
}
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .btn:focus,
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .actions ul li a:focus,
.actions ul li .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk a:focus,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox
  .dual-listbox__button:focus,
.dual-listbox
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox__button:focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 223, 234, 0.5);
}
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .btn.active,
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .actions ul li a.active,
.actions ul li .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk a.active,
.fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox
  .active.dual-listbox__button,
.dual-listbox
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .active.dual-listbox__button {
  color: #364a63;
  border: 1px solid #dbdfea !important;
  background: #f5f6fa;
  box-shadow: none;
}
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .fc-prev-button,
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .fc-next-button {
  border: none !important;
}
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .fc-prev-button:hover,
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .fc-prev-button:focus,
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .fc-next-button:hover,
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .fc-next-button:focus {
  background: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
}
@media (max-width: 767.98px) {
  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk {
    justify-content: space-between;
    width: 100%;
  }
  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .fc-dayGridMonth-button,
  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .fc-timeGridWeek-button,
  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .fc-timeGridDay-button,
  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .fc-listWeek-button {
    color: rgba(0, 0, 0, 0) !important;
    width: 2.125rem;
  }
  .fc
    .fc-toolbar.fc-header-toolbar
    .fc-toolbar-chunk
    .fc-dayGridMonth-button:before,
  .fc
    .fc-toolbar.fc-header-toolbar
    .fc-toolbar-chunk
    .fc-timeGridWeek-button:before,
  .fc
    .fc-toolbar.fc-header-toolbar
    .fc-toolbar-chunk
    .fc-timeGridDay-button:before,
  .fc
    .fc-toolbar.fc-header-toolbar
    .fc-toolbar-chunk
    .fc-listWeek-button:before {
    color: #8094ae;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .fc
    .fc-toolbar.fc-header-toolbar
    .fc-toolbar-chunk
    .fc-dayGridMonth-button:before {
    content: "M";
  }
  .fc
    .fc-toolbar.fc-header-toolbar
    .fc-toolbar-chunk
    .fc-timeGridWeek-button:before {
    content: "W";
  }
  .fc
    .fc-toolbar.fc-header-toolbar
    .fc-toolbar-chunk
    .fc-timeGridDay-button:before {
    content: "D";
  }
  .fc
    .fc-toolbar.fc-header-toolbar
    .fc-toolbar-chunk
    .fc-listWeek-button:before {
    content: "L";
  }
  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-title + .btn-group {
    margin-right: -0.75rem;
  }
}
.fc .fc-toolbar.fc-footer-toolbar {
  margin-top: 1.5em;
}
.fc .fc-toolbar-title {
  font-size: 1.125rem;
  margin: 0;
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 0.75em;
}
.fc-direction-rtl .fc-toolbar > * > :not(:first-child) {
  margin-right: 0.75em;
}
.fc-direction-rtl .fc-toolbar-ltr {
  flex-direction: row-reverse;
}
.fc .fc-scroller {
  -webkit-overflow-scrolling: touch;
  position: relative;
}
.fc .fc-scroller-liquid {
  height: 100%;
}
.fc .fc-scroller-liquid-absolute {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.fc .fc-scroller-harness {
  position: relative;
  overflow: hidden;
  direction: ltr;
}
.fc .fc-scroller-harness-liquid {
  height: 100%;
}
.fc-direction-rtl .fc-scroller-harness > .fc-scroller {
  direction: rtl;
}
.fc-theme-standard .fc-scrollgrid {
  border: 1px solid #ddd;
  border: 1px solid var(--fc-border-color, #ddd);
}
.fc .fc-scrollgrid,
.fc .fc-scrollgrid table {
  width: 100%;
  table-layout: fixed;
}
.fc .fc-scrollgrid table {
  border-top-style: hidden;
  border-left-style: hidden;
  border-right-style: hidden;
}
.fc .fc-scrollgrid {
  border-collapse: separate;
  border-right-width: 0;
  border-bottom-width: 0;
}
.fc .fc-scrollgrid-liquid {
  height: 100%;
}
.fc .fc-scrollgrid-section {
  height: 1px;
}
.fc .fc-scrollgrid-section > td {
  height: 1px;
}
.fc .fc-scrollgrid-section table {
  height: 1px;
}
.fc .fc-scrollgrid-section-liquid > td {
  height: 100%;
}
.fc .fc-scrollgrid-section > * {
  border-top-width: 0;
  border-left-width: 0;
}
.fc .fc-scrollgrid-section-header > *,
.fc .fc-scrollgrid-section-footer > * {
  border-bottom-width: 0;
}
.fc .fc-scrollgrid-section-body table,
.fc .fc-scrollgrid-section-footer table {
  border-bottom-style: hidden;
}
.fc .fc-scrollgrid-section-sticky > * {
  background: #fff;
  background: var(--fc-page-bg-color, #fff);
  position: sticky;
  z-index: 3;
}
.fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
  top: 0;
}
.fc .fc-scrollgrid-section-footer.fc-scrollgrid-section-sticky > * {
  bottom: 0;
}
.fc .fc-scrollgrid-sticky-shim {
  height: 1px;
  margin-bottom: -1px;
}
.fc-sticky {
  position: sticky;
}
.fc .fc-view-harness {
  flex-grow: 1;
  position: relative;
}
.fc .fc-view-harness-active > .fc-view {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
}
.fc .fc-bg-event,
.fc .fc-non-business,
.fc .fc-highlight {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.fc .fc-non-business {
  background: rgba(215, 215, 215, 0.3);
  background: var(--fc-non-business-color, rgba(215, 215, 215, 0.3));
}
.fc .fc-bg-event {
  background: #8fdf82;
  background: var(--fc-bg-event-color, rgb(143, 223, 130));
  opacity: 0.3;
  opacity: var(--fc-bg-event-opacity, 0.3);
}
.fc .fc-bg-event .fc-event-title {
  margin: 0.5em;
  font-size: 0.85em;
  font-size: var(--fc-small-font-size, 0.85em);
  font-style: italic;
}
.fc .fc-highlight {
  background: rgba(188, 232, 241, 0.3);
  background: var(--fc-highlight-color, rgba(188, 232, 241, 0.3));
}
.fc .fc-cell-shaded,
.fc .fc-day-disabled {
  background: rgba(208, 208, 208, 0.3);
  background: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
}
.fc .fc-view:not(.fc-timeGridWeek-view) .fc-event {
  margin: 0.5rem 0.375rem;
}
.fc .fc-event {
  padding: 0.125rem 0.375rem;
}
a.fc-event,
a.fc-event:hover {
  text-decoration: none;
}
.fc-event[href],
.fc-event.fc-event-draggable {
  cursor: pointer;
}
.fc-event .fc-event-main {
  position: relative;
  z-index: 2;
}
.fc-event-dragging:not(.fc-event-selected) {
  opacity: 0.75;
}
.fc-event-dragging.fc-event-selected {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
}
.fc-event .fc-event-resizer {
  display: none;
  position: absolute;
  z-index: 4;
}
.fc-event:hover .fc-event-resizer,
.fc-event-selected .fc-event-resizer {
  display: block;
}
.fc-event-selected .fc-event-resizer {
  border-radius: 4px;
  border-radius: calc(var(--fc-event-resizer-dot-total-width, 8px) / 2);
  border-width: 1px;
  border-width: var(--fc-event-resizer-dot-border-width, 1px);
  width: 8px;
  width: var(--fc-event-resizer-dot-total-width, 8px);
  height: 8px;
  height: var(--fc-event-resizer-dot-total-width, 8px);
  border-style: solid;
  border-color: inherit;
  background: #fff;
  background: var(--fc-page-bg-color, #fff);
}
.fc-event-selected .fc-event-resizer:before {
  content: "";
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
}
.fc-event-selected,
.fc-event:focus {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.fc-event-selected:before,
.fc-event:focus:before {
  content: "";
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.fc-event-selected:after,
.fc-event:focus:after {
  content: "";
  background: rgba(0, 0, 0, 0.25);
  background: var(--fc-event-selected-overlay-color, rgba(0, 0, 0, 0.25));
  position: absolute;
  z-index: 1;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
}
.fc-h-event {
  display: block;
  border: 1px solid #11c8fc;
  border: 1px solid var(--fc-event-border-color, #11c8fc);
  background-color: #11c8fc;
  background-color: var(--fc-event-bg-color, #11c8fc);
}
.fc-h-event .fc-event-main-frame {
  display: flex;
}
.fc-h-event .fc-event-time {
  max-width: 100%;
  overflow: hidden;
}
.fc-h-event .fc-event-title-container {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
}
.fc-h-event .fc-event-title {
  display: inline-block;
  vertical-align: top;
  left: 0;
  right: 0;
  max-width: 100%;
  overflow: hidden;
}
.fc-h-event.fc-event-selected:before {
  top: -10px;
  bottom: -10px;
}
.fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-start),
.fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-end) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;
}
.fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-end),
.fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-start) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
}
.fc-h-event:not(.fc-event-selected) .fc-event-resizer {
  top: 0;
  bottom: 0;
  width: 8px;
  width: var(--fc-event-resizer-thickness, 8px);
}
.fc-direction-ltr .fc-h-event:not(.fc-event-selected) .fc-event-resizer-start,
.fc-direction-rtl .fc-h-event:not(.fc-event-selected) .fc-event-resizer-end {
  cursor: w-resize;
  left: -4px;
  left: calc(-0.5 * var(--fc-event-resizer-thickness, 8px));
}
.fc-direction-ltr .fc-h-event:not(.fc-event-selected) .fc-event-resizer-end,
.fc-direction-rtl .fc-h-event:not(.fc-event-selected) .fc-event-resizer-start {
  cursor: e-resize;
  right: -4px;
  right: calc(-0.5 * var(--fc-event-resizer-thickness, 8px));
}
.fc-h-event.fc-event-selected .fc-event-resizer {
  top: 50%;
  margin-top: -4px;
  margin-top: calc(-0.5 * var(--fc-event-resizer-dot-total-width, 8px));
}
.fc-direction-ltr .fc-h-event.fc-event-selected .fc-event-resizer-start,
.fc-direction-rtl .fc-h-event.fc-event-selected .fc-event-resizer-end {
  left: -4px;
  left: calc(-0.5 * var(--fc-event-resizer-dot-total-width, 8px));
}
.fc-direction-ltr .fc-h-event.fc-event-selected .fc-event-resizer-end,
.fc-direction-rtl .fc-h-event.fc-event-selected .fc-event-resizer-start {
  right: -4px;
  right: calc(-0.5 * var(--fc-event-resizer-dot-total-width, 8px));
}
.fc .fc-popover {
  position: absolute;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}
.fc .fc-popover-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3px 4px;
}
.fc .fc-popover-title {
  margin: 0 2px;
}
.fc .fc-popover-close {
  cursor: pointer;
  opacity: 0.65;
  font-size: 1.1em;
}
.fc-theme-standard .fc-popover {
  border: 1px solid #ddd;
  border: 1px solid var(--fc-border-color, #ddd);
  background: #fff;
  background: var(--fc-page-bg-color, #fff);
}
.fc-theme-standard .fc-popover-header {
  background: rgba(208, 208, 208, 0.3);
  background: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
}
:root {
  --fc-daygrid-event-dot-width: 8px;
}
.fc-daygrid-day-frame:before,
.fc-daygrid-day-events:before,
.fc-daygrid-event-harness:before {
  content: "";
  clear: both;
  display: table;
}
.fc-daygrid-day-frame:after,
.fc-daygrid-day-events:after,
.fc-daygrid-event-harness:after {
  content: "";
  clear: both;
  display: table;
}
.fc .fc-daygrid-body {
  position: relative;
  z-index: 1;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(115, 58, 234, 0.07);
  background-color: var(--fc-today-bg-color, rgba(115, 58, 234, 0.07));
}
.fc .fc-daygrid-day-frame {
  position: relative;
  min-height: 100%;
}
.fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: row-reverse;
}
.fc .fc-day-other .fc-daygrid-day-top {
  opacity: 0.5;
}
.fc .fc-daygrid-day-number {
  position: relative;
  z-index: 4;
  padding: 4px;
}
.fc .fc-daygrid-day-events {
  margin-top: 1px;
}
.fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
  position: absolute;
  left: 0;
  right: 0;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  position: relative;
  min-height: 2em;
}
.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-bottom: 1em;
}
.fc .fc-daygrid-event-harness {
  position: relative;
}
.fc .fc-daygrid-event-harness-abs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fc .fc-daygrid-bg-harness {
  position: absolute;
  top: 0;
  bottom: 0;
}
.fc .fc-daygrid-day-bg .fc-non-business {
  z-index: 1;
}
.fc .fc-daygrid-day-bg .fc-bg-event {
  z-index: 2;
}
.fc .fc-daygrid-day-bg .fc-highlight {
  z-index: 3;
}
.fc .fc-daygrid-event {
  z-index: 6;
  margin: 0.375rem 0.5rem !important;
}
.fc .fc-daygrid-event.fc-event-mirror {
  z-index: 7;
}
.fc .fc-daygrid-day-bottom {
  font-size: 0.85em;
  padding: 2px 3px 0;
}
.fc .fc-daygrid-day-bottom:before {
  content: "";
  clear: both;
  display: table;
}
.fc .fc-daygrid-more-link {
  position: relative;
  z-index: 4;
  cursor: pointer;
}
.fc .fc-daygrid-week-number {
  position: absolute;
  z-index: 5;
  top: 0;
  padding: 2px;
  min-width: 1.5em;
  text-align: center;
  background-color: rgba(208, 208, 208, 0.3);
  background-color: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
  color: gray;
  color: var(--fc-neutral-text-color, #808080);
}
.fc .fc-more-popover .fc-popover-body {
  min-width: 220px;
  padding: 10px;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: 2px;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-right: 2px;
}
.fc-direction-ltr .fc-daygrid-week-number {
  left: 0;
  border-radius: 0 0 3px 0;
}
.fc-direction-rtl .fc-daygrid-week-number {
  right: 0;
  border-radius: 0 0 0 3px;
}
.fc-liquid-hack .fc-daygrid-day-frame {
  position: static;
}
.fc-daygrid-event {
  position: relative;
  white-space: nowrap;
  border-radius: 3px;
  font-size: 0.85em;
  font-size: var(--fc-small-font-size, 0.85em);
}
.fc-daygrid-block-event .fc-event-time {
  font-weight: bold;
}
.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
  padding: 1px;
}
.fc-daygrid-dot-event {
  display: flex;
  align-items: center;
  padding: 2px 0;
}
.fc-daygrid-dot-event .fc-event-title {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  overflow: hidden;
  font-weight: bold;
}
.fc-daygrid-dot-event:hover,
.fc-daygrid-dot-event.fc-event-mirror {
  background: rgba(0, 0, 0, 0.1);
}
.fc-daygrid-dot-event.fc-event-selected:before {
  top: -10px;
  bottom: -10px;
}
.fc-daygrid-event-dot {
  margin: 0 4px;
  box-sizing: content-box;
  width: 0;
  height: 0;
  border: 3px solid #11c8fc;
  border-radius: 3px;
}
.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  margin-right: 3px;
}
.fc-direction-rtl .fc-daygrid-event .fc-event-time {
  margin-left: 3px;
}
.fc-v-event {
  display: block;
  border: 1px solid #11c8fc;
  border: 1px solid var(--fc-event-border-color, #11c8fc);
  background-color: #11c8fc;
  background-color: var(--fc-event-bg-color, #11c8fc);
}
.fc-v-event .fc-event-main {
  height: 100%;
}
.fc-v-event .fc-event-main-frame {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.fc-v-event .fc-event-time {
  flex-grow: 0;
  flex-shrink: 0;
  max-height: 100%;
  overflow: hidden;
}
.fc-v-event .fc-event-title-container {
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 0;
}
.fc-v-event .fc-event-title {
  top: 0;
  bottom: 0;
  max-height: 100%;
  overflow: hidden;
}
.fc-v-event:not(.fc-event-start) {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.fc-v-event:not(.fc-event-end) {
  border-bottom-width: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.fc-v-event.fc-event-selected:before {
  left: -10px;
  right: -10px;
}
.fc-v-event .fc-event-resizer-start {
  cursor: n-resize;
}
.fc-v-event .fc-event-resizer-end {
  cursor: s-resize;
}
.fc-v-event:not(.fc-event-selected) .fc-event-resizer {
  height: 8px;
  height: var(--fc-event-resizer-thickness, 8px);
  left: 0;
  right: 0;
}
.fc-v-event:not(.fc-event-selected) .fc-event-resizer-start {
  top: -4px;
  top: calc(var(--fc-event-resizer-thickness, 8px) / -2);
}
.fc-v-event:not(.fc-event-selected) .fc-event-resizer-end {
  bottom: -4px;
  bottom: calc(var(--fc-event-resizer-thickness, 8px) / -2);
}
.fc-v-event.fc-event-selected .fc-event-resizer {
  left: 50%;
  margin-left: -4px;
  margin-left: calc(var(--fc-event-resizer-dot-total-width, 8px) / -2);
}
.fc-v-event.fc-event-selected .fc-event-resizer-start {
  top: -4px;
  top: calc(var(--fc-event-resizer-dot-total-width, 8px) / -2);
}
.fc-v-event.fc-event-selected .fc-event-resizer-end {
  bottom: -4px;
  bottom: calc(var(--fc-event-resizer-dot-total-width, 8px) / -2);
}
.fc .fc-timegrid .fc-daygrid-body {
  z-index: 2;
}
.fc .fc-timegrid-divider {
  padding: 0 0 2px;
}
.fc .fc-timegrid-body {
  position: relative;
  z-index: 1;
  min-height: 100%;
}
.fc .fc-timegrid-axis-chunk {
  position: relative;
}
.fc .fc-timegrid-axis-chunk > table {
  position: relative;
  z-index: 1;
}
.fc .fc-timegrid-slots {
  position: relative;
  z-index: 1;
}
.fc .fc-timegrid-slot {
  height: 1.5em;
  border-bottom: 0;
}
.fc .fc-timegrid-slot:empty:before {
  content: " ";
}
.fc .fc-timegrid-slot-minor {
  border-top-style: dotted;
}
.fc .fc-timegrid-slot-label-cushion {
  display: inline-block;
  white-space: nowrap;
}
.fc .fc-timegrid-slot-label {
  vertical-align: middle;
}
.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  padding: 0 4px;
}
.fc .fc-timegrid-axis-frame-liquid {
  height: 100%;
}
.fc .fc-timegrid-axis-frame {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.fc .fc-timegrid-axis-cushion {
  max-width: 60px;
  flex-shrink: 0;
}
.fc-direction-ltr .fc-timegrid-slot-label-frame {
  text-align: right;
}
.fc-direction-rtl .fc-timegrid-slot-label-frame {
  text-align: left;
}
.fc-liquid-hack .fc-timegrid-axis-frame-liquid {
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.fc .fc-timegrid-col.fc-day-today {
  background-color: rgba(255, 220, 40, 0.15);
  background-color: var(--fc-today-bg-color, rgba(255, 220, 40, 0.15));
}
.fc .fc-timegrid-col-frame {
  min-height: 100%;
  position: relative;
}
.fc-media-screen.fc-liquid-hack .fc-timegrid-col-frame {
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.fc-media-screen .fc-timegrid-cols {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.fc-media-screen .fc-timegrid-cols > table {
  height: 100%;
}
.fc-media-screen .fc-timegrid-col-bg,
.fc-media-screen .fc-timegrid-col-events,
.fc-media-screen .fc-timegrid-now-indicator-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fc .fc-timegrid-col-bg {
  z-index: 2;
}
.fc .fc-timegrid-col-bg .fc-non-business {
  z-index: 1;
}
.fc .fc-timegrid-col-bg .fc-bg-event {
  z-index: 2;
}
.fc .fc-timegrid-col-bg .fc-highlight {
  z-index: 3;
}
.fc .fc-timegrid-bg-harness {
  position: absolute;
  left: 0;
  right: 0;
}
.fc .fc-timegrid-col-events {
  z-index: 3;
}
.fc .fc-timegrid-now-indicator-container {
  bottom: 0;
  overflow: hidden;
}
.fc-direction-ltr .fc-timegrid-col-events {
  margin: 0 2.5% 0 2px;
}
.fc-direction-rtl .fc-timegrid-col-events {
  margin: 0 2px 0 2.5%;
}
.fc-timegrid-event-harness {
  position: absolute;
}
.fc-timegrid-event-harness > .fc-timegrid-event {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  box-shadow: 0px 0px 0px 1px #fff;
  box-shadow: 0px 0px 0px 1px var(--fc-page-bg-color, #fff);
}
.fc-timegrid-event,
.fc-timegrid-more-link {
  font-size: 0.85em;
  font-size: var(--fc-small-font-size, 0.85em);
  border-radius: 3px;
}
.fc-timegrid-event {
  margin-bottom: 1px;
}
.fc-timegrid-event .fc-event-main {
  padding: 1px 1px 0;
}
.fc-timegrid-event .fc-event-time {
  white-space: nowrap;
  font-size: 0.85em;
  font-size: var(--fc-small-font-size, 0.85em);
  margin-bottom: 1px;
}
.fc-timegrid-event-short .fc-event-main-frame {
  flex-direction: row;
  overflow: hidden;
}
.fc-timegrid-event-short .fc-event-time:after {
  content: " - ";
}
.fc-timegrid-event-short .fc-event-title {
  font-size: 0.85em;
  font-size: var(--fc-small-font-size, 0.85em);
}
.fc-timegrid-more-link {
  position: absolute;
  z-index: 9999;
  color: inherit;
  color: var(--fc-more-link-text-color, inherit);
  background: #d0d0d0;
  background: var(--fc-more-link-bg-color, #d0d0d0);
  cursor: pointer;
  margin-bottom: 1px;
}
.fc-timegrid-more-link-inner {
  padding: 3px 2px;
  top: 0;
}
.fc-direction-ltr .fc-timegrid-more-link {
  right: 0;
}
.fc-direction-rtl .fc-timegrid-more-link {
  left: 0;
}
.fc .fc-timegrid-now-indicator-line {
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  border-style: solid;
  border-color: #f2426e;
  border-color: var(--fc-now-indicator-color, #f2426e);
  border-width: 1px 0 0;
}
.fc .fc-timegrid-now-indicator-arrow {
  position: absolute;
  z-index: 4;
  margin-top: -5px;
  border-style: solid;
  border-color: #f2426e;
  border-color: var(--fc-now-indicator-color, #f2426e);
}
.fc-direction-ltr .fc-timegrid-now-indicator-arrow {
  left: 0;
  border-width: 5px 0 5px 6px;
  border-top-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
}
.fc-direction-rtl .fc-timegrid-now-indicator-arrow {
  right: 0;
  border-width: 5px 6px 5px 0;
  border-top-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
}
:root {
  --fc-list-event-dot-width: 10px;
  --fc-list-event-hover-bg-color: #f5f5f5;
}
.fc-theme-standard .fc-list {
  border: 1px solid #ddd;
  border: 1px solid var(--fc-border-color, #ddd);
}
.fc .fc-list-empty {
  background-color: #f5f6fa;
  background-color: var(--fc-neutral-bg-color, #f5f6fa);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fc .fc-list-empty-cushion {
  margin: 5em 0;
}
.fc .fc-list-table {
  width: 100%;
  border-style: hidden;
}
.fc .fc-list-table tr > * {
  border-left: 0;
  border-right: 0;
}
.fc .fc-list-sticky .fc-list-day > * {
  position: sticky;
  top: 0;
  background: #fff;
  background: var(--fc-page-bg-color, #fff);
}
.fc .fc-list-table thead {
  position: absolute;
  left: -10000px;
}
.fc .fc-list-table tbody > tr:first-child th {
  border-top: 0;
}
.fc .fc-list-table th {
  padding: 0;
}
.fc .fc-list-table td,
.fc .fc-list-day-cushion {
  padding: 8px 14px;
}
.fc .fc-list-day-cushion:after {
  content: "";
  clear: both;
  display: table;
}
.fc-theme-standard .fc-list-day-cushion {
  background-color: rgba(208, 208, 208, 0.3);
  background-color: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
}
.fc-direction-ltr .fc-list-day-text,
.fc-direction-rtl .fc-list-day-side-text {
  float: left;
}
.fc-direction-ltr .fc-list-day-side-text,
.fc-direction-rtl .fc-list-day-text {
  float: right;
}
.fc-direction-ltr .fc-list-table .fc-list-event-graphic {
  padding-right: 0;
}
.fc-direction-rtl .fc-list-table .fc-list-event-graphic {
  padding-left: 0;
}
.fc .fc-list-event.fc-event-forced-url {
  cursor: pointer;
}
.fc .fc-list-event:hover td {
  background-color: #f5f5f5;
  background-color: var(--fc-list-event-hover-bg-color, #f5f5f5);
}
.fc .fc-list-event-graphic,
.fc .fc-list-event-time {
  white-space: nowrap;
  width: 1px;
}
.fc .fc-list-event-dot {
  display: inline-block;
  box-sizing: content-box;
  width: 0;
  height: 0;
  border: 5px solid #11c8fc;
  border: calc(var(--fc-list-event-dot-width, 10px) / 2) solid
    var(--fc-event-border-color, #11c8fc);
  border-radius: 5px;
  border-radius: calc(var(--fc-list-event-dot-width, 10px) / 2);
}
.fc .fc-list-event-title a {
  color: inherit;
  text-decoration: none;
}
.fc .fc-list-event.fc-event-forced-url:hover a {
  text-decoration: underline;
}
.fc-theme-bootstrap a:not([href]) {
  color: inherit;
}
.fc-theme-bootstrap5 a:not([href]) {
  color: inherit;
  text-decoration: inherit;
}
.fc-theme-bootstrap5 .fc-list,
.fc-theme-bootstrap5 .fc-scrollgrid,
.fc-theme-bootstrap5 td,
.fc-theme-bootstrap5 th {
  border: 1px solid #dbdfea;
}
.fc-theme-bootstrap5 .fc-scrollgrid {
  border-right-width: 0;
  border-bottom-width: 0;
}
.fc-theme-bootstrap5-shaded {
  background-color: var(--bs-gray-200);
}
.fc-prev-button,
.fc-next-button {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.fc-prev-button > .bi,
.fc-next-button > .bi {
  font-size: 1.25rem;
}
span[class*="fc-event"] .dot {
  display: inline-block;
  border: 1px solid #11c8fc;
  margin-right: 0.5rem;
}
a.fc-event-primary {
  background-color: #11c8fc !important;
  border-color: #11c8fc !important;
  color: #fff !important;
}
a.fc-event-primary .fc-daygrid-event-dot,
a.fc-event-primary .fc-list-event-dot {
  border-color: #fff !important;
}
.modal-header.fc-event-primary {
  background-color: #11c8fc !important;
  border-color: #11c8fc !important;
}
.modal-header.fc-event-primary .close,
.modal-header.fc-event-primary .modal-title {
  color: #fff !important;
}
span.fc-event-primary .dot {
  background-color: #11c8fc !important;
  border-color: #11c8fc !important;
}
.fc-event-primary:not(a) .fc-list-event-dot {
  background-color: #11c8fc !important;
  border-color: #11c8fc !important;
}
a.fc-event-success {
  background-color: #0fca7a !important;
  border-color: #0fca7a !important;
  color: #fff !important;
}
a.fc-event-success .fc-daygrid-event-dot,
a.fc-event-success .fc-list-event-dot {
  border-color: #fff !important;
}
.modal-header.fc-event-success {
  background-color: #0fca7a !important;
  border-color: #0fca7a !important;
}
.modal-header.fc-event-success .close,
.modal-header.fc-event-success .modal-title {
  color: #fff !important;
}
span.fc-event-success .dot {
  background-color: #0fca7a !important;
  border-color: #0fca7a !important;
}
.fc-event-success:not(a) .fc-list-event-dot {
  background-color: #0fca7a !important;
  border-color: #0fca7a !important;
}
a.fc-event-info {
  background-color: #058efc !important;
  border-color: #058efc !important;
  color: #fff !important;
}
a.fc-event-info .fc-daygrid-event-dot,
a.fc-event-info .fc-list-event-dot {
  border-color: #fff !important;
}
.modal-header.fc-event-info {
  background-color: #058efc !important;
  border-color: #058efc !important;
}
.modal-header.fc-event-info .close,
.modal-header.fc-event-info .modal-title {
  color: #fff !important;
}
span.fc-event-info .dot {
  background-color: #058efc !important;
  border-color: #058efc !important;
}
.fc-event-info:not(a) .fc-list-event-dot {
  background-color: #058efc !important;
  border-color: #058efc !important;
}
a.fc-event-warning {
  background-color: #fd9722 !important;
  border-color: #fd9722 !important;
  color: #fff !important;
}
a.fc-event-warning .fc-daygrid-event-dot,
a.fc-event-warning .fc-list-event-dot {
  border-color: #fff !important;
}
.modal-header.fc-event-warning {
  background-color: #fd9722 !important;
  border-color: #fd9722 !important;
}
.modal-header.fc-event-warning .close,
.modal-header.fc-event-warning .modal-title {
  color: #fff !important;
}
span.fc-event-warning .dot {
  background-color: #fd9722 !important;
  border-color: #fd9722 !important;
}
.fc-event-warning:not(a) .fc-list-event-dot {
  background-color: #fd9722 !important;
  border-color: #fd9722 !important;
}
a.fc-event-danger {
  background-color: #f2426e !important;
  border-color: #f2426e !important;
  color: #fff !important;
}
a.fc-event-danger .fc-daygrid-event-dot,
a.fc-event-danger .fc-list-event-dot {
  border-color: #fff !important;
}
.modal-header.fc-event-danger {
  background-color: #f2426e !important;
  border-color: #f2426e !important;
}
.modal-header.fc-event-danger .close,
.modal-header.fc-event-danger .modal-title {
  color: #fff !important;
}
span.fc-event-danger .dot {
  background-color: #f2426e !important;
  border-color: #f2426e !important;
}
.fc-event-danger:not(a) .fc-list-event-dot {
  background-color: #f2426e !important;
  border-color: #f2426e !important;
}
a.fc-event-secondary {
  background-color: #364a63 !important;
  border-color: #364a63 !important;
  color: #fff !important;
}
a.fc-event-secondary .fc-daygrid-event-dot,
a.fc-event-secondary .fc-list-event-dot {
  border-color: #fff !important;
}
.modal-header.fc-event-secondary {
  background-color: #364a63 !important;
  border-color: #364a63 !important;
}
.modal-header.fc-event-secondary .close,
.modal-header.fc-event-secondary .modal-title {
  color: #fff !important;
}
span.fc-event-secondary .dot {
  background-color: #364a63 !important;
  border-color: #364a63 !important;
}
.fc-event-secondary:not(a) .fc-list-event-dot {
  background-color: #364a63 !important;
  border-color: #364a63 !important;
}
a.fc-event-dark {
  background-color: #1f2b3a !important;
  border-color: #1f2b3a !important;
  color: #fff !important;
}
a.fc-event-dark .fc-daygrid-event-dot,
a.fc-event-dark .fc-list-event-dot {
  border-color: #fff !important;
}
.modal-header.fc-event-dark {
  background-color: #1f2b3a !important;
  border-color: #1f2b3a !important;
}
.modal-header.fc-event-dark .close,
.modal-header.fc-event-dark .modal-title {
  color: #fff !important;
}
span.fc-event-dark .dot {
  background-color: #1f2b3a !important;
  border-color: #1f2b3a !important;
}
.fc-event-dark:not(a) .fc-list-event-dot {
  background-color: #1f2b3a !important;
  border-color: #1f2b3a !important;
}
a.fc-event-blue {
  background-color: #559bfb !important;
  border-color: #559bfb !important;
  color: #fff !important;
}
a.fc-event-blue .fc-daygrid-event-dot,
a.fc-event-blue .fc-list-event-dot {
  border-color: #fff !important;
}
.modal-header.fc-event-blue {
  background-color: #559bfb !important;
  border-color: #559bfb !important;
}
.modal-header.fc-event-blue .close,
.modal-header.fc-event-blue .modal-title {
  color: #fff !important;
}
span.fc-event-blue .dot {
  background-color: #559bfb !important;
  border-color: #559bfb !important;
}
.fc-event-blue:not(a) .fc-list-event-dot {
  background-color: #559bfb !important;
  border-color: #559bfb !important;
}
a.fc-event-azure {
  background-color: #1676fb !important;
  border-color: #1676fb !important;
  color: #fff !important;
}
a.fc-event-azure .fc-daygrid-event-dot,
a.fc-event-azure .fc-list-event-dot {
  border-color: #fff !important;
}
.modal-header.fc-event-azure {
  background-color: #1676fb !important;
  border-color: #1676fb !important;
}
.modal-header.fc-event-azure .close,
.modal-header.fc-event-azure .modal-title {
  color: #fff !important;
}
span.fc-event-azure .dot {
  background-color: #1676fb !important;
  border-color: #1676fb !important;
}
.fc-event-azure:not(a) .fc-list-event-dot {
  background-color: #1676fb !important;
  border-color: #1676fb !important;
}
a.fc-event-indigo {
  background-color: #323f9e !important;
  border-color: #323f9e !important;
  color: #fff !important;
}
a.fc-event-indigo .fc-daygrid-event-dot,
a.fc-event-indigo .fc-list-event-dot {
  border-color: #fff !important;
}
.modal-header.fc-event-indigo {
  background-color: #323f9e !important;
  border-color: #323f9e !important;
}
.modal-header.fc-event-indigo .close,
.modal-header.fc-event-indigo .modal-title {
  color: #fff !important;
}
span.fc-event-indigo .dot {
  background-color: #323f9e !important;
  border-color: #323f9e !important;
}
.fc-event-indigo:not(a) .fc-list-event-dot {
  background-color: #323f9e !important;
  border-color: #323f9e !important;
}
a.fc-event-purple {
  background-color: #816bff !important;
  border-color: #816bff !important;
  color: #fff !important;
}
a.fc-event-purple .fc-daygrid-event-dot,
a.fc-event-purple .fc-list-event-dot {
  border-color: #fff !important;
}
.modal-header.fc-event-purple {
  background-color: #816bff !important;
  border-color: #816bff !important;
}
.modal-header.fc-event-purple .close,
.modal-header.fc-event-purple .modal-title {
  color: #fff !important;
}
span.fc-event-purple .dot {
  background-color: #816bff !important;
  border-color: #816bff !important;
}
.fc-event-purple:not(a) .fc-list-event-dot {
  background-color: #816bff !important;
  border-color: #816bff !important;
}
a.fc-event-pink {
  background-color: #ff63a5 !important;
  border-color: #ff63a5 !important;
  color: #fff !important;
}
a.fc-event-pink .fc-daygrid-event-dot,
a.fc-event-pink .fc-list-event-dot {
  border-color: #fff !important;
}
.modal-header.fc-event-pink {
  background-color: #ff63a5 !important;
  border-color: #ff63a5 !important;
}
.modal-header.fc-event-pink .close,
.modal-header.fc-event-pink .modal-title {
  color: #fff !important;
}
span.fc-event-pink .dot {
  background-color: #ff63a5 !important;
  border-color: #ff63a5 !important;
}
.fc-event-pink:not(a) .fc-list-event-dot {
  background-color: #ff63a5 !important;
  border-color: #ff63a5 !important;
}
a.fc-event-orange {
  background-color: #ffa353 !important;
  border-color: #ffa353 !important;
  color: #fff !important;
}
a.fc-event-orange .fc-daygrid-event-dot,
a.fc-event-orange .fc-list-event-dot {
  border-color: #fff !important;
}
.modal-header.fc-event-orange {
  background-color: #ffa353 !important;
  border-color: #ffa353 !important;
}
.modal-header.fc-event-orange .close,
.modal-header.fc-event-orange .modal-title {
  color: #fff !important;
}
span.fc-event-orange .dot {
  background-color: #ffa353 !important;
  border-color: #ffa353 !important;
}
.fc-event-orange:not(a) .fc-list-event-dot {
  background-color: #ffa353 !important;
  border-color: #ffa353 !important;
}
a.fc-event-teal {
  background-color: #20c997 !important;
  border-color: #20c997 !important;
  color: #fff !important;
}
a.fc-event-teal .fc-daygrid-event-dot,
a.fc-event-teal .fc-list-event-dot {
  border-color: #fff !important;
}
.modal-header.fc-event-teal {
  background-color: #20c997 !important;
  border-color: #20c997 !important;
}
.modal-header.fc-event-teal .close,
.modal-header.fc-event-teal .modal-title {
  color: #fff !important;
}
span.fc-event-teal .dot {
  background-color: #20c997 !important;
  border-color: #20c997 !important;
}
.fc-event-teal:not(a) .fc-list-event-dot {
  background-color: #20c997 !important;
  border-color: #20c997 !important;
}
a.fc-event-primary-dim {
  background-color: #d5f5fe !important;
  border-color: #d5f5fe !important;
  color: #11c8fc !important;
}
a.fc-event-primary-dim .fc-daygrid-event-dot,
a.fc-event-primary-dim .fc-list-event-dot {
  border-color: #11c8fc !important;
}
.modal-header.fc-event-primary-dim {
  background-color: #d5f5fe !important;
  color: #11c8fc !important;
  border-color: #ddcef9 !important;
}
.modal-header.fc-event-primary-dim .close,
.modal-header.fc-event-primary-dim .modal-title {
  color: #11c8fc !important;
}
span.fc-event-primary-dim .dot {
  background-color: #d5f5fe !important;
  border-color: #11c8fc !important;
}
.fc-event-primary-dim:not(a) .fc-list-event-dot {
  background-color: #d5f5fe !important;
  border-color: #9468ef !important;
}
a.fc-event-success-dim {
  background-color: #e0f8ee !important;
  border-color: #e0f8ee !important;
  color: #0fca7a !important;
}
a.fc-event-success-dim .fc-daygrid-event-dot,
a.fc-event-success-dim .fc-list-event-dot {
  border-color: #0fca7a !important;
}
.modal-header.fc-event-success-dim {
  background-color: #e0f8ee !important;
  color: #0fca7a !important;
  border-color: #cbf3e3 !important;
}
.modal-header.fc-event-success-dim .close,
.modal-header.fc-event-success-dim .modal-title {
  color: #0fca7a !important;
}
span.fc-event-success-dim .dot {
  background-color: #e0f8ee !important;
  border-color: #0fca7a !important;
}
.fc-event-success-dim:not(a) .fc-list-event-dot {
  background-color: #e0f8ee !important;
  border-color: #1eee95 !important;
}
a.fc-event-info-dim {
  background-color: #dff0ff !important;
  border-color: #dff0ff !important;
  color: #058efc !important;
}
a.fc-event-info-dim .fc-daygrid-event-dot,
a.fc-event-info-dim .fc-list-event-dot {
  border-color: #058efc !important;
}
.modal-header.fc-event-info-dim {
  background-color: #dff0ff !important;
  color: #058efc !important;
  border-color: #c6e4ff !important;
}
.modal-header.fc-event-info-dim .close,
.modal-header.fc-event-info-dim .modal-title {
  color: #058efc !important;
}
span.fc-event-info-dim .dot {
  background-color: #dff0ff !important;
  border-color: #058efc !important;
}
.fc-event-info-dim:not(a) .fc-list-event-dot {
  background-color: #dff0ff !important;
  border-color: #37a5fd !important;
}
a.fc-event-warning-dim {
  background-color: #fff1e2 !important;
  border-color: #fff1e2 !important;
  color: #fd9722 !important;
}
a.fc-event-warning-dim .fc-daygrid-event-dot,
a.fc-event-warning-dim .fc-list-event-dot {
  border-color: #fd9722 !important;
}
.modal-header.fc-event-warning-dim {
  background-color: #fff1e2 !important;
  color: #fd9722 !important;
  border-color: #ffe5c9 !important;
}
.modal-header.fc-event-warning-dim .close,
.modal-header.fc-event-warning-dim .modal-title {
  color: #fd9722 !important;
}
span.fc-event-warning-dim .dot {
  background-color: #fff1e2 !important;
  border-color: #fd9722 !important;
}
.fc-event-warning-dim:not(a) .fc-list-event-dot {
  background-color: #fff1e2 !important;
  border-color: #fdaf55 !important;
}
a.fc-event-danger-dim {
  background-color: #fde6ec !important;
  border-color: #fde6ec !important;
  color: #f2426e !important;
}
a.fc-event-danger-dim .fc-daygrid-event-dot,
a.fc-event-danger-dim .fc-list-event-dot {
  border-color: #f2426e !important;
}
.modal-header.fc-event-danger-dim {
  background-color: #fde6ec !important;
  color: #f2426e !important;
  border-color: #fbceda !important;
}
.modal-header.fc-event-danger-dim .close,
.modal-header.fc-event-danger-dim .modal-title {
  color: #f2426e !important;
}
span.fc-event-danger-dim .dot {
  background-color: #fde6ec !important;
  border-color: #f2426e !important;
}
.fc-event-danger-dim:not(a) .fc-list-event-dot {
  background-color: #fde6ec !important;
  border-color: #f57293 !important;
}
a.fc-event-secondary-dim {
  background-color: #e5ecf5 !important;
  border-color: #e5ecf5 !important;
  color: #364a63 !important;
}
a.fc-event-secondary-dim .fc-daygrid-event-dot,
a.fc-event-secondary-dim .fc-list-event-dot {
  border-color: #364a63 !important;
}
.modal-header.fc-event-secondary-dim {
  background-color: #e5ecf5 !important;
  color: #364a63 !important;
  border-color: #d3dfee !important;
}
.modal-header.fc-event-secondary-dim .close,
.modal-header.fc-event-secondary-dim .modal-title {
  color: #364a63 !important;
}
span.fc-event-secondary-dim .dot {
  background-color: #e5ecf5 !important;
  border-color: #364a63 !important;
}
.fc-event-secondary-dim:not(a) .fc-list-event-dot {
  background-color: #e5ecf5 !important;
  border-color: #486384 !important;
}
a.fc-event-dark-dim {
  background-color: #d9e1ef !important;
  border-color: #d9e1ef !important;
  color: #1f2b3a !important;
}
a.fc-event-dark-dim .fc-daygrid-event-dot,
a.fc-event-dark-dim .fc-list-event-dot {
  border-color: #1f2b3a !important;
}
.modal-header.fc-event-dark-dim {
  background-color: #d9e1ef !important;
  color: #1f2b3a !important;
  border-color: #c7d3e7 !important;
}
.modal-header.fc-event-dark-dim .close,
.modal-header.fc-event-dark-dim .modal-title {
  color: #1f2b3a !important;
}
span.fc-event-dark-dim .dot {
  background-color: #d9e1ef !important;
  border-color: #1f2b3a !important;
}
.fc-event-dark-dim:not(a) .fc-list-event-dot {
  background-color: #d9e1ef !important;
  border-color: #31445b !important;
}
a.fc-event-blue-dim {
  background-color: #e9f2fe !important;
  border-color: #e9f2fe !important;
  color: #559bfb !important;
}
a.fc-event-blue-dim .fc-daygrid-event-dot,
a.fc-event-blue-dim .fc-list-event-dot {
  border-color: #559bfb !important;
}
.modal-header.fc-event-blue-dim {
  background-color: #e9f2fe !important;
  color: #559bfb !important;
  border-color: #d1e4fd !important;
}
.modal-header.fc-event-blue-dim .close,
.modal-header.fc-event-blue-dim .modal-title {
  color: #559bfb !important;
}
span.fc-event-blue-dim .dot {
  background-color: #e9f2fe !important;
  border-color: #559bfb !important;
}
.fc-event-blue-dim:not(a) .fc-list-event-dot {
  background-color: #e9f2fe !important;
  border-color: #87b8fc !important;
}
a.fc-event-azure-dim {
  background-color: #e1edfe !important;
  border-color: #e1edfe !important;
  color: #1676fb !important;
}
a.fc-event-azure-dim .fc-daygrid-event-dot,
a.fc-event-azure-dim .fc-list-event-dot {
  border-color: #1676fb !important;
}
.modal-header.fc-event-azure-dim {
  background-color: #e1edfe !important;
  color: #1676fb !important;
  border-color: #c8defd !important;
}
.modal-header.fc-event-azure-dim .close,
.modal-header.fc-event-azure-dim .modal-title {
  color: #1676fb !important;
}
span.fc-event-azure-dim .dot {
  background-color: #e1edfe !important;
  border-color: #1676fb !important;
}
.fc-event-azure-dim:not(a) .fc-list-event-dot {
  background-color: #e1edfe !important;
  border-color: #4893fc !important;
}
a.fc-event-indigo-dim {
  background-color: #e4e6f2 !important;
  border-color: #e4e6f2 !important;
  color: #323f9e !important;
}
a.fc-event-indigo-dim .fc-daygrid-event-dot,
a.fc-event-indigo-dim .fc-list-event-dot {
  border-color: #323f9e !important;
}
.modal-header.fc-event-indigo-dim {
  background-color: #e4e6f2 !important;
  color: #323f9e !important;
  border-color: #d3d6ea !important;
}
.modal-header.fc-event-indigo-dim .close,
.modal-header.fc-event-indigo-dim .modal-title {
  color: #323f9e !important;
}
span.fc-event-indigo-dim .dot {
  background-color: #e4e6f2 !important;
  border-color: #323f9e !important;
}
.fc-event-indigo-dim:not(a) .fc-list-event-dot {
  background-color: #e4e6f2 !important;
  border-color: #4050c3 !important;
}
a.fc-event-purple-dim {
  background-color: #efecff !important;
  border-color: #efecff !important;
  color: #816bff !important;
}
a.fc-event-purple-dim .fc-daygrid-event-dot,
a.fc-event-purple-dim .fc-list-event-dot {
  border-color: #816bff !important;
}
.modal-header.fc-event-purple-dim {
  background-color: #efecff !important;
  color: #816bff !important;
  border-color: #dad3ff !important;
}
.modal-header.fc-event-purple-dim .close,
.modal-header.fc-event-purple-dim .modal-title {
  color: #816bff !important;
}
span.fc-event-purple-dim .dot {
  background-color: #efecff !important;
  border-color: #816bff !important;
}
.fc-event-purple-dim:not(a) .fc-list-event-dot {
  background-color: #efecff !important;
  border-color: #ac9eff !important;
}
a.fc-event-pink-dim {
  background-color: #ffebf3 !important;
  border-color: #ffebf3 !important;
  color: #ff63a5 !important;
}
a.fc-event-pink-dim .fc-daygrid-event-dot,
a.fc-event-pink-dim .fc-list-event-dot {
  border-color: #ff63a5 !important;
}
.modal-header.fc-event-pink-dim {
  background-color: #ffebf3 !important;
  color: #ff63a5 !important;
  border-color: #ffd2e4 !important;
}
.modal-header.fc-event-pink-dim .close,
.modal-header.fc-event-pink-dim .modal-title {
  color: #ff63a5 !important;
}
span.fc-event-pink-dim .dot {
  background-color: #ffebf3 !important;
  border-color: #ff63a5 !important;
}
.fc-event-pink-dim:not(a) .fc-list-event-dot {
  background-color: #ffebf3 !important;
  border-color: #ff96c2 !important;
}
a.fc-event-orange-dim {
  background-color: #fff3e9 !important;
  border-color: #fff3e9 !important;
  color: #ffa353 !important;
}
a.fc-event-orange-dim .fc-daygrid-event-dot,
a.fc-event-orange-dim .fc-list-event-dot {
  border-color: #ffa353 !important;
}
.modal-header.fc-event-orange-dim {
  background-color: #fff3e9 !important;
  color: #ffa353 !important;
  border-color: #ffe5d0 !important;
}
.modal-header.fc-event-orange-dim .close,
.modal-header.fc-event-orange-dim .modal-title {
  color: #ffa353 !important;
}
span.fc-event-orange-dim .dot {
  background-color: #fff3e9 !important;
  border-color: #ffa353 !important;
}
.fc-event-orange-dim:not(a) .fc-list-event-dot {
  background-color: #fff3e9 !important;
  border-color: #ffbe86 !important;
}
a.fc-event-teal-dim {
  background-color: #e2f8f1 !important;
  border-color: #e2f8f1 !important;
  color: #20c997 !important;
}
a.fc-event-teal-dim .fc-daygrid-event-dot,
a.fc-event-teal-dim .fc-list-event-dot {
  border-color: #20c997 !important;
}
.modal-header.fc-event-teal-dim {
  background-color: #e2f8f1 !important;
  color: #20c997 !important;
  border-color: #cdf3e7 !important;
}
.modal-header.fc-event-teal-dim .close,
.modal-header.fc-event-teal-dim .modal-title {
  color: #20c997 !important;
}
span.fc-event-teal-dim .dot {
  background-color: #e2f8f1 !important;
  border-color: #20c997 !important;
}
.fc-event-teal-dim:not(a) .fc-list-event-dot {
  background-color: #e2f8f1 !important;
  border-color: #3ce0af !important;
}
.fc-draggable {
  background-color: #11c8fc;
  border-radius: 4px;
  margin: 0.75rem 0;
  padding: 0.25rem 0.5rem;
  font-size: 13px;
  color: #fff;
  list-style: none;
  display: inline-block;
}
.fc-day {
  color: #364a63;
}
.fc-day-other {
  color: #526484;
}
.fc-col-header-cell {
  color: #526484;
}
.fc .fc-scrollgrid-section table,
.fc .fc-daygrid-body,
.fc .fc-timegrid-body {
  width: 100% !important;
}
.nk-kanban {
  overflow: auto;
  padding-bottom: 1rem;
}
.kanban-container {
  position: relative;
  box-sizing: border-box;
  width: auto;
  display: flex;
  margin-left: -14px;
  margin-right: -14px;
  overflow: auto;
}
.kanban-container * {
  box-sizing: border-box;
}
.kanban-board {
  padding: 14px;
  position: relative;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  flex-shrink: 0;
}
.kanban-board.disabled-board {
  opacity: 0.3;
}
.kanban-board.is-moving.gu-mirror {
  transform: rotate(3deg);
}
.kanban-board.is-moving.gu-mirror .kanban-drag {
  overflow: hidden;
  padding-right: 50px;
}
.kanban-board-header {
  background: #fff;
  border: 1px solid #e5e9f2;
  border-top: 3px solid #dbdfea;
  border-radius: 4px;
  padding: 0.5rem 1.25rem;
  margin-bottom: 1rem;
}
.kanban-primary {
  border-top-color: #11c8fc;
}
.kanban-success {
  border-top-color: #0fca7a;
}
.kanban-info {
  border-top-color: #058efc;
}
.kanban-warning {
  border-top-color: #fd9722;
}
.kanban-danger {
  border-top-color: #f2426e;
}
.kanban-title-content {
  display: flex;
  align-items: center;
}
.kanban-title-content .title {
  font-size: 0.9375rem;
  margin-bottom: 0;
  margin-right: 0.75rem;
  color: #526484;
}
.kanban-title-board {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.kanban-board header .kanban-title-button {
  float: right;
}
.kanban-board .kanban-drag {
  min-height: 100px;
  margin-bottom: 1rem;
}
.kanban-board .kanban-drag:empty {
  border-radius: 4px;
  border: 1px dashed #e5e9f2;
}
.kanban-board:after {
  clear: both;
  display: block;
  content: "";
}
.kanban-item {
  border-radius: 4px;
  border: 1px solid #e5e9f2;
  padding: 1rem 1.25rem;
  margin-bottom: 0.75rem;
  background: #fff;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.kanban-item-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.kanban-item-title + .kanban-item-text {
  margin-top: 0.5rem;
}
.kanban-item-title .title {
  font-size: 0.9375rem;
  margin-bottom: 0;
  margin-right: 0.75rem;
}
.kanban-item-tags {
  display: flex;
  padding-top: 0.5rem;
  margin: -0.125rem;
}
.kanban-item-tags li {
  padding: 0.125rem;
}
.kanban-item-meta {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 0.5rem;
}
.kanban-item-meta-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -0.25rem;
}
.kanban-item-meta-list:last-child {
  flex-wrap: nowrap;
}
.kanban-item-meta-list:first-child {
  padding-right: 1rem;
}
.kanban-item-meta-list li {
  font-size: 0.8125rem;
  padding: 0 0.25rem;
  color: #8094ae;
}
.kanban-item-meta-list li span {
  white-space: nowrap;
}
.kanban-item-meta-list li .icon + span {
  margin-left: 0.25rem;
}
.kanban-item:hover {
  cursor: move;
}
.kanban-item:last-child {
  margin: 0;
}
.kanban-item.is-moving.gu-mirror {
  transform: rotate(3deg);
  height: auto !important;
}
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2 !important;
  transform: rotate(0deg) !important;
}
.drag_handler {
  background: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: relative;
  top: -3px;
  float: left;
  margin-right: 4px;
}
.drag_handler:hover {
  cursor: move;
}
.drag_handler_icon {
  position: relative;
  display: block;
  background: #000;
  width: 24px;
  height: 2px;
  top: 12px;
  transition: 0.5s ease-in-out;
}
.drag_handler_icon:before,
.drag_handler_icon:after {
  background: #000;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: 0.5s ease-in-out;
}
.drag_handler_icon:before {
  top: 6px;
}
.drag_handler_icon:after {
  bottom: 6px;
}
.kanban-add-task {
  color: #11c8fc;
  background: #fff;
  border: 1px dashed #e5e9f2;
  padding-top: 0.675rem;
  padding-bottom: 0.675rem;
}
.kanban-add-task:hover {
  color: #fff;
  background: #11c8fc;
  border: 1px solid #11c8fc;
}
.kanban-add-task:focus {
  outline: none;
  box-shadow: none;
}
.dual-listbox {
  display: flex;
  flex-direction: column;
}
.dual-listbox .dual-listbox__container {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.dual-listbox .dual-listbox__search {
  max-width: 300px;
}
.dual-listbox .dual-listbox__search--hidden {
  display: none;
}
.dual-listbox .dual-listbox__available,
.dual-listbox .dual-listbox__selected {
  border: 1px solid #e5e9f2;
  height: 300px;
  overflow-y: auto;
  padding: 0;
  width: 300px;
  margin-top: 0;
  border-radius: 0 0 4px 4px;
}
.dual-listbox .dual-listbox__buttons {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px 10px 0;
}
.dual-listbox .dual-listbox__button {
  margin-bottom: 5px;
  justify-content: center;
  text-transform: capitalize;
}
.dual-listbox .dual-listbox__title {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-left: 1px solid #e5e9f2;
  border-right: 1px solid #e5e9f2;
  border-top: 1px solid #e5e9f2;
  margin-top: 1rem;
  -webkit-margin-before: 1rem;
  border-radius: 4px 4px 0 0;
}
.dual-listbox .dual-listbox__item {
  display: block;
  padding: 0.5rem 1rem;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  border-bottom: 1px solid #e5e9f2;
  transition: background-color 0.2s ease;
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .dual-listbox .dual-listbox__container {
    flex-wrap: nowrap;
  }
  .dual-listbox .dual-listbox__container > div:not(.dual-listbox__buttons) {
    width: 100%;
  }
  .dual-listbox .dual-listbox__available,
  .dual-listbox .dual-listbox__selected {
    width: auto;
  }
  .dual-listbox .dual-listbox__buttons {
    margin: 10px auto;
  }
  .dual-listbox__buttons {
    flex-shrink: 0;
  }
}
@media (max-width: 575.98px) {
  .dual-listbox .dual-listbox__container > div:not(.dual-listbox__buttons) {
    width: 100%;
  }
  .dual-listbox .dual-listbox__available,
  .dual-listbox .dual-listbox__selected {
    width: 100%;
  }
  .dual-listbox .dual-listbox__buttons {
    margin: 10px auto 0;
  }
}
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}
.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}
.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}
.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.mfp-align-top .mfp-container:before {
  display: none;
}
.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}
.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}
.mfp-ajax-cur {
  cursor: progress;
}
.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}
.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}
.mfp-auto-cursor .mfp-content {
  cursor: auto;
}
.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.mfp-loading.mfp-figure {
  display: none;
}
.mfp-hide {
  display: none !important;
}
.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}
.mfp-preloader a {
  color: #ccc;
}
.mfp-preloader a:hover {
  color: #fff;
}
.mfp-s-ready .mfp-preloader {
  display: none;
}
.mfp-s-error .mfp-content {
  display: none;
}
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: rgba(0, 0, 0, 0);
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button.mfp-close {
  overflow: visible;
  cursor: pointer;
  border: 0;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: 0 3px 12px 1px rgba(43, 55, 72, 0.15);
  touch-action: manipulation;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  transform: translate(50%, -50%);
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
  background-color: #fff;
  border-radius: 50%;
}
.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}
.mfp-close:active {
  top: 1px;
}
.mfp-close:after {
  position: absolute;
  font-family: "Nioicon";
  content: "";
  color: #526484;
}
.mfp-close-btn-in .mfp-close {
  color: #333;
}
.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
}
.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}
.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mfp-arrow:active {
  margin-top: -54px;
}
.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}
.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  margin-right: 35px;
  border: medium inset rgba(0, 0, 0, 0);
}
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}
.mfp-arrow-left {
  left: 0;
}
.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f;
}
.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}
.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}
.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: calc(100% - 34px);
  max-width: 900px;
}
.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
  border-radius: 8px;
}
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 8px;
}
.mfp-figure {
  line-height: 0;
}
.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}
.mfp-figure small,
.mfp-figure .small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.mfp-figure figure {
  margin: 0;
}
.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}
.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}
.mfp-image-holder .mfp-content {
  max-width: calc(100% - 34px);
}
.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}
@media screen and (max-width: 800px) and (orientation: landscape),
  screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small,
  .mfp-img-mobile .mfp-figure .small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  cursor: grabbing;
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
.dragula-container > *:not(:last-child) {
  margin-bottom: 0.75rem;
}
.dragula-container > * {
  cursor: move;
  cursor: grab;
  touch-action: none;
}
.dragula-container.has-handle > * {
  cursor: initial;
}
.dragula-handle {
  display: flex;
}
.dragula-handle .handle {
  position: relative;
  font-size: 1.125rem;
  cursor: move;
  margin: 0 0.875rem;
}
.dragula-handle .handle:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  font-family: "Nioicon";
  content: "";
  font-size: 1rem;
}
.google-map {
  height: 400px;
  width: 100%;
}
body {
  min-width: 320px;
}
body.nav-shown {
  overflow: hidden;
}
.nk-body {
  outline: none;
}
.nk-app-root {
  outline: none;
}
.nk-main {
  position: relative;
}
.nk-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.nk-wrap:not(.nk-wrap-nosidebar) {
  padding: 15px 0;
}
@media (min-width: 1200px) {
  .nk-wrap:not(.nk-wrap-nosidebar) {
    padding: 30px 0;
  }
}
.nk-content {
  padding: 24px 6px;
}
.nk-content-fs {
  min-height: calc(100vh - (65px + 65px));
  display: flex;
  align-items: center;
}
@media (min-width: 576px) {
  .nk-content {
    padding: 16px 22px 24px;
  }
  .nk-content-fluid {
    padding-left: 22px;
    padding-right: 22px;
  }
}
@media (min-width: 992px) {
  .nk-content-lg {
    padding-top: 36px;
    padding-bottom: 36px;
  }
  .nk-content-fluid {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (min-width: 1660px) {
  .nk-content-lg {
    padding-top: 54px;
    padding-bottom: 54px;
  }
  .nk-content-fluid {
    padding-left: 44px;
    padding-right: 44px;
  }
}
.nk-content-body {
  flex-grow: 1;
}
.nk-wrap-nosidebar .nk-content {
  padding: 0 !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.logo-link {
  position: relative;
  display: inline-block;
  align-items: center;
}
.logo-dark {
  opacity: 1;
}
.tc-light .logo-dark,
.is-dark .logo-dark,
.is-theme .logo-dark {
  opacity: 0;
}
.logo-light {
  opacity: 0;
}
.tc-light .logo-light,
.is-dark .logo-light,
.is-theme .logo-light {
  opacity: 1;
}
.logo-img {
  max-height: 56px;
}
.logo-img-lg {
  max-height: 60px;
}
.logo-img-sm {
  max-height: 28px;
}
.logo-img-icon {
  max-height: 33px;
}
.logo-img:not(:first-child) {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.nk-header {
  background: rgba(0, 0, 0, 0);
  padding: 0 6px;
  z-index: 1010;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  min-width: 320px;
}
.nk-header + .nk-content {
  position: relative;
}
.nk-header.is-dark:not([class*="bg-"]) {
  background: rgba(0, 0, 0, 0);
}
.nk-header.is-light:not([class*="bg-"]) {
  background: rgba(0, 0, 0, 0);
}
.nk-header.is-theme:not([class*="bg-"]) {
  background: rgba(0, 0, 0, 0);
}
.nk-header-wrap {
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.4s ease;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.has-fixed .nk-header-wrap {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(128, 148, 174, 0.3);
}
.nk-header-right {
  align-items: center;
  justify-content: flex-end;
}
.nk-header-center {
  justify-content: center;
}
.nk-header-tools {
  /* margin-left: auto; */
  padding-top: 14px;
  padding-bottom: 14px;
  width: 100%;
}
.nk-header-brand {
  flex-shrink: 0;
  padding-top: 14px;
  padding-bottom: 14px;
}
.nk-header .dropdown-menu .lead-text {
  color: #364a63;
}
.nk-header .dropdown-menu .sub-text,
.nk-header .dropdown-menu .overline-title,
.nk-header .dropdown-menu .overline-title-alt {
  color: #8094ae;
}
.nk-header-search {
  display: none;
}
.nk-header-search .form-control,
.nk-header-search div.dataTables_wrapper div.dataTables_filter input,
div.dataTables_wrapper div.dataTables_filter .nk-header-search input,
.nk-header-search .dual-listbox .dual-listbox__search,
.dual-listbox .nk-header-search .dual-listbox__search {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}
.is-dark .nk-header-search .icon {
  color: #fff;
}
.is-theme .nk-header-search .icon {
  color: #fff;
}
.nk-quick-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -8px;
}
.nk-quick-nav > li {
  padding: 0 6px;
}
.nk-quick-nav > li.user-dropdown > a {
  padding: 0 2px;
}
.nk-quick-nav-icon {
  display: inline-flex;
  position: relative;
  font-size: 1.5rem;
  z-index: 1;
  color: #526484;
  padding: 0.375rem;
}
.is-dark .nk-quick-nav-icon {
  color: #9faec2;
}
.is-theme .nk-quick-nav-icon {
  color: #ab89f2;
}
.is-theme .nk-quick-nav-icon.nk-nav-toggle {
  color: #fff;
}
.nk-quick-nav-icon:focus {
  box-shadow: none;
}
.nk-quick-nav-icon:before {
  position: absolute;
  z-index: -1;
  height: 20px;
  width: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  content: "";
  background-color: #e5e9f2;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.3s;
}
.is-dark .nk-quick-nav-icon:before {
  background-color: #0b1219;
}
.is-theme .nk-quick-nav-icon:before {
  background-color: #030206;
}
.show > .nk-quick-nav-icon:before {
  opacity: 1;
  height: 116%;
  width: 116%;
}
.nk-quick-nav-icon:hover:before,
.nk-quick-nav-icon.active:before {
  opacity: 1;
  height: 116%;
  width: 116%;
}
@media (max-width: 359px) {
  .nk-quick-nav {
    margin: 0 -5px;
    justify-content: end;
  }
  .nk-quick-nav > li {
    padding: 0 3px;
  }
  .hide-mb-xs {
    display: none;
  }
}
@media (max-width: 575px) {
  .hide-mb-sm {
    display: none;
  }
}
@media (min-width: 576px) {
  .nk-header {
    padding: 0 22px;
  }
  .nk-header-search {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  .nk-quick-nav {
    margin: 0 -12px;
  }
  .nk-quick-nav > li {
    padding: 0 10px;
  }
}
@media (min-width: 992px) {
  .nk-header {
    position: sticky;
  }
  .nk-header-fluid {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (min-width: 1660px) {
  .nk-header-fluid {
    padding-left: 44px;
    padding-right: 44px;
  }
}
.nk-header-menu {
  position: fixed;
  left: 0;
  transform: translateX(-100%);
  top: 0;
  width: 280px;
  height: 100vh;
  max-height: 100vh;
  z-index: 999;
  background: #fff;
}
.nk-header-menu-inner {
  max-height: 100%;
  min-height: 100%;
}
.nk-header-menu.mobile-menu {
  padding-top: 65px;
  padding-left: 0;
  padding-right: 0;
  transition: transform 0.4s ease;
}
.nk-header-menu.nk-sidebar-active {
  transform: translateX(0);
}
.nk-header-mobile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 28px;
  border-bottom: 1px solid #e5e9f2;
}
.nk-header-mobile .logo-dark {
  opacity: 1;
}
.nk-header-mobile .logo-light {
  opacity: 0;
}
.nk-header-mobile .nk-nav-toggle.nk-quick-nav-icon {
  color: #526484;
}
.nk-header-mobile .nk-nav-toggle.nk-quick-nav-icon:before {
  background-color: #e5e9f2;
}
@media (min-width: 992px) {
  .nk-header-menu {
    position: static;
    background: rgba(0, 0, 0, 0);
    height: auto;
    max-height: auto;
    border-right: none;
    width: auto;
    padding: 0;
    overflow: visible;
    transform: translateX(0) !important;
  }
  .nk-header-menu .nk-header-mobile {
    display: none;
  }
}
.nk-header-app-name {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.nk-header-app-logo {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 0.75rem;
}
.nk-header-app-logo .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 20px;
  color: #11c8fc;
  background: rgba(115, 58, 234, 0.3);
}
.nk-header-app-info {
  line-height: 1.3;
}
.nk-header-app-info .sub-text {
  font-size: 11px;
  margin-bottom: 0.125rem;
}
.nk-header-app-info .lead-text {
  font-size: 14px;
}
.nk-header-app-switch {
  position: relative;
}
.nk-header-app-switch .nk-header-app-name {
  padding: 1.5rem 36px;
}
.nk-header-app-switch > a {
  display: block;
  width: 100%;
}
.nk-header-app-switch > a:after {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1rem;
  content: "";
  font-family: "Nioicon";
}
.mobile-menu .nk-header-app-switch {
  width: 100%;
  border-bottom: 1px solid #e5e9f2;
}
.nk-header-app-switch .toggle-content {
  width: 100%;
  top: 0;
  transform: translateY(0);
  height: calc(100vh - 65px);
  overflow: auto;
  box-shadow: none;
  border-radius: 0;
  background: #fff;
}
.nk-header-app-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 36px;
}
.nk-header-app-head .title {
  font-size: 16px;
  font-weight: 500;
}
.nk-header-app-head .toggle {
  position: relative;
  height: 24px;
  width: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.nk-header-app-list li {
  border-bottom: 1px solid #e5e9f2;
}
.nk-header-app-list .nk-header-app-name {
  padding: 1rem 36px;
}
@media (max-height: 615px) {
  .nk-header-app-switch .dropdown-menu {
    max-height: 416px;
    overflow: auto;
  }
}
@media (min-width: 768px) {
  .nk-header-onlymobile {
    display: none;
  }
  .nk-header-onlymobile + .nk-content {
    margin-top: 0;
  }
}
.quick-icon {
  display: inline-flex;
  height: 24px;
  width: 24px;
  border-radius: 50%;
}
.quick-icon img.icon {
  border-radius: 50%;
}
.nk-sidebar {
  position: absolute;
  background: #fff;
  top: 0;
  left: 0;
  bottom: 0;
  transform: translateX(-100%);
  z-index: 1021;
  width: 296px;
  transition: transform 450ms ease, width 450ms ease;
  box-shadow: 0 0 25px -12px rgba(31, 43, 58, 0.15);
}
.nk-sidebar + .nk-wrap {
  transition: padding 450ms ease;
}
.nk-sidebar + .nk-wrap > .nk-header-fixed {
  transition: left 450ms ease;
}
.nk-sidebar.is-light {
  background: #fff;
  border-right-color: #ebeef2;
}
.nk-sidebar.is-dark {
  background: #18212d;
}
.nk-sidebar.is-theme {
  background: #1f103f;
}
.nk-sidebar-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(16, 25, 36, 0.4);
  z-index: 1020;
  animation: overlay-fade-in 0.4s ease 1;
}
.nk-sidebar-element {
  overflow: hidden;
  width: 100%;
  max-height: 100%;
}
.nk-sidebar.nk-sidebar-active {
  transform: translateX(0);
}
.nk-sidebar-fixed {
  position: fixed;
  max-height: 100vh;
}
.nk-sidebar-head {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 14px 36px;
  min-width: 100%;
  width: 296px;
  height: 65px;
}
.nk-sidebar-brand {
  position: relative;
  flex-shrink: 0;
}
.nk-sidebar-logo {
  transition: opacity 0.3s ease;
}
.nk-sidebar-logo .logo-img:not(:first-child) {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.nk-sidebar .logo-img-small {
  opacity: 0;
  display: block;
}
.nk-sidebar-body {
  position: relative;
}
.nk-sidebar-body .simplebar-content {
  padding-bottom: 0.25rem !important;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.nk-sidebar-body .simplebar-scroll-content {
  min-height: 100%;
  width: 100%;
}
.nk-sidebar-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 65px - 30px);
}
.nk-sidebar-content .nk-sidebar-menu {
  padding-top: 1.5rem;
  padding-bottom: 3rem;
}
.nk-sidebar-content .nk-sidebar-menu[data-simplebar] {
  height: 100%;
}
.nk-sidebar-content[data-simplebar] > div {
  width: 100%;
}
.nk-sidebar.is-theme .user-balance-alt,
.nk-sidebar.is-dark .user-balance-alt {
  color: #ab89f2;
}
.nk-sidebar-profile {
  margin-top: auto;
  width: 100%;
}
.nk-sidebar-profile .user-avatar {
  height: 36px !important;
  width: 36px !important;
}
.nk-sidebar-profile .dropdown-toggle {
  width: 100%;
  padding: 14px 36px;
}
.nk-sidebar-profile .dropdown-toggle:after {
  margin-left: auto;
  font-size: 1.125rem;
  color: #8094ae;
}
.nk-sidebar-profile .dropdown-menu {
  width: calc(296px - 72px);
}
@keyframes overlay-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 767.98px) {
  .nk-sidebar-mobile {
    width: 296px !important;
  }
  .logo-img{
    max-height: 40px !important;
  }
}
@media (min-width: 1200px) {
  .nk-sidebar-content .nk-sidebar-menu {
    padding-top: 1rem;
    padding-bottom: 2.5rem;
  }
  .nk-sidebar {
    top: 30px;
    left: 30px;
    bottom: 30px;
    border-radius: 5px;
    overflow: hidden;
    transform: translateX(0);
    width: 296px;
  }
  .nk-sidebar + .nk-wrap,
  .nk-sidebar-overlay + .nk-wrap {
    padding-left: 326px;
  }
  .nk-sidebar + .nk-wrap > .nk-header-fixed,
  .nk-sidebar-overlay + .nk-wrap > .nk-header-fixed {
    left: 326px;
  }
  .nk-sidebar.is-compact:not(:hover) {
    width: 76px;
  }
  .nk-sidebar.is-compact:not(:hover) .logo-img {
    opacity: 0;
    transition: opacity 0.2s linear;
  }
  .nk-sidebar.is-compact:not(:hover) .logo-img-small {
    opacity: 1;
  }
  .nk-sidebar.is-compact:not(:hover) .user-card {
    position: relative;
    left: -5px;
  }
  .nk-sidebar.is-compact:not(:hover) .user-info {
    opacity: 0;
  }
  .nk-sidebar.is-compact:not(:hover) .nk-sidebar-profile .dropdown-menu {
    opacity: 0;
  }
  .nk-sidebar.is-compact + .nk-wrap {
    padding-left: 106px;
  }
  .nk-sidebar.is-compact + .nk-wrap > .nk-header-fixed {
    left: 106px;
  }
  .nk-sidebar-overlay {
    display: none;
  }
}
@media (min-width: 1200px) {
  .nk-sidebar {
    transform: translateX(0);
  }
}
.nk-menu li a {
  vertical-align: middle;
  display: flex;
  position: relative;
  align-items: center;
  transition: color 0.3s, background-color 0.3s;
}
.nk-menu-item {
  padding: 4px 18px;
}
.nk-menu-sub .nk-menu-item {
  padding: 1px 0;
}
.nk-menu-item.has-sub {
  position: relative;
}
.nk-menu > .nk-menu-item.menu-without-icon > .nk-menu-sub .nk-menu-link {
  padding-left: 0;
}
.nk-menu-link {
  padding: 0.625rem 2px 0.625rem 18px;
  color: #6e82a5;
  font-family: Nunito, sans-serif;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.01em;
  text-transform: none;
  line-height: 1.25rem;
  border-radius: 6px;
}
.active.has-sub > .nk-menu-link {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.nk-menu-link:hover,
.active > .nk-menu-link {
  color: #00c4fb;
}
.nk-menu-link:hover .count,
.active > .nk-menu-link .count {
  color: #00c4fb;
}
.nk-menu-sub .nk-menu-link {
  padding: 0.375rem 52px 0.375rem 36px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: normal;
  text-transform: none;
  line-height: 1.25rem;
  color: #6e82a5;
}
.nk-menu-link.is-disable {
  cursor: default;
}
.nk-menu-link.is-disable:hover,
.active > .nk-menu-link.is-disable {
  color: #6e82a5;
}
.nk-menu-link.is-disable:hover .count,
.active > .nk-menu-link.is-disable .count {
  color: #6e82a5;
}
.is-light .nk-menu-link {
  color: #526484;
}
.is-theme .nk-menu-link {
  color: #11c8fc2e;
}
.is-light .nk-menu-link:hover,
.is-light .active > .nk-menu-link {
  color: #00c4fb;
}
.is-theme .nk-menu-link:hover,
.is-theme .active > .nk-menu-link {
  color: #fff;
}
.nk-menu-link span.small {
  color: #8094ae;
  margin-left: 0.5rem;
}
.nk-menu-icon {
  font-weight: normal;
  letter-spacing: normal;
  width: 36px;
  line-height: 1;
  flex-grow: 0;
  flex-shrink: 0;
  color: #8094ae;
}
.is-theme .nk-menu-icon {
  color: #9164ee;
}
.nk-menu-icon svg,
.nk-menu-icon img {
  width: 36px;
}
.nk-menu-icon .icon {
  font-size: 24px;
  letter-spacing: normal;
  vertical-align: middle;
  color: currentColor;
  transition: color 0.4s, background-color 0.4s;
}
.nk-menu-link:hover .nk-menu-icon,
.nk-menu-item.active > .nk-menu-link .nk-menu-icon,
.nk-menu-item.current-menu > .nk-menu-link .nk-menu-icon {
  color: #00c4fb;
}
.is-theme .nk-menu-link:hover .nk-menu-icon,
.is-theme .nk-menu-item.active > .nk-menu-link .nk-menu-icon,
.is-theme .nk-menu-item.current-menu > .nk-menu-link .nk-menu-icon {
  color: #fff;
}
.nk-menu-link.is-disable:hover .nk-menu-icon {
  color: #8094ae;
}
.is-theme .nk-menu-link.is-disable:hover .nk-menu-icon {
  color: #ab89f2;
}
.nk-menu-text {
  flex-grow: 1;
  display: inline-block;
}
.nk-menu-badge {
  border-radius: 3px;
  padding: 0 0.4rem;
  font-size: 11px;
  color: #11c8fc;
  background: #afebfb;
  position: absolute;
  top: 50%;
  right: 36px !important;
  transform: translateY(-50%);
}
.nk-menu-toggle > .nk-menu-badge {
  right: 56px !important;
}
.is-dark .nk-menu-badge {
  color: #9c73f5;
  background: #2a3a4e;
}
.is-theme .nk-menu-badge {
  color: #ab89f2;
  background: #371d70;
}
.nk-menu-main .nk-menu-sub .nk-menu-badge {
  color: #11c8fc;
  background: #afebfb;
}
.nk-menu-heading {
  padding: 0.25rem 36px 0.5rem;
  color: #8094ae;
}
.is-theme .nk-menu-heading {
  color: #7a44ea;
}
.nk-menu-item + .nk-menu-heading {
  padding-top: 2rem;
}
.nk-menu-heading .overline-title {
  color: inherit;
  white-space: nowrap;
}
.nk-menu-heading span {
  font-size: 13px;
  font-weight: 400;
}
.nk-menu-hr {
  margin: 1.25rem 0;
  border-bottom: 1px solid #dbdfea;
}
.is-dark .nk-menu-hr {
  border-color: rgba(255, 255, 255, 0.12);
}
.is-theme .nk-menu-hr {
  border-color: rgba(255, 255, 255, 0.12);
}
.nk-menu-hr + .nk-menu-heading {
  padding-top: 1rem;
}
.nk-menu-toggle + .nk-menu-sub {
  display: none;
}
.active > .nk-menu-sub {
  display: block;
}
.nk-menu-sub .nk-menu-link {
  padding-left: 54px;
}
.nk-menu-sub .nk-menu-link:hover {
  color: #11c8fc;
}
.is-theme .nk-menu-sub .nk-menu-link:hover {
  color: #fff;
}
.nk-menu-sub .nk-menu-link:hover,
.active > .nk-menu-sub .nk-menu-link {
  background-color: rgba(0, 0, 0, 0);
}
.nk-menu-sub .nk-menu-sub {
  margin-left: 54px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0;
}
.nk-menu-sub .nk-menu-sub .nk-menu-link {
  padding-left: 1rem;
}
.nk-menu-sub .active > .nk-menu-link {
  color: #11c8fc;
}
.is-theme .nk-menu-sub .active > .nk-menu-link {
  color: #fff;
}
.nk-menu-sub .nk-menu-icon {
  width: 24px;
  margin-top: -1px;
}
.nk-menu-sub .nk-menu-icon .icon {
  margin-top: -1px;
  font-size: 1.2em;
}
.nk-menu-toggle {
  position: relative;
}
.nk-menu-toggle:after {
  position: absolute;
  font-family: "Nioicon";
  top: 50%;
  content: "";
  right: 1.25rem;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #8094ae;
  transition: transform 0.3s, color 0.1s, opacity 0.3s ease 0.3s;
}
.is-dark .nk-menu-toggle:after,
.is-theme .nk-menu-toggle:after {
  color: rgba(255, 255, 255, 0.4);
}
.has-sub.active > .nk-menu-toggle:after {
  color: #00c4fb;
  transform: translateY(-50%) rotate(90deg);
}
.nk-menu .count {
  display: inline-block;
  font-size: 0.85em;
  margin-left: 0.25rem;
  color: #8094ae;
  line-height: 1;
}
.nk-menu-icon-colored .nk-menu-icon {
  color: #11c8fc;
}
.nk-sidebar .nk-menu > li .nk-menu-sub .nk-menu-link {
  padding-left: 54px;
}
.nk-sidebar .nk-menu > li .nk-menu-sub .nk-menu-link:before {
  content: "";
  height: 7px;
  width: 7px;
  border: 1px solid #d5f5fe;
  background: #ebe1ff;
  position: absolute;
  left: 31px;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.nk-sidebar .nk-menu > li .nk-menu-sub .nk-menu-sub {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0;
  margin-left: 50px;
}
.nk-sidebar .nk-menu > li .nk-menu-sub .nk-menu-sub:before {
  left: 4px;
}
.nk-sidebar .nk-menu > li .nk-menu-sub .nk-menu-sub .nk-menu-link {
  padding-left: 1.25rem;
}
.nk-sidebar .nk-menu > li .nk-menu-sub .nk-menu-sub .nk-menu-link:before {
  left: 4px;
}
.nk-sidebar .nk-menu > li.active > .nk-menu-link {
  background: #d5f5fe;
}
.is-dark .nk-sidebar .nk-menu > li.active > .nk-menu-link {
  background: #212d3d;
}
.is-theme .nk-sidebar .nk-menu > li.active > .nk-menu-link {
  background: #2b1658;
}
.nk-sidebar .nk-menu > li > .nk-menu-link:hover {
  background: #d5f5fe;
}
.nk-sidebar
  .nk-menu
  > li
  > .nk-menu-link:hover.is-dark
  .nk-sidebar
  .nk-menu
  > li
  > .nk-menu-link:hover {
  background: #27254b;
}
.is-theme .nk-sidebar .nk-menu > li > .nk-menu-link:hover {
  background: #2c175a;
}
.nk-sidebar .nk-menu-sub {
  position: relative;
  background: #faf8ff;
  padding: 1rem 0;
  border-radius: 0 0 6px 6px;
}
.nk-sidebar .nk-menu-sub:before {
  content: "";
  height: 100%;
  width: 1px;
  background: #d5f5fe;
  position: absolute;
  left: 31px;
  top: 0;
  transform: translateX(-50%);
}
.nk-sidebar .nk-menu-sub .active > .nk-menu-link:before {
  background: #11c8fc !important;
}
.nk-sidebar.is-dark .nk-menu > li .nk-menu-sub .nk-menu-link:before {
  border-color: #1d2338;
  background: #27254b;
}
.nk-sidebar.is-dark .nk-menu > li.active > .nk-menu-link {
  background: #27254b;
}
.nk-sidebar.is-dark .nk-menu > li > .nk-menu-link:hover {
  background: #27254b;
}
.nk-sidebar.is-dark .nk-menu-sub {
  background: #1d2338;
}
.nk-sidebar.is-dark .nk-menu-sub:before {
  background: #27254b;
}
.nk-sidebar.is-dark .nk-menu-sub .active > .nk-menu-link:before {
  background: #fff !important;
}
.nk-sidebar.is-dark .nk-menu .nk-menu-link:hover .nk-menu-icon,
.nk-sidebar.is-dark .nk-menu .nk-menu-item.active > .nk-menu-link .nk-menu-icon,
.nk-sidebar.is-dark
  .nk-menu
  .nk-menu-item.current-menu
  > .nk-menu-link
  .nk-menu-icon,
.nk-sidebar.is-dark .nk-menu .nk-menu-link:hover,
.nk-sidebar.is-dark .nk-menu .active > .nk-menu-link {
  color: #fff;
}
.nk-sidebar.is-theme .nk-menu > li .nk-menu-sub .nk-menu-link:before {
  border-color: #241349;
  background: #2c175a;
}
.nk-sidebar.is-theme .nk-menu > li.active > .nk-menu-link {
  background: #2c175a;
}
.nk-sidebar.is-theme .nk-menu > li > .nk-menu-link:hover {
  background: #2c175a;
}
.nk-sidebar.is-theme .nk-menu-sub {
  background: #241349;
}
.nk-sidebar.is-theme .nk-menu-sub:before {
  background: #2c175a;
}
.nk-sidebar.is-theme .nk-menu-sub .active > .nk-menu-link:before {
  background: #fff !important;
}
.nk-menu-main.nk-menu {
  padding-top: 0.75rem;
}
.nk-menu-main .nk-menu-link {
  color: #526484;
  padding-left: 36px;
  border-radius: 0;
}
.nk-menu-main .nk-menu-sub {
  padding: 0;
  margin: 0;
  transition: none;
}
.nk-menu-main .nk-menu-sub .nk-menu-link {
  color: #526484;
}
.nk-menu-main .nk-menu-sub .nk-menu-sub {
  border-left: 1px solid #dbdfea;
  margin: 0.25rem 0 0.25rem 36px;
  padding-bottom: 0;
}
.nk-menu-main .nk-menu-sub .nk-menu-sub .nk-menu-link {
  padding-left: 0.875rem;
}
.nk-menu-main .nk-menu-link:hover,
.nk-menu-main .nk-menu-link:focus,
.nk-menu-main .nk-menu-item.active > .nk-menu-link,
.nk-menu-main .nk-menu-item.current-menu > .nk-menu-link,
.nk-menu-main .nk-menu-item:hover > .nk-menu-link {
  color: #00c4fb;
}
.nk-menu-main > li > .nk-menu-link {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  letter-spacing: -0.01rem;
}
@media (max-width: 991.98px) {
  .nk-menu-main.nk-menu {
    max-height: calc(100vh - 65px);
    overflow: auto;
  }
}
@media (min-width: 992px) {
  .nk-menu-main.nk-menu {
    padding: 0 0.5rem 0 0.5rem;
  }
  .nk-menu-main > li {
    padding: 0;
    display: inline-block;
  }
  .nk-menu-main > li.nk-menu-item {
    padding: 0 0.75rem;
  }
  .nk-menu-main > li.nk-menu-item > .nk-menu-link {
    padding: 0.5rem 0;
    font-size: 14px;
  }
  .nk-menu-main .nk-menu-link {
    padding: 0.5rem 1.25rem;
    font-size: 13px;
  }
  .nk-menu-main .nk-menu-icon {
    display: none;
  }
  .nk-menu-main .nk-menu-text {
    line-height: 1.5rem;
  }
  .nk-menu-main .nk-menu-item .nk-menu-toggle:after {
    line-height: 1;
    position: static;
    transform: translateY(0);
    content: "";
    vertical-align: middle;
    color: rgba(128, 148, 174, 0.7);
    margin-left: 0.5rem;
    margin-right: -2px;
    margin-top: -1px;
    transition: color 0.1s;
  }
  .nk-menu-main .nk-menu-item:hover > .nk-menu-sub {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
  }
  .nk-menu-main .nk-menu-sub {
    position: absolute;
    left: 0;
    top: 100%;
    width: 200px;
    background: #fff;
    border: 1px solid #e5e9f2;
    box-shadow: 0 0.125rem 0.25rem rgba(43, 55, 72, 0.15);
    border-radius: 4px;
    padding: 0.5rem 0;
    opacity: 0;
    visibility: hidden;
    margin-top: 6px;
    transition: opacity 0.4s, margin 0.2s;
    display: block !important;
  }
  .nk-menu-main .nk-menu-sub .nk-menu-link {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .nk-menu-main .nk-menu-sub .nk-menu-sub {
    top: -9px;
    padding: 0.5rem 0;
    border: 1px solid #e5e9f2;
    margin: 0;
    margin-top: 6px;
    left: 100%;
    margin-left: -0.75rem;
  }
  .nk-menu-main .nk-menu-sub .nk-menu-sub .nk-menu-link {
    padding-left: 1.5rem;
  }
  .nk-menu-main .nk-menu-sub .nk-menu-item:hover > a {
    background: #f5f6fa;
  }
  .nk-menu-main .nk-menu-sub .nk-menu-toggle:after {
    content: "";
  }
  .is-dark .nk-menu-main > li > .nk-menu-link:before,
  .is-theme .nk-menu-main > li > .nk-menu-link:before {
    border-radius: 0;
    bottom: auto;
    top: 0;
  }
  .is-dark .nk-menu-main > li > .nk-menu-link {
    color: #9faec2;
  }
  .is-dark .nk-menu-main > li > .nk-menu-link.nk-menu-toggle:after {
    color: rgba(128, 148, 174, 0.7);
  }
  .is-dark .nk-menu-main > li:hover > .nk-menu-link,
  .is-dark .nk-menu-main > li.active > .nk-menu-link,
  .is-dark .nk-menu-main > li.current-menu > .nk-menu-link,
  .is-dark .nk-menu-main > li > .nk-menu-link:hover {
    color: #fff;
  }
  .is-theme .nk-menu-main > li > .nk-menu-link {
    color: #ab89f2;
  }
  .is-theme .nk-menu-main > li > .nk-menu-link.nk-menu-toggle:after {
    color: rgba(171, 137, 242, 0.7);
  }
  .is-theme .nk-menu-main > li:hover > .nk-menu-link,
  .is-theme .nk-menu-main > li.active > .nk-menu-link,
  .is-theme .nk-menu-main > li.current-menu > .nk-menu-link,
  .is-theme .nk-menu-main > li > .nk-menu-link:hover {
    color: #fff;
  }
}
@media (min-width: 1200px) {
  .nk-menu-main.nk-menu {
    padding: 0 1.5rem 0 0;
    margin: 0 -1rem;
  }
  .nk-menu-main > li.nk-menu-item {
    padding: 0 1rem;
  }
}
.nk-footer {
  margin-top: auto;
  background: rgba(0, 0, 0, 0);
  padding: 8px 6px;
}
.nk-footer.is-dark:not([class*="bg-"]) {
  background: #150b2b;
}
.nk-footer-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: -0.25rem -0.75rem;
}
.nk-footer-wrap > * {
  padding: 0.25rem 0.75rem;
}
.nk-footer-copyright {
  color: #8094ae;
}
.nk-footer-copyright a {
  color: inherit;
}
.nk-footer-copyright a:hover {
  color: #11c8fc;
}
@media (min-width: 576px) {
  .nk-footer {
    padding: 8px 22px;
  }
}
@media (min-width: 992px) {
  .nk-footer-fluid {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (min-width: 1660px) {
  .nk-footer-fluid {
    padding-left: 44px;
    padding-right: 44px;
  }
}
.nk-split {
  position: relative;
  display: flex;
}
.nk-split-page {
  min-height: 100vh;
}
.nk-split-content {
  position: relative;
  flex-shrink: 0;
  min-height: 100%;
}
@media (min-width: 768px) {
  .nk-split-content-md {
    width: 642px;
  }
}
.nk-split-stretch {
  flex-grow: 1;
  flex-shrink: 1;
}
@media (max-width: 991.98px) {
  .nk-split-stretch.toggle-break-lg {
    width: 460px;
  }
}
@media (max-width: 575.98px) {
  .nk-split-sm {
    flex-wrap: wrap;
  }
  .nk-split-sm > * {
    width: 100% !important;
  }
}
@media (max-width: 767.98px) {
  .nk-split-md {
    flex-wrap: wrap;
  }
  .nk-split-md > * {
    width: 100% !important;
  }
}
@media (max-width: 991.98px) {
  .nk-split-lg {
    flex-wrap: wrap;
  }
  .nk-split-lg > * {
    width: 100% !important;
  }
}
@media (max-width: 1199.98px) {
  .nk-split-xl {
    flex-wrap: wrap;
  }
  .nk-split-xl > * {
    width: 100% !important;
  }
}
@media (max-width: 1539.98px) {
  .nk-split-xxl {
    flex-wrap: wrap;
  }
  .nk-split-xxl > * {
    width: 100% !important;
  }
}
canvas {
  width: 100%;
  max-width: 100%;
}
a:hover {
  text-decoration: none;
}
p:last-child {
  margin-bottom: 0;
}
.del {
  text-decoration: line-through;
}
span[data-toggle="tooltip"]:focus {
  outline: none;
}
li {
  list-style: none;
}
.g-0:not(.row) {
  margin: 0;
}
.g-0:not(.row) > li,
.g-0:not(.row) > div {
  padding: 0;
}
.gx-0:not(.row) {
  margin-left: 0;
  margin-right: 0;
}
.gx-0:not(.row) > li,
.gx-0:not(.row) > div {
  padding-left: 0;
  padding-right: 0;
}
.gy-0:not(.row) {
  margin-top: 0;
  margin-bottom: 0;
}
.gy-0:not(.row) > li,
.gy-0:not(.row) > div {
  padding-top: 0;
  padding-bottom: 0;
}
.g-1:not(.row) {
  margin: -0.1875rem;
}
.g-1:not(.row) > li,
.g-1:not(.row) > div {
  padding: 0.1875rem;
}
.gx-1:not(.row) {
  margin-left: -0.1875rem;
  margin-right: -0.1875rem;
}
.gx-1:not(.row) > li,
.gx-1:not(.row) > div {
  padding-left: 0.1875rem;
  padding-right: 0.1875rem;
}
.gy-1:not(.row) {
  margin-top: -0.1875rem;
  margin-bottom: -0.1875rem;
}
.gy-1:not(.row) > li,
.gy-1:not(.row) > div {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
}
.g-2:not(.row) {
  margin: -0.375rem;
}
.g-2:not(.row) > li,
.g-2:not(.row) > div {
  padding: 0.375rem;
}
.gx-2:not(.row) {
  margin-left: -0.375rem;
  margin-right: -0.375rem;
}
.gx-2:not(.row) > li,
.gx-2:not(.row) > div {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.gy-2:not(.row) {
  margin-top: -0.375rem;
  margin-bottom: -0.375rem;
}
.gy-2:not(.row) > li,
.gy-2:not(.row) > div {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.g-3:not(.row) {
  margin: -0.5rem;
}
.g-3:not(.row) > li,
.g-3:not(.row) > div {
  padding: 0.5rem;
}
.gx-3:not(.row) {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.gx-3:not(.row) > li,
.gx-3:not(.row) > div {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.gy-3:not(.row) {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
.gy-3:not(.row) > li,
.gy-3:not(.row) > div {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.g-4:not(.row) {
  margin: -0.75rem;
}
.g-4:not(.row) > li,
.g-4:not(.row) > div {
  padding: 0.75rem;
}
.gx-4:not(.row) {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.gx-4:not(.row) > li,
.gx-4:not(.row) > div {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.gy-4:not(.row) {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}
.gy-4:not(.row) > li,
.gy-4:not(.row) > div {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.g-5:not(.row) {
  margin: -1.375rem;
}
.g-5:not(.row) > li,
.g-5:not(.row) > div {
  padding: 1.375rem;
}
.gx-5:not(.row) {
  margin-left: -1.375rem;
  margin-right: -1.375rem;
}
.gx-5:not(.row) > li,
.gx-5:not(.row) > div {
  padding-left: 1.375rem;
  padding-right: 1.375rem;
}
.gy-5:not(.row) {
  margin-top: -1.375rem;
  margin-bottom: -1.375rem;
}
.gy-5:not(.row) > li,
.gy-5:not(.row) > div {
  padding-top: 1.375rem;
  padding-bottom: 1.375rem;
}
.g-gs:not(.row) {
  margin: -14px;
}
.g-gs:not(.row) > li,
.g-gs:not(.row) > div {
  padding: 14px;
}
.gx-gs:not(.row) {
  margin-left: -14px;
  margin-right: -14px;
}
.gx-gs:not(.row) > li,
.gx-gs:not(.row) > div {
  padding-left: 14px;
  padding-right: 14px;
}
.gy-gs:not(.row) {
  margin-top: -14px;
  margin-bottom: -14px;
}
.gy-gs:not(.row) > li,
.gy-gs:not(.row) > div {
  padding-top: 14px;
  padding-bottom: 14px;
}
.gap {
  width: 100%;
  display: block;
  height: 28px;
}
.gap-0 {
  height: 0;
}
.gap-1 {
  height: 0.375rem;
}
.gap-2 {
  height: 0.75rem;
}
.gap-3 {
  height: 1rem;
}
.gap-4 {
  height: 1.5rem;
}
.gap-5 {
  height: 2.75rem;
}
.gap-gs {
  height: 28px;
}
.gap-sm {
  height: 0.75rem;
}
.gap-md {
  height: 1.25rem;
}
.gap-lg {
  height: 2rem;
}
.gap-xl {
  height: 2.5rem;
}
.gap-10px {
  height: 10px;
}
.gap-20px {
  height: 20px;
}
.gap-30px {
  height: 30px;
}
.gap-40px {
  height: 40px;
}
.gap-50px {
  height: 50px;
}
.gap-60px {
  height: 60px;
}
.gap-70px {
  height: 70px;
}
.gap-80px {
  height: 80px;
}
.gap-90px {
  height: 90px;
}
.gap-100px {
  height: 100px;
}
.li-col2x > li {
  width: 50%;
  float: left;
}
.li-col3x > li {
  width: 33.3333333333%;
  float: left;
}
.li-col4x > li {
  width: 25%;
  float: left;
}
.alert-cta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.alert-actions {
  display: inline-flex;
  align-items: center;
}
.alert-actions a + a {
  margin-left: 1rem;
}
.accordion {
  border-radius: 4px;
  background: #fff;
}
.accordion-heading {
  padding-bottom: 1.5rem;
}
.accordion-body {
  border-radius: 4px;
  padding: 0;
}
.accordion-item {
  border: none;
}
.accordion-item:not(:last-child) .accordion-head {
  border-bottom: 1px solid #dbdfea;
}
.accordion-item:not(:last-child) .accordion-inner {
  border-bottom: 1px solid #dbdfea;
}
.accordion-item:last-child .accordion-inner {
  border-top: 1px solid #dbdfea;
}
.accordion-head {
  padding: 1rem 2.25rem 1rem 1.25rem;
  display: block;
  position: relative;
}
.accordion-head .title {
  margin-bottom: 0;
  font-size: 1rem;
  color: #364a63;
  line-height: 1.3;
}
.accordion-head.collapsed .title {
  color: #526484;
}
.accordion-inner {
  padding: 1rem 1.25rem 1.25rem;
}
.accordion-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  font-size: 1rem;
  color: #364a63;
  transform: translateY(-50%);
  transition: rotate 0.4s;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
}
.accordion-icon:before {
  content: "";
  font-family: "Nioicon";
  transition: 0.4s;
}
.accordion-head.collapsed .accordion-icon:before {
  content: "";
}
@media (min-width: 768px) {
  .accordion-head {
    padding: 1rem 1.5rem;
  }
  .accordion-inner {
    padding: 1rem 1.5rem 1.25rem;
  }
  .accordion-icon {
    right: 1.5rem;
  }
}
.accordion-s2 {
  border: none;
}
.accordion-s2 .accordion-icon {
  right: 0;
}
.accordion-s2 .accordion-icon:before {
  content: "";
}
.accordion-s2 .accordion-head {
  padding: 0.5rem 2.25rem 0.5rem 0;
  border: none !important;
}
.accordion-s2 .accordion-head .title {
  font-size: 1.1rem;
  color: #11c8fc;
}
.accordion-s2 .accordion-head.collapsed .title {
  color: #526484;
}
.accordion-s2 .accordion-head.collapsed .accordion-icon:before {
  content: "";
}
.accordion-s2 .accordion-inner {
  padding: 0.5rem 0 0.625rem;
  border: none !important;
}
@media (min-width: 768px) {
  .accordion-s2 .accordion-head {
    padding: 0.5rem 2.25rem 0.5rem 0;
  }
  .accordion-s2 .accordion-inner {
    padding: 0.5rem 0 0.625rem;
  }
}
.accordion-s3 {
  border: none;
}
.accordion-s3 .accordion-icon {
  left: 0;
  right: auto;
}
.accordion-s3 .accordion-icon:before {
  content: "";
}
.accordion-s3 .accordion-head {
  padding: 0.5rem 0 0.5rem 2.25rem;
  border: none !important;
}
.accordion-s3 .accordion-head .title {
  font-size: 1.1rem;
  color: #11c8fc;
}
.accordion-s3 .accordion-head.collapsed .title {
  color: #526484;
}
.accordion-s3 .accordion-head.collapsed .accordion-icon:before {
  content: "";
}
.accordion-s3 .accordion-inner {
  padding: 0.5rem 0 0.625rem;
  border: none !important;
}
@media (min-width: 768px) {
  .accordion-s3 .accordion-head {
    padding: 0.5rem 0 0.5rem 2.25rem;
  }
  .accordion-s3 .accordion-inner {
    padding: 0.5rem 2.25rem 0.625rem;
  }
}
.dot {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}
.dot-label:after {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background: #fff;
  content: "";
  transform: scale(0.65);
}
.dot.sq {
  border-radius: 3px;
}
.dot.sq:after {
  border-radius: 3px;
}
.dot-xs {
  height: 4px;
  width: 4px;
}
.dot-sm {
  height: 6px;
  width: 6px;
}
.dot-md {
  height: 8px;
  width: 8px;
}
.dot-rg {
  height: 10px;
  width: 10px;
}
.dot-lg {
  height: 12px;
  width: 12px;
}
.dot-xl {
  height: 16px;
  width: 16px;
}
.dot-primary {
  background: #11c8fc;
}
.dot-secondary {
  background: #364a63;
}
.dot-success {
  background: #0fca7a;
}
.dot-info {
  background: #058efc;
}
.dot-warning {
  background: #fd9722;
}
.dot-danger {
  background: #f2426e;
}
.dot-light {
  background: #e5e9f2;
}
.dot-dark {
  background: #1f2b3a;
}
.dot-gray {
  background: #8091a7;
}
.dot-lighter {
  background: #f5f6fa;
}
.list:not(:last-child) {
  margin-bottom: 1rem;
}
.list li {
  position: relative;
  padding-left: 1.5rem;
  line-height: 1.5rem;
}
.list li:not(:last-child) {
  padding-bottom: 0.5rem;
}
.list li:before {
  position: absolute;
  left: 0;
  font-size: 14px;
  line-height: 1.5rem;
  font-family: "Nioicon";
  content: "";
}
.list li span {
  color: #8094ae;
}
.list li ul {
  margin-top: 0.5rem;
}
.list-checked li:before {
  color: #11c8fc;
  content: "";
}
.list-checked-circle li:before {
  color: #11c8fc;
  content: "";
}
.list-cross li:before {
  color: rgba(242, 66, 110, 0.8);
  content: "";
}
.list-status {
  list-style: none;
  line-height: 1.3;
}
.list-status li {
  display: inline-flex;
  align-items: center;
  padding-right: 1rem;
  vertical-align: middle;
}
.list-status .icon {
  margin-right: 0.375rem;
  line-height: 1.3;
}
.list-lg li {
  padding-left: 2rem;
}
.list-lg li:not(:last-child) {
  padding-bottom: 0.75rem;
}
.list-lg li:before {
  font-size: 1.35rem;
}
.list-sm li {
  padding-left: 1.2rem;
}
.list-sm li:not(:last-child) {
  padding-bottom: 0.35rem;
}
.list-sm li:before {
  font-size: 12px;
}
.list-step li {
  color: #8094ae;
  font-weight: 500;
}
.list-step li:before {
  content: "";
}
.list-step li.list-step-current {
  color: #364a63;
}
.list-step li.list-step-current:before {
  content: "";
  color: #11c8fc;
}
.list-step li.list-step-done {
  color: #526484;
}
.list-step li.list-step-done:before {
  content: "";
  color: #0fca7a;
}
.list-category a {
  font-weight: 500;
}
.list-download li > a {
  display: inline-block;
  padding: 0.25rem;
}
.list-download li > a > .icon {
  margin: 0 0.25rem;
  font-size: 1.25rem;
}
.list-styled {
  margin-bottom: 1rem;
}
.list-item {
  position: relative;
  padding: 0.25rem 1.5rem;
  color: #526484;
}
.list-item::before {
  position: absolute;
  font-family: "Nioicon";
  content: "";
  font-size: 1.125rem;
  left: 0;
  color: #526484;
  line-height: 1.25rem;
}
.list-apps {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem;
}
.list-apps + .list-apps {
  border-top: 1px solid #e5e9f2;
}
.list-apps li {
  width: 33.33%;
  padding: 0.5rem;
  text-align: center;
}
.list-apps a:hover .list-apps-title {
  color: #627a99;
}
.list-apps-media {
  display: inline-block;
  width: 36px;
  height: 36px;
  margin: 0.25rem auto 0.375rem;
}
.list-apps-media .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-size: 20px;
  color: #11c8fc;
  background: rgba(115, 58, 234, 0.3);
}
.list-apps-title {
  padding: 0.125rem;
  display: block;
  transition: color 0.4s;
  color: #364a63;
  font-size: 12px;
  font-weight: 500;
}
.list-apps-wrap {
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  background-color: #fff;
  width: 100%;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
  max-height: calc(100vh - (65px + 1px));
}
.list-apps-wrap.content-active {
  opacity: 1;
  visibility: visible;
}
.list-primary li:before {
  color: #11c8fc;
}
.list-secondary li:before {
  color: #364a63;
}
.list-success li:before {
  color: #0fca7a;
}
.list-info li:before {
  color: #058efc;
}
.list-warning li:before {
  color: #fd9722;
}
.list-danger li:before {
  color: #f2426e;
}
.list-light li:before {
  color: #e5e9f2;
}
.list-dark li:before {
  color: #1f2b3a;
}
.list-gray li:before {
  color: #8091a7;
}
.list-lighter li:before {
  color: #f5f6fa;
}
.link-bdr-t,
.link-list-plain li + li,
.link-list-opt li + li,
.link-list + .link-list,
.link-tidy li + li,
.link-check + .link-check {
  border-top: 1px solid #e5e9f2;
}
.link-bdr-b {
  border-bottom: 1px solid #e5e9f2;
}
.link-pd-sm,
.link-list-plain.sm a,
.link-tidy.sm li {
  padding: 6px 1rem;
}
.link-act-check:after,
.link-list-opt li.active > a:after,
.link-list-opt a.active:after,
.link-check li.active > a:after,
.link-check li a.active:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  font-family: "Nioicon";
}
.link-check {
  padding: 0.5rem 0;
}
.link-check .divider {
  margin: 0.5rem 0;
}
.link-check li {
  display: block;
}
.link-check li > span,
.link-check li a {
  display: block;
  line-height: 1.25rem;
  padding: 0.35rem 1rem;
}
.link-check li > span {
  text-transform: uppercase;
  color: #364a63;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1px;
}
.link-check li a {
  display: flex;
  align-items: center;
  color: #526484;
  font-size: 13px;
  position: relative;
}
.link-check li a:hover {
  color: #11c8fc;
  background: #f5f6fa;
}
.link-check li .icon {
  width: 1.75rem;
  font-size: 1.125rem;
}
.link-tidy.no-bdr {
  padding: 0.5rem 0;
}
.link-tidy.no-bdr li:not(.divider) {
  border-top-width: 0;
}
.link-tidy .divider {
  margin: 0.5rem 0;
}
.link-tidy li {
  position: relative;
  padding: 0.625rem 1.1rem;
  line-height: 1.3rem;
}
.link-list {
  padding: 0.75rem 0;
}
.link-list .divider {
  margin: 0.5rem 0;
}
.link-list a {
  display: flex;
  align-items: center;
  color: #526484;
  font-size: 13px;
  line-height: 1.4rem;
  font-weight: 500;
  padding: 0.575rem 0;
  position: relative;
}
.link-list a:hover {
  color: #11c8fc;
}
.link-list a .icon {
  font-size: 1.125rem;
  width: 1.5rem;
  line-height: 1;
}
.link-list-opt.no-bdr {
  padding: 0.5rem 0;
}
.link-list-opt.no-bdr li:not(.divider) {
  border-top-width: 0;
}
.link-list-opt .divider {
  margin: 0.5rem 0;
}
.link-list-opt a {
  display: flex;
  align-items: center;
  padding: 0.625rem 1.25rem;
  font-size: 12px;
  font-weight: 500;
  color: #526484;
  transition: all 0.4s;
  line-height: 1.3rem;
  position: relative;
}
.link-list-opt a:hover {
  color: #11c8fc;
  background: #f5f6fa;
}
.link-list-opt a .icon {
  font-size: 1.125rem;
  width: 1.75rem;
  opacity: 0.8;
}
.link-list-opt li.disabled > a,
.link-list-opt a.disabled {
  pointer-events: none;
  color: #8094ae;
  opacity: 0.7;
}
.link-list-opt .opt-head {
  padding: 0.25rem 1.25rem;
  color: #8094ae;
}
.link-list-opt.ui-v2 a {
  font-weight: 400;
}
.link-list-opt.no-bdr + .link-check {
  margin-top: -0.75rem;
}
.link-list-opt.ui-colored li.active > a,
.link-list-opt.ui-colored a.active {
  color: #11c8fc;
}
.link-list-menu {
  padding: 0.75rem 0;
}
.link-list-menu .divider {
  margin: 0.5rem 0;
}
.link-list-menu a {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
  font-weight: 500;
  color: #526484;
  transition: all 0.4s;
  line-height: 1.3rem;
}
.link-list-menu a:after {
  position: absolute;
  font-size: 1rem;
  font-family: "Nioicon";
  content: "";
  right: 1.25rem;
  color: #8094ae;
  transition: 0.3s ease;
}
.link-list-menu a .icon {
  font-size: 1.125rem;
  width: 1.75rem;
  opacity: 0.8;
  color: #8094ae;
  margin-top: -2px;
}
.link-list-menu li.active > a,
.link-list-menu a.active,
.link-list-menu a:hover,
.link-list-menu li.active > a .icon,
.link-list-menu a.active .icon,
.link-list-menu a:hover .icon,
.link-list-menu li.active > a:after,
.link-list-menu a.active:after,
.link-list-menu a:hover:after {
  color: #11c8fc;
}
.link-list-menu.nav {
  display: block;
}
@media (min-width: 576px) {
  .link-list-menu a {
    padding: 1rem 1.5rem;
  }
}
.link-list-plain.no-bdr {
  padding: 0.5rem 0;
}
.link-list-plain.no-bdr li {
  border-top-width: 0;
}
.link-list-plain .divider {
  margin: 0.5rem 0;
}
.link-list-plain a {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.25rem;
  color: #526484;
  transition: all 0.4s;
  line-height: 1.4rem;
  position: relative;
}
.link-list-plain a:hover {
  color: #11c8fc;
  background: #f5f6fa;
}
.link-list-plain a .icon {
  font-size: 1.125rem;
  width: 1.75rem;
  opacity: 0.8;
  color: #11c8fc;
}
.link-list-plain li.active > a,
.link-list-plain a.active {
  color: #11c8fc;
  background: #f5f6fa;
}
.link-list-plain.text-center a {
  justify-content: center;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.link-list-plain.li-col2x > li {
  border-right: 1px solid #e5e9f2;
  border-bottom: 1px solid #e5e9f2;
  border-top: 0;
}
.link-list-plain.li-col2x > li:nth-child(2n) {
  border-right: none;
}
.link-list-plain.li-col3x > li {
  border-right: 1px solid #e5e9f2;
  border-bottom: 1px solid #e5e9f2;
  border-top: 0;
}
.link-list-plain.li-col3x > li:nth-child(3n) {
  border-right: none;
}
.link-list-plain.li-col4x > li {
  border-right: 1px solid #e5e9f2;
  border-bottom: 1px solid #e5e9f2;
  border-top: 0;
}
.link-list-plain.li-col4x > li:nth-child(4n) {
  border-right: none;
}
.link-list-template a {
  font-weight: 400;
}
.passcode-icon {
  display: none;
}
.passcode-icon.icon-show {
  display: block;
}
.is-shown .passcode-icon.icon-show {
  display: none;
}
.is-hidden .passcode-icon.icon-show {
  display: block;
}
.is-shown .passcode-icon.icon-hide {
  display: block;
}
.is-hidden .passcode-icon.icon-hide {
  display: none;
}
.form-dropdown {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #8094ae;
}
.form-dropdown > div > span {
  margin: 0 0.5rem;
}
.form-dropdown .dropdown > a {
  display: inline-flex;
}
.form-clip,
.form-text-hint {
  position: absolute;
  right: 2px;
  top: 2px;
  height: calc(2.125rem - 2px);
  display: flex;
  align-items: center;
  color: #11c8fc;
  padding-left: 1rem;
  padding-right: 0.75rem;
  background: #fff;
  border-radius: 4px;
}
.form-clip.sm,
.form-text-hint.sm {
  height: calc(1.75rem + 2px);
}
.form-clip.lg,
.form-text-hint.lg {
  height: calc(2.625rem + 2px);
}
.form-clip.xl,
.form-text-hint.xl {
  height: calc(3.25rem + 2px);
}
.form-clip .icon + span,
.form-clip span + .icon,
.form-text-hint .icon + span,
.form-text-hint span + .icon {
  margin-left: 0.25rem;
}
.nk-upload-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.125rem + 2px);
  margin: 0;
  opacity: 0;
}
.nk-upload-label {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;
  min-height: 150px;
  border-radius: 4px;
  border: 1px dashed #e5e9f2;
  padding-bottom: 0;
}
.nk-upload-init {
  display: block;
  text-align: center;
}
.nk-upload-files {
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  margin: -0.5rem;
  padding-bottom: 2rem;
}
.nk-upload-files li {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0.5rem;
}
.nk-upload-files li img {
  width: 60px;
  flex-shrink: 0;
  border-radius: 4px;
}
.is-multiple .nk-upload-files li img {
  width: 40px;
}
.is-multiple .nk-upload-files li .nk-upload-name {
  display: none;
}
.nk-upload-name {
  margin: 0.5rem 0 0;
  color: #526484;
  font-size: 0.8125rem;
  font-weight: 500;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nk-upload-response {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
}
.nk-upload-response .message {
  font-size: 12px;
  color: #8094ae;
}
.nk-upload-response .message + .icon {
  margin-left: 0.5rem;
}
.nk-upload-response .icon:after {
  font-family: "Nioicon";
}
.nk-upload-response .icon + .message {
  margin-left: 0.5rem;
}
.nk-upload-response.invalid .icon {
  color: #fd9722;
}
.nk-upload-response.invalid .icon:after {
  content: "";
}
.nk-upload-response.valid .icon {
  color: #0fca7a;
}
.nk-upload-response.valid .icon:after {
  content: "";
}
.nk-upload-response.error .icon {
  color: #f2426e;
}
.nk-upload-response.error .icon:after {
  content: "";
}
.nk-upload-foot {
  display: flex;
  justify-content: space-between;
  border-top: 1px dashed #e5e9f2;
  padding: 1rem 1.25rem;
  margin: 0 -1.5rem;
}
.nk-upload-foot > span,
.nk-upload-foot > button {
  margin: 0.25rem;
}
.form-editor-custom textarea {
  border-radius: 4px 4px 0 0;
}
.form-editor-action {
  border: 1px solid #dbdfea;
  border-top: 0;
  border-radius: 0 0 4px 4px;
  padding: 0.25rem 0.5rem;
}
.form-editor-action > .link {
  padding: 0.5rem;
}
.form-editor-action > .link.collapsed {
  color: #8094ae;
}
.form-editor-action > .link .icon {
  margin-right: 0.25rem;
}
.form-editor-btn-group,
.form-btn-group {
  display: flex;
  align-items: center;
  margin: -0.25rem;
  flex-wrap: wrap;
}
.form-editor-btn-group li,
.form-btn-group li {
  padding: 0.25rem;
  line-height: 0.5rem;
}
.form-btn-group li:first-child {
  margin-right: 0.5rem;
}
.form-btn-secondary {
  margin-left: auto;
}
input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.number-spinner {
  padding-left: 3.125rem;
  padding-right: 3.125rem;
  text-align: center;
}
.number-spinner:focus {
  border-color: #dbdfea;
}
.number-spinner-btn {
  position: absolute;
  top: 0;
}
.number-plus {
  right: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.number-minus {
  left: 0;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.input-group-addon {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  background-color: #f5f6fa;
  border: 1px solid #dbdfea;
  font-size: 0.75rem;
  border-radius: 4px;
}
.input-group-addon:not(:last-child) {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-addon:not(:first-child) {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #3c4d62;
  transition: background-color 5000s ease-in-out 0s;
}
.page-title {
  font-family: Nunito, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
}
@media (min-width: 992px) {
  .page-title {
    font-size: 1.75rem;
  }
}
.overline-title {
  font-size: 11px;
  line-height: 1.2;
  letter-spacing: 0.2em;
  color: #8094ae;
  text-transform: uppercase;
  font-weight: 700;
  font-family: Roboto, sans-serif;
}
.text-light .overline-title {
  color: #8094ae;
}
.is-dark .overline-title {
  color: #b7c2d0;
}
.is-theme .overline-title {
  color: #ab89f2;
}
.overline-title-alt {
  font-family: Roboto, sans-serif;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.15em;
  font-size: 11px;
  color: #8094ae;
  text-transform: uppercase;
}
.is-dark .overline-title-alt {
  color: #8094ae;
}
.is-theme .overline-title-alt {
  color: #ab89f2;
}
.overline-title-sap {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  padding-left: 0.35rem;
  padding-right: 0.25rem;
  color: #b6c6e3;
}
.overline-title-sap:before,
.overline-title-sap:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 1px;
  content: "";
  background: #b6c6e3;
}
.overline-title-sap:before {
  right: 100%;
}
.overline-title-sap:after {
  left: 100%;
}
.caption-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: -0.01em;
}
.caption-text + .sub-text,
.caption-text + .sub-text-sm {
  margin-top: 0.5rem;
  display: block;
}
.lead-text {
  font-size: 0.875rem;
  font-weight: 700;
  color: #364a63;
  display: block;
}
.lead-text + .sub-text {
  font-size: 12px;
}
.lead-text span {
  color: #526484;
  font-weight: 400;
}
.lead-text-lg {
  font-size: 1.125rem;
  font-weight: 500;
  color: #364a63;
  display: block;
}
.is-theme .lead-text-lg {
  color: #fff;
}
.is-theme .is-light .lead-text-lg {
  color: #364a63;
}
.is-dark .lead-text {
  color: #b7c2d0;
}
.is-dark .lead-text span {
  color: #8091a7;
}
.is-theme .lead-text {
  color: #fff;
}
.is-theme .lead-text span {
  color: #ab89f2;
}
.is-theme .is-light .lead-text,
.is-dark .is-light .lead-text {
  color: #364a63;
}
.is-theme .is-light .lead-text span,
.is-dark .is-light .lead-text span {
  color: #526484;
}
.sub-text {
  display: block;
  font-size: 13px;
  color: #8094ae;
}
.sub-text span + .dot,
.sub-text .dot + span {
  margin-left: 0.25rem;
}
.sub-text-sm,
.sub-text + .sub-text {
  font-size: 12px;
}
.sub-text-sm {
  color: #8094ae;
}
.is-theme .sub-text-sm {
  color: #ab89f2;
}
.is-theme .is-light .sub-text-sm {
  color: #8094ae;
}
.text-ellipsis .sub-text {
  display: inline;
}
.sub-text strong {
  color: #526484;
  font-weight: 700;
}
.is-theme .sub-text strong {
  color: #ab89f2;
}
.is-theme .is-light .sub-text strong {
  color: #526484;
}
.sub-text span {
  white-space: nowrap;
}
.is-theme .sub-text {
  color: #ab89f2;
}
.is-theme .is-light .sub-text {
  color: #8094ae;
}
.text-date span {
  color: #526484;
}
h6 .badge,
.h6 .badge {
  margin-left: 0.5rem;
}
.fs-9px {
  font-size: 9px !important;
}
.fs-10px {
  font-size: 10px !important;
}
.fs-11px {
  font-size: 11px !important;
}
.fs-12px {
  font-size: 12px !important;
}
.fs-13px {
  font-size: 13px !important;
}
.fs-14px {
  font-size: 14px !important;
}
.fs-15px {
  font-size: 15px !important;
}
.fs-16px {
  font-size: 16px !important;
}
.fs-17px {
  font-size: 17px !important;
}
.fs-18px {
  font-size: 18px !important;
}
.fs-19px {
  font-size: 19px !important;
}
.fs-20px {
  font-size: 20px !important;
}
.fs-21px {
  font-size: 21px !important;
}
.fs-22px {
  font-size: 22px !important;
}
.note-text {
  font-size: 12px;
  font-style: italic;
  color: #8094ae;
}
.card .table {
  margin-bottom: 0;
}
.card .table tr:first-child th,
.card .table tr:first-child td {
  border-top: none;
}
.card > .table tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
}
.card > .table tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}
.card > .table tr:first-child th:first-child {
  border-top-left-radius: 4px;
}
.card > .table tr:first-child th:last-child {
  border-top-right-radius: 4px;
}
.card + .lead-text {
  margin-top: 2rem;
}
.lead-text + .card {
  margin-top: 1rem;
}
.card-custom-s1 .card-inner-group .card-inner {
  border-bottom: 1px solid #dbdfea;
}
@media (max-width: 991.98px) {
  .card-custom-s1 .card-inner-lg {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (min-width: 992px) {
  .card-custom-s1 .card-inner-group {
    border-right: 1px solid #dbdfea;
  }
  .card-custom-s1 .card-inner-group .card-inner:last-child {
    border-bottom: none;
  }
}
@media (min-width: 576px) {
  .card-search .card-body {
    padding: 1.5rem;
  }
}
.card-aside {
  width: 300px;
  background-color: #fff;
  min-height: 100vh;
  flex-shrink: 0;
}
.card-aside-left {
  order: -1;
  border-right: 1px solid #e5e9f2;
}
.card-aside-right {
  order: 100;
  border-left: 1px solid #e5e9f2;
}
.card-aside-wrap {
  display: flex;
}
.card-aside-wrap .card-inner,
.card-aside-wrap .card-content {
  flex-grow: 1;
}
@media (max-width: 767.98px) {
  .toggle-break-md.card-aside .card-inner-group {
    max-height: 100vh;
  }
}
@media (max-width: 991.98px) {
  .toggle-break-lg.card-aside .card-inner-group {
    max-height: 100vh;
  }
}
@media (max-width: 1199.98px) {
  .toggle-break-xl.card-aside .card-inner-group {
    max-height: 100vh;
  }
}
@media (max-width: 1539.98px) {
  .toggle-break-xxl.card-aside .card-inner-group {
    max-height: 100vh;
  }
}
@media (min-width: 992px) {
  .card-aside {
    position: static;
    display: block;
  }
}
@media (min-width: 1540px) {
  .card-aside {
    width: 380px;
  }
}
.user-avatar,
[class^="user-avatar"]:not([class*="-group"]) {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #11c8fc;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.06em;
  flex-shrink: 0;
  position: relative;
}
.user-avatar + .user-info,
[class^="user-avatar"]:not([class*="-group"]) + .user-info {
  margin-left: 1rem;
}
.user-avatar + .user-name,
[class^="user-avatar"]:not([class*="-group"]) + .user-name {
  margin-left: 0.75rem;
}
.user-avatar img,
[class^="user-avatar"]:not([class*="-group"]) img {
  border-radius: 50%;
}
.user-avatar.md + .user-info,
[class^="user-avatar"]:not([class*="-group"]).md + .user-info {
  margin-left: 1.5rem;
}
.user-avatar.md + .user-name,
[class^="user-avatar"]:not([class*="-group"]).md + .user-name {
  margin-left: 1.25rem;
}
.user-avatar .edit,
[class^="user-avatar"]:not([class*="-group"]) .edit {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  background-color: rgba(31, 43, 58, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s;
}
.user-avatar:hover .edit,
[class^="user-avatar"]:not([class*="-group"]):hover .edit {
  opacity: 1;
}
.user-avatar .status,
[class^="user-avatar"]:not([class*="-group"]) .status {
  border: 2px solid #fff;
  position: absolute;
  bottom: 0;
  right: 0;
}
.user-avatar.sq,
[class^="user-avatar"]:not([class*="-group"]).sq {
  border-radius: 4px;
}
.user-avatar.sq img,
[class^="user-avatar"]:not([class*="-group"]).sq img {
  border-radius: 4px;
}
.user-avatar.sq .status,
[class^="user-avatar"]:not([class*="-group"]).sq .status {
  bottom: -6px;
  right: -6px;
}
.user-avatar-multiple {
  background-color: rgba(0, 0, 0, 0) !important;
}
.user-avatar-multiple .user-avatar {
  position: absolute;
  height: 70%;
  width: 70%;
  font-size: 80%;
  border: 2px solid #fff;
}
.user-avatar-multiple .user-avatar:nth-child(1) {
  top: 0;
  right: 0;
}
.user-avatar-multiple .user-avatar:nth-child(2) {
  bottom: 0;
  left: 0;
}
.user-card .user-avatar.d-none {
  margin-right: 1rem;
}
.user-card .user-avatar.d-none + .user-info,
.user-card .user-avatar.d-none + .user-name {
  margin-left: 0;
}
.user-avatar-xl,
.user-avatar.xl {
  height: 110px;
  width: 110px;
  font-size: 40px;
  font-weight: 400;
}
.user-avatar-xl .status,
.user-avatar.xl .status {
  bottom: 9px;
  right: 9px;
}
.user-avatar-lg,
.user-avatar.lg {
  height: 80px;
  width: 80px;
  font-size: 28px;
  font-weight: 400;
}
.user-avatar-lg .status,
.user-avatar.lg .status {
  bottom: 5px;
  right: 5px;
}
.user-avatar-md,
.user-avatar.md {
  height: 60px;
  width: 60px;
  font-size: 18px;
}
.user-avatar-md .status,
.user-avatar.md .status {
  bottom: 2px;
  right: 2px;
}
.user-avatar-sm,
.user-avatar.sm {
  height: 32px;
  width: 32px;
  font-size: 12px;
}
.user-avatar-sm .status,
.user-avatar.sm .status {
  bottom: -2px;
  right: -2px;
}
.user-avatar-xs,
.user-avatar.xs {
  height: 26px;
  width: 26px;
  font-size: 9px;
  font-weight: 700;
}
.user-avatar-xs .status,
.user-avatar.xs .status {
  bottom: -2px;
  right: -2px;
}
.user-avatar[class*="-blue-dim"] {
  color: #559bfb;
}
.user-avatar[class*="-azure-dim"] {
  color: #1676fb;
}
.user-avatar[class*="-indigo-dim"] {
  color: #323f9e;
}
.user-avatar[class*="-purple-dim"] {
  color: #816bff;
}
.user-avatar[class*="-pink-dim"] {
  color: #ff63a5;
}
.user-avatar[class*="-orange-dim"] {
  color: #ffa353;
}
.user-avatar[class*="-teal-dim"] {
  color: #20c997;
}
.user-avatar[class*="-primary-dim"] {
  color: #11c8fc;
}
.user-avatar[class*="-secondary-dim"] {
  color: #364a63;
}
.user-avatar[class*="-success-dim"] {
  color: #0fca7a;
}
.user-avatar[class*="-info-dim"] {
  color: #058efc;
}
.user-avatar[class*="-warning-dim"] {
  color: #fd9722;
}
.user-avatar[class*="-danger-dim"] {
  color: #f2426e;
}
.user-avatar[class*="-dark-dim"] {
  color: #1f2b3a;
}
.user-avatar[class*="-gray-dim"] {
  color: #8091a7;
}
.user-avatar[class*="-lighter"] {
  color: #8094ae;
}
.user-avatar[class*="-light"] {
  color: #526484;
}
.user-card {
  display: flex;
  align-items: center;
}
.user-card-wrap {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}
.user-card .user-info {
  color: #8094ae;
}
.user-card-menu {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.user-toggle {
  display: flex;
  align-items: center;
  padding: 0 1px 1px;
}
.user-status {
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  padding-top: 0.125rem;
  padding-bottom: 0.375rem;
}
.user-status-unverified {
  color: #f2426e;
}
.user-status-verified {
  color: #0fca7a;
}
.user-name {
  color: #526484;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
}
.is-dark .user-name {
  color: #90a1b8;
}
.is-theme .user-name {
  color: #ab89f2;
}
.user-action {
  margin-left: auto;
  font-size: 20px;
  color: #8094ae;
}
.user-account-info {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}
.user-account-info:first-child {
  padding-top: 0.5rem;
}
.nk-profile-content .user-account-info:first-child {
  padding-top: 1.5rem;
}
.user-account-data li {
  display: flex;
  justify-content: space-between;
  padding: 0.125rem 0;
}
.user-account-actions:not(:first-child) {
  margin-top: 1.5rem;
}
.user-account-actions ul {
  display: flex;
}
.user-account-actions ul li {
  width: 44%;
}
.user-account-actions ul li:last-child {
  width: 56%;
}
.user-account-actions .btn,
.user-account-actions .actions ul li a,
.actions ul li .user-account-actions a,
.user-account-actions .dual-listbox .dual-listbox__button,
.dual-listbox .user-account-actions .dual-listbox__button {
  width: 100%;
  font-size: 12px;
  letter-spacing: 0.04em;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-transform: uppercase;
}
.user-account-links {
  padding: 1rem 0;
  border-bottom: 1px solid #e5e9f2;
}
.user-account-info .user-account-links:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.user-account-info + .user-account-links {
  padding-top: 0;
  margin-top: -0.5rem;
}
.user-account-links a:link {
  padding: 0.5rem 0;
  display: flex;
}
.user-account-value {
  display: inline-flex;
}
.user-balance {
  font-size: 1.5rem;
  line-height: 1;
  padding: 0.125rem 0 0.375rem;
  color: #11c8fc;
}
.user-balance small,
.user-balance .small {
  font-size: 1rem;
}
.user-balance-alt {
  font-size: 15px;
  color: #526484;
  font-weight: 700;
}
.user-balance-alt span {
  font-weight: 400;
}
.user-balance-sub {
  font-size: 13px;
  color: #8094ae;
}
.user-balance-sub span {
  color: #526484;
}
.user-balance-sub + a {
  margin-top: 1rem;
}
.user-card-s2 {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0.5rem;
}
.user-card-s2 .user-avatar {
  margin: 0 auto;
}
.user-card-s2 .user-info {
  margin: 1.25rem 0 0 !important;
}
.user-card-s2 .user-info .sub-text {
  justify-content: center;
}
.user-card-s2 .user-info .badge {
  margin-bottom: 1rem;
}
.user-card-md .lead-text {
  font-size: 1rem;
}
.user-card-md .sub-text {
  font-size: 0.875rem;
}
.user-activity {
  display: flex;
}
.user-activity-group {
  display: flex;
  flex-wrap: wrap;
}
.user-activity-ck {
  height: 110px;
  padding: 0.5rem 0.375rem 0;
}
.user-activity .icon {
  width: 2.25rem;
  font-size: 1.5rem;
  color: #8094ae;
}
.user-activity .info span {
  display: block;
}
.user-activity .amount {
  font-size: 1.125rem;
  line-height: 1.3;
  font-weight: 500;
  color: #526484;
}
.user-activity .title {
  font-size: 12px;
  color: #8094ae;
}
.user-activity .gfx {
  align-self: flex-end;
  width: 50px;
  margin-left: 1rem;
  margin-bottom: 0.25rem;
}
.user-contacts li {
  color: #526484;
  display: flex;
  font-size: 0.875rem;
  line-height: 1.4rem;
  padding: 0.375rem 0;
}
.user-contacts li .icon {
  color: #8094ae;
  font-size: 1rem;
  width: 1.75rem;
  line-height: inherit;
  display: inline-block;
}
.user-avatar-group {
  display: flex;
}
.user-avatar-group .user-avatar {
  border: 2px solid #fff;
}
.user-avatar-group .user-avatar:not(:first-child) {
  margin-left: -20%;
}
.user-avatar-group .user-avatar.sm:not(:first-child) {
  margin-left: -0.625rem;
}
.user-avatar-group .user-avatar.xs:not(:first-child) {
  margin-left: -0.5rem;
}
.user-info .lead-text,
.user-info .sub-text {
  display: flex;
  align-items: center;
}
.user-info .edit {
  font-size: 1.25rem;
  color: #8094ae;
  margin-left: 0.5rem;
  display: inline-flex;
}
.user-info .edit:hover {
  color: #11c8fc;
}
.user-plan {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.75rem 0;
}
.user-plan-title {
  display: flex;
  align-items: center;
  color: #364a63;
  font-weight: 700;
  font-size: 1rem;
}
.user-plan-title .icon {
  font-size: 1.5rem;
  margin-right: 0.75rem;
  color: #11c8fc;
}
.user-plan-info,
.user-plan-actions {
  padding: 0.5rem 0;
}
.user-plan-status {
  font-size: 13px;
  font-weight: 500;
  color: #8094ae;
  text-transform: uppercase;
  margin-top: 0.5rem;
}
.user-plan-progress {
  width: 100%;
  margin-top: 1rem;
}
.data-list:not(:first-child) {
  margin-top: 1.5rem;
}
.data-item {
  position: relative;
  padding: 1rem 1.25rem;
  display: flex;
  align-items: center;
}
.data-item .icon {
  color: #8094ae;
  text-align: center;
  height: 32px;
  vertical-align: middle;
  line-height: 32px;
  display: inline-block;
}
.data-item:not(:last-child) {
  border-bottom: 1px solid #e5e9f2;
}
.data-item[data-toggle] {
  cursor: pointer;
}
.data-item:hover .data-label {
  color: #526484;
}
.data-item:hover .data-value,
.data-item:hover .icon {
  color: #364a63;
}
.data-head {
  padding: 0.5rem 1.25rem;
  margin-bottom: 0.25rem;
  background-color: #ebeef2;
  border-radius: 4px;
}
.data-head.is-plain {
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0);
}
.data-head.is-plain:first-child {
  margin-top: 1rem;
}
.data-head .title {
  color: #526484;
}
.data-col {
  flex-grow: 1;
}
.data-col span:not(.data-more) {
  display: inline-block;
  transition: color 0.3s;
}
.data-col-end {
  flex-grow: 0;
  margin-left: auto;
}
.data-label {
  color: #8094ae;
  width: 100%;
  flex-shrink: 0;
}
.data-value {
  color: #526484;
  font-size: 0.9375rem;
}
.data-more {
  position: relative;
  z-index: 4;
  text-align: center;
  line-height: 28px;
  height: 28px;
  width: 28px;
  display: inline-grid;
  align-content: center;
}
.data-more:before {
  position: absolute;
  z-index: -1;
  height: 20px;
  width: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  content: "";
  background-color: #e5e9f2;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.3s;
}
.data-item:hover .data-more:before {
  opacity: 1;
  height: 40px;
  width: 40px;
}
.data-more.disable:before {
  display: none;
}
.data-list-s2:not(:first-child) {
  margin-top: 1rem;
}
.data-list-s2 .data-item,
.data-list-s2 .data-head {
  padding-left: 0;
  padding-right: 0;
  background: rgba(0, 0, 0, 0);
}
.data-list-s2 .data-head {
  border-bottom: 1px solid #dbdfea;
}
.data-list-s2 .data-item:not(:last-child) {
  border-bottom-color: #dbdfea;
}
.data-list-s2 .data-label {
  color: #526484;
}
.data-list-s2 .data-placeholder {
  color: #8094ae;
}
@media (min-width: 768px) {
  .data-list:not(:first-child) {
    margin-top: 2rem;
  }
  .data-list-s2:not(:first-child) {
    margin-top: 1.25rem;
  }
  .data-item {
    padding: 1.5rem 1.25rem;
  }
  .data-col {
    display: flex;
    align-items: center;
  }
  .data-col-end {
    width: 200px;
    text-align: right;
    justify-content: flex-end;
  }
  .data-label:not([class^="w-"]) {
    width: 50%;
  }
}
@media (min-width: 1200px) and (max-width: 1359px) {
  .card-aside-wrap .data-list .data-col {
    flex-wrap: wrap;
  }
  .card-aside-wrap .data-list .data-label {
    width: 100%;
  }
}
.is-compact .data-item {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.is-medium .data-item {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}
.dropdown-menu .nk-notification {
  max-height: 239px;
  overflow: auto;
}
.nk-notification-item {
  display: flex;
  align-items: center;
  padding: 1.25rem 1.75rem;
}
.nk-notification-icon {
  flex-shrink: 0;
  margin-right: 0.75rem;
}
.nk-notification-text {
  font-size: 13px;
  color: #526484;
}
.nk-notification-text span {
  color: #364a63;
}
.nk-notification-time {
  font-size: 11px;
  color: #8094ae;
}
.dropdown-menu .nk-chat-list,
.dropdown-menu .chat-list {
  max-height: 274px;
  overflow: auto;
}
.icon-light {
  color: #fff;
}
.icon-dark {
  color: #364a63;
}
.icon-text {
  display: flex;
  align-items: center;
  font-size: 13px;
}
.icon-text .icon {
  font-size: 1.5em;
  width: 1.75rem;
}
.icon-image {
  width: 70px;
}
.icon-image + h6,
.icon-image + .h6 {
  margin-top: 1rem;
}
.icon-circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  background: #11c8fc;
  color: #fff;
  font-size: 18px;
  height: 36px;
  width: 36px;
}
.icon-circle-sm,
.icon-circle.sm {
  height: 20px;
  width: 20px;
  font-size: 12px;
}
.icon-circle-md,
.icon-circle.md {
  height: 28px;
  width: 28px;
  font-size: 14px;
}
.icon-circle-lg,
.icon-circle.lg {
  height: 44px;
  width: 44px;
  font-size: 24px;
}
.icon-circle-xxl,
.icon-circle.xxl {
  height: 80px;
  width: 80px;
  font-size: 40px;
}
.icon-status {
  position: relative;
  display: inline-flex;
}
.icon-status:after {
  position: absolute;
  border-radius: 50%;
  right: 0;
  top: 1px;
  height: 10px;
  width: 10px;
  border: 2px solid #fff;
  content: "";
}
.icon-status-info:after {
  background: #058efc;
}
.icon-status-danger:after {
  background: #f2426e;
}
.icon-status-success:after {
  background: #0fca7a;
}
.icon-status-warning:after {
  background: #fd9722;
}
.icon-status-on:after {
  background: #0fca7a;
}
.icon-status-off:after {
  background: #8094ae;
}
.icon-status-na:after {
  display: none;
}
.icon-overlap {
  display: inline-flex;
  align-items: center;
  flex-direction: row-reverse;
  flex-shrink: 0;
}
.icon-overlap li {
  border-radius: 50%;
  border: 2px solid #fff;
}
.icon-overlap li + li {
  margin-right: -14px;
}
.icon-overlap-alt {
  display: inline-flex;
  align-items: flex-end;
  flex-shrink: 0;
}
.icon-overlap-alt li {
  border-radius: 50%;
}
.icon-overlap-alt li + li {
  margin-left: -16px;
  margin-bottom: -10px;
}
.icon-circle[class*="btc"] {
  background: #f9841e;
}
.icon-circle[class*="facebook"] {
  background: #337fff;
}
.icon-circle[class*="google"] {
  background: #de5246;
}
.icon-circle[class*="btc-dim"] {
  background: #feefe2;
}
.icon-circle[class*="eth-dim"] {
  background: #eaedf6;
}
.icon-circle[class*="white"] {
  background: #fff;
}
.icon[class*="bg-blue-dim"] {
  color: #559bfb;
}
.icon[class*="bg-azure-dim"] {
  color: #1676fb;
}
.icon[class*="bg-indigo-dim"] {
  color: #323f9e;
}
.icon[class*="bg-purple-dim"] {
  color: #816bff;
}
.icon[class*="bg-pink-dim"] {
  color: #ff63a5;
}
.icon[class*="bg-orange-dim"] {
  color: #ffa353;
}
.icon[class*="bg-teal-dim"] {
  color: #20c997;
}
.icon[class*="bg-primary-dim"] {
  color: #11c8fc;
}
.icon[class*="bg-secondary-dim"] {
  color: #364a63;
}
.icon[class*="bg-success-dim"] {
  color: #0fca7a;
}
.icon[class*="bg-info-dim"] {
  color: #058efc;
}
.icon[class*="bg-warning-dim"] {
  color: #fd9722;
}
.icon[class*="bg-danger-dim"] {
  color: #f2426e;
}
.icon[class*="bg-light"] {
  color: #526484;
}
.icon[class*="bg-lighter"] {
  color: #8094ae;
}
.icon[class*="bg-dark-dim"] {
  color: #dde2ea;
}
.icon[class*="bg-gray-dim"] {
  color: #e9f0f9;
}
.icon[class*="bg-facebook-dim"] {
  color: #337fff;
}
.icon[class*="bg-google-dim"] {
  color: #de5246;
}
.icon[class*="bg-btc-dim"] {
  color: #f9841e;
}
.icon[class*="bg-eth-dim"] {
  color: #6174b9;
}
.icon[class*="bg-white"] {
  color: #526484;
}
.icon-avatar {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  text-align: center;
  line-height: 1.5rem;
  border-radius: 50%;
  background: #11c8fc2e;
  color: #11c8fc;
  margin-right: 0.5rem;
  font-size: 12px;
}
.icon-avatar-md {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 14px;
}
.tb-col-hide,
.tb-col-xxl,
.tb-col-xl,
.tb-col-lg,
.tb-col-md,
.tb-col-sm,
.tb-col-xs,
.tb-col-mb {
  display: none !important;
}
@media (min-width: 576px) {
  .tb-col-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) {
  .tb-col-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) {
  .tb-col-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .tb-col-xl {
    display: table-cell !important;
  }
}
@media (min-width: 1540px) {
  .tb-col-xxl {
    display: table-cell !important;
  }
}
@media (min-width: 359px) {
  .tb-col-xs {
    display: table-cell !important;
  }
}
@media (min-width: 414px) {
  .tb-col-mb {
    display: table-cell !important;
  }
}
.table-middle td,
.tb-row-middle td {
  vertical-align: middle;
}
.tb-col-end,
.tb-col-action {
  text-align: right;
}
.tb-col-middle {
  vertical-align: middle;
}
.tb-col-action .link-cross,
.tb-col-action .link-done {
  font-size: 1rem;
  text-align: center;
  line-height: 28px;
  height: 24px;
  width: 24px;
  display: inline-block;
  color: #8094ae;
  vertical-align: middle;
  border-radius: 12px;
}
.tb-col-action .link-cross .icon,
.tb-col-action .link-done .icon {
  padding-left: 1px;
}
.tb-col-action .link-cross:hover {
  color: #f2426e;
  background: #fde5eb;
}
.tb-col-action .link-done:hover {
  color: #0fca7a;
  background: #ddf8ec;
}
@media (max-width: 575.98px) {
  .table-ulogs th.tb-col-ip {
    display: none;
  }
  .table-ulogs tr {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    padding: 0.5rem 1.25rem;
  }
  .table-ulogs tr:not(:first-child) {
    border-top: 1px solid #dbdfea;
  }
  .table-ulogs tr td {
    padding: 0;
    border: 0;
  }
  .table-ulogs tr td:first-child,
  .table-ulogs tr td:last-child {
    padding: 0;
  }
  .table-ulogs tr td,
  .table-ulogs tr th {
    flex-grow: 1;
  }
  .table-ulogs tr td.tb-col-os {
    width: 100%;
  }
  .table-ulogs tr th.tb-col-os {
    width: 40%;
    min-width: 142px;
    flex-grow: 0;
  }
  .table-ulogs tr td.tb-col-ip {
    width: 40%;
    min-width: 130px;
    flex-grow: 0;
  }
  .table-ulogs tr .tb-col-action {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
  }
  .table-ulogs thead tr {
    padding: 0;
  }
}
.toggle-content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: calc(100% + 10px);
  z-index: 9;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 12px 1px rgba(43, 55, 72, 0.15);
  transform: translateY(10px);
}
.toggle-content-right {
  left: auto;
  right: 0;
}
.toggle-content-bottom {
  top: auto;
  bottom: 0;
}
.toggle-content.content-active {
  transition: all 0.2s ease;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.toggle-slide {
  position: fixed;
  top: 0;
  z-index: 1031;
  min-width: 260px;
  max-width: calc(100% - 40px);
  transition: transform 650ms ease;
}
.toggle-slide-left {
  left: 0;
  transform: translateX(-100%);
}
.toggle-slide-right {
  right: 0;
  transform: translateX(100%);
}
.toggle-slide.content-active {
  transform: translate(0) !important;
}
.toggle-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(31, 43, 58, 0.2);
  z-index: 1030;
  animation: overlay-fade-in 0.4s ease 1;
}
.toggle-expand-content {
  display: none;
}
.toggle-expand-content.expanded {
  display: block;
}
.toggle-expand .inactive-text,
.toggle-opt .inactive-text {
  display: block;
}
.toggle-expand.active > .inactive-text,
.toggle-opt.active > .inactive-text {
  display: none;
}
.toggle-expand .active-text,
.toggle-opt .active-text {
  display: none;
}
.toggle-expand.active > .active-text,
.toggle-opt.active > .active-text {
  display: block;
}
body.toggle-shown {
  overflow: hidden;
}
@media (min-width: 576px) {
  .nk-block-tools-toggle .toggle-expand {
    display: none;
  }
  .nk-block-tools-toggle .toggle-expand-content {
    display: block !important;
  }
}
@media (max-width: 575.98px) {
  .nk-block-tools-toggle .toggle-expand-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 99;
    padding: 0 18px !important;
    background: #fff;
    margin-left: -18px;
    margin-right: -18px;
    box-shadow: 0 20px 35px rgba(0, 0, 0, 0.2);
  }
  .nk-block-tools-toggle .nk-block-tools {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .nk-block-tools-toggle .nk-block-tools-opt {
    margin-left: auto;
  }
}
@media (max-width: 575.98px) {
  .card-tools-toggle .card-title-group {
    position: static;
  }
  .card-tools-toggle .toggle-wrap .toggle-content {
    padding: 1.25rem 1rem;
    transform: translate(0);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .card-tools-toggle .toggle-wrap .toggle-close {
    margin-right: auto;
  }
  .card-tools-toggle .toggle-wrap .toggle-close .btn-trigger:before {
    opacity: 0;
  }
  .card-tools-toggle .toggle-wrap .toggle-close .btn-trigger:hover:before {
    opacity: 1;
  }
}
@media (min-width: 576px) {
  .card-tools-toggle {
    z-index: 9;
  }
  .card-tools-toggle .toggle-wrap .toggle {
    display: none;
  }
  .card-tools-toggle .toggle-wrap .toggle-content {
    z-index: 9;
    opacity: 1;
    visibility: visible;
    position: static;
    background: rgba(0, 0, 0, 0);
    box-shadow: none;
    transform: translate(0);
  }
  .card-tools-toggle .toggle-wrap .toggle-close {
    display: none;
  }
}
@media (min-width: 576px) {
  .toggle-break-sm {
    transition: none;
    opacity: 1;
    visibility: visible;
    transform: translateY(0) !important;
    position: static;
    background: rgba(0, 0, 0, 0);
    box-shadow: none;
    z-index: 1 !important;
  }
  .toggle-break-sm.collapse {
    display: block;
  }
}
@media (min-width: 768px) {
  .toggle-break-md {
    transition: none;
    opacity: 1;
    visibility: visible;
    transform: translateY(0) !important;
    position: static;
    background: rgba(0, 0, 0, 0);
    box-shadow: none;
    z-index: 1 !important;
  }
  .toggle-break-md.collapse {
    display: block;
  }
}
@media (min-width: 992px) {
  .toggle-break-lg {
    transition: none;
    opacity: 1;
    visibility: visible;
    transform: translateY(0) !important;
    position: static;
    background: rgba(0, 0, 0, 0);
    box-shadow: none;
    z-index: 1 !important;
  }
  .toggle-break-lg.collapse {
    display: block;
  }
}
@media (min-width: 1200px) {
  .toggle-break-xl {
    transition: none;
    opacity: 1;
    visibility: visible;
    transform: translateY(0) !important;
    position: static;
    background: rgba(0, 0, 0, 0);
    box-shadow: none;
    z-index: 1 !important;
  }
  .toggle-break-xl.collapse {
    display: block;
  }
}
@media (min-width: 1540px) {
  .toggle-break-xxl {
    transition: none;
    opacity: 1;
    visibility: visible;
    transform: translateY(0) !important;
    position: static;
    background: rgba(0, 0, 0, 0);
    box-shadow: none;
    z-index: 1 !important;
  }
  .toggle-break-xxl.collapse {
    display: block;
  }
}
.nav-switch {
  display: inline-flex;
  margin-bottom: 1.5rem;
  border: 1px solid #dbdfea;
  border-radius: 30px;
  padding: 5px;
}
.nav-switch .nav-link {
  padding: 5px 16px;
  line-height: 20px;
  border-radius: 20px;
  position: static;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #8094ae;
}
.nav-switch .nav-link.active {
  background: #11c8fc;
  color: #fff;
}
.nav-switch .nav-link:after {
  display: none;
}
.nav-switch .nav-item {
  padding: 0;
  margin: 0 1px;
}
.nav-switch .nav-item:last-child {
  margin-right: 0;
}
.nav-switch .nav-item:first-child {
  margin-left: 0;
}
@media (min-width: 576px) {
  .nav-switch {
    margin-bottom: 2.5rem;
  }
}
.nav-switch-s2 {
  display: inline-flex;
  border-radius: 4px;
  border: none;
}
.nav-switch-s2 .nav-link {
  padding: 0.25rem 0.7rem;
  line-height: 20px;
  border-radius: 0;
  position: static;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #8094ae;
  border: 1px solid #dbdfea;
}
.nav-switch-s2 .nav-link:hover,
.nav-switch-s2 .nav-link:focus {
  color: #526484;
  background: #f5f6fa;
  border: 1px solid #dbdfea;
}
.nav-switch-s2 .nav-link.active {
  color: #364a63;
  border: 1px solid #dbdfea;
  background: #f5f6fa;
  box-shadow: inset 0 0 4px -1px rgba(128, 148, 174, 0.25);
}
.nav-switch-s2 .nav-link:after {
  display: none;
}
.nav-switch-s2 .nav-item {
  padding: 0;
  margin: 0;
}
.nav-switch-s2 .nav-item:last-child {
  margin-right: 0;
}
.nav-switch-s2 .nav-item:last-child .nav-link {
  border-radius: 0 4px 4px 0;
}
.nav-switch-s2 .nav-item:first-child {
  margin-left: 0;
}
.nav-switch-s2 .nav-item:first-child .nav-link {
  border-radius: 4px 0 0 4px;
}
.nav-switch-s2 .nav-item:not(:first-child) {
  margin-left: -1px;
}
.widget-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}
.widget-title > * {
  margin-bottom: 0;
}
.text-center .widget-title {
  justify-content: center;
}
.is-theme .widget-title a:hover {
  color: #fff;
}
.side-wg:not(:last-child) {
  margin-bottom: 2.5rem;
}
.side-wg-title {
  padding-bottom: 0.75rem;
}
.side-wg .back-to {
  font-size: 0.875rem;
  line-height: 1.1;
  font-weight: 400;
  position: relative;
  color: #8094ae;
  display: inline-flex;
  align-items: center;
}
.side-wg .back-to .icon {
  font-size: 1.25rem;
  width: 1.75rem;
  margin-top: -3px;
  display: inline-block;
}
.nk-ck {
  height: 260px;
}
.nk-ck-sm {
  height: 180px;
}
.nk-ck1 {
  height: 120px;
}
.nk-ck2 {
  height: 240px;
}
.nk-ck3 {
  height: 160px;
}
.nk-cktv {
  height: 300px;
  overflow: hidden;
  border: 1px solid #dbdfea;
  border-radius: 4px;
}
.nk-cktv .tradingview-widget-container {
  overflow: hidden;
  position: relative;
  top: 8px;
}
.nk-cktv .tradingview-widget-container > div {
  margin: -1px;
}
@media (min-width: 576px) {
  .nk-ck {
    height: 260px;
  }
  .nk-ck-sm {
    height: 180px;
  }
  .nk-ck1 {
    height: 120px;
  }
  .nk-ck2 {
    height: 240px;
  }
  .nk-ck3 {
    height: 258px;
  }
}
.nk-wg6-title:not(:first-child) {
  margin-top: 1.5rem;
}
.nk-wg6-title:not(:last-child) {
  margin-bottom: 1.5rem;
}
.nk-wg6-text:not(:last-child) {
  margin-bottom: 1.5rem;
}
.aside-wg + .aside-wg {
  padding-top: 2rem;
}
.nk-modal-title:not(:first-child) {
  margin-top: 1.5rem;
}
.nk-modal-title:not(:last-child) {
  margin-bottom: 1.5rem;
}
.nk-modal-title.title {
  font-size: 1.5rem;
}
.nk-modal-text:not(:last-child) {
  margin-bottom: 1.5rem;
}
.nk-modal-text .lead {
  font-size: 1.1rem;
  line-height: 1.5;
}
.nk-modal-text .sub-text {
  font-size: 14px;
}
.nk-modal-action {
  margin-top: 1.5rem;
}
.nk-modal-action-sm {
  margin-top: 1rem;
}
.nk-modal-action-md {
  margin-top: 2rem;
}
.nk-modal-action-lg {
  margin-top: 2.25rem;
}
.modal-body-lg .tab-content {
  margin-top: 2rem;
}
@media (min-width: 576px) {
  .nk-modal-action-md {
    margin-top: 2.5rem;
  }
  .nk-modal-action-lg {
    margin-top: 3.25rem;
  }
}
[data-bs-toggle="modal"] {
  cursor: pointer;
}
.search-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: #fff;
  transition: opacity 0.4s;
  border-radius: 6px;
  pointer-events: none;
  display: flex;
  align-items: center;
}
.search-wrap.active {
  opacity: 1;
  z-index: 9;
  pointer-events: auto;
}
.search-wrap-extend {
  margin-top: -1rem;
  left: -2px;
  right: -2px;
}
.search-toggle {
  transition: all 0.4s;
  opacity: 1;
}
.search-toggle.active {
  opacity: 0;
}
.search-content {
  position: relative;
  width: 100%;
}
.search-content .form-control,
.search-content div.dataTables_wrapper div.dataTables_filter input,
div.dataTables_wrapper div.dataTables_filter .search-content input,
.search-content .dual-listbox .dual-listbox__search,
.dual-listbox .search-content .dual-listbox__search {
  padding-left: calc(2.125rem + 2px);
  padding-right: calc(2.125rem + 2px);
}
.search-content .form-control-sm {
  padding-left: calc(1.75rem + 2px);
  padding-right: calc(1.75rem + 2px);
}
.search-back,
.search-submit {
  position: absolute;
  top: 50%;
}
.search-back:focus,
.search-submit:focus {
  box-shadow: none;
}
.search-back {
  left: 0;
  transform: translate(-0.25rem, -50%);
}
.search-submit {
  right: 0;
  transform: translate(0.5rem, -50%);
}
.search-submit:hover {
  color: #11c8fc;
}
.nk-search-box {
  margin-top: 1.5rem;
  margin-bottom: 1.25rem;
}
.nk-search-box .form-icon {
  height: 100%;
  border: none;
  background: rgba(0, 0, 0, 0);
  width: 3.5rem;
}
.nk-search-box .form-control,
.nk-search-box div.dataTables_wrapper div.dataTables_filter input,
div.dataTables_wrapper div.dataTables_filter .nk-search-box input,
.nk-search-box .dual-listbox .dual-listbox__search,
.dual-listbox .nk-search-box .dual-listbox__search {
  border-radius: 1.5rem;
  padding-left: 1.25rem;
  padding-right: 1.5rem;
}
.nk-block + .nk-block,
.nk-block + .nk-block-head {
  padding-top: 28px;
}
.nk-block + .nk-block-lg,
.nk-block + .nk-block-head-lg {
  padding-top: 2.5rem;
}
.nav-tabs + .nk-block {
  padding-top: 1.5rem;
}
.nav-tabs + .nk-block-sm {
  padding-top: 1rem;
}
.nk-block-area {
  display: flex;
}
.nk-block-area-column {
  flex-direction: column;
}
.nk-block-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nk-block-between > .title:first-child {
  margin-bottom: 0;
}
.nk-block-middle {
  margin-top: auto;
  margin-bottom: auto;
}
.nk-block-head {
  position: relative;
  padding-bottom: 1.25rem;
}
.nk-block-head:only-child {
  padding-bottom: 0;
}
.nk-block-head h2:not(:last-child),
.nk-block-head .h2:not(:last-child) {
  margin-bottom: 1rem;
}
.nk-block-head-sub {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  color: #8094ae;
  font-weight: 400;
  position: relative;
}
.nk-block-head-sub .back-to {
  color: inherit;
  display: inline-flex;
  align-items: center;
}
.nk-block-head-sub .back-to .icon {
  font-size: 1.5rem;
  width: 2rem;
  margin-top: -3px;
  display: inline-block;
}
.nk-block-head-sub .dropdown {
  position: absolute;
  right: -8px;
  top: -6px;
}
.nk-block-head-xs {
  padding-bottom: 0.75rem;
}
.nk-block-head-sm {
  padding-bottom: 1rem;
}
.nk-block-head-lg {
  padding-bottom: 1.5rem;
}
.nk-block-head + .nav-tabs {
  margin-top: -1rem;
}
.nk-content-body > .nk-block-head:first-child {
  padding-bottom: 1.75rem;
}
.nk-block-head .nk-block-text {
  margin-top: 1.5rem;
}
.nk-block-head .nk-block-text + .btn,
.nk-block-head .actions ul li .nk-block-text + a,
.actions ul li .nk-block-head .nk-block-text + a,
.nk-block-head .dual-listbox .nk-block-text + .dual-listbox__button,
.dual-listbox .nk-block-head .nk-block-text + .dual-listbox__button {
  margin-top: 1.5rem;
}
.nk-block-tools {
  display: flex;
  align-items: center;
}
.nk-block-tools > * {
  display: inline-flex;
}
.nk-block-des {
  color: #526484;
}
.nk-block-des strong {
  color: #364a63;
}
.nk-block-des .icon {
  vertical-align: middle;
}
.nk-block-content + .nk-block-head {
  padding-top: 2rem;
}
.nk-block-content + .nk-block-head-sm {
  padding-top: 1.5rem;
}
.nk-block-content-head:not(:last-child) {
  margin-bottom: 1rem;
}
.nk-block-title-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nk-block-title-group .title {
  margin-bottom: 0;
}
.nk-block-title-group:not(:last-child) {
  margin-bottom: 0.5rem;
}
@media (min-width: 576px) {
  .nk-block-head-lg {
    padding-bottom: 2.5rem;
  }
  .nk-block-content + .nk-block-head {
    padding-top: 4rem;
  }
  .nk-block-content + .nk-block-head-sm {
    padding-top: 2.5rem;
  }
}
@media (min-width: 768px) {
  .nk-content-body > .nk-block-head:first-child {
    padding-bottom: 2.5rem;
  }
  .nk-content-body > .nk-block-head-sm:first-child {
    padding-bottom: 1.75rem;
  }
  .nav-tabs + .nk-block {
    padding-top: 2.5rem;
  }
  .nav-tabs + .nk-block-sm {
    padding-top: 2rem;
  }
  .nav-tabs + .nk-block-xs {
    padding-top: 1.25rem;
  }
  .nk-block-text h5,
  .nk-block-text .h5,
  .nk-block-text h6,
  .nk-block-text .h6 {
    font-size: 1rem;
  }
}
@media (max-width: 767.98px) {
  .nk-block-tools .opt-menu-md {
    order: 100;
    margin-left: auto;
  }
}
@media (min-width: 768px) {
  .nk-block-between-md {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.nk-block-subhead {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dbdfea;
}
.nk-feature-center {
  text-align: center;
}
.filter-wg label.overline-title {
  margin-bottom: 0.5rem;
}
.change {
  line-height: 1;
}
.change .sign {
  font-family: "Nioicon";
}
.change .sign:before {
  content: "";
}
.change.up {
  color: #0fca7a !important;
}
.change.up .sign:before {
  content: "";
}
.change.down {
  color: #f2426e !important;
}
.change.down .sign:before {
  content: "";
}
.nk-knob > div {
  position: relative;
  display: inline-block !important;
}
.knob {
  height: 100% !important;
  margin-top: 0 !important;
  font-size: 1.5rem !important;
}
.knob-half {
  height: 50% !important;
  font-size: 1.25rem !important;
}
[class*="knob"] {
  text-align: center;
  pointer-events: none;
  width: 100% !important;
  font-weight: 400 !important;
  font-family: Roboto, sans-serif !important;
  color: #364a63 !important;
  margin-left: 0 !important;
  left: 0;
  border: none;
}
[class*="knob"]:focus {
  outline: none;
}
.stats {
  padding: 0 0 1rem;
}
@media (min-width: 1540px) {
  .nk-content-sidebar .stats {
    padding: 1.25rem 1.5rem 1rem;
  }
}
.collapse-shown {
  display: inline-block !important;
}
.collapsed .collapse-shown {
  display: none !important;
}
.collapse-hidden {
  display: none !important;
}
.collapsed .collapse-hidden {
  display: inline-block !important;
}
.clipboard-init {
  cursor: pointer;
}
.clipboard-success .clipboard-init {
  color: #0db26c;
}
.clipboard-text {
  font-size: 12px;
}
.clipboard-success .form-control:focus,
.clipboard-success div.dataTables_wrapper div.dataTables_filter input:focus,
div.dataTables_wrapper div.dataTables_filter .clipboard-success input:focus,
.clipboard-success .dual-listbox .dual-listbox__search:focus,
.dual-listbox .clipboard-success .dual-listbox__search:focus {
  border-color: #dbdfea;
  box-shadow: inset 0 1px 1px rgba(16, 25, 36, 0.075);
}
.nk-news-item {
  display: flex;
  align-items: center;
}
.nk-news-icon {
  width: 2rem;
  display: inline-flex;
  flex-shrink: 0;
}
.nk-news-icon .icon {
  font-size: 24px;
  color: #11c8fc;
}
.is-theme .nk-news-icon .icon {
  color: #00c4fb;
}
.nk-news-icon img {
  width: 24px;
}
.nk-news-text {
  display: flex;
  align-items: center;
  max-width: calc(100% - 1.5rem);
}
.nk-news-text p {
  font-size: 13px;
  margin-bottom: 0;
  color: #526484;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 2rem);
}
.nk-news-text p span {
  color: #8094ae;
  font-weight: 400;
}
.is-dark .nk-news-text p {
  color: #90a1b8;
}
.is-dark .nk-news-text p span {
  color: rgba(128, 148, 174, 0.9);
}
.is-theme .nk-news-text p {
  color: #bba0f5;
}
.is-theme .nk-news-text p span {
  color: rgba(171, 137, 242, 0.8);
}
.nk-news-text .icon {
  color: #8094ae;
  margin-left: 0.25rem;
}
.is-dark .nk-news-text .icon {
  color: #8094ae;
}
.is-theme .nk-news-text .icon {
  color: #ab89f2;
}
.nk-news-item:hover .nk-news-text .icon {
  color: #11c8fc;
}
.language-list li:not(:last-child) .language-item {
  border-bottom: 1px solid #ecf2ff;
}
.language-item {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  color: #344357;
  transition: all 0.4s;
}
.language-item:hover {
  color: #3c4d62;
  background: #ebeef2;
}
.language-name {
  font-size: 12px;
}
.language-flag {
  width: 24px;
  margin-right: 12px;
}
.entry img,
.entry .video,
.entry .image-group {
  border-radius: 4px;
}
.entry img + p,
.entry img + h2,
.entry img + .h2,
.entry img + h3,
.entry img + .h3,
.entry img + h4,
.entry img + .h4,
.entry img + h5,
.entry img + .h5,
.entry img + h6,
.entry img + .h6,
.entry img + ul,
.entry img + ol,
.entry .video + p,
.entry .video + h2,
.entry .video + .h2,
.entry .video + h3,
.entry .video + .h3,
.entry .video + h4,
.entry .video + .h4,
.entry .video + h5,
.entry .video + .h5,
.entry .video + h6,
.entry .video + .h6,
.entry .video + ul,
.entry .video + ol,
.entry .image-group + p,
.entry .image-group + h2,
.entry .image-group + .h2,
.entry .image-group + h3,
.entry .image-group + .h3,
.entry .image-group + h4,
.entry .image-group + .h4,
.entry .image-group + h5,
.entry .image-group + .h5,
.entry .image-group + h6,
.entry .image-group + .h6,
.entry .image-group + ul,
.entry .image-group + ol {
  margin-top: 2rem;
}
.entry p + img {
  margin-top: 1rem;
}
.entry p + h2,
.entry p + .h2,
.entry p + h3,
.entry p + .h3,
.entry p + h4,
.entry p + .h4,
.entry p + h5,
.entry p + .h5,
.entry p + h6,
.entry p + .h6,
.entry p + .video,
.entry p + .image-group {
  padding-top: 0.75rem;
}
p > span > .icon.ni {
  vertical-align: middle;
}
.btn-trigger + .dropdown-menu-end,
.btn-trigger + .dropdown-menu-start {
  margin: -0.75rem 0 !important;
}
.btn-trigger + .dropdown-menu-end {
  margin-right: 16px !important;
}
.btn-trigger + .dropdown-menu-start {
  margin-left: 16px;
}
@media (max-width: 420px) {
  .btn-trigger + .dropdown-menu-xl,
  .btn-trigger + .dropdown-menu-lg,
  .btn-trigger + .dropdown-menu-md {
    margin-right: -16px !important;
    margin-top: 0 !important;
  }
}
.bq-note-item:not(:first-child) {
  margin-top: 1.75rem;
}
.bq-note-text {
  padding: 1rem 1.25rem;
  background: #f5f6fa;
  border-radius: 4px;
}
.bq-note-meta {
  font-size: 12px;
  color: #8094ae;
  margin-top: 0.75rem;
}
.bq-note-meta span > span {
  color: #526484;
}
.bq-note-meta .link {
  margin-left: 0.75rem;
}
.bq-note-sep {
  height: 0.25rem;
  display: block;
  visibility: hidden;
  padding: 0 0.25rem;
}
.bq-note-by {
  display: inline-block;
}
@media (min-width: 576px) {
  .bq-note-text {
    padding: 1.25rem 1.5rem;
  }
  .bq-note-sep {
    height: auto;
    display: inline-block;
    visibility: visible;
  }
}
@media (min-width: 1200px) and (max-width: 1359px) {
  .form-settings .col-lg-5,
  .form-settings .col-lg-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.ratio {
  border-radius: 4px;
}
.video {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}
.video:before {
  position: absolute;
  content: "";
  bottom: 0;
  right: 0;
  left: 0;
  height: 120px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}
.video-play {
  display: flex;
  align-items: center;
  position: absolute;
  left: 2rem;
  bottom: 2rem;
  color: #fff;
  font-size: 1.25rem;
}
.video-play:hover {
  color: #fff;
}
.video-play .icon {
  font-size: 2rem;
}
.nk-slider {
  position: relative;
}
.nk-slider-s1 {
  width: 290px;
  max-width: 100%;
}
.nk-slider-s1 .slick-dots {
  position: absolute;
  right: 1.25rem;
  top: 1.5rem;
  padding-top: 0;
}
.nk-slider-s2 .slider-arrows > div {
  padding: 0.5rem 0;
}
.project-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  align-items: start;
}
.project-title {
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
}
.project-title .user-avatar {
  margin-right: 1rem;
}
.project-title .title {
  font-size: 0.975rem;
}
.project-title .title:not(:last-child) {
  margin-bottom: 0.125rem;
}
.project-details {
  margin-bottom: 1rem;
}
.project-progress {
  margin-bottom: 1rem;
}
.project-progress-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.project-progress-task {
  display: flex;
  align-items: center;
  color: #8094ae;
}
.project-progress-task .icon {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-right: 0.25rem;
}
.project-progress-percent {
  color: #526484;
  font-weight: 500;
}
.project-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.project-users {
  display: flex;
  align-items: center;
}
.project-list-progress {
  display: flex;
  align-items: center;
}
.project-list-progress .progress {
  width: 100px;
  margin-right: 0.5rem;
}
@media (min-width: 1540px) {
  .project-list-progress .progress {
    width: 140px;
    margin-right: 1rem;
  }
}
.team {
  position: relative;
}
.team-info {
  padding: 1rem 0 1.25rem;
}
.team-info li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9375rem;
  line-height: 1.75rem;
}
.team-info li span:first-child {
  color: #8094ae;
}
.team-info li span:last-child {
  color: #526484;
}
.team-status {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
.team-options {
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
}
.team-details {
  padding-top: 0.5rem;
  text-align: center;
  max-width: 200px;
  margin: 0 auto;
}
.team-statistics {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 1rem 0 1.5rem;
}
.team-statistics li span {
  display: block;
}
.team-statistics li span:first-child {
  font-size: 1.125rem;
  color: #1c2b46;
}
.team-statistics li span:last-child {
  font-size: 0.875rem;
  color: #8094ae;
}
.team-view {
  display: flex;
  justify-content: center;
  padding-bottom: 0.25rem;
}
.rating {
  display: flex;
  align-items: center;
  margin: 0 -0.125rem;
  color: #fd9722;
}
.rating > * {
  padding: 0 0.125rem;
  display: inline-flex;
}
.rating .icon {
  font-size: 1rem;
}
.rating-wrap {
  display: inline-flex;
  align-items: flex-start;
}
.rating-wrap .amount {
  line-height: 1.25rem;
}
.rating-wrap .rating + span,
.rating-wrap span + .rating {
  margin-left: 0.75rem;
}
.rating-pill {
  padding: 0.35rem 0.875rem;
  border-radius: 3rem;
}
.rating-card-description .icon {
  color: #11c8fc;
}
.rating-card-description li {
  color: #8094ae;
}
.rating-progress {
  display: flex;
  align-items: center;
}
.icon + span,
span + .icon {
  margin-left: 0.25rem;
}
.nk-stepper {
  display: none;
}
.nk-stepper-nav {
  display: flex;
  margin: -0.5rem;
  counter-reset: step-number;
}
.nk-stepper-nav > * {
  padding: 0.5rem;
}
.nk-stepper-nav > * .step-item {
  display: flex;
  align-items: center;
}
.nk-stepper-nav > * .step-item:before {
  counter-increment: step-number;
  content: counter(step-number);
  display: grid;
  place-items: center;
  height: 32px;
  width: 32px;
  border-radius: 100px;
  color: #11c8fc;
  background: rgba(115, 58, 234, 0.15);
  margin-right: 1.125rem;
  flex-shrink: 0;
  flex-grow: 0;
}
.nk-stepper-nav > *.done .step-item:before {
  font-family: "Nioicon";
  content: "";
}
.nk-stepper-nav > *.current .step-item:before {
  color: #fff;
  background: #11c8fc;
}
.nk-stepper-nav.is-vr {
  flex-direction: column;
}
.nk-stepper-nav-s2 > * .step-item {
  border-radius: 4px;
  padding: 1rem 1.25rem;
  background: #f5f6fa;
  border-left: 2px solid rgba(0, 0, 0, 0);
  height: 100%;
}
.nk-stepper-nav-s2 > *.current .step-item {
  border-left-color: #11c8fc;
}
.nk-stepper-nav-s2 > *.done .step-item:before {
  color: #fff;
  background: #11c8fc;
}
.nk-stepper-step {
  display: none;
}
.nk-stepper-step.active {
  display: block;
}
.nk-stepper-pagination {
  display: flex;
}
.nk-stepper-pagination li {
  display: none;
}
.nk-stepper-s1[data-step-current="last"] .stepper-pagination {
  justify-content: center;
}
@media (min-width: 576px) {
  .nk-stepper-nav.is-vr-sm {
    flex-direction: column;
  }
  .nk-stepper-nav.is-hr-sm {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .nk-stepper-nav.is-vr-md {
    flex-direction: column;
  }
  .nk-stepper-nav.is-hr-md {
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .nk-stepper-nav.is-vr-lg {
    flex-direction: column;
  }
  .nk-stepper-nav.is-hr-lg {
    flex-direction: row;
  }
}
@media (min-width: 1200px) {
  .nk-stepper-nav.is-vr-xl {
    flex-direction: column;
  }
  .nk-stepper-nav.is-hr-xl {
    flex-direction: row;
  }
}
@media (min-width: 1540px) {
  .nk-stepper-nav.is-vr-xxl {
    flex-direction: column;
  }
  .nk-stepper-nav.is-hr-xxl {
    flex-direction: row;
  }
}
.nk-auth-body {
  padding: 1.25rem;
}
.nk-auth-footer {
  padding-bottom: 40px;
}
.nk-auth-body,
.nk-auth-footer {
  width: 100%;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
}
.nk-auth-footer {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.nk-auth-footer-full {
  margin-top: 0;
}
@media (min-width: 992px) {
  .nk-auth-container {
    width: 45%;
  }
}
@media (min-width: 1540px) {
  .nk-split .nk-auth-body,
  .nk-split .nk-auth-footer {
    margin-right: 90px;
  }
}
.nk-error-ld {
  padding: 2rem 1rem;
}
.nk-error-gfx {
  padding-bottom: 30px;
  max-width: 90%;
}
.nk-error-head {
  font-size: 160px;
  font-weight: 700;
  background: -webkit-linear-gradient(#11c8fc, #170c2f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  opacity: 0.9;
}
.nk-error-title {
  padding-bottom: 1rem;
}
.nk-error-text {
  font-size: 1rem;
}
@media (min-width: 576px) {
  .nk-error-lead {
    font-size: 200px;
  }
  .nk-error-gfx {
    padding-bottom: 60px;
    max-width: 520px;
  }
}
.clipboard-success-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0fca7a;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}
.clipboard-success .clipboard-success-message {
  opacity: 1;
}
.has-shadow.bg-lighter .card,
.dual-listbox .has-shadow.dual-listbox__item:hover .card,
.has-shadow.bg-lighter .sp-pdl-item,
.dual-listbox .has-shadow.dual-listbox__item:hover .sp-pdl-item,
.has-shadow.bg-lighter .invoice-wrap,
.dual-listbox .has-shadow.dual-listbox__item:hover .invoice-wrap,
.has-shadow.bg-lighter .accordion,
.dual-listbox .has-shadow.dual-listbox__item:hover .accordion {
  box-shadow: 0 0 8px -3px rgba(60, 77, 98, 0.15);
}
.has-shadow .card,
.has-shadow .sp-pdl-item,
.has-shadow .invoice-wrap,
.has-shadow .accordion {
  box-shadow: 0 0 6px -2px rgba(60, 77, 98, 0.2);
}
.has-shadow .data-head,
.has-shadow .data-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.has-shadow .card .data-head:not(.is-plain) {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  padding-left: 0.75rem;
}
.has-shadow .nav-tabs {
  box-shadow: 0 2px 0 -1px rgba(60, 77, 98, 0.06);
}
.has-shadow .nk-header {
  box-shadow: 0 3px 0 -1px rgba(60, 77, 98, 0.04);
}
.nio-version {
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0.02em;
  position: absolute;
  right: -1px;
  bottom: -3px;
}
.code-tag {
  color: #816bff;
}
.code-class {
  color: #ff63a5;
}
.code-fnc {
  color: #09c2de;
}
.nk-switch-icon {
  color: #90a1b8;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nk-switch-icon .icon {
  font-size: 1.5rem;
}
.nk-switch-icon .icon-sm {
  font-size: 1.25rem;
}
.nk-switch-icon:hover {
  color: #526484;
}
.nk-switch-icon.active {
  color: #11c8fc;
}
.dark-switch {
  position: relative;
}
.dark-switch:before,
.dark-switch:after {
  position: absolute;
  content: "";
  transform: translateY(-50%);
  border-radius: 1.5rem;
  display: block;
  top: 50%;
}
.dark-switch:before {
  right: 0;
  width: 3rem;
  height: 1.5rem;
  background-color: #fff;
  border: #dbdfea solid 2px;
}
.dark-switch:after {
  height: 1rem;
  width: 1rem;
  right: calc(2rem - 2px - 0.125rem);
  background-color: #dbdfea;
}
.dark-switch.active:before {
  background-color: #11c8fc;
  border-color: #11c8fc;
}
.dark-switch.active:after {
  right: calc(2px + 0.125rem);
  background-color: #fff;
}
.is-scrollable {
  overflow: auto;
}
.country-list {
  display: flex;
  flex-wrap: wrap;
}
.country-list li {
  width: 100%;
}
.country-item {
  display: flex;
  align-items: center;
}
.country-flag {
  width: 1.25rem;
  margin-right: 0.75rem;
}
.country-name {
  font-size: 1rem;
  color: #526484;
}
@media (min-width: 576px) {
  .country-list li {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .country-list li {
    width: 33.33%;
  }
}
.collapsed .switch-text-normal {
  display: none;
}
.switch-text-collapsed {
  display: none;
}
.collapsed .switch-text-collapsed {
  display: inline-flex;
}
.close {
  float: right;
  font-size: 1.505rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover-focus {
  opacity: 0.75;
}
button.close {
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}
.popover-header {
  border-bottom: 1px solid #eaeaea;
}
.profile-ud-label {
  display: block;
  color: #8094ae;
}
.plain .profile-ud-label {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.wider .profile-ud-label {
  display: inline-flex;
  letter-spacing: -0.01em;
  width: 120px;
  flex-shrink: 0;
}
.profile-ud-value {
  color: #526484;
}
.wider .profile-ud-value {
  text-align: right;
  font-weight: 500;
  width: 100%;
}
.profile-ud.wider {
  display: flex;
  padding: 0.5rem 0;
}
.profile-ud-item:nth-child(n + 2) .profile-ud.plain {
  margin-top: 1.25rem;
}
@media (min-width: 768px) {
  .profile-ud.wider {
    padding: 0.75rem 0;
  }
  .profile-ud-list {
    display: flex;
    flex-wrap: wrap;
    margin: -0.25rem -3.25rem;
    max-width: 960px;
  }
  .profile-ud-item {
    width: 50%;
    padding: 0 3.25rem;
  }
  .profile-ud-item:nth-child(2) .profile-ud.plain {
    margin-top: 0;
  }
}
.profile-stats .amount {
  font-size: 1.125rem;
  font-weight: 700;
}
.profile-balance-group {
  display: flex;
  align-items: flex-end;
}
.profile-balance-ck {
  position: relative;
  height: 70px;
  margin-top: 0.5rem;
}
.profile-balance-sub {
  font-size: 0.875rem;
  color: #8094ae;
}
.profile-balance-subtitle {
  font-size: 0.75rem;
  margin-top: 0.5rem;
}
.profile-balance-sub .icon {
  font-size: 0.75rem;
}
.profile-balance-plus {
  position: absolute;
}
.profile-balance-plus .icon {
  font-size: 1rem;
  line-height: 1.5rem;
}
.profile-balance-plus ~ * {
  padding-left: 24px;
}
.profile-balance-amount .number {
  font-size: 1.25rem;
  font-weight: 700;
  color: #364a63;
  line-height: 1.5rem;
  white-space: nowrap;
}
.profile-balance-amount .number small,
.profile-balance-amount .number .small {
  font-weight: 400;
}
.steps ul {
  display: flex;
}
.steps ul li {
  flex-grow: 1;
}
.steps .current-info {
  display: none;
}
.actions ul {
  display: flex;
  margin: -10px;
  padding-top: 20px;
}
.actions ul li {
  padding: 10px;
}
.actions ul li:first-child {
  order: 1;
}
.actions ul li a {
  display: inline-block;
  position: relative;
  color: #fff;
  font-weight: 500;
  transition: all 0.4s ease;
  border-color: #11c8fc;
  background: #11c8fc;
  line-height: 1.25rem;
  --bs-btn-padding-y: 0.4375rem;
  --bs-btn-padding-x: 1.125rem;
  --bs-btn-font-size: 0.8125rem;
  --bs-btn-border-radius: 4px;
}
.actions ul li.disabled {
  display: none;
}
.nk-wizard-head {
  display: none;
}
.nk-wizard-simple .steps {
  margin-bottom: 20px;
}
.nk-wizard-simple .steps ul li {
  position: relative;
  padding-bottom: 5px;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
}
.nk-wizard-simple .steps ul li h5,
.nk-wizard-simple .steps ul li .h5 {
  border: none;
  padding: 0 0 6px 0;
  letter-spacing: 0.02em;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  color: #8094ae;
}
.nk-wizard-simple .steps ul li .number {
  font-size: 13px;
  color: #8094ae;
  font-weight: 700;
}
.nk-wizard-simple .steps ul li:after {
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  bottom: 0;
  content: "";
  transition: all 0.4s;
  background: #e5e9f2;
}
.nk-wizard-simple .steps ul li.done:after,
.nk-wizard-simple .steps ul li.current:after {
  height: 2px;
  background: #11c8fc;
  width: 100%;
}
.nk-wizard-simple .steps ul li.done h5,
.nk-wizard-simple .steps ul li.done .h5,
.nk-wizard-simple .steps ul li.done .number,
.nk-wizard-simple .steps ul li.current h5,
.nk-wizard-simple .steps ul li.current .h5,
.nk-wizard-simple .steps ul li.current .number {
  color: #11c8fc;
}
.nk-wizard-simple .steps ul li.current ~ .done:after {
  height: 1px;
  background: #e5e9f2;
}
.nk-wizard-simple .steps ul li.current ~ .done h5,
.nk-wizard-simple .steps ul li.current ~ .done .h5,
.nk-wizard-simple .steps ul li.current ~ .done .number {
  color: #8094ae;
}
.nk-wizard-simple .steps .current-info {
  display: none;
}
.nk-wizard.is-vertical .steps ul {
  flex-wrap: wrap;
}
.nk-wizard.is-vertical .steps ul li h5,
.nk-wizard.is-vertical .steps ul li .h5 {
  text-transform: none;
}
@media (min-width: 768px) {
  .nk-wizard.is-vertical {
    display: flex;
    flex-wrap: wrap;
  }
  .nk-wizard.is-vertical .steps {
    width: 33%;
    padding-right: 2rem;
  }
  .nk-wizard.is-vertical .steps ul li {
    width: 100%;
  }
  .nk-wizard.is-vertical .steps ul li:not(:last-child) {
    margin-bottom: 1rem;
  }
  .nk-wizard.is-vertical .content {
    width: 66%;
  }
  .nk-wizard.is-vertical .actions {
    margin-left: 33%;
  }
}
.tb-tnx-head {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  background: #e5e9f2;
}
.tb-tnx-head td,
.tb-tnx-head th {
  color: #8094ae;
  padding: 0.625rem 0.25rem;
}
.tb-tnx-head th {
  border-radius: 0;
}
.tb-tnx-head th:first-child {
  border-top-left-radius: 4px;
}
.tb-tnx-head th:last-child {
  border-top-right-radius: 4px;
}
.card .tb-tnx-head th:first-child {
  border-top-left-radius: 0;
}
.card .tb-tnx-head th:last-child {
  border-top-right-radius: 0;
}
.tb-tnx-item {
  font-size: 14px;
}
.tb-tnx-item td {
  padding: 1.25rem 0.25rem;
  vertical-align: middle;
}
.is-compact .tb-tnx-item td {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.tb-tnx-id a {
  display: block;
}
.tb-tnx-id span {
  font-weight: 500;
}
.tb-tnx-item .tb-tnx-total {
  font-weight: 500;
  color: #526484;
}
.tb-tnx-desc {
  padding-right: 0.5rem;
}
.tb-tnx-item .tb-tnx-date {
  color: #8094ae;
}
.tb-tnx-item .tb-tnx-date .date {
  font-size: 13px;
}
.tb-tnx-action {
  width: 60px;
  text-align: right;
}
.tb-tnx-action .dropdown {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}
.tb-tnx-btns .btn + .btn,
.tb-tnx-btns .dual-listbox .dual-listbox__button + .btn,
.dual-listbox .tb-tnx-btns .dual-listbox__button + .btn,
.tb-tnx-btns .actions ul li a + .btn,
.actions ul li .tb-tnx-btns a + .btn,
.tb-tnx-btns .dual-listbox .btn + .dual-listbox__button,
.dual-listbox .tb-tnx-btns .btn + .dual-listbox__button,
.tb-tnx-btns .dual-listbox .dual-listbox__button + .dual-listbox__button,
.dual-listbox .tb-tnx-btns .dual-listbox__button + .dual-listbox__button,
.tb-tnx-btns .actions ul li .dual-listbox a + .dual-listbox__button,
.dual-listbox .tb-tnx-btns .actions ul li a + .dual-listbox__button,
.actions ul li .tb-tnx-btns .dual-listbox a + .dual-listbox__button,
.dual-listbox .actions ul li .tb-tnx-btns a + .dual-listbox__button,
.tb-tnx-btns .actions ul li .btn + a,
.actions ul li .tb-tnx-btns .btn + a,
.tb-tnx-btns .dual-listbox .actions ul li .dual-listbox__button + a,
.actions ul li .tb-tnx-btns .dual-listbox .dual-listbox__button + a,
.dual-listbox .tb-tnx-btns .actions ul li .dual-listbox__button + a,
.actions ul li .dual-listbox .tb-tnx-btns .dual-listbox__button + a,
.tb-tnx-btns .actions ul li a + a,
.actions ul li .tb-tnx-btns a + a {
  margin-left: 0.5rem;
}
.tb-tnx-btns + .dropdown {
  margin-left: 1rem;
}
@media (max-width: 767px) {
  .tb-tnx-item,
  .tb-tnx-head {
    display: flex;
    width: 100%;
  }
  .tb-tnx-item th,
  .tb-tnx-head th {
    flex-grow: 1;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .tb-tnx-item td,
  .tb-tnx-head td {
    flex-grow: 1;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
  }
  .tb-tnx-item {
    flex-wrap: wrap;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .tb-tnx-item:not(:last-child) {
    border-bottom: 1px solid #e5e9f2;
  }
  .tb-tnx-amount {
    width: 110px;
    text-align: right;
  }
  .tb-tnx-id,
  .tb-tnx-head th:first-child {
    min-width: 85px;
    padding-right: 0.25rem !important;
    flex-grow: 0 !important;
  }
  .tb-tnx-info {
    width: 45%;
  }
  .tb-tnx-date .date {
    font-size: 12px;
  }
  .tb-tnx-date .date + .date {
    padding-left: 0.5rem;
  }
  .tb-tnx-action:last-child {
    flex-grow: 0;
    padding-left: 0.5rem;
    display: inline-flex;
    align-items: center;
  }
}
@media (max-width: 575px) {
  .tb-tnx-item {
    font-size: 13px;
  }
  .tb-tnx-id {
    width: 100%;
  }
  .tb-tnx-id + td {
    padding-left: 1.25rem;
  }
  .tb-tnx-info {
    width: 65%;
  }
  .tb-tnx-amount:last-child {
    width: 25%;
  }
  .tb-tnx-amount:not(:last-child) {
    display: none;
  }
  .tb-tnx-amount.is-alt {
    position: absolute;
    display: inline-flex;
    right: 1.25rem;
    width: auto;
  }
  .tb-tnx-amount.is-alt .tb-tnx-total {
    order: 5;
    padding-left: 1.25rem;
  }
}
@media (min-width: 768px) {
  .tb-tnx-desc,
  .tb-tnx-date,
  .tb-tnx-total,
  .tb-tnx-status {
    display: inline-block;
    vertical-align: middle;
  }
  .tb-tnx-desc,
  .tb-tnx-date {
    width: calc(50% - 4px);
  }
  .tb-tnx-total {
    width: calc(60% - 4px);
  }
  .tb-tnx-status {
    width: calc(40% - 4px);
  }
  .tb-tnx-amount {
    width: 25%;
  }
  .tb-tnx-date .date,
  .tb-tnx-date > span > span {
    width: calc(50% - 4px);
    display: inline-block;
  }
  .tb-tnx-date .date + .date,
  .tb-tnx-date > span > span + span {
    padding-left: 0.75rem;
  }
}
.tb-odr-item {
  font-size: 14px;
}
.tb-odr-item td {
  padding: 1rem 0.25rem;
  vertical-align: middle;
}
.tb-odr-id,
.tb-odr-total {
  display: block;
}
.tb-odr-item .tb-odr-id,
.tb-odr-item .tb-odr-total {
  font-weight: 500;
}
.tb-odr-id a {
  display: block;
}
.tb-odr-id span {
  font-weight: 500;
}
.tb-odr-item .tb-odr-total {
  color: #364a63;
}
.tb-odr-head .tb-odr-item .tb-odr-total {
  color: inherit;
}
.tb-odr-item .tb-odr-date {
  color: #8094ae;
}
.tb-odr-head .tb-odr-item .tb-odr-date {
  color: inherit;
}
.tb-odr-action > div,
.tb-odr-action > .btn,
.dual-listbox .tb-odr-action > .dual-listbox__button,
.actions ul li .tb-odr-action > a {
  vertical-align: middle;
}
.tb-odr-action:last-child {
  padding-left: 1.25rem;
  text-align: right;
}
.tb-odr-btns .btn + .btn,
.tb-odr-btns .dual-listbox .dual-listbox__button + .btn,
.dual-listbox .tb-odr-btns .dual-listbox__button + .btn,
.tb-odr-btns .actions ul li a + .btn,
.actions ul li .tb-odr-btns a + .btn,
.tb-odr-btns .dual-listbox .btn + .dual-listbox__button,
.dual-listbox .tb-odr-btns .btn + .dual-listbox__button,
.tb-odr-btns .dual-listbox .dual-listbox__button + .dual-listbox__button,
.dual-listbox .tb-odr-btns .dual-listbox__button + .dual-listbox__button,
.tb-odr-btns .actions ul li .dual-listbox a + .dual-listbox__button,
.dual-listbox .tb-odr-btns .actions ul li a + .dual-listbox__button,
.actions ul li .tb-odr-btns .dual-listbox a + .dual-listbox__button,
.dual-listbox .actions ul li .tb-odr-btns a + .dual-listbox__button,
.tb-odr-btns .actions ul li .btn + a,
.actions ul li .tb-odr-btns .btn + a,
.tb-odr-btns .dual-listbox .actions ul li .dual-listbox__button + a,
.actions ul li .tb-odr-btns .dual-listbox .dual-listbox__button + a,
.dual-listbox .tb-odr-btns .actions ul li .dual-listbox__button + a,
.actions ul li .dual-listbox .tb-odr-btns .dual-listbox__button + a,
.tb-odr-btns .actions ul li a + a,
.actions ul li .tb-odr-btns a + a {
  margin-left: 0.5rem;
}
.tb-odr-id {
  min-width: 90px;
}
.tb-odr-head {
  background: #f5f6fa;
}
.tb-odr-head th {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  color: #8094ae;
  padding: 0.625rem 0.25rem;
}
@media (max-width: 767px) {
  .tb-odr-item th,
  .tb-odr-item td {
    flex-grow: 1;
  }
  .tb-odr-amount {
    text-align: right;
  }
}
@media (max-width: 575px) {
  .tb-odr-item {
    font-size: 13px;
  }
  .tb-odr-amount {
    width: 24%;
  }
  .tb-odr-info {
    width: 44%;
  }
  .tb-odr-action {
    width: 2rem;
    min-width: auto;
  }
  .tb-odr-item .tb-odr-action {
    padding-left: 0.5rem;
  }
}
@media (min-width: 768px) {
  .tb-odr-info {
    min-width: 55%;
  }
  .tb-odr-amount {
    width: 30%;
  }
  .tb-odr-total {
    min-width: 100px;
  }
  .tb-odr-id,
  .tb-odr-total {
    display: inline-block;
  }
  .tb-odr-id + span,
  .tb-odr-total + span {
    padding-left: 1rem;
  }
  .tb-odr-btns + .dropdown,
  .tb-odr-btns + .btn,
  .dual-listbox .tb-odr-btns + .dual-listbox__button,
  .actions ul li .tb-odr-btns + a {
    margin-left: 0.75rem;
  }
}
@media (min-width: 992px) {
  .tb-odr-id {
    min-width: 120px;
  }
  .tb-odr-total {
    min-width: 50%;
  }
}
.is-compact .tb-tnx-item td,
.is-compact .tb-odr-item td {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
@media (max-width: 767px) {
  .is-compact .tb-tnx-item,
  .is-compact .tb-odr-item {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .is-compact .tb-tnx-item td,
  .is-compact .tb-odr-item td {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.nk-tb-list {
  display: table;
  width: 100%;
  font-size: 13px;
  color: #8094ae;
}
.nk-tb-list.is-alt {
  color: #526484;
}
.nk-tb-list .list-status,
.nk-tb-list .tb-status {
  font-size: 12px;
  font-weight: 500;
}
.nk-tb-list .tb-lead,
.nk-tb-list .tb-amount {
  font-weight: 500;
  color: #364a63;
  display: block;
  line-height: 1.4;
}
.nk-tb-list .tb-lead-sub,
.nk-tb-list .tb-amount-sub {
  color: #526484;
  display: block;
  line-height: 1.4;
}
.nk-tb-list .tb-sub,
.nk-tb-list .tb-amount-sm {
  font-size: 0.9em;
}
.nk-tb-list .tb-amount span,
.nk-tb-list .tb-amount .currency {
  color: #526484;
  font-weight: 400;
}
.nk-tb-list .user-info .tb-lead + span,
.nk-tb-list .tb-lead + .tb-date {
  font-size: 11px;
}
.nk-tb-list .tb-country {
  display: flex;
  align-items: center;
}
.nk-tb-list .tb-country .name {
  color: #364a63;
}
.nk-tb-list .tb-country .flag {
  height: 16px;
  border-radius: 3px;
}
.nk-tb-list .tb-country .flag + .name {
  margin-left: 0.6875rem;
}
.nk-tb-list .tb-product {
  display: flex;
  align-items: center;
}
.nk-tb-list .tb-product .thumb {
  width: 48px;
  border-radius: 4px;
  margin-right: 1rem;
}
.nk-tb-list .tb-product .title {
  font-weight: 700;
  font-size: 0.875rem;
  color: #364a63;
}
.nk-tb-list .tb-asterisk {
  font-size: 1.125rem;
  line-height: 1;
  vertical-align: middle;
}
.nk-tb-list .tb-asterisk a {
  color: #11c8fc;
}
.nk-tb-item {
  transition: background-color 0.3s, box-shadow 0.3s;
  display: table-row;
}
.nk-tb-item:not(.nk-tb-head):hover,
.nk-tb-item:not(.nk-tb-head).seleted {
  background: #f8f9fc;
  box-shadow: 0 0 10px -4px rgba(54, 74, 99, 0.2);
}
.nk-tb-flush .nk-tb-item {
  background: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
}
.nk-tb-col {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  padding: 1rem 0.5rem;
}
.nk-tb-col:first-child {
  padding-left: 1.25rem;
}
.nk-tb-flush .nk-tb-col:first-child {
  padding-left: 0 !important;
}
.nk-tb-col:last-child {
  padding-right: 1.25rem;
}
.nk-tb-flush .nk-tb-col:last-child {
  padding-right: 0 !important;
}
.nk-tb-col-tools {
  max-width: 3.75rem;
}
.nk-tb-col-check {
  width: 3rem;
}
.nk-tb-col-check .custom-control {
  vertical-align: middle;
}
.nk-tb-item:not(:last-child):not(.nk-tb-head) .nk-tb-col {
  border-bottom: 1px solid #dbdfea;
}
.nk-tb-dashed .nk-tb-item:not(:last-child):not(.nk-tb-head) .nk-tb-col {
  border-bottom-style: dashed;
}
.nk-tb-head .nk-tb-col {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #8094ae;
  font-size: 0.9em;
  border-bottom: 1px solid #dbdfea;
}
.nk-tb-col-action {
  text-align: right;
}
.nk-tb-col-action > .dropdown:last-child {
  right: -0.5rem;
}
.nk-tb-col-nosort:before,
.nk-tb-col-nosort:after {
  display: none !important;
}
.nk-tb-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.nk-tb-ulist .nk-tb-actions,
.nk-tb-actions-adj .nk-tb-actions {
  right: -0.5rem;
}
.nk-tb-actions.visible1 {
  width: 1rem;
}
.nk-tb-action-hidden {
  opacity: 0;
  transition: 0.3s ease;
}
.nk-tb-item:hover .nk-tb-action-hidden {
  opacity: 1;
  background: #f8f9fc;
}
.nk-tb-list .nk-tb-head .btn-trigger .icon {
  font-size: 1.5em;
}
.nk-tb-list.is-separate {
  margin-top: -8px;
  border-collapse: separate;
  border-spacing: 0 8px;
}
.nk-tb-list.is-separate .nk-tb-item > .nk-tb-col {
  background: #fff;
  border: none;
  box-shadow: 0px 1px 3px 0px rgba(54, 74, 99, 0.05);
}
.nk-tb-list.is-separate .nk-tb-item > .nk-tb-col:first-child {
  border-radius: 4px 0 0 4px;
}
.nk-tb-list.is-separate .nk-tb-item > .nk-tb-col:last-child {
  border-radius: 0 4px 4px 0;
}
.nk-tb-list.is-separate .nk-tb-item:hover .nk-tb-action-hidden {
  background: #fff;
}
.nk-tb-list.is-separate .nk-tb-head .nk-tb-col {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .nk-tb-col-check {
    width: 3.25rem;
  }
  .nk-tb-item .nk-tb-col:first-child {
    padding-left: 1.5rem;
  }
  .nk-tb-item .nk-tb-col:last-child {
    padding-right: 1.5rem;
  }
}
@media (min-width: 1540px) {
  .nk-tb-list {
    font-size: 14px;
  }
  .nk-tb-list .list-status,
  .nk-tb-list .tb-status {
    font-size: 13px;
  }
  .nk-tb-list .user-info .tb-lead + span,
  .nk-tb-list .tb-lead + .tb-date {
    font-size: 12px;
  }
}
.is-loose .nk-tb-item:not(.nk-tb-head) .nk-tb-col {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}
.is-medium .nk-tb-item:not(.nk-tb-head) .nk-tb-col {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.is-compact .nk-tb-item:not(.nk-tb-head) .nk-tb-col {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.nk-tnx-type {
  display: flex;
  align-items: center;
}
.nk-tnx-type-icon {
  font-size: 1.125rem;
  flex-shrink: 0;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #e5e9f2;
  margin-right: 1rem;
}
@media (max-width: 991.98px) {
  .nk-tb-tnx {
    font-size: 12px;
    letter-spacing: -0.01em;
  }
  .nk-tnx-type-icon {
    font-size: 0.875rem;
    height: 1.75rem;
    width: 1.75rem;
    margin-right: 0.75rem;
  }
}
@media (max-width: 575.98px) {
  .nk-tb-tnx .nk-tb-head .nk-tb-col-tools {
    opacity: 0;
  }
  .nk-tb-tnx .nk-tb-col-status {
    text-align: right;
    padding-right: 1.25rem !important;
  }
  .nk-tb-tnx .nk-tb-col-tools {
    position: absolute;
    right: 0;
    opacity: 0;
    transition: 0.3s ease;
  }
  .nk-tb-tnx .nk-tb-item:hover .nk-tb-col-tools {
    opacity: 1;
  }
  .nk-tnx-type-icon {
    margin-right: 0.5rem;
  }
}
@media (min-width: 768px) {
  .nk-tb-tnx .nk-tb-col-status {
    padding-left: 2.5rem;
  }
}
@media (min-width: 1800px) {
  .nk-tb-tnx .nk-tb-col-status {
    padding-left: 6rem;
  }
}
.card-full .nk-wg1 {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.nk-wg1-block {
  display: flex;
  padding: 1.25rem;
  align-items: flex-start;
  flex-direction: column;
}
.nk-wg1-img {
  width: 84px;
  margin-right: 0;
  margin-bottom: 1.5rem;
  flex-shrink: 0;
  text-align: center;
  padding: 0.125rem;
}
.nk-wg1-text {
  color: #8094ae;
}
.nk-wg1-action {
  margin-top: auto;
  border-top: 1px solid #e5e9f2;
}
.nk-wg1-action .link {
  position: relative;
  display: block;
  line-height: 1.3;
  padding: 1.25rem;
  padding-right: 2.5rem;
  white-space: inherit;
}
.nk-wg1-action .link:hover {
  background-color: #f5f6fa;
}
.nk-wg1-action .link .icon {
  position: absolute;
  right: 1.25rem;
  margin-right: -0.25rem;
  margin-top: -1px;
}
.nk-wg1-action .link-inline {
  display: inline-block;
  padding-right: 0.25rem;
}
.nk-wg1-action .link-inline .icon {
  position: static;
  padding-left: 0.25rem;
  margin-right: 0;
}
@media (min-width: 576px) {
  .nk-wg1-block {
    padding: 1.5rem;
  }
  .nk-wg1-action .link {
    padding: 1.5rem;
    padding-right: 2.75rem;
  }
  .nk-wg1-action .link-inline {
    padding-right: 0.25rem;
  }
}
@media (min-width: 1200px) {
  .nk-wg1-img {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }
  .nk-wg1-block {
    flex-direction: row;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.nk-kyc-app-icon {
  margin-bottom: 2rem;
}
.nk-kyc-app-icon .icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  font-size: 36px;
  border-radius: 50%;
  color: #8094ae;
  border: 2px solid #dbdfea;
}
.nk-kyc-app-text:not(:last-child) {
  margin-bottom: 2rem;
}
.nk-kycfm-head {
  display: flex;
  align-items: center;
}
.nk-kycfm-head,
.nk-kycfm-content,
.nk-kycfm-footer {
  padding: 1.5rem;
}
.nk-kycfm-head:not(:last-child),
.nk-kycfm-content:not(:last-child),
.nk-kycfm-footer:not(:last-child) {
  border-bottom: 1px solid #e5e9f2;
}
.nk-kycfm-count {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  font-size: 16px;
  border-radius: 50%;
  color: #526484;
  border: 2px solid #dbdfea;
  margin-right: 1rem;
  flex-shrink: 0;
}
.nk-kycfm-title .title {
  margin: 0.25rem 0;
}
.nk-kycfm-content .title {
  margin-bottom: 0.75rem;
}
.nk-kycfm-note {
  color: #8094ae;
  display: flex;
}
.nk-kycfm-note:not(:last-child) {
  margin-bottom: 1rem;
}
.nk-kycfm-note .icon {
  margin-right: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.65;
}
.nk-kycfm-label {
  display: flex;
  align-items: center;
  border: 2px solid #e5e9f2;
  border-radius: 4px;
  padding: 0.5rem 2.875rem 0.5rem 1rem;
  font-size: 13px;
  line-height: 1.3;
  letter-spacing: -0.01rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
  margin-bottom: 0;
}
.nk-kycfm-label::after {
  position: absolute;
  right: 1.375rem;
  top: 50%;
  height: 20px;
  width: 20px;
  line-height: 20px;
  font-size: 12px;
  border-radius: 50%;
  transform: translateY(-50%);
  font-family: "Nioicon";
  content: "";
  color: #fff;
  background: #11c8fc;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
}
.nk-kycfm-label-icon {
  position: relative;
  width: 34px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 12px;
}
.nk-kycfm-label-icon .label-icon {
  transition: all 0.3s;
  color: #8094ae;
  width: 100%;
  height: 28px;
}
.nk-kycfm-control {
  position: absolute;
  height: 1px;
  width: 1px;
  opacity: 0;
}
.nk-kycfm-control-list {
  display: flex;
  flex-wrap: wrap;
}
.nk-kycfm-control-list + * {
  margin-top: 1.25rem;
}
.nk-kycfm-control-item {
  position: relative;
  flex-grow: 1;
}
.nk-kycfm-control:checked ~ .nk-kycfm-label {
  border-color: #11c8fc;
}
.nk-kycfm-control:checked ~ .nk-kycfm-label:after {
  opacity: 1;
}
.nk-kycfm-control:checked ~ .nk-kycfm-label .label-icon {
  color: #11c8fc;
}
.nk-kycfm-upload:not(:first-child) {
  margin-top: 1.5rem;
}
@media (min-width: 576px) {
  .nk-kycfm-head,
  .nk-kycfm-content,
  .nk-kycfm-footer {
    padding: 1.5rem 2.25rem;
  }
  .nk-kycfm-content {
    padding-bottom: 1.75rem;
  }
  .nk-kycfm-footer {
    padding: 2.25rem;
  }
}
.nk-refwg {
  display: flex;
  flex-wrap: wrap;
}
.nk-refwg-invite,
.nk-refwg-stats {
  width: 100%;
}
.nk-refwg-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
}
.nk-refwg-group {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 30px;
}
.nk-refwg-name .title {
  font-size: 13px;
  color: #11c8fc;
}
.nk-refwg-name .title .icon {
  color: #8094ae;
  font-size: 0.75rem;
  margin-left: 0.125rem;
}
.nk-refwg-info {
  flex-grow: 1;
  text-align: center;
  display: flex;
  justify-content: space-around;
}
.nk-refwg-info .title {
  font-size: 1.125rem;
  font-weight: 500;
}
.nk-refwg-more {
  position: absolute;
  right: -4px;
  top: -2px;
}
.nk-refwg .nk-refwg-invite {
  border-bottom: 1px solid #dbdfea;
}
.nk-refwg-ck {
  height: 50px;
  margin-top: 0.75rem;
}
@media (min-width: 768px) {
  .nk-refwg .nk-refwg-invite {
    width: 55%;
    border-bottom: none;
    border-right: 1px solid #dbdfea;
  }
  .nk-refwg .nk-refwg-stats {
    width: 45%;
  }
}
.timeline {
  color: #8094ae;
  line-height: 1.3;
}
.timeline + .timeline,
.timeline-list + .timeline-head {
  margin-top: 1.75rem;
}
.timeline-head {
  font-size: 14px;
  color: #8094ae;
  margin-bottom: 1rem;
}
.timeline-item {
  position: relative;
  display: flex;
  align-items: flex-start;
}
.timeline-item:not(:last-child) {
  padding-bottom: 1.5rem;
}
.timeline-item:not(:last-child):before {
  position: absolute;
  height: calc(100% - 11px);
  width: 1px;
  background: #dbdfea;
  content: "";
  top: 13px;
  left: 5px;
}
.timeline-status {
  position: relative;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-top: 2px;
}
.timeline-status.is-outline:after {
  position: absolute;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: #fff;
  content: "";
  top: 2px;
  left: 2px;
}
.timeline-date {
  position: relative;
  color: #8094ae;
  width: 90px;
  margin-left: 0.75rem;
  flex-shrink: 0;
  line-height: 1rem;
}
.timeline-date .icon {
  vertical-align: middle;
  color: #8094ae;
  display: inline-block;
  position: absolute;
  margin-right: 0.25rem;
  right: 0;
  top: 2px;
}
.timeline-data {
  padding-left: 8px;
}
.timeline-title {
  font-size: 15px;
  color: #364a63;
  margin-bottom: 0.75rem;
}
.timeline-des {
  color: #8094ae;
}
.timeline-des p {
  margin-bottom: 0.25rem;
}
.timeline .time {
  display: block;
  font-size: 12px;
  color: #8094ae;
}
@media (min-width: 576px) {
  .timeline + .timeline,
  .timeline-list + .timeline-head {
    margin-top: 2.5rem;
  }
}
@media (max-width: 413px) {
  .timeline-item {
    flex-wrap: wrap;
  }
  .timeline-date {
    width: 80px;
  }
  .timeline-data {
    padding: 0.75rem 0 0 24px;
  }
}
.coin-item {
  display: flex;
  align-items: center;
  padding: 16px 20px;
}
.coin-icon {
  display: inline-flex;
  flex-shrink: 0;
}
.coin-icon .icon {
  font-size: 31px;
  height: 32px;
  width: 32px;
  color: #8094ae;
}
.coin-btc .coin-icon .icon {
  color: #f9841e;
}
.coin-icon + .coin-info {
  margin-left: 12px;
}
.coin-name {
  font-size: 14px;
  color: #364a63;
  display: block;
}
.coin-text {
  font-size: 12px;
  color: #8094ae;
  display: block;
}
.invoice {
  position: relative;
}
.invoice-wrap {
  padding: 1.25rem;
  border-radius: 4px;
  background: #fff;
}
.invoice-action {
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  z-index: 1;
}
.invoice-brand {
  padding-bottom: 1.5rem;
}
.invoice-brand img {
  max-height: 60px;
}
.invoice-bills {
  font-size: 12px;
}
.invoice-bills .table {
  min-width: 580px;
}
.invoice-bills .table th {
  color: #11c8fc;
  font-size: 12px;
  text-transform: uppercase;
  border-top: 0;
}
.invoice-bills .table th:last-child,
.invoice-bills .table td:last-child {
  text-align: right;
}
.invoice-bills .table tfoot {
  border-top: 1px solid #dbdfea;
}
.invoice-bills .table tfoot td {
  border-top: 0;
  white-space: nowrap;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.invoice-bills .table tfoot tr:last-child td:not(:first-child),
.invoice-bills .table tfoot tr:first-child td:not(:first-child) {
  font-weight: 500;
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}
.invoice-bills .table tfoot tr:last-child td:not(:first-child) {
  border-top: 1px solid #dbdfea;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.invoice-head {
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.invoice-desc {
  width: 210px;
  padding-top: 1.5rem;
}
.invoice-desc .title {
  text-transform: uppercase;
  color: #11c8fc;
}
.invoice-desc ul li {
  padding: 0.25rem 0;
}
.invoice-desc ul span {
  font-size: 13px;
  font-weight: 500;
  color: #526484;
}
.invoice-desc ul span:first-child {
  min-width: 90px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #8094ae;
}
.invoice-desc ul span:last-child {
  padding-left: 0.75rem;
}
.invoice-contact .title {
  margin-bottom: 1rem;
}
.invoice-contact ul li {
  padding: 0.5rem 0;
  line-height: 1.3;
}
.invoice-contact ul li:first-child {
  padding-top: 0;
}
.invoice-contact ul li:last-child {
  padding-bottom: 0;
}
.invoice-contact ul .icon {
  line-height: 1.3;
  font-size: 1.1em;
  display: inline-block;
  vertical-align: top;
  margin-top: -2px;
  color: #11c8fc;
  margin-right: 0.5rem;
}
.invoice-contact ul .icon + span {
  display: inline-block;
  vertical-align: top;
  color: #8094ae;
}
.invoice-print {
  max-width: 940px;
  margin: 2rem auto;
}
.invoice-print .invoice-wrap {
  padding: 0;
  border: none !important;
}
@media (min-width: 768px) {
  .invoice-wrap {
    padding: 3rem;
  }
  .invoice-head {
    flex-direction: row;
  }
  .invoice-desc {
    padding-top: 0;
  }
  .invoice-bills {
    font-size: 0.875rem;
  }
}
.pricing {
  position: relative;
  height: 100%;
}
.pricing-badge {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
.pricing-head {
  text-align: center;
  padding: 1.75rem 1.5rem;
  border-bottom: 1px solid #e5e9f2;
}
.pricing-title {
  max-width: 100% !important;
}
.pricing-title .title {
  margin-bottom: 0.25rem;
}
.pricing-title:not(:last-child) {
  padding-bottom: 1.25rem;
}
.pricing-body {
  padding: 1.75rem 1.75rem 2rem;
}
.pricing-features li {
  display: flex;
  padding: 0.25rem 0;
}
.pricing-features .icon {
  margin-top: 0.175rem;
}
.pricing-action {
  margin-top: 1.5rem;
  text-align: center;
}
.pricing-media {
  padding: 0.25rem 0;
  margin-bottom: 1.5rem;
}
.pricing-media img {
  max-width: 90px;
}
.pricing-amount {
  font-size: 0.875rem;
  color: #8094ae;
}
.pricing-amount .amount {
  font-size: 1.5rem;
  font-weight: 700;
  color: #526484;
}
.nk-add-product {
  background-color: #fff;
  width: 400px;
  height: 100vh;
  padding: 1.5rem;
}
.rating {
  display: flex;
  align-items: center;
  margin: 0 -0.125rem;
  color: #fd9722;
}
.rating > * {
  padding: 0 0.125rem;
}
.rating .icon {
  font-size: 1rem;
}
.product-card {
  overflow: hidden;
  height: 100%;
}
.product-thumb {
  position: relative;
}
.product-badges {
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin: -0.25rem;
}
.product-badges > li {
  padding: 0.25rem;
}
.product-tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.product-tags > li {
  padding: 0.25rem;
}
.product-tags a {
  color: #8094ae;
}
.product-tags a:hover {
  color: #11c8fc;
}
.product-tags + .product-title {
  margin-top: 0.5rem;
}
.product-title a {
  color: #364a63;
}
.product-title + .product-price {
  margin-top: 1rem;
}
.product-actions {
  position: absolute;
  bottom: 0.25rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  transition: 0.2s linear;
  display: flex;
  opacity: 0;
}
.product-card:hover .product-actions {
  opacity: 1;
}
.product-actions li {
  padding: 0 0.125rem;
}
.product-actions li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #526484;
  font-size: 15.6px;
  height: 2.125rem;
  width: 2.125rem;
  transition: 0.3s ease;
}
.product-actions li a:hover {
  color: #11c8fc;
}
.product-gallery {
  position: relative;
  height: auto;
  margin: 0 0 30px 0;
  border-radius: 4px;
  border: 1px solid #e5e9f2;
}
.product-gallery .slick-list {
  padding: 0 !important;
}
.product-gallery .slider-nav {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: 282px;
}
.product-gallery .slider-nav .slider-item {
  padding: 0 8px;
}
.product-gallery .slider-nav .slider-item .thumb {
  width: 78px;
  padding: 1px;
  border: 2px solid #e5e9f2;
  box-shadow: inset 1px 1px 0 #fff, inset -1px -1px 0 #fff,
    inset 1px -1px 0 #fff, inset -1px 1px 0 #fff;
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: pointer;
}
.product-gallery .slider-nav .slider-item.slick-current .thumb {
  border-color: #11c8fc;
  cursor: default;
}
.product-rating {
  display: flex;
  align-items: center;
}
.product-rating .amount {
  margin-left: 0.5rem;
  color: #8094ae;
}
.product-excrept {
  margin-top: 1.5rem;
}
.product-meta {
  margin-top: 2rem;
}
.product-meta + .product-meta {
  margin-top: 1.25rem;
}
.product-slider .slick-track {
  display: flex;
}
.product-slider .slick-slide {
  height: auto;
}
@media (max-width: 420px) {
  .product-gallery .slider-nav {
    width: 188px;
  }
}
@media (min-width: 768px) {
  .product-gallery .slider-nav {
    width: 384px;
  }
}
@media (min-width: 1540px) {
  .product-gallery .slider-nav {
    width: 470px;
  }
}
.gallery-image {
  position: relative;
  z-index: 1;
}
.nk-cmwg1 {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.nk-cmwg1-ck {
  height: 70px;
}
.nk-cmwg2-ck {
  height: 270px;
}
.nk-cmwg3-ck {
  height: 140px;
}
.nk-cmwg4-ck {
  height: 180px;
}
.nk-order-ovwg-ck {
  height: 180px;
}
.nk-order-ovwg-data {
  padding: 0.75rem 1.25rem 1.25rem;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
}
.nk-order-ovwg-data .amount {
  font-size: 1.5rem;
  font-weight: 700;
}
.nk-order-ovwg-data .amount small,
.nk-order-ovwg-data .amount .small {
  font-weight: 400;
}
.nk-order-ovwg-data .info {
  font-size: 12px;
  color: #8094ae;
  margin-bottom: 0.25rem;
}
.nk-order-ovwg-data .info strong {
  color: #364a63;
}
.nk-order-ovwg-data .title {
  font-size: 14px;
  line-height: 1.3;
  color: #8094ae;
  font-weight: 500;
}
.nk-order-ovwg-data .title .icon {
  font-size: 1rem;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background: #f5f6fa;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}
.nk-order-ovwg-data.buy {
  border-color: #87e5bd;
}
.nk-order-ovwg-data.buy .amount,
.nk-order-ovwg-data.buy .title .icon {
  color: #0fca7a;
}
.nk-order-ovwg-data.sell {
  border-color: #b99df5;
}
.nk-order-ovwg-data.sell .amount,
.nk-order-ovwg-data.sell .title .icon {
  color: #11c8fc;
}
@media (min-width: 1540px) {
  .nk-order-ovwg-ck {
    height: 260px;
  }
}
.nk-wg-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nk-wg-action-content {
  position: relative;
  padding-left: 2rem;
  padding-right: 0.5rem;
}
.nk-wg-action-content .icon {
  font-size: 1.5rem;
  left: 0;
  position: absolute;
  color: #8094ae;
}
.nk-wg-action-content .title {
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 0.25rem;
}
.nk-wg-action-content p {
  color: #8094ae;
  font-size: 13px;
  line-height: 1.25rem;
}
.nk-wg-action-content p strong {
  color: #11c8fc;
}
.nk-coin-ovwg {
  display: flex;
}
.nk-coin-ovwg-ck {
  flex-grow: 1;
  height: 165px;
}
.nk-coin-ovwg-legends {
  width: 100px;
  flex-shrink: 0;
}
.nk-coin-ovwg-legends li {
  display: flex;
  align-items: center;
  line-height: 1.25rem;
  font-size: 12px;
  padding: 0.25rem 0;
  color: #8094ae;
}
.nk-coin-ovwg-legends li .dot {
  margin-right: 0.5rem;
}
.nk-tb-orders-type {
  width: 66px;
}
.nk-sale-data {
  flex-shrink: 0;
}
.nk-sale-data-group {
  display: flex;
  flex-wrap: wrap;
}
.nk-sale-data .amount {
  display: block;
  font-size: 1.75rem;
  color: #364a63;
}
.nk-sale-data .amount .change {
  font-size: 0.875rem;
}
.nk-sale-data .amount.sm {
  font-size: 1.125rem;
  color: #8094ae;
}
.nk-sale-data .sub-title {
  color: #8094ae;
  font-size: 12px;
}
.nk-sale-data .sub-title .change {
  margin-right: 0.5rem;
}
.nk-sales-ck {
  height: 80px;
  flex-grow: 1;
}
.nk-sales-ck.large {
  height: 200px;
}
@media (min-width: 992px) and (max-width: 1539.98px) {
  .nk-sales-ck.sales-revenue {
    height: 164px;
  }
}
.nk-activity-item {
  display: flex;
  align-items: center;
  padding: 1.25rem;
}
.nk-activity-item:not(:last-child) {
  border-bottom: 1px solid #e5e9f2;
}
.nk-activity-data {
  margin-left: 1rem;
}
.nk-activity-data .time {
  display: block;
  font-size: 12px;
  color: #8094ae;
  line-height: 1.3;
}
@media (min-width: 576px) {
  .nk-activity-item {
    display: flex;
    padding: 1rem 1.5rem;
  }
}
.nk-support-item {
  display: flex;
  padding: 1.25rem;
}
.nk-support-item:not(:last-child) {
  border-bottom: 1px solid #e5e9f2;
}
.nk-support-content {
  flex-grow: 1;
  margin-left: 1rem;
}
.nk-support-content .title {
  font-size: 0.875rem;
  font-weight: 700;
  color: #364a63;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nk-support-content p {
  margin-bottom: 0;
  margin-top: 0.25rem;
}
.nk-support-content .time {
  display: block;
  font-size: 12px;
  color: #8094ae;
}
@media (min-width: 576px) {
  .nk-support-item {
    display: flex;
    padding: 1.25rem 1.5rem;
  }
}
.device-status {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}
.device-status-ck {
  height: 160px;
  margin: auto 0;
}
.device-status-group {
  display: flex;
  justify-content: space-between;
  padding-top: 1.75rem;
  margin: auto;
  width: 280px;
  max-width: 100%;
}
.device-status-data > .icon {
  font-size: 1.5rem;
}
.device-status-data .title {
  font-size: 12px;
  color: #8094ae;
  margin-bottom: 0.5rem;
}
.device-status-data .amount {
  font-size: 1.125rem;
  color: #364a63;
  font-weight: 500;
}
.device-status-data .change {
  margin-left: -0.125rem;
}
.traffic-channel {
  margin-top: 1rem;
}
.traffic-channel-ck {
  height: 44px;
  width: 130px;
  margin-top: -4px;
  margin-bottom: -4px;
}
.traffic-channel-doughnut-ck {
  height: 160px;
}
.traffic-channel-group {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1.75rem;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 320px;
  max-width: 100%;
}
.traffic-channel-data {
  width: 50%;
}
.traffic-channel-data .title {
  display: flex;
  align-items: center;
  color: #8094ae;
  font-size: 12px;
}
.traffic-channel-data .title .dot {
  margin-right: 0.5rem;
}
.traffic-channel-data .amount {
  font-size: 1.125rem;
  color: #364a63;
  margin-left: 1.25rem;
}
.traffic-channel-data .amount small,
.traffic-channel-data .amount .small {
  font-size: 12px;
  color: #8094ae;
}
@media (max-width: 575.98px) {
  .traffic-channel-ck {
    width: 100%;
  }
  .traffic-channel-table .nk-tb-item {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e5e9f2;
    padding: 1.125rem 0;
  }
  .traffic-channel-table .nk-tb-col {
    border-bottom: none !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .traffic-channel-table .nk-tb-channel {
    width: 50%;
  }
  .traffic-channel-table .nk-tb-sessions {
    width: 25%;
  }
  .traffic-channel-table .nk-tb-prev-sessions {
    display: none;
  }
  .traffic-channel-table .nk-tb-change {
    width: 25%;
  }
  .traffic-channel-table .nk-tb-trend {
    width: 100%;
    padding-left: 1.25rem;
  }
}
@media (min-width: 1540px) and (max-width: 1800px) {
  .traffic-channel-data .amount {
    font-size: 1rem;
  }
}
.analytic-data-group {
  display: flex;
  flex-wrap: wrap;
}
.analytic-data .title {
  font-size: 12px;
  font-weight: 500;
  color: #8094ae;
  margin-bottom: 0.375rem;
}
.analytic-data .title span {
  font-weight: 400;
}
.analytic-data .amount {
  color: #364a63;
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: 0.25rem;
}
.analytic-data .amount-sm {
  font-size: 1.125rem;
}
.analytic-data .change {
  font-size: 13px;
  margin-left: -0.125rem;
}
.analytic-data .subtitle {
  font-size: 12px;
  color: #8094ae;
}
@media (min-width: 1540px) and (max-width: 1800px) {
  .analytic-data .amount {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .analytic-data .amount-sm {
    font-size: 1.125rem;
  }
}
.analytic-ov-data {
  width: 50%;
}
.analytic-ov-ck {
  height: 175px;
  margin-top: 2rem;
}
@media (min-width: 480px) {
  .analytic-ov-data {
    width: 25%;
  }
}
.analytic-au-data {
  width: 33.3333%;
}
.analytic-au-ck {
  height: 170px;
  margin-top: 2.25rem;
}
.analytic-wp-data {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.analytic-wp-ck {
  height: 36px;
}
.analytic-wp-graph {
  width: 150px;
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}
.analytic-wp-graph .title {
  margin-bottom: 0;
}
.analytic-wp-text {
  text-align: right;
  margin-left: 0.5rem;
}
@media (min-width: 420px) and (max-width: 767px) {
  .analytic-wp-graph {
    width: 60%;
  }
}
@media (min-width: 1540px) and (max-width: 1800px) {
  .analytic-wp-graph {
    width: 120px;
  }
}
.chart-label {
  font-size: 12px;
  color: #8094ae;
}
.chart-label-group {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
}
.analytics-map .vector-map {
  height: 160px;
  margin: 1rem 0;
}
.analytics-map-data {
  font-size: 0.875rem;
}
.analytics-map-data td {
  padding: 0.25rem 0;
}
.analytics-map-data .country {
  color: #8094ae;
}
.analytics-map-data .amount {
  color: #364a63;
  text-align: right;
}
.analytics-map-data .percent {
  text-align: right;
  color: #8094ae;
}
.analytics-map-data-list {
  width: 100%;
  margin-bottom: -0.25rem;
}
.card-title .subtitle {
  color: #8094ae;
  font-size: 13px;
  font-weight: 500;
}
.card-amount {
  display: flex;
  align-items: baseline;
}
.card-amount .change {
  margin-left: 0.5rem;
}
.card-amount .amount {
  font-size: 1.5rem;
  color: #364a63;
}
.card-amount .amount span {
  color: #526484;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .card-amount .amount {
    font-size: 1rem;
    font-weight: 500;
  }
  .card-amount .amount span {
    font-weight: 400;
  }
  .card-amount .change {
    font-size: 12px;
  }
}
.card-inner .nav-tabs-card {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}
@media (min-width: 576px) {
  .card-inner .nav-tabs-card {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
}
.nav-tabs-xs .nav-link {
  padding: 0.75rem 0;
  font-size: 13px;
  font-weight: 500;
}
@media (min-width: 768px) {
  .nav-tabs-xs .nav-item {
    padding-right: 1.25rem;
  }
}
@media (min-width: 992px) {
  .nav-tabs-sm .nav-item {
    padding-right: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .nav-tabs-sm .nav-item {
    padding-right: 1.25rem;
  }
}
.invest-data {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.invest-data-history {
  margin-right: 20px;
  flex-grow: 1;
}
.invest-data-history .title {
  font-size: 11px;
  color: #8094ae;
  text-transform: uppercase;
  margin-bottom: 0.125rem;
  letter-spacing: 0.1em;
}
.invest-data-history .amount {
  font-size: 0.9375rem;
  line-height: 1.1;
  color: #364a63;
}
.invest-data-history .amount span {
  color: #526484;
}
.invest-data-amount {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
}
.invest-data-ck {
  flex-grow: 1;
  height: 48px;
  width: 80px;
  margin-bottom: 0.125rem;
}
@media (min-width: 768px) and (max-width: 1540px), (max-width: 359px) {
  .invest-data {
    margin-top: 0.75rem;
  }
  .invest-data-amount {
    flex-wrap: wrap;
    flex-shrink: 1;
  }
  .invest-data-ck {
    height: 68px;
    width: 140px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .invest-data-history .amount {
    font-size: 0.75rem;
    font-weight: 700;
  }
  .invest-data-history .amount span {
    font-weight: 400;
  }
}
.invest-ov {
  padding: 1.25rem 0 1rem;
}
.invest-ov:last-child {
  padding-bottom: 0;
}
.invest-ov:not(:last-child) {
  border-bottom: 1px solid #e5e9f2;
}
.invest-ov .subtitle {
  color: #8094ae;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0.25rem;
}
.invest-ov .title {
  font-size: 11px;
  color: #8094ae;
  text-transform: uppercase;
  margin-top: 0.25rem;
  letter-spacing: 0.1em;
}
.invest-ov .amount {
  font-size: 1.25rem;
  line-height: 1.1;
  color: #364a63;
}
.invest-ov .amount span {
  color: #526484;
}
.invest-ov-details {
  display: flex;
}
.invest-ov-info {
  width: 60%;
}
.invest-ov-stats {
  width: 40%;
}
.invest-ov-stats > div {
  display: flex;
  align-items: baseline;
}
.invest-ov-stats .change {
  margin-left: 0.25rem;
}
.invest-top-ck {
  padding-top: 20px;
  height: 70px;
}
.nk-editor {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - (65px + 78px + 60px));
}
.nk-editor-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.125rem 1.5rem;
  border-bottom: 1px solid #e5e9f2;
}
.nk-editor-title {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nk-editor-tools {
  display: flex;
  align-items: center;
}
.nk-editor-main {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}
.nk-editor-body {
  flex-grow: 1;
  font-family: "Nunito";
  line-height: 1.4;
  font-size: 0.875rem;
  color: #556581;
}
.nk-editor-body table {
  border-collapse: collapse;
}
.nk-editor-body table td,
.nk-editor-body table th {
  border: 1px solid #ccc;
  padding: 0.4rem;
}
.nk-editor-body figure {
  display: table;
  margin: 1rem auto;
}
.nk-editor-body figure figcaption {
  color: #999;
  display: block;
  margin-top: 0.25rem;
  text-align: center;
}
.nk-editor-body hr {
  border-color: #ccc;
  border-style: solid;
  border-width: 1px 0 0 0;
}
.nk-editor-body code {
  background-color: #e8e8e8;
  border-radius: 3px;
  padding: 0.1rem 0.2rem;
}
.nk-editor-body h1,
.nk-editor-body .h1 {
  font-size: 2em;
  font-weight: bold;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  color: inherit !important;
}
.nk-editor-body h2,
.nk-editor-body .h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  color: inherit !important;
}
.nk-editor-body h3,
.nk-editor-body .h3 {
  font-size: 1.17em;
  font-weight: bold;
  margin-block-start: 1em;
  margin-block-end: 1em;
  color: inherit !important;
}
.nk-editor-body h4,
.nk-editor-body .h4 {
  font-size: 1em;
  font-weight: bold;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  color: inherit !important;
}
.nk-editor-body h5,
.nk-editor-body .h5 {
  font-size: 0.83em;
  font-weight: bold;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  color: inherit !important;
}
.nk-editor-body h6,
.nk-editor-body .h6 {
  font-size: 0.67em;
  font-weight: bold;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
  color: inherit !important;
}
.nk-editor-body p {
  font-size: 1em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  color: inherit !important;
}
.nk-editor-style-clean + .tox-tinymce {
  border: none;
}
.nk-editor-style-clean + .tox .tox-toolbar,
.nk-editor-style-clean + .tox .tox-toolbar__primary,
.nk-editor-style-clean + .tox .tox-toolbar__overflow {
  border-bottom: 0;
}
.nk-editor-style-clean + .tox .tox-toolbar__group:not(:last-of-type) {
  border-right: 0;
}
.nk-editor-style-clean + .tox .tox-statusbar {
  display: none;
}
.nk-editor-style-clean + .tox .tox-editor-header {
  padding: 0.375rem 0.75rem 0;
}
.nk-editor-style-clean + .tox .tox-edit-area {
  padding: 0 0.625rem 0;
}
.nk-editor-full + .tox {
  min-height: 400px;
  height: 100% !important;
}
.nk-editor-base {
  width: 100%;
  border-bottom: 1px solid #e5e9f2;
}
@media (min-width: 1200px) {
  .nk-editor-main {
    flex-direction: row-reverse;
    flex-wrap: nowrap;
  }
  .nk-editor-base {
    flex-shrink: 0;
    width: 360px;
    border-left: 1px solid #e5e9f2;
    border-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .nk-editor-title {
    justify-content: start;
  }
}
@media (max-width: 1199.98px) {
  .nk-editor {
    min-height: calc(100vh - (65px + 78px + 30px));
  }
}
@media (max-width: 575.98px) {
  .nk-editor {
    min-height: calc(100vh - (65px + 116px + 30px));
  }
}
.nk-history {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-left: 0.5rem;
}
.nk-history-item {
  padding-left: 1.75rem;
  position: relative;
}
.nk-history-item:not(:first-child):before {
  position: absolute;
  left: 0;
  width: 1px;
  content: "";
  background: #e5e9f2;
  height: 50%;
  top: 0;
}
.nk-history-item:not(:last-child):after {
  position: absolute;
  left: 0;
  width: 1px;
  content: "";
  background: #e5e9f2;
  top: 50%;
  height: calc(50% + 2rem);
}
.nk-history-meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}
.nk-history-meta li {
  display: inline-flex;
  align-items: center;
  font-size: 0.875rem;
  color: #8094ae;
}
.nk-history-meta li:not(:last-child):after {
  content: "";
  display: inline-block;
  height: 0.25rem;
  width: 0.25rem;
  background: #8094ae;
  border-radius: 50%;
  margin: auto 0.5rem;
  vertical-align: middle;
}
.nk-history-symbol {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.nk-history-symbol-dot {
  border: 3px solid #11c8fc;
  border-radius: 50%;
  background: #fff;
  height: 1rem;
  width: 1rem;
}
.nk-history-symbol-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e9f2;
  border-radius: 0.5rem;
  background: #fff;
  height: 1.5rem;
  width: 1.5rem;
  font-size: 0.75rem;
  color: #364a63;
  transition: all 0.3s;
}
.nk-history-symbol-text {
  color: inherit;
}
.nk-history-expand {
  color: #1c2b46;
}
.nk-history-expand:hover {
  color: #11c8fc;
}
.nk-history-expand:hover .nk-history-symbol-button {
  border-color: #11c8fc;
  background: #11c8fc;
  color: #fff;
}
@media (min-width: 576px) {
  .nk-history {
    padding-left: 1rem;
  }
  .nk-history-item {
    padding-left: 2.75rem;
  }
}
.filter-button {
  border-radius: 999px;
  border: none;
  color: #526484;
  background-color: #fff;
  font-size: 0.813rem;
  line-height: 1.25rem;
  padding: 0.375rem 1rem;
  transition: 0.3s ease;
  box-shadow: 0 0 25px -12px rgba(31, 43, 58, 0.15);
}
.filter-button.active {
  background: #11c8fc;
  border-color: #11c8fc;
  color: #fff;
}
.filter-button-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.75rem;
}
.filter-animation-off .filter-item {
  transition: none !important;
}
.asterisk a {
  position: relative;
  color: #8094ae;
  display: inline-flex;
}
.asterisk a:hover,
.asterisk a.active {
  color: #ffa353;
}
.asterisk a:hover .asterisk-on,
.asterisk a.active .asterisk-on {
  opacity: 1;
}
.asterisk a.active:hover .asterisk-on {
  opacity: 0;
}
.asterisk-on {
  opacity: 0;
  position: absolute;
  left: 0;
  color: #ffa353;
  z-index: 1;
  transition: opacity 0.2s;
}
.nk-reply {
  height: 100%;
  max-height: 100%;
  overflow: auto;
}
.nk-reply-item {
  padding: 2rem 1.25rem;
}
.nk-reply-item + .nk-reply-item {
  padding-top: 0;
}
.nk-reply-item > div:not(.nk-reply-header) {
  margin-left: 48px;
}
.nk-reply-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.nk-reply-header .user-name {
  margin-left: 1rem;
  font-size: 0.875rem;
}
.nk-reply-header .user-name span {
  color: #8094ae;
  font-weight: 400;
}
.nk-reply-header .date-time {
  color: #8094ae;
  font-size: 12px;
}
.nk-reply-entry.note {
  background: #f5f6fa;
  padding: 1.25rem;
  border-radius: 4px;
}
.nk-reply-from {
  margin-top: 1.25rem;
  padding: 0.375rem 0.75rem;
  background: #f5f6fa;
  border-radius: 4px;
  display: inline-block;
  font-size: 13px;
}
.nk-reply-meta {
  overflow: hidden;
  text-align: center;
  margin: 0 1.25rem;
  padding: 0 2rem;
}
.nk-reply-meta-info {
  position: relative;
  display: inline-block;
  padding: 0 0.75rem;
  color: #8094ae;
  font-size: 13px;
  line-height: 1.4;
}
.nk-reply-meta-info span,
.nk-reply-meta-info strong,
.nk-reply-meta-info .who {
  color: #526484;
}
.nk-reply-meta-info .whom {
  color: #11c8fc;
}
.nk-reply-meta-info:before,
.nk-reply-meta-info:after {
  position: absolute;
  height: 1px;
  background: #e5e9f2;
  content: "";
  width: 100vw;
  top: 50%;
}
.nk-reply-meta-info:before {
  right: 100%;
}
.nk-reply-meta-info:after {
  left: 100%;
}
.nk-reply-form {
  border-top: 1px solid #e5e9f2;
}
.nk-reply-form-dropdown {
  margin-left: -0.5rem;
  margin-right: 0.75rem;
}
.nk-reply-form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.25rem;
  border-bottom: 1px solid #e5e9f2;
}
.nk-reply-form-title {
  display: flex;
  align-items: center;
}
.nk-reply-form-title .title {
  color: #8094ae;
  margin-right: 0.75rem;
}
.nk-reply-form-field {
  padding: 0.5rem 1.25rem;
}
.nk-reply-form-field:not(:last-child) {
  border-bottom: 1px solid #e5e9f2;
}
.nk-reply-form-field .form-control,
.nk-reply-form-field div.dataTables_wrapper div.dataTables_filter input,
div.dataTables_wrapper div.dataTables_filter .nk-reply-form-field input,
.nk-reply-form-field .dual-listbox .dual-listbox__search,
.dual-listbox .nk-reply-form-field .dual-listbox__search {
  font-size: 0.875rem;
}
.nk-reply-form-field textarea.form-control,
.nk-reply-form-field .dual-listbox textarea.dual-listbox__search,
.dual-listbox .nk-reply-form-field textarea.dual-listbox__search {
  min-height: 78px;
}
.nk-reply-form-field textarea.form-control.ex-large,
.nk-reply-form-field .dual-listbox textarea.ex-large.dual-listbox__search,
.dual-listbox .nk-reply-form-field textarea.ex-large.dual-listbox__search {
  min-height: 160px;
}
.nk-reply-form-field + .nk-reply-form-tools {
  border-top: 0;
}
.nk-reply-form-tools {
  padding: 1.25rem;
  border-top: 1px solid #e5e9f2;
  display: flex;
  justify-content: space-between;
}
.nk-reply-form-actions {
  display: inline-flex;
  align-items: center;
}
.nk-reply-form-options {
  display: inline-flex;
}
.nk-reply-form-input {
  display: flex;
}
.nk-reply-form-input .label {
  margin: 0.3rem 0.5rem 0.3rem 0;
  width: 1.25rem;
  color: #8094ae;
}
.nk-reply-form-input-group {
  width: 100%;
}
.nk-reply-form-input-cc,
.nk-reply-form-input-bcc {
  display: none;
}
.nk-reply-form-input-cc.expanded,
.nk-reply-form-input-bcc.expanded {
  display: flex;
}
.nk-reply-form-input .toggle-opt {
  display: inline-flex;
  align-items: center;
  color: #364a63;
}
.nk-reply-form-input .toggle-opt:hover {
  color: #11c8fc;
}
.nk-reply-form-group {
  position: relative;
  padding: 1rem 0;
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
}
.nk-reply-form-input-to {
  padding-right: 3.5rem;
}
.nk-reply-form-nav {
  position: absolute;
  display: flex;
  align-items: center;
  align-self: flex-start;
  padding: 1.0625rem 0 0;
  right: 0;
  top: 0;
  margin: 0 -0.375rem;
}
.nk-reply-form-nav li a {
  font-size: 12px;
  color: #8094ae;
  display: block;
  line-height: 1.5rem;
  padding: 0.25rem 0.375rem;
}
.nk-reply-form-nav li a:hover {
  color: #11c8fc;
}
.is-collapsed .nk-reply-msg-info {
  display: none;
}
.nk-reply-msg-excerpt {
  color: #526484;
  display: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.is-collapsed .nk-reply-msg-excerpt {
  display: block;
}
.nk-reply-msg-meta {
  padding: 0.75rem;
}
.nk-reply-msg-meta li {
  display: flex;
  line-height: 1.125rem;
  padding: 0.1875rem 0;
}
.nk-reply-msg-meta li .label {
  width: 75px;
  color: #8094ae;
  font-size: 12px;
  text-align: right;
  margin-right: 0.75rem;
}
.nk-reply-msg-meta li .info {
  font-size: 13px;
}
.nk-reply-msg-meta li .info a {
  color: #526484;
}
.nk-reply-tools {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: default;
}
.nk-reply-tools .date {
  margin-right: 1rem;
  line-height: 2.25rem;
  color: #526484;
}
.nk-reply-tools .attach-msg {
  font-size: 1.25rem;
  margin-right: 0.375rem;
}
.nk-reply-desc {
  display: flex;
  flex-grow: 1;
}
.is-collapsed .nk-reply-desc {
  overflow: hidden;
}
.nk-reply-header .nk-reply-desc {
  align-items: center;
}
.nk-reply-info {
  margin-left: 1rem;
  max-width: calc(100% - 4.5rem);
}
.nk-reply-header.is-collapsed {
  margin-bottom: 0;
}
.nk-reply-header:not(.is-opened) {
  cursor: pointer;
}
.is-collapsed + .nk-reply-body {
  display: none;
}
.nk-reply-quick-btns ul {
  display: flex;
  align-items: center;
}
.is-collapsed .prev-msg,
.is-collapsed .next-msg,
.is-collapsed .more-actions,
.is-collapsed .replyto-msg,
.is-collapsed .forwardto-msg {
  display: none;
}
.input-mail {
  width: 100%;
  border: none;
}
@media (max-width: 767.98px) {
  .nk-reply-tools {
    flex-wrap: wrap;
  }
  .nk-reply-tools .date {
    margin-right: 0.5rem;
    font-size: 13px;
  }
  .nk-reply-tools .attach-msg {
    font-size: 1.25rem;
    margin-right: 0.375rem;
  }
}
@media (max-width: 575.98px) {
  .nk-reply-info {
    margin-left: 0.75rem;
    max-width: calc(100% - 2.5rem);
  }
  .is-collapsed .nk-reply-info {
    max-width: calc(100% - 4.5rem);
  }
  .nk-reply-info .date {
    font-size: 11px;
    color: #8094ae;
    margin-left: 0.375rem;
  }
  .nk-reply-info .lead-text {
    font-size: 13px;
  }
  .nk-reply-form-dropdown {
    margin-right: 0.5rem;
  }
  .nk-reply-form-group {
    flex-wrap: wrap;
    align-items: center;
  }
  .nk-reply-form-title {
    font-weight: 500;
  }
  .nk-reply-tools .attach-msg,
  .nk-reply-tools .date-msg {
    display: none;
  }
  .nk-reply-avatar {
    height: 30px;
    width: 30px;
  }
  .nk-reply-msg-excerpt {
    font-size: 12px;
    margin-top: 0.25rem;
  }
  .nk-reply-msg-info .sub-text {
    font-size: 12px;
  }
  .nk-reply-msg-info .dropdown-menu {
    margin-right: 16px !important;
  }
}
@media (min-width: 576px) {
  .nk-reply-form {
    margin: 0.5rem 2.25rem 2.25rem;
    border: 1px solid #e5e9f2;
    border-radius: 4px;
  }
  .nk-reply-form-header {
    padding: 0 1.5rem;
  }
  .nk-reply-form-field {
    padding: 0.5rem 1.5rem;
  }
  .nk-reply-form-field textarea.form-control.ex-large,
  .nk-reply-form-field .dual-listbox textarea.ex-large.dual-listbox__search,
  .dual-listbox .nk-reply-form-field textarea.ex-large.dual-listbox__search {
    min-height: 278px;
  }
  .nk-reply-form-tools {
    padding: 1rem 1.5rem;
  }
  .modal-body .nk-reply-form-tools {
    padding: 1.5rem;
  }
  .nk-reply-item {
    padding: 2rem 2.25rem;
  }
  .nk-reply-meta {
    margin: 0 2.25rem;
  }
  .nk-reply-header .date-time {
    font-size: 13px;
  }
  .nk-reply-quick-btns {
    margin: 0.5rem 2.25rem 2.25rem;
  }
}
@media (min-width: 992px) {
  .nk-reply-form {
    margin: 0.5rem 2.5rem 2.5rem;
  }
  .nk-reply-item {
    padding: 2rem 2.5rem;
  }
  .nk-reply-meta {
    margin: 0 2.5rem;
  }
  .nk-reply-quick-btns {
    margin: 0.5rem 2.5rem 2.5rem;
  }
}
.attach-files {
  margin-top: 1.75rem;
  border: 1px solid #e5e9f2;
  border-radius: 4px;
}
.attach-list {
  padding: 1rem 1.25rem;
  margin-bottom: -0.5rem;
  display: flex;
  flex-wrap: wrap;
}
.attach-item {
  width: 180px;
  padding-right: 2rem;
  padding-bottom: 0.5rem;
}
.attach-item a {
  display: flex;
  align-items: center;
  width: 100%;
  color: #526484;
}
.attach-item .icon {
  font-size: 1.5rem;
  color: #11c8fc;
  margin-right: 0.75rem;
}
.attach-foot {
  border-top: 1px solid #e5e9f2;
  background: #f5f6fa;
  padding: 0.75rem 1.25rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.attach-info {
  font-weight: 500;
  color: #364a63;
}
.attach-download span {
  color: #8094ae;
  transition: all 0.3s;
}
.attach-download:hover span {
  color: #11c8fc;
}
@media (max-width: 359px) {
  .attach-info {
    font-size: 12px;
  }
  .attach-download {
    font-size: 12px;
  }
}
.nk-msg-nav {
  position: relative;
  border-bottom: 1px solid #e5e9f2;
  padding: 0 1.25rem;
  background: #fff;
  z-index: 2;
  border-top-left-radius: 3px;
}
.nk-msg-nav .search-wrap {
  padding: 0 1rem;
  bottom: -1px;
  border-bottom: 1px solid #e5e9f2;
  border-radius: 0;
}
.nk-msg-nav .search-submit {
  transform: translate(0.25rem, -50%);
}
.nk-msg-menu {
  display: flex;
  margin: 0 -0.75rem;
}
.nk-msg-menu-item {
  padding: 0 0.75rem;
}
.nk-msg-menu-item a {
  display: inline-flex;
  position: relative;
  color: #8094ae;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 1rem 0;
}
.nk-msg-menu-item a:after {
  position: absolute;
  content: "";
  height: 2px;
  background: #11c8fc;
  bottom: -1px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: 0.3s ease;
}
.nk-msg-menu-item a .icon {
  font-size: 1.125rem;
  line-height: 1.5rem;
}
.nk-msg-menu-item a:hover {
  color: #11c8fc;
}
.nk-msg-menu-item.active a {
  color: #11c8fc;
}
.nk-msg-menu-item.active a:after {
  opacity: 1;
}
.nk-msg {
  position: relative;
  display: flex;
  overflow: hidden;
  background: #fff;
  border-radius: 4px;
  min-height: calc(100vh - (65px + 78px + 60px));
  max-height: calc(100vh - (65px + 78px + 60px));
}
.nk-msg-aside {
  width: 100%;
  overflow: hidden;
  max-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background: #fff;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.nk-msg-list {
  max-height: 100%;
  height: 100%;
  overflow: auto;
}
.nk-msg-item {
  display: flex;
  padding: 1.25rem 1.25rem;
  cursor: pointer;
}
.nk-msg-item:not(:last-child) {
  border-bottom: 1px solid #e5e9f2;
}
.nk-msg-item.active,
.nk-msg-item.current {
  background: rgba(245, 246, 250, 0.7);
  position: relative;
  cursor: default;
}
.nk-msg-item.active:after,
.nk-msg-item.current:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background: #11c8fc;
}
.nk-msg-info {
  max-width: calc(100% - 52px);
  margin-left: 0.75rem;
  flex-grow: 1;
}
.nk-msg-from {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nk-msg-context {
  display: flex;
  justify-content: space-between;
  margin-top: 0.25rem;
}
.nk-msg-context .asterisk {
  margin-top: auto;
}
.nk-msg-sender {
  display: inline-flex;
  align-items: center;
  color: #8094ae;
  font-size: 12px;
}
.nk-msg-sender .name {
  margin-right: 0.5rem;
}
.is-unread .nk-msg-sender .name {
  font-weight: 500;
  color: #364a63;
}
.nk-msg-meta {
  display: inline-flex;
  align-items: center;
  color: #8094ae;
  font-size: 12px;
}
.nk-msg-meta .attchment .icon {
  font-size: 1.125rem;
}
.nk-msg-meta .date {
  margin-left: 0.75rem;
}
.nk-msg-text {
  max-width: calc(100% - 35px);
  flex-grow: 0;
  padding-right: 0.5rem;
}
.nk-msg-text .title {
  font-size: 15px;
  margin-bottom: 0.25rem;
}
.nk-msg-text p {
  font-size: 13px;
  color: #8094ae;
  max-height: 42px;
  overflow: hidden;
}
.is-unread .nk-msg-text p {
  color: #526484;
  font-weight: 500;
}
.nk-msg-lables {
  text-align: center;
  margin: -0.125rem;
  display: inline-flex;
  flex-direction: column;
}
.nk-msg-lables > div {
  padding: 0.125rem;
}
.nk-msg-lables .icon {
  font-size: 1.125rem;
}
.nk-msg-lables .badge {
  line-height: 14px;
  font-size: 10px;
  padding: 0 0.25rem;
}
.nk-msg-body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: padding 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;
  z-index: 5;
}
.nk-msg-body.show-message {
  opacity: 1;
  pointer-events: auto;
}
.nk-msg-profile {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  width: 280px;
  height: 100%;
  max-height: 100%;
  transition: transform 0.3s ease-in-out;
  background: #fff;
  z-index: 100;
}
.nk-msg-profile-toggle {
  position: absolute;
  height: 36px;
  width: 36px;
  display: none;
  align-items: center;
  justify-content: center;
  bottom: -18px;
  right: -18px;
  margin-right: 40px;
  transition: right 0.3s ease-in-out, margin 0.3s ease-in-out 0.2s;
  border: 1px solid #e5e9f2;
  background-color: #fff;
  border-radius: 50%;
  font-size: 1.125rem;
  z-index: 2;
  color: #8094ae;
  z-index: 110;
}
.nk-msg-profile-toggle.active {
  margin-right: 0;
  transform: rotate(-180deg);
}
.nk-msg-profile-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(16, 25, 36, 0.4);
  z-index: 600;
  z-index: 90;
  animation: overlay-fade-in 0.4s ease 1;
}
.nk-msg-profile.visible {
  transform: none;
}
.nk-msg-head {
  position: relative;
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #e5e9f2;
}
.nk-msg-head .title {
  margin-bottom: 0.5rem;
}
.nk-msg-head-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nk-msg-hide {
  font-size: 1rem;
}
.nk-msg-tags {
  margin: 0 -0.75rem;
}
.nk-msg-tags li {
  padding: 0 0.75rem;
  color: #8094ae;
}
.nk-msg-tags li > span {
  display: inline-flex;
  align-items: center;
}
.nk-msg-tags li > span .icon {
  color: #11c8fc;
  margin-right: 0.25rem;
}
.nk-msg-actions {
  display: flex;
  margin: 0 -0.25rem;
}
.nk-msg-actions li {
  padding: 0 0.25rem;
}
@media (max-width: 1199.98px) {
  .nk-msg {
    min-height: calc(100vh - (65px + 78px + 30px));
    max-height: calc(100vh - (65px + 78px + 30px));
  }
}
@media (max-width: 575.98px) {
  .nk-msg {
    min-height: calc(100vh - (65px + 116px + 30px));
    max-height: calc(100vh - (65px + 116px + 30px));
  }
}
@media (min-width: 576px) {
  .nk-msg-nav {
    padding: 0 2rem;
  }
  .nk-msg-nav .search-wrap {
    padding: 0 1.7rem;
  }
  .nk-msg-item {
    padding: 1.25rem 2rem;
  }
  .nk-msg-head {
    padding: 1rem 2.25rem;
  }
  .nk-msg-head .title {
    margin-bottom: 1rem;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .nk-msg-text .title {
    margin-top: 0.125rem;
    margin-bottom: 0.375rem;
  }
}
@media (min-width: 992px) {
  .nk-msg-nav {
    padding: 0 1.75rem;
  }
  .nk-msg-nav .search-wrap {
    padding: 0 1.425rem;
  }
  .nk-msg-item {
    padding: 1.25rem 1.75rem;
  }
  .nk-msg-head {
    padding: 2rem 2.5rem;
  }
  .nk-msg-head .title {
    max-height: 82px;
    overflow: hidden;
  }
  .nk-msg-aside {
    width: 320px;
    border-right: 1px solid #e5e9f2;
  }
  .nk-msg-body {
    position: static;
    opacity: 1;
    pointer-events: auto;
  }
  .nk-msg-profile {
    border-left: 1px solid #e5e9f2;
  }
  .nk-msg-profile-toggle {
    margin-right: 55px;
    display: flex;
  }
}
@media (min-width: 1540px) {
  .nk-msg-aside {
    width: 380px;
  }
}
@media (max-width: 1539.98px) {
  .profile-shown .nk-msg-profile-toggle {
    right: 262px;
  }
}
@media (min-width: 1540px) {
  .nk-msg-body.profile-shown {
    padding-right: 280px;
  }
}
.ibx-count {
  position: relative;
  padding: 0.3125rem 0.75rem;
  font-size: 0.75rem;
  color: #8094ae;
  background: #fff;
  border: 1px solid #e5e9f2;
  display: inline-block;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
}
.ibx-count:before,
.ibx-count:after {
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  border-style: solid;
}
.ibx-count:before {
  right: -8px;
  border-width: 4px 0 4px 7px;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e5e9f2;
}
.ibx-count:after {
  right: -6px;
  border-width: 3px 0 3px 6px;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff;
}
.nk-ibx-context {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.nk-ibx-context-group {
  display: flex;
  align-items: center;
}
.nk-ibx-context-badges {
  margin-right: 0.75rem;
}
.nk-ibx-context-text {
  display: inline;
  color: #8094ae;
}
.is-unread .nk-ibx-context-text {
  color: #526484;
}
.nk-ibx-context-text .heading {
  color: #364a63;
}
.is-unread .nk-ibx-context-text .heading {
  font-weight: 500;
}
.ibx-actions {
  position: relative;
  display: inline-block;
  transition: background-color 0.3s;
}
.ibx-actions-hidden {
  position: absolute;
  display: flex;
  right: calc(100% + 8px);
  opacity: 0;
  transition: background-color 0.3s;
}
.nk-ibx-item:hover .ibx-actions-hidden {
  opacity: 1;
  background-color: #f5f6fa;
}
.nk-ibx-item:hover .ibx-actions {
  background-color: #f5f6fa;
}
.nk-ibx {
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: 4px;
  min-height: calc(100vh - (65px + 86px + 60px));
  max-height: calc(100vh - (65px + 86px + 60px));
  background: #fff;
}
.nk-ibx-aside {
  position: absolute;
  background: #fff;
  width: 260px;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-shrink: 0;
  border-right: 1px solid #e5e9f2;
  transform: translateX(-100%);
  z-index: 99;
  transition: transform 0.4s linear;
}
.nk-ibx-aside + .toggle-overlay {
  z-index: 90;
  position: absolute;
}
.nk-ibx-aside.content-active {
  transform: none;
}
.nk-ibx-nav {
  overflow: auto;
  flex-grow: 1;
  height: 100%;
  max-width: 100%;
}
.nk-ibx-nav .simplebar-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.nk-ibx-nav-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1.5rem 0.75rem;
}
.nk-ibx-nav-head .title {
  font-size: 1rem;
  margin-bottom: 0;
}
.nk-ibx-nav-head .dropdown > a,
.nk-ibx-nav-head > .link {
  color: #8094ae;
  font-size: 0.875rem;
}
.nk-ibx-head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #e5e9f2;
  min-height: 61px;
}
.nk-ibx-head .title {
  font-size: 1rem;
  margin-bottom: 0;
}
.nk-ibx-head-actions,
.nk-ibx-head-tools {
  display: flex;
  align-items: center;
}
.nk-ibx-head-tools .btn-icon.btn-trigger .icon,
.nk-ibx-head-tools .dt-buttons .btn-trigger.btn-secondary .icon,
.dt-buttons .nk-ibx-head-tools .btn-trigger.btn-secondary .icon {
  font-size: 1.25rem;
}
.nk-ibx-head .search-wrap {
  padding: 0 1.365rem 0 1rem;
}
.nk-ibx-head .search-wrap .icon {
  font-size: 1.25rem;
}
.nk-ibx-head .search-back {
  transform: translate(-0.5rem, -50%);
}
.nk-ibx-head-check {
  display: flex;
  margin-right: 0.325rem;
}
.nk-ibx-action {
  position: relative;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e5e9f2;
}
.nk-ibx-action-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nk-ibx-action-item a {
  display: flex;
  align-items: center;
}
.nk-ibx-action-item .icon {
  font-size: 1.5rem;
}
.nk-ibx-action-item .icon + span {
  margin-left: 0.5rem;
}
.nk-ibx-action-item .search-toggle .icon {
  font-size: 1.125rem;
}
.nk-ibx-menu li {
  margin: 2px 0;
}
.nk-ibx-menu li:hover {
  background: #f5f6fa;
  border-radius: 3px;
}
.nk-ibx-menu li.active {
  background: #afebfb;
  border-radius: 3px;
}
.nk-ibx-menu-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
}
.nk-ibx-menu-item .icon {
  font-size: 1.25rem;
  line-height: 1.5rem;
  width: 2rem;
  color: #8094ae;
}
.nk-ibx-menu-item .badge {
  margin-left: auto;
  min-width: 2.25rem;
  justify-content: center;
}
.active .nk-ibx-menu-item .icon {
  color: #11c8fc;
}
.nk-ibx-menu-text {
  font-size: 0.875rem;
  color: #526484;
  font-weight: 500;
  line-height: 1.5rem;
}
.active .nk-ibx-menu-text {
  color: #11c8fc;
}
.nk-ibx-label > li,
.nk-ibx-contact > li {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin: 2px 0;
  transition: background-color 0.3s;
}
.nk-ibx-label > li > a,
.nk-ibx-contact > li > a {
  display: flex;
  padding: 0.5rem 0.75rem;
  flex-grow: 1;
}
.nk-ibx-label > li .dropdown,
.nk-ibx-contact > li .dropdown {
  opacity: 0;
}
.nk-ibx-label > li .dropdown > a,
.nk-ibx-contact > li .dropdown > a {
  color: #526484;
  padding: 0.6875rem;
}
.nk-ibx-label > li:hover,
.nk-ibx-contact > li:hover {
  background: #f5f6fa;
}
.nk-ibx-label > li:hover .dropdown,
.nk-ibx-contact > li:hover .dropdown {
  opacity: 1;
}
.nk-ibx-label > li.active,
.nk-ibx-contact > li.active {
  background: #ab89f2;
}
.nk-ibx-label-text {
  line-height: 1;
  margin-left: 1rem;
  font-size: 0.875rem;
  color: #526484;
  font-weight: 500;
}
.nk-ibx-menu,
.nk-ibx-label,
.nk-ibx-contact {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.nk-ibx-menu {
  padding-top: 0.75rem;
}
.nk-ibx-contact {
  padding-bottom: 1.75rem;
}
.nk-ibx-status {
  margin-top: auto;
  background: #f5f6fa;
  padding: 1.5rem 1.5rem;
}
.nk-ibx-status-info {
  margin-bottom: 0.5rem;
}
.nk-ibx-status-info .icon {
  width: 1.25rem;
  display: inline-block;
}
.nk-ibx-body {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
}
.nk-ibx-list {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  overflow: auto;
}
.nk-ibx-item {
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 1rem 1.25rem;
  transition: background-color 0.3s;
  cursor: pointer;
}
.nk-ibx-item:not(:last-child) {
  border-bottom: 1px solid #e5e9f2;
}
.nk-ibx-item:not(.no-hover):hover,
.nk-ibx-item.active {
  background: #f5f6fa;
}
.nk-ibx-item.is-unread {
  background: rgba(245, 246, 250, 0.6);
}
.nk-ibx-item-elem {
  padding: 0 8px;
  flex-shrink: 0;
}
.nk-ibx-item-elem:first-child {
  padding-left: 0;
}
.nk-ibx-item-check {
  width: 28px;
  display: inline-flex;
}
.nk-ibx-item-fluid {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
.nk-ibx-item-user .user-name {
  max-width: calc(100% - 50px);
}
.nk-ibx-item-user .user-name .lead-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
}
.is-unread .nk-ibx-item-user .user-name .lead-text {
  font-weight: 500;
}
.nk-ibx-item-count {
  width: 3.25rem;
}
.nk-ibx-item-time {
  width: 80px;
  text-align: right;
}
.nk-ibx-item-attach {
  width: 2.25rem;
  text-align: center;
}
.nk-ibx-item-star .asterisk {
  padding: 0 0.25rem;
}
.nk-ibx-item-star .asterisk a {
  display: flex;
}
.nk-ibx-item-star .asterisk .icon {
  line-height: 1;
  font-size: 1.125rem;
}
.nk-ibx-item-tools {
  padding-right: 0;
  margin-left: auto;
  text-align: right;
}
.nk-ibx-item-check,
.nk-ibx-item-star,
.nk-ibx-item-attach {
  position: relative;
  z-index: 2;
}
.nk-ibx-item-tools {
  position: relative;
}
.nk-ibx-link {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
.nk-ibx-link + .nk-ibx-item-elem {
  padding-left: 0;
}
.nk-ibx-view {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.nk-ibx-view.show-ibx {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.nk-ibx-reply-head {
  padding: 1.5rem 1.25rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.nk-ibx-reply-head .title {
  margin-bottom: 0.75rem;
}
.nk-ibx-reply-header {
  margin-right: -0.5rem;
}
.nk-ibx-reply-item {
  position: relative;
}
.nk-ibx-reply-item > div:not(.nk-reply-header) {
  margin-left: 0;
  margin-top: 1.25rem;
  margin-bottom: 0.75rem;
}
.nk-ibx-reply-item:not(:last-child):after {
  position: absolute;
  left: 1.25rem;
  right: 1.25rem;
  height: 1px;
  bottom: 1rem;
  content: "";
  background: #e5e9f2;
}
@media (min-width: 576px) {
  .nk-ibx-item,
  .nk-ibx-head {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .nk-ibx-head .search-wrap {
    padding: 0 2.375rem 0 2rem;
  }
  .nk-ibx-reply-head {
    padding: 2em 2.25rem 0;
  }
  .nk-ibx-reply-item {
    padding-bottom: 2.75rem;
  }
  .nk-ibx-reply-item > div:not(.nk-reply-header) {
    margin-left: 56px;
  }
  .nk-ibx-reply-item:not(:last-child):after {
    bottom: 1.375rem;
    left: 2.25rem;
    right: 2.25rem;
  }
  .nk-ibx-reply-quick-btns,
  .nk-ibx-reply-form {
    margin-left: 5.75rem;
  }
}
@media (min-width: 768px) {
  .nk-ibx-item-user {
    width: 180px;
  }
}
@media (min-width: 992px) {
  .nk-ibx-aside {
    position: static;
    transform: none;
    transition: none;
    height: calc(100vh - 128px);
  }
  .nk-ibx-menu,
  .nk-ibx-label,
  .nk-ibx-contact {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .nk-ibx-menu {
    padding-top: 1rem;
  }
  .nk-ibx-contact {
    padding-bottom: 1rem;
  }
  .nk-ibx-nav-head {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .nk-ibx-item,
  .nk-ibx-head {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .nk-ibx-head .search-wrap {
    padding: 0 2.25rem 0 1.5rem;
  }
  .nk-ibx-body {
    max-width: calc(100% - 260px);
  }
  .nk-ibx-reply-quick-btns,
  .nk-ibx-reply-form {
    margin-left: 5.25rem;
  }
  .nk-ibx-reply-item,
  .nk-ibx-reply-head {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
}
@media (min-width: 1200px) {
  .nk-ibx-aside {
    width: 300px;
  }
  .nk-ibx-item-user {
    width: 200px;
  }
  .nk-ibx-item-time {
    margin-right: 5rem;
  }
}
@media (min-width: 1540px) {
  .nk-ibx-item-user {
    width: 260px;
  }
  .nk-ibx-item-tools {
    margin-left: 5rem;
  }
}
@media (max-width: 1199.98px) {
  .nk-ibx-item-attach {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .nk-ibx-item {
    flex-wrap: wrap;
  }
  .nk-ibx-item-elem {
    padding: 0 4px;
  }
  .nk-ibx-item-fluid {
    padding-left: 32px;
    padding-right: 48px;
    margin-top: 6px;
    width: calc(100% - 2.5rem);
  }
  .nk-ibx-item-fluid .heading {
    display: block;
  }
  .nk-ibx-item-count,
  .nk-ibx-item-attach {
    display: none;
  }
  .nk-ibx-item-user {
    flex-grow: 1;
  }
  .nk-ibx-item-user .user-avatar {
    height: 28px;
    width: 28px;
    font-size: 12px;
  }
  .nk-ibx-item-time {
    position: absolute;
    right: 32px;
    top: 19px;
  }
  .nk-ibx-item-tools {
    position: absolute;
    text-align: right;
    right: 28px;
    bottom: 18px;
  }
  .nk-ibx-item-star {
    position: absolute;
    left: 28px;
    top: 50px;
  }
  .nk-ibx-context-badges {
    display: none;
  }
  .ibx-actions-hidden {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .nk-ibx-item-star {
    left: 12px;
  }
  .nk-ibx-item-time {
    right: 12px;
  }
  .nk-ibx-item-tools {
    right: 8px;
  }
  .nk-ibx-reply-head .title {
    font-size: 1.125rem;
  }
  .nk-ibx-reply .btn-icon.btn-trigger,
  .nk-ibx-reply .dt-buttons .btn-trigger.btn-secondary,
  .dt-buttons .nk-ibx-reply .btn-trigger.btn-secondary {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .nk-ibx-reply .btn-icon.btn-trigger .icon,
  .nk-ibx-reply .dt-buttons .btn-trigger.btn-secondary .icon,
  .dt-buttons .nk-ibx-reply .btn-trigger.btn-secondary .icon {
    width: 1.875rem;
  }
}
@media (max-width: 359px) {
  .nk-ibx-head .btn-icon.btn-trigger,
  .nk-ibx-head .dt-buttons .btn-trigger.btn-secondary,
  .dt-buttons .nk-ibx-head .btn-trigger.btn-secondary {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .nk-ibx-head .btn-icon.btn-trigger .icon,
  .nk-ibx-head .dt-buttons .btn-trigger.btn-secondary .icon,
  .dt-buttons .nk-ibx-head .btn-trigger.btn-secondary .icon {
    width: 1.875rem;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .nk-ibx-aside {
    width: 320px;
  }
  .nk-ibx-menu,
  .nk-ibx-label,
  .nk-ibx-contact {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .nk-ibx-nav-head,
  .nk-ibx-status {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
}
@media (min-width: 576px) and (max-width: 1199.98px) {
  .nk-ibx {
    min-height: calc(100vh - (65px + 78px + 30px));
    max-height: calc(100vh - (65px + 78px + 30px));
  }
}
.nk-fmg {
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  min-height: calc(100vh - (65px + 78px + 60px));
}
.nk-fmg-aside {
  position: fixed;
  width: 260px;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
  background: #fff;
  flex-shrink: 0;
  z-index: 9999;
  left: 0;
  transform: translateX(-100%);
  border-right: 1px solid #e5e9f2;
  top: 0;
  transition: transform 0.4s linear;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}
.nk-fmg-aside + .toggle-overlay {
  z-index: 90;
}
.nk-fmg-aside.content-active {
  transform: none;
}
.nk-fmg-aside .simplebar-content {
  height: 100%;
}
.nk-fmg-aside-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.nk-fmg-aside-top {
  margin-bottom: auto;
  margin-bottom: 30px;
}
.nk-fmg-menu {
  padding: 1rem;
}
.nk-fmg-menu li {
  margin: 2px 0;
}
.nk-fmg-menu-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
}
.nk-fmg-menu-item:hover {
  background: #f5f6fa;
  border-radius: 3px;
}
.active > .nk-fmg-menu-item {
  background: #afebfb;
  border-radius: 3px;
}
.nk-fmg-menu-item .icon {
  font-size: 1.25rem;
  line-height: 1.25rem;
  margin-top: -2px;
  width: 2rem;
  color: #8094ae;
}
.nk-fmg-menu-item .badge {
  margin-left: auto;
  min-width: 2.25rem;
}
.active > .nk-fmg-menu-item .icon {
  color: #11c8fc;
}
.nk-fmg-menu-text {
  font-size: 0.875rem;
  color: #526484;
  font-weight: 500;
  line-height: 1.5rem;
}
.active > .nk-fmg-menu-item > .nk-fmg-menu-text {
  color: #11c8fc;
}
.nk-fmg-menu li ul {
  margin-top: -0.125rem;
  margin-bottom: 0.5rem;
  padding-left: 2.75rem;
}
.nk-fmg-menu li ul ul {
  padding-left: 0.75rem;
  border-left: 1px solid #e5e9f2;
}
.nk-fmg-menu li ul .nk-fmg-menu-item {
  padding: 0.125rem 0;
}
.nk-fmg-menu li ul .nk-fmg-menu-item:hover,
.nk-fmg-menu li ul .nk-fmg-menu-item.active {
  background: rgba(0, 0, 0, 0);
}
.nk-fmg-menu li ul .nk-fmg-menu-item:hover span,
.nk-fmg-menu li ul .nk-fmg-menu-item.active span {
  color: #11c8fc;
}
.nk-fmg-menu li ul .nk-fmg-menu-text {
  font-size: 90%;
  line-height: 1.375rem;
}
.nk-fmg-status {
  padding: 1.75rem;
}
.nk-fmg-status-title {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  color: #526484;
  margin-bottom: 1rem;
}
.nk-fmg-status-title .icon {
  font-size: 1.25rem;
  margin-right: 0.75rem;
}
.nk-fmg-status-info {
  font-size: 12px;
  font-weight: 500;
  color: #8094ae;
  margin-top: 1rem;
}
.nk-fmg-body {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  flex-grow: 1;
  flex-shrink: 0;
}
.nk-fmg-body-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #dbdfea;
  background-color: #fff;
  z-index: 9;
  position: relative;
  top: 0;
  border-top-right-radius: inherit;
}
.nk-fmg-body-content {
  padding: 1.125rem 1.25rem;
  height: 100%;
  max-height: 100%;
  overflow: auto;
}
.nk-fmg-body .simplebar-content {
  min-height: 470px;
}
.nk-fmg-search {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.nk-fmg-filter {
  margin-bottom: 1.25rem;
}
.nk-fmg-filter .form-label {
  font-weight: 500;
  font-size: 13px;
  color: #8094ae;
}
.nk-fmg-filter .form-group {
  margin-bottom: 1rem;
}
.nk-fmg-switch {
  background-color: rgba(0, 0, 0, 0);
  border-top: 1px solid #e5e9f2;
}
.nk-fmg-switch > div {
  width: 100%;
}
.nk-fmg-switch .dropdown-toggle {
  padding: 1.25rem 1.75rem;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}
.nk-fmg-switch .dropdown-toggle:after {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #526484;
}
.nk-fmg-listing {
  min-height: 380px;
}
.nk-fmg-listing.is-scrollable {
  min-height: auto;
  max-height: 298px;
}
@media (min-width: 576px) {
  .nk-fmg-body-content {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .nk-fmg-aside-top {
    margin-bottom: 100px;
  }
}
@media (min-width: 992px) {
  .nk-fmg {
    padding-left: 260px;
  }
  .nk-fmg-aside {
    transform: none;
    transition: none;
    width: 260px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    min-height: 100%;
    max-height: 100%;
  }
  .nk-fmg-aside-top {
    flex-grow: 0;
  }
  .nk-fmg-body {
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
  }
  .nk-fmg-body-head {
    padding: 0.75rem 1.75rem;
    z-index: 9;
  }
  .nk-fmg-body-content {
    padding: 1.75rem;
  }
}
@media (min-width: 1200px) {
  .nk-fmg {
    padding-left: 220px;
  }
  .nk-fmg-filter.toggle-expand-content {
    display: block !important;
  }
  .nk-fmg-aside {
    width: 220px;
  }
}
@media (min-width: 992px) and (max-width: 1360px) {
  .nk-fmg {
    padding-left: 220px;
  }
  .nk-fmg-aside {
    width: 220px;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .nk-fmg-aside {
    width: 320px;
  }
  .nk-fmg-body-head,
  .nk-fmg-body-content,
  .nk-fmg-status,
  .nk-fmg-switch .dropdown-toggle {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .nk-fmg-menu {
    padding: 1rem 1.75rem;
  }
}
.nk-file .tb-sub {
  font-size: 12px;
  color: #8094ae;
}
.nk-file .tb-head {
  font-weight: 500;
  font-size: 13px;
  color: #8094ae;
}
.nk-file-info:hover .nk-file-name .asterisk a,
.nk-file-info .nk-file-name .asterisk a.active {
  opacity: 1;
}
.nk-file-icon {
  margin-right: 8px;
  flex-shrink: 0;
}
.nk-file-icon-link {
  display: block;
  text-decoration: none;
  color: inherit;
}
.nk-file-icon-type {
  width: 32px;
  display: inline-block;
  text-decoration: none;
  color: inherit;
  margin: auto;
}
.nk-file-title {
  display: flex;
  align-items: center;
}
.nk-file-title .breadcrumb {
  padding-top: 0.25rem;
  padding-bottom: 0;
}
.nk-file-name {
  font-weight: 500;
  color: #364a63;
  position: relative;
  line-height: 1.4;
}
.nk-file-name-text {
  padding-right: 1.5rem;
  display: inline-block;
}
.nk-file-name-text .title {
  transition: color 0.3s;
  display: inline-block;
  text-decoration: none;
  word-break: break-word;
}
.nk-file-name-text a.title {
  color: #364a63;
}
.nk-file-name-text a.title:hover {
  color: #11c8fc;
}
.nk-file-name-sub {
  font-size: 12px;
  color: #8094ae;
}
.nk-file-name .asterisk {
  position: absolute;
  display: inline-flex;
  margin-left: 0.5rem;
  transition: 0.3s opacity;
  margin-top: -1px;
  top: 50%;
  transform: translateY(-50%);
}
.nk-file-name .asterisk a {
  opacity: 0;
}
.nk-file-name .asterisk .icon {
  font-size: 1.125rem;
  color: #11c8fc;
}
.nk-file-desc {
  display: flex;
  align-items: center;
  margin: 0 -0.375rem;
}
.nk-file-desc li {
  position: relative;
  padding: 0 0.375rem;
  font-size: 12px;
  color: #8094ae;
}
.nk-file-desc li:not(:first-child):before {
  content: "";
  font-family: "Nioicon";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  font-size: 11px;
  line-height: 10px;
  opacity: 0.8;
}
.nk-file-link {
  text-decoration: none;
  color: inherit;
  display: block;
}
.nk-file-link:hover .title {
  color: #11c8fc;
}
.nk-file-info > .nk-file-link {
  padding: 0.75rem 0;
}
.is-compact .nk-file-info > .nk-file-link {
  padding: 0.375rem 0;
}
.nk-file-share-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.25rem;
  border-bottom: 1px solid #e5e9f2;
}
.nk-file-share-field {
  padding: 0.5rem 1.25rem;
}
.nk-file-share-field:not(:last-child) {
  border-bottom: 1px solid #e5e9f2;
}
.nk-file-share-field .form-control,
.nk-file-share-field div.dataTables_wrapper div.dataTables_filter input,
div.dataTables_wrapper div.dataTables_filter .nk-file-share-field input,
.nk-file-share-field .dual-listbox .dual-listbox__search,
.dual-listbox .nk-file-share-field .dual-listbox__search {
  font-size: 0.875rem;
}
.nk-file-share-field textarea.form-control,
.nk-file-share-field .dual-listbox textarea.dual-listbox__search,
.dual-listbox .nk-file-share-field textarea.dual-listbox__search {
  min-height: 78px;
}
.nk-file-share-field textarea.form-control.ex-large,
.nk-file-share-field .dual-listbox textarea.ex-large.dual-listbox__search,
.dual-listbox .nk-file-share-field textarea.ex-large.dual-listbox__search {
  min-height: 160px;
}
.nk-file-share-input {
  display: flex;
}
.nk-file-share-input .label {
  margin: 0.3rem 0.5rem 0.3rem 0;
  width: 1.25rem;
  color: #8094ae;
}
.nk-file-share-input-group {
  width: 100%;
}
.nk-file-share-group {
  position: relative;
  padding: 1rem 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.nk-file-share-nav {
  flex-shrink: 0;
}
.nk-file-details-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0.25rem 0;
}
.nk-file-details-col {
  font-size: 13px;
}
.nk-file-details-col:first-child {
  color: #8094ae;
  width: 100%;
}
.nk-file-details-col:last-child {
  color: #526484;
}
.nk-file-details-col .breadcrumb {
  padding: 0;
}
@media (min-width: 420px) {
  .nk-file-details-row {
    flex-wrap: nowrap;
    padding: 0.375rem 0;
  }
  .nk-file-details-col:first-child {
    width: 100px;
    flex-shrink: 0;
  }
}
@media (min-width: 576px) {
  .nk-file-share-field textarea.form-control.ex-large,
  .nk-file-share-field .dual-listbox textarea.ex-large.dual-listbox__search,
  .dual-listbox .nk-file-share-field textarea.ex-large.dual-listbox__search {
    min-height: 278px;
  }
}
.nk-files .nk-file .hideable {
  opacity: 0;
  transition: opacity 0.3s;
}
.nk-files .nk-file:hover .hideable {
  opacity: 1;
}
.nk-files .nk-file-item {
  display: table-row;
}
.nk-files .nk-file-item > div {
  position: relative;
  display: table-cell;
  padding: 0.5rem;
  vertical-align: middle;
}
.nk-files .nk-file-actions {
  width: 60px;
  text-align: center;
}
.nk-files-head {
  display: table-header-group;
}
.nk-files-head .nk-file-item > div {
  font-weight: 500;
  font-size: 0.8125rem;
  padding: 0.25rem;
}
.nk-files-head .nk-file-item > div:first-child {
  padding-left: 0;
}
.nk-files-head .nk-file-item > div:last-child {
  padding-right: 0.5rem;
}
.nk-files-head .dropdown-menu-xs {
  min-width: 140px;
  max-width: 140px;
}
.nk-files-list {
  display: table-row-group;
}
.nk-files-view-list {
  display: table;
  width: 100%;
}
.nk-files-view-list .nk-file {
  display: table-row;
}
.nk-files-view-list .nk-file.selected {
  background: #11c8fc2e;
}
.nk-files-view-list .nk-file.selected > div {
  background: rgba(0, 0, 0, 0);
}
.nk-files-view-list .nk-file > div {
  padding: 0.5rem 0.375rem;
  border-bottom: 1px solid #dbdfea;
  height: 4rem;
  background-color: #fff;
}
.nk-files-view-list .nk-file > div:first-child {
  border-left: 1px solid #dbdfea;
  padding-left: 1.25rem;
}
.nk-files-view-list .nk-file > div:last-child {
  border-right: 1px solid #dbdfea;
}
.nk-files-view-list .nk-file > div.nk-file-info {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.nk-files-view-list .nk-file:first-child > div {
  border-top: 1px solid #dbdfea;
}
.nk-files-view-list .nk-file:first-child > div:first-child {
  border-top-left-radius: 4px;
}
.nk-files-view-list .nk-file:first-child > div:last-child {
  border-top-right-radius: 4px;
}
.nk-files-view-list .nk-file:last-child > div:first-child {
  border-bottom-left-radius: 4px;
}
.nk-files-view-list .nk-file:last-child > div:last-child {
  border-bottom-right-radius: 4px;
}
.nk-files-view-list .nk-file:not(:last-child) > div {
  border-bottom: 1px solid #dbdfea;
}
.nk-files-view-list .nk-file .custom-checkbox {
  margin-right: 1rem;
}
.nk-files-view-list .nk-file-members .tb-shared {
  position: absolute;
  left: -1rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1rem;
}
.nk-files-view-list .nk-file-name-text a.title {
  padding: 1rem 0 0.875rem;
}
.nk-files-view-list .nk-file-icon + .nk-file-name a.title {
  padding-left: 40px;
  margin-left: -40px;
}
.nk-files-view-list.is-compact .nk-file > div {
  height: 3rem;
}
.nk-files-view-grid .nk-files-head,
.nk-files-view-group .nk-files-head {
  margin-bottom: 0.25rem;
  width: 100%;
  display: flex;
}
.nk-files-view-grid .nk-files-head .nk-file-item,
.nk-files-view-group .nk-files-head .nk-file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
}
.nk-files-view-grid
  .nk-files-head
  .nk-file-item
  > div:not(.nk-file-info):not(.nk-file-actions),
.nk-files-view-group
  .nk-files-head
  .nk-file-item
  > div:not(.nk-file-info):not(.nk-file-actions) {
  display: none;
}
.nk-files-view-grid .nk-files-head .nk-file-actions,
.nk-files-view-group .nk-files-head .nk-file-actions {
  width: 30px;
  padding: 0 !important;
  margin-right: -4px;
}
.nk-files-view-grid .nk-files-list,
.nk-files-view-group .nk-files-list {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
}
.nk-files-view-grid .nk-files-list .nk-file-actions,
.nk-files-view-group .nk-files-list .nk-file-actions {
  position: absolute;
  border: 0 none;
  background: rgba(0, 0, 0, 0);
  width: 46px;
  right: 4px;
  top: 10px;
}
.nk-files-view-grid .nk-files-list .custom-control,
.nk-files-view-group .nk-files-list .custom-control {
  position: absolute;
}
.nk-files-view-grid .nk-file,
.nk-files-view-group .nk-file {
  display: flex;
  position: relative;
  margin: 8px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #dbdfea;
}
.nk-files-view-grid .nk-file .nk-file-members,
.nk-files-view-group .nk-file .nk-file-members {
  display: none;
}
.nk-files-view-grid .nk-file {
  display: block;
  position: relative;
  width: calc(50% - 16px);
  text-align: center;
  padding: 0.5rem 0.25rem;
}
.nk-files-view-grid .nk-file > div {
  display: block;
  padding: 0;
}
.nk-files-view-grid .nk-file-title {
  display: flex;
  flex-direction: column;
}
.nk-files-view-grid .nk-file-icon {
  display: block;
  margin: 0;
  width: 100%;
}
.nk-files-view-grid .nk-file-icon-type {
  width: 72px;
  padding: 0.5rem 0;
}
.nk-files-view-grid .nk-file-name .title {
  padding: 0.5rem 0.125rem 0.5rem;
}
.nk-files-view-grid .nk-file-name-text {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.nk-files-view-grid .nk-file-desc {
  padding: 0 0.25rem 0.75rem;
  margin-top: -0.125rem;
  justify-content: center;
}
.nk-files-view-grid .nk-file-desc .members {
  display: none;
}
.nk-files-view-grid .nk-file-info > .nk-file-link {
  padding: 0.5rem 0;
}
.nk-files-view-grid .nk-file .nk-file-members {
  display: none;
}
.nk-files-view-grid .nk-file .nk-file-meta {
  padding-bottom: 0.5rem;
}
.nk-files-view-grid .nk-file .custom-control {
  top: 0.75rem;
  left: 1rem;
}
.nk-files-view-group .nk-file {
  position: relative;
  padding: 0.5rem 0.5rem;
  width: 100%;
}
.nk-files-view-group .nk-file > div {
  display: block;
  width: 100%;
  padding-right: 2.5rem;
}
.nk-files-view-group .nk-file-desc {
  padding: 0.25rem 0.125rem 0;
}
.nk-files-view-group .nk-file-name-text a.title {
  padding: 0.125rem 0;
}
.nk-files-view-group .nk-file-icon + .nk-file-name a.title {
  padding-left: 40px;
  margin-left: -40px;
}
.nk-files-view-group .nk-file .nk-file-actions {
  right: 2px;
  top: 4px;
}
.nk-files-view-group .nk-file .custom-control {
  right: 0.5rem;
  bottom: 0.5rem;
}
.nk-files-view-group .nk-file .nk-file-meta {
  display: none;
}
.nk-files-group > .title {
  border-top: 1px solid #e5e9f2;
  font-size: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding: 1rem 0;
  margin: 0;
}
.nk-files-group + .nk-files-group {
  margin-top: 3rem;
}
@media (max-width: 767.98px) {
  .nk-file-name-text {
    font-size: 13px;
  }
  .nk-files-view-grid .nk-file-name-text {
    font-size: 12px;
  }
}
@media (max-width: 991.98px) {
  .nk-fmg-body .nk-block-tools .btn-icon.btn-trigger .icon,
  .nk-fmg-body .nk-block-tools .dt-buttons .btn-trigger.btn-secondary .icon,
  .dt-buttons .nk-fmg-body .nk-block-tools .btn-trigger.btn-secondary .icon {
    font-size: 1.25rem;
  }
}
@media (min-width: 576px) {
  .nk-files-view-grid .nk-file {
    width: calc(33.33% - 16px);
  }
  .nk-files-view-group .nk-file {
    width: calc(100% - 16px);
  }
}
@media (min-width: 660px) {
  .nk-files-view-group .nk-file {
    width: calc(50% - 16px);
  }
}
@media (min-width: 1200px) {
  .nk-files-view-grid .nk-file {
    width: calc(25% - 16px);
  }
  .nk-files-view-group .nk-file {
    width: calc(50% - 16px);
  }
}
@media (min-width: 1540px) {
  .nk-files-view-grid .nk-file {
    width: calc(20% - 16px);
  }
  .nk-files-view-group .nk-file {
    width: calc(33.33% - 16px);
  }
}
@media (min-width: 1800px) {
  .nk-files-view-grid .nk-file {
    width: calc(16.66% - 16px);
  }
  .nk-files-view-group .nk-file {
    width: calc(25% - 16px);
  }
}
@media (max-width: 1199px) and (min-width: 992px), (max-width: 767px) {
  .nk-files-view-list .nk-file-members,
  .nk-files-view-list .nk-file-size {
    display: none !important;
  }
}
@media (max-width: 599px) {
  .nk-files-view-list .nk-file-icon-type {
    width: 24px;
  }
  .nk-files-view-list .nk-file-meta,
  .nk-files-view-list .nk-file-date,
  .nk-files-view-list .nk-file-size {
    display: none !important;
  }
  .nk-files-view-list .nk-file .custom-checkbox {
    margin-right: 0.75rem;
  }
}
@media (max-width: 359px) {
  .nk-files-view-grid .nk-file {
    width: 100%;
  }
}
.nk-upload-form {
  margin-bottom: 2.25rem;
}
.nk-upload-list > .title {
  font-size: 0.875rem;
  padding-bottom: 1rem;
}
.nk-upload-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #e5e9f2;
  margin: 0.5rem 0;
}
.nk-upload-icon {
  width: 48px;
  margin-right: 0.25rem;
  flex-shrink: 0;
}
.nk-upload-info {
  font-weight: 500;
  color: #364a63;
  flex-grow: 1;
  padding-right: 0.75rem;
}
.nk-upload-title {
  display: flex;
  align-items: flex-end;
}
.nk-upload-title .title {
  font-size: 0.875rem;
}
.nk-upload-title .meta {
  font-size: 12px;
  margin-left: auto;
  color: #8094ae;
  font-weight: 400;
}
.nk-upload-size {
  font-size: 12px;
  color: #8094ae;
  font-weight: 400;
}
.nk-upload-progress {
  margin: 0.375rem 0 0.25rem;
}
.nk-upload-action {
  margin-left: auto;
}
.add-opt:hover .sub-text,
.add-opt:hover .icon {
  color: #11c8fc;
}
.add-opt-icon .icon-circle,
.contacts-add-link .icon-circle,
.chat-members-link .icon-circle,
.chat-option-link .icon-circle {
  height: 32px;
  width: 32px;
  font-size: 1rem;
}
.add-opt-icon .icon + span,
.contacts-add-link .icon + span,
.chat-members-link .icon + span,
.chat-option-link .icon + span,
.add-opt-icon .icon + div,
.contacts-add-link .icon + div,
.chat-members-link .icon + div,
.chat-option-link .icon + div {
  margin-left: 0.75rem;
}
.chat-item {
  position: relative;
  border-radius: 4px;
  transition: background-color 0.3s;
}
.chat-item:hover {
  background-color: #ebeef2;
}
.chats-dropdown .chat-item:hover {
  background-color: #f5f6fa;
}
.chat-item:hover .chat-actions {
  opacity: 1;
  pointer-events: initial;
  transition: opacity 0.5s;
}
.chat-item.current {
  background-color: #f5f6fa;
}
.chat-item.current:hover {
  background-color: #ebeef2;
}
.chat-link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem;
  cursor: pointer;
}
.chat-media {
  height: 44px;
  width: 44px;
}
.chat-media + .chat-info {
  margin-left: 1rem;
}
.chat-info {
  width: calc(100% - 3.75rem);
}
.chat-from {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat-from .name {
  font-size: 0.9375rem;
  margin-bottom: 0;
  font-weight: 500;
  color: #526484;
}
.is-unread .chat-from .name {
  font-weight: 700;
  color: #364a63;
}
.chat-from .time {
  font-size: 12px;
  color: #8094ae;
}
.chat-context {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat-context .text {
  width: calc(100% - 2.5rem);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
  color: #8094ae;
}
.is-unread .chat-context .text {
  font-weight: 500;
  color: #526484;
}
.chat-context .status {
  display: flex;
  color: rgba(128, 148, 174, 0.8);
}
.is-unread .chat-context .status {
  color: #11c8fc;
}
.chat-context .status.seen {
  color: #11c8fc;
}
.chat-actions {
  background-color: #ebeef2;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.75rem;
  opacity: 0;
  pointer-events: none;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 3rem;
}
.chat-profile-group {
  border-top: 1px solid #e5e9f2;
  padding: 0.25rem 0;
}
.chat-profile-head {
  padding: 1.25rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat-profile-head .title {
  margin-bottom: 0;
}
.chat-profile-head .indicator-icon {
  display: inline-flex;
}
.chat-profile-head.collapsed .indicator-icon {
  transform: rotate(-180deg);
}
.chat-profile-body-inner {
  padding: 0 1.5rem 1.5rem;
}
.chat-profile-options {
  margin: -0.25rem;
}
.chat-profile-options li {
  padding: 0.25rem;
}
.chat-profile-settings {
  margin: -0.5rem;
}
.chat-profile-settings li {
  padding: 0.5rem;
}
.chat-profile-settings .custom-control-sm .custom-control-label {
  padding-left: 0.25rem;
  font-size: 13px;
  font-weight: 500;
  color: #526484;
}
.chat-profile-media {
  display: flex;
  margin: 0 -0.25rem;
}
.chat-profile-media li {
  width: 33.33%;
  padding: 0.25rem;
}
.chat-profile-media li a {
  display: inline-block;
}
.chat-profile-media li img {
  border-radius: 4px;
}
.chat-option-link {
  display: flex;
  align-items: center;
}
.chat-option-link .lead-text {
  font-weight: 500;
  font-size: 13px;
  transition: color 0.3s;
  color: #526484;
}
.chat-option-link:hover .lead-text {
  color: #1c2b46;
}
.chat-members {
  margin: -0.375rem -0.5rem;
}
.chat-members li {
  position: relative;
}
.chat-members-link {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.5rem;
}
.chat-members .user-card {
  position: relative;
  padding: 0.375rem 0.5rem;
  border-radius: 3px;
  transition: background-color 0.3s;
}
.chat-members .user-card > a {
  width: 100%;
  display: flex;
  align-items: center;
}
.chat-members .user-card:hover {
  background-color: #ebeef2;
}
.chat-members .user-card:hover .user-actions {
  opacity: 1;
  pointer-events: initial;
  transition: opacity 0.5s;
}
.chat-members .user-role {
  margin-left: auto;
  font-size: 12px;
  color: #8094ae;
}
.chat-members .user-actions {
  position: absolute;
  top: 0;
  right: 0.25rem;
  bottom: 0;
  width: 3rem;
  background-color: #ebeef2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: 0;
  pointer-events: none;
}
.chat {
  display: flex;
  align-items: flex-end;
  margin: -4px;
}
.chat > div {
  padding: 4px;
}
.chat + .chat {
  padding-top: 0.5rem;
}
.chat-avatar {
  margin-bottom: 1.4rem;
}
.chat-avatar.no-meta {
  margin-bottom: 0;
}
.chat-bubble {
  display: flex;
  align-items: center;
  padding: 0.125rem 0;
}
.chat-bubble:hover .chat-msg-more {
  opacity: 1;
}
.chat-bubbles .attach-files {
  margin-top: 0.75rem;
}
.chat-msg {
  background-color: #fff;
  border-radius: 8px;
  padding: 0.5rem 1rem;
}
.chat-msg-more {
  display: flex;
  align-items: center;
  margin: 0 0.75rem;
  opacity: 0;
  flex-shrink: 0;
  transition: all 0.3s;
}
.chat-msg-more > li {
  padding: 0.25rem;
}
.chat-msg.is-light {
  background-color: #e5e9f2;
  color: #526484;
}
.chat-msg.is-theme {
  background-color: #11c8fc;
  color: #fff;
}
.chat-meta {
  display: flex;
  align-items: center;
  margin: 0.25rem -0.375rem 0;
}
.chat-meta li {
  position: relative;
  padding: 0 0.375rem;
  font-size: 12px;
  color: #8094ae;
}
.chat-meta li:not(:first-child):before {
  content: "";
  font-family: "Nioicon";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  font-size: 11px;
  line-height: 10px;
  opacity: 0.8;
}
.chat-sap {
  overflow: hidden;
  text-align: center;
  padding: 1rem 0;
}
.chat-sap-meta {
  position: relative;
  display: inline-block;
  padding: 0 0.75rem;
  color: #8094ae;
  font-size: 12px;
  line-height: 1.4;
}
.chat-sap-meta:before,
.chat-sap-meta:after {
  position: absolute;
  height: 1px;
  background: #dbdfea;
  content: "";
  width: 100vw;
  top: 50%;
}
.chat-sap-meta:before {
  right: 100%;
}
.chat-sap-meta:after {
  left: 100%;
}
.chat.is-you .chat-bubbles .attach-files {
  border-color: #fff;
  overflow: hidden;
}
.chat.is-you .chat-bubbles .attach-files,
.chat.is-you .chat-bubbles .attach-foot {
  background-color: #fff;
}
.chat.is-you .chat-bubble:last-child .chat-msg {
  border-bottom-left-radius: 0;
}
.chat.is-you .chat-bubble:not(:first-child) .chat-msg {
  border-top-left-radius: 4px;
}
.chat.is-you .chat-bubble:not(:last-child) .chat-msg {
  border-bottom-left-radius: 4px;
}
.chat.is-me {
  justify-content: flex-end;
}
.chat.is-me .chat-msg {
  background-color: #11c8fc;
  color: #fff;
}
.chat.is-me .chat-meta {
  justify-content: flex-end;
}
.chat.is-me .chat-bubble {
  flex-direction: row-reverse;
}
.chat.is-me .chat-bubbles .attach-files {
  border-color: #ab89f2;
  overflow: hidden;
}
.chat.is-me .chat-bubbles .attach-files,
.chat.is-me .chat-bubbles .attach-foot {
  background-color: #fff;
}
.chat.is-me .chat-bubble:last-child .chat-msg {
  border-bottom-right-radius: 0;
}
.chat.is-me .chat-bubble:not(:first-child) .chat-msg {
  border-top-right-radius: 4px;
}
.chat.is-me .chat-bubble:not(:last-child) .chat-msg {
  border-bottom-right-radius: 4px;
}
.chat-upload-option {
  display: none;
  position: absolute;
  left: 100%;
  padding: 0.5rem;
  background-color: #fff;
}
.chat-upload-option.expanded {
  display: block;
}
.chat-upload-option ul {
  display: flex;
  align-items: center;
}
.chat-upload-option a {
  color: #11c8fc;
  font-size: 1.25rem;
  height: 36px;
  width: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.chat-upload-option a:hover {
  color: #5a18e3;
}
.fav-list {
  display: flex;
  margin: -0.375rem;
  overflow-x: auto;
}
.fav-list li {
  padding: 0.375rem;
}
.fav-list a:hover > .user-avatar:after {
  opacity: 1;
}
.fav-list .user-avatar {
  height: 44px;
  width: 44px;
}
.fav-list .user-avatar:after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  height: 100%;
  background: #e5e9f2;
  transform: scale(1.15);
  opacity: 0;
  border-radius: 50%;
  z-index: -1;
  transition: opacity 0.3s;
}
@media (min-width: 768px) {
  .fav-list {
    flex-wrap: wrap;
  }
}
@media (max-width: 859px) {
  .fav-list {
    margin: -0.375rem 0;
  }
  .fav-list li:first-child {
    padding-left: 0;
  }
  .fav-list li:last-child {
    padding-right: 0;
  }
}
.channel-list li {
  margin: 0.125rem -0.5rem;
}
.channel-list a {
  padding: 0.375rem 0.5rem;
  display: block;
  transition: all 0.3s;
  font-weight: 500;
  color: #526484;
  border-radius: 3px;
}
.channel-list a:hover,
.channel-list a.active {
  color: #11c8fc;
  background: #afebfb;
}
.contacts-list {
  margin: -0.375rem -0.5rem;
}
.contacts-list + .contacts-list {
  margin-top: 1.75rem;
}
.contacts-list li {
  position: relative;
}
.contacts-list li > .title {
  padding-left: 1.25rem;
  margin-bottom: 0.25rem;
}
.contacts-list .user-card {
  position: relative;
  padding: 0.375rem 0.5rem;
  border-radius: 3px;
  transition: background-color 0.3s;
}
.contacts-list .user-card > a {
  width: 100%;
  display: flex;
  align-items: center;
}
.contacts-list .user-card:hover {
  background-color: #ebeef2;
}
.contacts-list .user-card:hover .user-actions {
  opacity: 1;
  pointer-events: initial;
  transition: opacity 0.4s;
}
.contacts-list .user-meta {
  margin-left: auto;
  font-size: 12px;
  color: #8094ae;
}
.contacts-list .user-actions {
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0.25rem;
  bottom: 0;
  width: 4.5rem;
  background-color: #ebeef2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: 0;
  pointer-events: none;
}
.contacts-list .user-actions > a {
  padding: 0 0.375rem;
}
.contacts-add-link {
  padding: 0.375rem 0.5rem;
  display: flex;
  align-items: center;
}
.contacts-add-link .lead-text {
  font-weight: 500;
  font-size: 13px;
}
.nk-chat {
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: 4px;
  min-height: calc(100vh - (65px + 78px + 60px));
  max-height: calc(100vh - (65px + 78px + 60px));
  background: #fff;
}
.nk-chat-blank {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background-color: #e5e9f2;
}
.nk-chat-blank-icon {
  margin-bottom: 1.5rem;
}
.nk-chat-boxed {
  border: 1px solid #dbdfea;
  border-radius: 4px;
  min-height: calc(100vh - (65px + 64px + 64px));
  max-height: calc(100vh - (65px + 64px + 64px));
}
.nk-chat-aside {
  background: #fff;
  width: 100%;
  overflow: hidden;
  max-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.nk-chat-aside-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.125rem 1.25rem 0.875rem;
}
.nk-chat-aside-user .title {
  font-size: 1.375rem;
  color: #364a63;
}
.nk-chat-aside-user .user-avatar {
  height: 36px;
  width: 36px;
}
.nk-chat-aside-user .user-avatar + .title {
  margin-left: 1rem;
}
.nk-chat-aside-user .dropdown-toggle:after {
  font-size: 1.125rem;
  color: #8094ae;
  margin-left: 1rem;
}
.nk-chat-aside-tools {
  display: flex;
  align-items: center;
}
.nk-chat-aside-body {
  max-height: 100%;
  height: 100%;
  overflow: auto;
}
.nk-chat-aside-search {
  padding: 0 1.25rem;
  margin-bottom: 1.75rem;
  margin-top: 0.25rem;
}
.nk-chat-aside-search .form-control,
.nk-chat-aside-search div.dataTables_wrapper div.dataTables_filter input,
div.dataTables_wrapper div.dataTables_filter .nk-chat-aside-search input,
.nk-chat-aside-search .dual-listbox .dual-listbox__search,
.dual-listbox .nk-chat-aside-search .dual-listbox__search {
  background-color: #f5f6fa;
  border-color: #f5f6fa;
}
.nk-chat-aside-search .form-control::placeholder,
.nk-chat-aside-search
  div.dataTables_wrapper
  div.dataTables_filter
  input::placeholder,
div.dataTables_wrapper
  div.dataTables_filter
  .nk-chat-aside-search
  input::placeholder,
.nk-chat-aside-search .dual-listbox .dual-listbox__search::placeholder,
.dual-listbox .nk-chat-aside-search .dual-listbox__search::placeholder {
  color: #8094ae;
}
.nk-chat-aside-panel {
  padding: 0 1.25rem 1.75rem;
}
.nk-chat-aside-panel .title {
  margin-bottom: 0.75rem;
}
.nk-chat-list {
  padding: 0 0.5rem;
}
.nk-chat-list .title {
  margin-left: 0.75rem;
}
.nk-chat-body {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: padding 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;
  z-index: 5;
}
.nk-chat-body.show-chat {
  opacity: 1;
  pointer-events: auto;
}
.nk-chat-head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.75rem;
  border-bottom: 1px solid #e5e9f2;
  background-color: #fff;
}
.nk-chat-head-info {
  width: 60%;
}
.nk-chat-head-info .user-avatar + .user-info {
  margin-left: 0.75rem;
}
.nk-chat-head-info .user-info .lead-text {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.nk-chat-head-info,
.nk-chat-head-tools {
  display: flex;
  align-items: center;
  margin: -0.125rem;
}
.nk-chat-head-info > li,
.nk-chat-head-tools > li {
  padding: 0.125rem;
}
.nk-chat-head-info .btn-icon .icon,
.nk-chat-head-info .dt-buttons .btn-secondary .icon,
.dt-buttons .nk-chat-head-info .btn-secondary .icon,
.nk-chat-head-tools .btn-icon .icon,
.nk-chat-head-tools .dt-buttons .btn-secondary .icon,
.dt-buttons .nk-chat-head-tools .btn-secondary .icon {
  font-size: 1.25rem;
}
.nk-chat-head-search {
  position: absolute;
  top: calc(100% + 1rem);
  left: 0;
  z-index: 9;
  width: 100%;
  padding: 0 1.75rem;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-10px);
  transition: all 0.5s;
}
.nk-chat-head-search.show-search {
  opacity: 1;
  pointer-events: auto;
  transform: none;
}
.nk-chat-panel {
  background-color: rgba(235, 238, 242, 0.7);
  height: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 1.25rem;
}
.nk-chat-editor {
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  background-color: #fff;
}
.nk-chat-editor-form {
  padding: 0 0.5rem;
  flex-grow: 1;
}
.nk-chat-editor-form .form-control,
.nk-chat-editor-form div.dataTables_wrapper div.dataTables_filter input,
div.dataTables_wrapper div.dataTables_filter .nk-chat-editor-form input,
.nk-chat-editor-form .dual-listbox .dual-listbox__search,
.dual-listbox .nk-chat-editor-form .dual-listbox__search {
  min-height: 36px;
  padding-top: 0.5rem;
}
.nk-chat-editor-upload,
.nk-chat-editor-tools {
  display: flex;
  align-items: center;
}
.nk-chat-editor-upload .btn-icon .icon,
.nk-chat-editor-upload .dt-buttons .btn-secondary .icon,
.dt-buttons .nk-chat-editor-upload .btn-secondary .icon,
.nk-chat-editor-tools .btn-icon .icon,
.nk-chat-editor-tools .dt-buttons .btn-secondary .icon,
.dt-buttons .nk-chat-editor-tools .btn-secondary .icon {
  font-size: 1.5rem;
}
.nk-chat-editor-upload {
  position: relative;
  z-index: 2;
}
.nk-chat-editor-upload .toggle-opt {
  transition: 0.3s;
}
.nk-chat-editor-upload .toggle-opt.active {
  opacity: 0.7;
  transform: rotate(-45deg);
}
.nk-chat-profile {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  width: 325px;
  height: 100%;
  max-height: 100%;
  transition: transform 0.3s ease-in-out;
  background: #fff;
  z-index: 100;
}
.nk-chat-profile-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(16, 25, 36, 0.4);
  z-index: 600;
  z-index: 90;
  animation: overlay-fade-in 0.4s ease 1;
}
.nk-chat-profile.visible {
  transform: none;
}
@media (max-width: 1199.98px) {
  .nk-chat {
    min-height: calc(100vh - (65px + 78px + 30px));
    max-height: calc(100vh - (65px + 78px + 30px));
  }
}
@media (max-width: 575.98px) {
  .nk-chat {
    border: none;
    border-radius: 0;
    min-height: calc(100vh - (65px + 116px + 30px));
    max-height: calc(100vh - (65px + 116px + 30px));
  }
  .nk-chat-boxed {
    border: 1px solid #dbdfea;
    border-radius: 4px;
  }
  .nk-chat-head {
    padding: 0.75rem 1.25rem;
  }
  .nk-chat-head-user {
    max-width: calc(100% - 34px);
  }
  .nk-chat-head-user .user-avatar {
    height: 36px;
    width: 36px;
  }
  .nk-chat-head-user .user-info {
    width: calc(100% - 36px - 0.75rem);
  }
}
@media (min-width: 576px) {
  .nk-chat-head-info,
  .nk-chat-head-tools {
    margin: -0.375rem;
  }
  .nk-chat-head-info > li,
  .nk-chat-head-tools > li {
    padding: 0.375rem;
  }
}
@media (min-width: 576px) and (max-width: 991px) {
  .nk-chat-aside-head,
  .nk-chat-aside-search {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .nk-chat-aside-panel {
    padding: 0 2.25rem 1.75rem;
  }
  .nk-chat-list {
    padding: 0 1.5rem;
  }
  .nk-chat-head,
  .nk-chat-panel,
  .nk-chat-editor {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .nk-chat-head-search {
    padding: 0 2.25rem;
  }
}
@media (min-width: 860px) {
  .nk-chat-aside {
    width: 320px;
    border-right: 1px solid #e5e9f2;
  }
  .nk-chat-body {
    position: static;
    opacity: 1;
    pointer-events: auto;
  }
  .nk-chat-body-close {
    display: none;
  }
}
@media (max-width: 859px) {
  .nk-chat-body.show-chat {
    position: fixed;
    z-index: 2999;
  }
  .nk-chat-head {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}
@media (min-width: 860px) and (max-width: 991px) {
  .nk-chat-aside {
    width: 345px;
    border-right: 1px solid #e5e9f2;
  }
}
@media (min-width: 992px) {
  .nk-chat-aside-panel,
  .nk-chat-aside-head,
  .nk-chat-aside-search {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .nk-chat-list {
    padding: 0 1rem;
  }
  .nk-chat-aside {
    width: 325px;
  }
  .nk-chat-panel {
    padding: 1.25rem 1.75rem;
  }
  .chat-profile-head,
  .chat-profile-body-inner {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
}
@media (min-width: 1540px) {
  .nk-chat-boxed {
    min-height: calc(100vh - (65px + 64px + 112px));
    max-height: calc(100vh - (65px + 64px + 112px));
  }
  .nk-chat-boxed .nk-chat-body.profile-shown {
    padding-right: 0;
  }
  .nk-chat-aside {
    width: 382px;
  }
  .nk-chat-body.profile-shown {
    padding-right: 365px;
  }
  .nk-chat-profile {
    width: 365px;
    border-left: 1px solid #e5e9f2;
  }
}
@media (max-width: 1539.98px) {
  .profile-shown .nk-chat-profile-toggle {
    right: 262px;
  }
  .has-apps-sidebar .profile-shown .nk-chat-profile-toggle {
    right: -18px;
  }
}
@media (max-width: 359px) {
  .nk-chat-profile {
    width: 280px;
  }
}
.nk-code-preview-box {
  display: block;
  color: #8094ae;
  padding: 0.15rem 1.25rem;
  border-left: 1px solid #e5e9f2;
}
.dashboard-preview {
  height: 200px;
  justify-content: center;
}
.dropdown-preview .dropdown-menu {
  position: static;
  display: block;
}
.dropdown-preview:after {
  display: block;
  clear: both;
  content: "";
}
@media (max-width: 420px) {
  .dropdown-preview .dropdown-menu-xl,
  .dropdown-preview .dropdown-menu-lg,
  .dropdown-preview .dropdown-menu-md {
    min-width: 100%;
    max-width: 100%;
  }
}
.prettyprint {
  padding: 1.25rem;
  white-space: pre;
  box-shadow: none !important;
  margin-bottom: 0;
  border-radius: 4px;
  overflow: auto;
}
.code-block {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 1.25rem;
  background: #fff;
  border-radius: 4px;
}
.code-block .clipboard-init {
  position: absolute;
  top: 12px;
  right: 12px;
}
.card-preview + .code-block {
  margin-top: 28px;
}
.code-block + .code-block {
  margin-top: -3px;
  border-top-color: #e5e9f2;
}
.code-block + .card-preview,
.code-block + .nk-block-head {
  margin-top: 40px;
}
.code-block .prettyprint {
  background: #f5f6fa;
  border: none;
  padding: 0.75rem;
  font-size: 11px;
  line-height: 20px;
  max-height: 136px;
}
.code-block .title {
  margin-bottom: 1rem;
}
.code-block .btn,
.code-block .dual-listbox .dual-listbox__button,
.dual-listbox .code-block .dual-listbox__button,
.code-block .actions ul li a,
.actions ul li .code-block a {
  background-color: #fff;
}
.code-block .btn:focus,
.code-block .dual-listbox .dual-listbox__button:focus,
.dual-listbox .code-block .dual-listbox__button:focus,
.code-block .actions ul li a:focus,
.actions ul li .code-block a:focus,
.code-block .btn:hover,
.code-block .dual-listbox .dual-listbox__button:hover,
.dual-listbox .code-block .dual-listbox__button:hover,
.code-block .actions ul li a:hover,
.actions ul li .code-block a:hover {
  border-color: #dbdfea;
  box-shadow: none;
}
.code-block-clean .title {
  font-size: 11px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #8094ae;
  font-weight: 500;
  margin-bottom: 0.45rem;
  display: block;
}
.card-preview + .code-block-group {
  margin-top: 28px;
}
.code-block-group .code-block:not(:last-child) {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.code-block-group .code-block + .code-block {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.preview-icon-list {
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
}
.preview-icon-item {
  flex: 1 0 150px;
  padding: 15px;
  max-width: 50%;
}
.preview-icon-box {
  position: relative;
  padding: 25px 20px;
  text-align: center;
}
.preview-icon-box .btn-clipboard {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  z-index: 1;
}
.preview-icon-box .btn-clipboard:focus {
  border-color: rgba(0, 0, 0, 0);
  box-shadow: none;
}
.preview-icon-box:hover .btn-clipboard {
  opacity: 1;
}
.preview-icon-wrap {
  padding-bottom: 5px;
}
.preview-icon-wrap .ni {
  font-size: 30px;
}
.preview-icon-svg .preview-icon-wrap {
  margin-bottom: 0.5rem;
}
.preview-icon-svg .preview-icon-wrap svg {
  width: 90px;
}
.preview-icon-name {
  color: #8094ae;
  font-size: 11px;
}
.preview-title,
.preview-title-lg {
  display: block;
  padding-bottom: 0.75rem;
}
.preview-title-lg {
  font-size: 13px;
}
.preview-hr {
  border-top: 1px solid #dbdfea;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.preview-hr + .preview-title {
  padding-top: 0.5rem;
}
.nk-block-head + .preview-hr {
  margin-top: 0.5rem;
}
.preview-list {
  display: flex;
  flex-wrap: wrap;
  margin: -0.75rem;
}
.preview-item {
  padding: 0.75rem;
}
.preview-btn-list {
  display: flex;
  flex-wrap: wrap;
  margin: -0.75rem;
}
.preview-btn-item {
  width: 50%;
  padding: 0.75rem;
}
.preview-btn-list-fw .preview-btn-item {
  width: 100%;
}
.preview-btn-list-3x .preview-btn-item {
  width: 33.33%;
}
.example-width > div,
.example-height > div,
.example-border > div,
.example-spacing > div {
  font-size: 11px;
  padding: 0.5rem;
  text-align: center;
  background-color: #f5f6fa;
}
.example-border {
  margin: -0.5rem;
}
.example-border > div {
  height: 4.5rem;
  width: 4.5rem;
  margin: 0.5rem;
}
.example-border-all > div {
  border: 1px solid #dbdfea;
}
.example-width > div {
  height: 2rem;
  background-color: #e5e9f2;
  margin-bottom: 0.25rem;
}
.example-height {
  height: 8rem;
}
.example-height > div {
  width: 8rem;
  background-color: #e5e9f2;
  margin-right: 0.5rem;
}
.example-spacing > div {
  height: 2rem;
  width: 4.5rem;
  background-color: #e5e9f2;
  margin-bottom: 0.5rem;
}
.example-spacing-pd > div {
  margin-right: 0.5rem;
  height: auto;
  width: auto;
}
.example-spacing-pd > div > span {
  display: block;
  padding: 0.25rem;
  line-height: 1;
  border-radius: 2px;
  background-color: #fff;
}
.example-alerts:not(:last-child) {
  margin-bottom: 28px;
}
.components-preview h4.title,
.components-preview .title.h4,
.components-preview h5.title,
.components-preview .title.h5 {
  font-size: 1.25rem;
  letter-spacing: -0.01rem;
  font-family: Roboto, sans-serif;
  font-weight: 500;
}
.components-preview .card-preview > .card-inner {
  padding: 1.25rem;
}
@media (min-width: 768px) {
  .preview-btn-item {
    width: 25%;
  }
  .preview-btn-list-fw .preview-btn-item {
    width: 25%;
  }
  .preview-btn-list-3x .preview-btn-item {
    width: 16.66%;
  }
}
@media (min-width: 992px) {
  .components-preview .nk-block + .nk-block-lg {
    padding-top: 3.75rem;
  }
}
@media (max-width: 767.98px) {
  .preview-reference tr,
  .preview-reference th,
  .preview-reference td {
    display: block;
    border: 0;
    width: 100% !important;
  }
  .preview-reference tbody tr {
    padding: 0.5rem 0;
  }
  .preview-reference tbody tr:not(:last-child) {
    border-bottom: 1px solid #e5e9f2;
  }
  .preview-reference tbody tr:nth-child(2n) {
    background-color: rgba(245, 246, 250, 0.3);
  }
  .preview-reference tr td {
    padding: 0.2rem 1.25rem;
  }
  .preview-reference tr th:not(:first-child) {
    display: none;
  }
}
.nk-body.ui-bordered .has-fixed .nk-header-wrap,
.nk-body.ui-bordered .nk-sidebar,
.nk-body.ui-bordered .card:not([class*="bg-"]),
.nk-body.ui-bordered .code-block,
.nk-body.ui-bordered .accordion:not(.accordion-s2):not(.accordion-s3),
.nk-body.ui-bordered .card-bordered,
.nk-body.ui-bordered .nk-chat,
.nk-body.ui-bordered .nk-ibx,
.nk-body.ui-bordered .nk-msg,
.nk-body.ui-bordered .nk-fmg,
.nk-body.ui-bordered .nk-download {
  border: 1px solid var(--bs-border-color);
  box-shadow: none;
}
.ui-rounder .card,
.ui-rounder .ratio,
.ui-rounder .accordion,
.ui-rounder .modal-content,
.ui-rounder .dropdown-menu,
.ui-rounder .alert,
.ui-rounder .toast,
.ui-rounder .swal2-popup,
.ui-rounder .code-block,
.ui-rounder .bq-note-text,
.ui-rounder .video,
.ui-rounder div.dt-button-info,
.ui-rounder .nk-msg,
.ui-rounder .nk-ibx,
.ui-rounder .nk-fmg,
.ui-rounder .nk-chat,
.ui-rounder .nk-reply-entry.note,
.ui-rounder .nk-reply-form,
.ui-rounder .attach-files,
.ui-rounder .nk-files-view-grid .nk-file,
.ui-rounder .nk-files-view-group .nk-file,
.ui-rounder .product-gallery,
.ui-rounder .product-gallery .slider-nav .slider-item .thumb,
.ui-rounder .ui-timepicker-container,
.ui-rounder .entry img,
.ui-rounder .entry .video,
.ui-rounder .entry .image-group,
.ui-rounder .toastr,
.ui-rounder .tox-tinymce,
.ui-rounder .tox .tox-menu,
.ui-rounder .tox-tinymce-inline .tox-editor-header,
.ui-rounder .tox .tox-toolbar-overlord,
.ui-rounder .nk-order-ovwg-data,
.ui-rounder .fc table,
.ui-rounder .fc-theme-bootstrap5 .fc-list,
.ui-rounder .kanban-item,
.ui-rounder .note-dropdown-menu,
.ui-rounder .note-frame {
  border-radius: 12px;
}
.ui-rounder .rounded,
.ui-rounder .round {
  border-radius: 12px !important;
}
.ui-rounder .nk-header-wrap,
.ui-rounder .accordion-item:last-of-type,
.ui-rounder .card > .list-group:last-child,
.ui-rounder .attach-foot,
.ui-rounder .dual-listbox .dual-listbox__available,
.ui-rounder .dual-listbox .dual-listbox__selected,
.ui-rounder .tox-tinymce-inline .tox-editor-header .tox-toolbar:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.ui-rounder .accordion-item:first-of-type,
.ui-rounder .card > .list-group:first-child,
.ui-rounder .dual-listbox__title,
.ui-rounder .tox-tinymce-inline .tox-editor-header .tox-toolbar:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.ui-rounder .rounded-top,
.ui-rounder .card-img,
.ui-rounder .card-img-top,
.ui-rounder .card-header,
.ui-rounder .modal-header,
.ui-rounder .toast-header,
.ui-rounder .ql-toolbar,
.ui-rounder .note-toolbar,
.ui-rounder .fc-list-table tr:first-child th,
.ui-rounder .fc-list-table tr:first-child .fc-list-day-cushion {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}
.ui-rounder .rounded-bottom,
.ui-rounder .card-img,
.ui-rounder .card-img-bottom,
.ui-rounder .card-footer,
.ui-rounder .modal-footer,
.ui-rounder .toast-footer,
.ui-rounder .kanban-board-header,
.ui-rounder .ql-container,
.ui-rounder .note-statusbar {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.ui-rounder .card > .table tr:first-child th:first-child,
.ui-rounder .nk-files-view-list .nk-file:first-child > div:first-child,
.ui-rounder .fc table thead tr:first-child td:first-child,
.ui-rounder .fc table thead tr:first-child th:first-child {
  border-top-left-radius: 12px !important;
}
.ui-rounder .card > .table tr:first-child th:last-child,
.ui-rounder .nk-files-view-list .nk-file:first-child > div:last-child,
.ui-rounder .fc table thead tr:first-child td:last-child,
.ui-rounder .fc table thead tr:first-child th:last-child {
  border-top-right-radius: 12px !important;
}
.ui-rounder .card > .table tr:last-child td:first-child,
.ui-rounder .nk-files-view-list .nk-file:last-child > div:first-child,
.ui-rounder .fc table tbody tr:last-child td:first-child,
.ui-rounder .fc table tbody tr:last-child th:first-child {
  border-bottom-left-radius: 12px !important;
}
.ui-rounder .card > .table tr:last-child td:last-child,
.ui-rounder .nk-files-view-list .nk-file:last-child > div:last-child,
.ui-rounder .fc table tbody tr:last-child td:last-child,
.ui-rounder .fc table tbody tr:last-child th:last-child {
  border-bottom-right-radius: 12px !important;
}
.ui-rounder .btn,
.ui-rounder .dual-listbox .dual-listbox__button,
.dual-listbox .ui-rounder .dual-listbox__button,
.ui-rounder .actions ul li a,
.actions ul li .ui-rounder a,
.ui-rounder .popover,
.ui-rounder .prettyprint,
.ui-rounder .tooltip-inner,
.ui-rounder .nk-reply-from,
.ui-rounder .form-control,
.ui-rounder div.dataTables_wrapper div.dataTables_filter input,
div.dataTables_wrapper div.dataTables_filter .ui-rounder input,
.ui-rounder .dual-listbox .dual-listbox__search,
.dual-listbox .ui-rounder .dual-listbox__search,
.ui-rounder
  .custom-control:not(.custom-switch):not(.custom-radio)
  .custom-control-label::before,
.ui-rounder
  .custom-control:not(.custom-switch):not(.custom-radio)
  .custom-control-label::after,
.ui-rounder .form-file-label,
.ui-rounder .dropzone,
.ui-rounder .custom-control-pro .custom-control-label,
.ui-rounder .pro-control .custom-control-label,
.ui-rounder .tox .tox-mbtn,
.ui-rounder .select2-container--default .select2-selection--single,
.ui-rounder .datatable-wrap {
  border-radius: 6px;
}
.ui-rounder
  .select2-container--default.select2-container--below.select2-container--open
  .select2-selection--single,
.ui-rounder .select2-dropdown--above,
.ui-rounder .popover-header {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.ui-rounder .select2-dropdown--below,
.ui-rounder .popover-footer {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.ui-rounder .input-daterange input:first-child,
.ui-rounder .page-item:first-child .page-link {
  border-top-left-radius: 6px;
}
.ui-rounder .input-daterange input:last-child,
.ui-rounder .page-item:last-child .page-link {
  border-top-right-radius: 6px;
}
.ui-rounder .input-daterange input:first-child,
.ui-rounder .page-item:first-child .page-link {
  border-bottom-left-radius: 6px;
}
.ui-rounder .input-daterange input:last-child,
.ui-rounder .page-item:last-child .page-link {
  border-bottom-right-radius: 6px;
}
@media (min-width: 992px) {
  .ui-rounder .nk-menu-main .nk-menu-sub {
    border-radius: 12px;
  }
}
@media (min-width: 1200px) {
  .ui-rounder .nk-sidebar {
    border-radius: 12px;
  }
}
body.dark-mode {
  background: #101924 !important;
  color: #b6c6e3;
}
body.dark-mode.bg-lighter,
.dual-listbox body.dark-mode.dual-listbox__item:hover,
body.dark-mode.bg-light {
  background: #0d141d !important;
}
body.dark-mode.nk-body.ui-softy,
body.dark-mode.nk-body.ui-shady {
  background: #101924 !important;
}
.dark-mode .logo-dark {
  opacity: 0;
}
.dark-mode .logo-light {
  opacity: 1;
}
.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6,
.dark-mode .h1,
.dark-mode .h2,
.dark-mode .h3,
.dark-mode .h4,
.dark-mode .h5,
.dark-mode .h6,
.dark-mode .lead-text,
.dark-mode .dropdown-title,
.dark-mode pre {
  color: #fff;
}
.dark-mode .text-base,
.dark-mode .text-body,
.dark-mode .link-text {
  color: #b6c6e3 !important;
}
.dark-mode .text-secondary,
.dark-mode .icon[class*="bg-secondary-dim"],
.dark-mode .user-avatar[class*="-light"],
.dark-mode .user-avatar[class*="bg-secondary-dim"],
.dark-mode .icon[class*="bg-light"] {
  color: #8094ae !important;
}
.dark-mode .text-dark,
.dark-mode .text-head {
  color: #fff !important;
}
.dark-mode .text-muted {
  color: #8094ae !important;
}
.dark-mode .text-light,
.dark-mode .user-avatar[class*="-dark"] {
  color: #8091a7 !important;
}
.dark-mode .text-lighter {
  color: #3c4d62 !important;
}
.dark-mode .text-indigo {
  color: #7a85d5 !important;
}
.dark-mode .bg-light {
  background-color: #0d141d !important;
}
.dark-mode .bg-lighter,
.dark-mode .dual-listbox .dual-listbox__item:hover,
.dual-listbox .dark-mode .dual-listbox__item:hover {
  background-color: #101924 !important;
}
.dark-mode .table-striped tbody tr:nth-of-type(odd) {
  background-color: #101924 !important;
}
.dark-mode .bg-white {
  background-color: #18212d !important;
}
.dark-mode .bg-transparent.bg-transparent {
  background-color: rgba(0, 0, 0, 0) !important;
}
.dark-mode .bg-dark {
  background-color: #192330 !important;
}
.dark-mode .bg-blue-dim {
  background-color: #21334c !important;
}
.dark-mode .bg-azure-dim {
  background-color: #182e4c !important;
}
.dark-mode .bg-indigo-dim {
  background-color: #1f2949 !important;
}
.dark-mode .bg-purple-dim {
  background-color: #282c4d !important;
}
.dark-mode .bg-pink-dim {
  background-color: #3b2b3f !important;
}
.dark-mode .bg-orange-dim {
  background-color: #3b3533 !important;
}
.dark-mode .bg-teal-dim {
  background-color: #193a3d !important;
}
.dark-mode .bg-primary-dim,
.dark-mode .dual-listbox .dual-listbox__item:active,
.dual-listbox .dark-mode .dual-listbox__item:active,
.dark-mode .dual-listbox .dual-listbox__item.dual-listbox__item--selected,
.dual-listbox .dark-mode .dual-listbox__item.dual-listbox__item--selected {
  background-color: #262549 !important;
}
.dark-mode .bg-success-dim {
  background-color: #13865b !important;
}
.dark-mode .bg-info-dim {
  background-color: #15314c !important;
}
.dark-mode .bg-warning-dim {
  background-color: #3a332b !important;
}
.dark-mode .bg-danger-dim {
  background-color: #392637 !important;
}
.dark-mode .bg-secondary-dim {
  background-color: #1e2938 !important;
}
.dark-mode .bg-dark-dim {
  background-color: #1e2937 !important;
}
.dark-mode .bg-gray-dim {
  background-color: #28323f !important;
}
.dark-mode .bg-btc-dim {
  background-color: #3a302b !important;
}
.dark-mode .bg-eth-dim {
  background-color: #232d42 !important;
}
.dark-mode .alert-primary {
  color: #11c8fc;
  background-color: #262549;
  border-color: #262549;
}
.dark-mode .alert-primary hr {
  border-top-color: #262549;
}
.dark-mode .alert-primary .alert-link {
  color: #5617da;
}
.dark-mode .alert-fill.alert-primary {
  color: #fff;
  background: #11c8fc;
}
.dark-mode .alert-fill.alert-primary .alert-link {
  color: #fff;
}
.dark-mode .alert-pro.alert-primary {
  border-color: #11c8fc;
}
.dark-mode .alert-secondary {
  color: #8ca3c0;
  background-color: #1e2938;
  border-color: #1e2938;
}
.dark-mode .alert-secondary hr {
  border-top-color: #1e2938;
}
.dark-mode .alert-secondary .alert-link {
  color: #6b89ae;
}
.dark-mode .alert-fill.alert-secondary {
  color: #fff;
  background: #8ca3c0;
}
.dark-mode .alert-fill.alert-secondary .alert-link {
  color: #fff;
}
.dark-mode .alert-pro.alert-secondary {
  border-color: #8ca3c0;
}
.dark-mode .alert-success {
  color: #0fca7a;
  background-color: #173a39;
  border-color: #173a39;
}
.dark-mode .alert-success hr {
  border-top-color: #173a39;
}
.dark-mode .alert-success .alert-link {
  color: #0b9b5d;
}
.dark-mode .alert-fill.alert-success {
  color: #fff;
  background: #0fca7a;
}
.dark-mode .alert-fill.alert-success .alert-link {
  color: #fff;
}
.dark-mode .alert-pro.alert-success {
  border-color: #0fca7a;
}
.dark-mode .alert-warning {
  color: #fd9722;
  background-color: #3a332b;
  border-color: #3a332b;
}
.dark-mode .alert-warning hr {
  border-top-color: #3a332b;
}
.dark-mode .alert-warning .alert-link {
  color: #ea7e02;
}
.dark-mode .alert-fill.alert-warning {
  color: #fff;
  background: #fd9722;
}
.dark-mode .alert-fill.alert-warning .alert-link {
  color: #fff;
}
.dark-mode .alert-pro.alert-warning {
  border-color: #fd9722;
}
.dark-mode .alert-info {
  color: #058efc;
  background-color: #15314c;
  border-color: #15314c;
}
.dark-mode .alert-info hr {
  border-top-color: #15314c;
}
.dark-mode .alert-info .alert-link {
  color: #0272cc;
}
.dark-mode .alert-fill.alert-info {
  color: #fff;
  background: #058efc;
}
.dark-mode .alert-fill.alert-info .alert-link {
  color: #fff;
}
.dark-mode .alert-pro.alert-info {
  border-color: #058efc;
}
.dark-mode .alert-danger {
  color: #f2426e;
  background-color: #392637;
  border-color: #392637;
}
.dark-mode .alert-danger hr {
  border-top-color: #392637;
}
.dark-mode .alert-danger .alert-link {
  color: #ef1249;
}
.dark-mode .alert-fill.alert-danger {
  color: #fff;
  background: #f2426e;
}
.dark-mode .alert-fill.alert-danger .alert-link {
  color: #fff;
}
.dark-mode .alert-pro.alert-danger {
  border-color: #f2426e;
}
.dark-mode .alert-gray {
  color: #b7c2d0;
  background-color: #28323f;
  border-color: #28323f;
}
.dark-mode .alert-gray hr {
  border-top-color: #28323f;
}
.dark-mode .alert-gray .alert-link {
  color: #98a8bc;
}
.dark-mode .alert-fill.alert-gray {
  color: #fff;
  background: #b7c2d0;
}
.dark-mode .alert-fill.alert-gray .alert-link {
  color: #fff;
}
.dark-mode .alert-pro.alert-gray {
  border-color: #b7c2d0;
}
.dark-mode .alert-dark {
  color: #1f2b3a;
  background-color: #19232f;
  border-color: #19232f;
}
.dark-mode .alert-dark hr {
  border-top-color: #19232f;
}
.dark-mode .alert-dark .alert-link {
  color: #0d1219;
}
.dark-mode .alert-fill.alert-dark {
  color: #fff;
  background: #1f2b3a;
}
.dark-mode .alert-fill.alert-dark .alert-link {
  color: #fff;
}
.dark-mode .alert-pro.alert-dark {
  border-color: #1f2b3a;
}
.dark-mode .alert-light {
  color: #ebeef2;
  background-color: #28323f;
  border-color: #28323f;
}
.dark-mode .alert-light hr {
  border-top-color: #28323f;
}
.dark-mode .alert-light .alert-link {
  color: #ccd4de;
}
.dark-mode .alert-fill.alert-light {
  color: #000;
  background: #ebeef2;
}
.dark-mode .alert-fill.alert-light .alert-link {
  color: #000;
}
.dark-mode .alert-pro.alert-light {
  border-color: #ebeef2;
}
.dark-mode .badge-dim.bg-primary {
  color: #11c8fc;
  background-color: #262549 !important;
  border-color: #262549;
}
.dark-mode .badge-dim.bg-outline-primary {
  color: #11c8fc;
  background-color: #262549;
  border-color: #3c2b79;
}
.dark-mode .badge-dim.bg-secondary {
  color: #8ca3c0;
  background-color: #293543 !important;
  border-color: #293543;
}
.dark-mode .badge-dim.bg-outline-secondary {
  color: #8ca3c0;
  background-color: #293543;
  border-color: #465568;
}
.dark-mode .badge-dim.bg-success {
  color: #0fca7a;
  background-color: #173a39 !important;
  border-color: #173a39;
}
.dark-mode .badge-dim.bg-outline-success {
  color: #0fca7a;
  background-color: #173a39;
  border-color: #14654c;
}
.dark-mode .badge-dim.bg-info {
  color: #058efc;
  background-color: #15314c !important;
  border-color: #15314c;
}
.dark-mode .badge-dim.bg-outline-info {
  color: #058efc;
  background-color: #15314c;
  border-color: #104d80;
}
.dark-mode .badge-dim.bg-warning {
  color: #fd9722;
  background-color: #3a332b !important;
  border-color: #3a332b;
}
.dark-mode .badge-dim.bg-outline-warning {
  color: #fd9722;
  background-color: #3a332b;
  border-color: #745029;
}
.dark-mode .badge-dim.bg-danger {
  color: #f2426e;
  background-color: #392637 !important;
  border-color: #392637;
}
.dark-mode .badge-dim.bg-outline-danger {
  color: #f2426e;
  background-color: #392637;
  border-color: #6f2e47;
}
.dark-mode .badge-dim.bg-light {
  color: #b7c2d0;
  background-color: #303945 !important;
  border-color: #303945;
}
.dark-mode .badge-dim.bg-outline-light {
  color: #b7c2d0;
  background-color: #303945;
  border-color: #58616e;
}
.dark-mode .badge-dim.bg-dark {
  color: #e5e9f2;
  background-color: #373f4b !important;
  border-color: #373f4b;
}
.dark-mode .badge-dim.bg-outline-dark {
  color: #e5e9f2;
  background-color: #373f4b;
  border-color: #6a717c;
}
.dark-mode .badge-dim.bg-gray {
  color: #8091a7;
  background-color: #28323f !important;
  border-color: #28323f;
}
.dark-mode .badge-dim.bg-outline-gray {
  color: #8091a7;
  background-color: #28323f;
  border-color: #424e5e;
}
.dark-mode .badge-dim.bg-lighter,
.dark-mode .dual-listbox .badge-dim.dual-listbox__item:hover,
.dual-listbox .dark-mode .badge-dim.dual-listbox__item:hover {
  color: #8091a7;
  background-color: #28323f !important;
  border-color: #28323f;
}
.dark-mode .badge-dim.bg-outline-lighter {
  color: #8091a7;
  background-color: #28323f;
  border-color: #424e5e;
}
.dark-mode .bg-outline-secondary,
.dark-mode .badge-dot.bg-dark,
.dark-mode .badge-dot.bg-secondary {
  color: #b7c2d0;
}
.dark-mode .bg-outline-dark {
  color: #e5e9f2;
  border-color: #e5e9f2;
}
.dark-mode .bg-outline-light {
  color: #b7c2d0;
  border-color: #344357;
}
.dark-mode .badge-dot.bg-dark,
.dark-mode .badge-dot.bg-light {
  background-color: rgba(0, 0, 0, 0) !important;
}
.dark-mode .badge-dot.bg-light {
  color: #8091a7;
}
.dark-mode .bg-light,
.dark-mode .code-block .btn,
.dark-mode .code-block .dual-listbox .dual-listbox__button,
.dual-listbox .dark-mode .code-block .dual-listbox__button,
.dark-mode .code-block .actions ul li a,
.actions ul li .dark-mode .code-block a {
  color: #e5e9f2;
  background: #344357;
  border-color: #344357;
}
.dark-mode .bg-dark {
  color: #8091a7;
  border-color: #192330;
}
.dark-mode .bg-dark.user-avatar {
  color: #1f2b3a !important;
  background: #e5e9f2 !important;
  border-color: #e5e9f2 !important;
}
.dark-mode .btn-light,
.dark-mode .dual-listbox .dual-listbox__button,
.dual-listbox .dark-mode .dual-listbox__button {
  color: #e5e9f2;
  background: #344357;
  border-color: #344357;
}
.dark-mode .btn-light:not(:disabled):not(.disabled):hover,
.dark-mode
  .dual-listbox
  .dual-listbox__button:not(:disabled):not(.disabled):hover,
.dual-listbox
  .dark-mode
  .dual-listbox__button:not(:disabled):not(.disabled):hover,
.dark-mode .btn-light:not(:disabled):not(.disabled):focus,
.dark-mode
  .dual-listbox
  .dual-listbox__button:not(:disabled):not(.disabled):focus,
.dual-listbox
  .dark-mode
  .dual-listbox__button:not(:disabled):not(.disabled):focus,
.dark-mode .btn-light:not(:disabled):not(.disabled).focus,
.dark-mode
  .dual-listbox
  .dual-listbox__button:not(:disabled):not(.disabled).focus,
.dual-listbox
  .dark-mode
  .dual-listbox__button:not(:disabled):not(.disabled).focus,
.dark-mode .btn-light:not(:disabled):not(.disabled):active,
.dark-mode
  .dual-listbox
  .dual-listbox__button:not(:disabled):not(.disabled):active,
.dual-listbox
  .dark-mode
  .dual-listbox__button:not(:disabled):not(.disabled):active,
.dark-mode .btn-light:not(:disabled):not(.disabled).active,
.dark-mode
  .dual-listbox
  .dual-listbox__button:not(:disabled):not(.disabled).active,
.dual-listbox
  .dark-mode
  .dual-listbox__button:not(:disabled):not(.disabled).active {
  background: #435771;
  border-color: #435771;
  color: #e5e9f2;
}
.dark-mode .btn-dark {
  color: #1f2b3a;
  background: #e5e9f2;
  border-color: #e5e9f2;
}
.dark-mode .btn-dark:not(:disabled):not(.disabled):hover,
.dark-mode .btn-dark:not(:disabled):not(.disabled):focus,
.dark-mode .btn-dark:not(:disabled):not(.disabled).focus,
.dark-mode .btn-dark:not(:disabled):not(.disabled):active,
.dark-mode .btn-dark:not(:disabled):not(.disabled).active {
  background: #fff;
  border-color: #fff;
  color: #1f2b3a;
}
.dark-mode .btn-outline-dark {
  border-color: #e5e9f2;
  color: #e5e9f2;
}
.dark-mode .btn-outline-dark:not(:disabled):not(.disabled):hover {
  background: #e5e9f2;
  color: #1f2b3a;
}
.dark-mode .btn-outline-secondary {
  border-color: #5a7ba5;
  color: #5a7ba5;
}
.dark-mode .btn-outline-secondary:not(:disabled):not(.disabled):hover {
  background: #5a7ba5;
  color: #fff;
}
.dark-mode .btn-outline-light,
.dark-mode .dt-buttons .btn-secondary,
.dt-buttons .dark-mode .btn-secondary {
  border-color: #3c4d62;
  color: #b7c2d0;
}
.dark-mode .btn-outline-light:not(:disabled):not(.disabled):hover,
.dark-mode .dt-buttons .btn-secondary:not(:disabled):not(.disabled):hover,
.dt-buttons .dark-mode .btn-secondary:not(:disabled):not(.disabled):hover {
  background: #3c4d62;
  color: #fff;
}
.dark-mode .btn-dim.btn-primary {
  color: #11c8fc;
  background-color: #28264f;
  border-color: rgba(0, 0, 0, 0);
}
.dark-mode .btn-dim.btn-primary:not(:disabled):not(.disabled):hover {
  color: #9468ef;
  background-color: #343267;
  border-color: #343267;
}
.dark-mode .btn-dim.btn-secondary {
  color: #adbdd2;
  background-color: #202b3b;
  border-color: rgba(0, 0, 0, 0);
}
.dark-mode .btn-dim.btn-secondary:not(:disabled):not(.disabled):hover {
  color: #ced8e4;
  background-color: #2d3c52;
  border-color: #2d3c52;
}
.dark-mode .btn-dim.btn-success {
  color: #0fca7a;
  background-color: #163f3b;
  border-color: rgba(0, 0, 0, 0);
}
.dark-mode .btn-dim.btn-success:not(:disabled):not(.disabled):hover {
  color: #1eee95;
  background-color: #1f5954;
  border-color: #1f5954;
}
.dark-mode .btn-dim.btn-warning {
  color: #fd9722;
  background-color: #41362b;
  border-color: rgba(0, 0, 0, 0);
}
.dark-mode .btn-dim.btn-warning:not(:disabled):not(.disabled):hover {
  color: #fdaf55;
  background-color: #564839;
  border-color: #564839;
}
.dark-mode .btn-dim.btn-info {
  color: #058efc;
  background-color: #153552;
  border-color: rgba(0, 0, 0, 0);
}
.dark-mode .btn-dim.btn-info:not(:disabled):not(.disabled):hover {
  color: #37a5fd;
  background-color: #1c476e;
  border-color: #1c476e;
}
.dark-mode .btn-dim.btn-danger {
  color: #f2426e;
  background-color: #3f2739;
  border-color: rgba(0, 0, 0, 0);
}
.dark-mode .btn-dim.btn-danger:not(:disabled):not(.disabled):hover {
  color: #f57293;
  background-color: #55354d;
  border-color: #55354d;
}
.dark-mode .btn-dim.btn-gray {
  color: #b7c2d0;
  background-color: #2b3543;
  border-color: rgba(0, 0, 0, 0);
}
.dark-mode .btn-dim.btn-gray:not(:disabled):not(.disabled):hover {
  color: #d6dce4;
  background-color: #394659;
  border-color: #394659;
}
.dark-mode .btn-dim.btn-dark {
  color: #e5e9f2;
  background-color: #353e4a;
  border-color: rgba(0, 0, 0, 0);
}
.dark-mode .btn-dim.btn-dark:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #444f5f;
  border-color: #444f5f;
}
.dark-mode .btn-dim.btn-light,
.dark-mode .dual-listbox .btn-dim.dual-listbox__button,
.dual-listbox .dark-mode .btn-dim.dual-listbox__button {
  color: #ebeef2;
  background-color: #2b3543;
  border-color: rgba(0, 0, 0, 0);
}
.dark-mode .btn-dim.btn-light:not(:disabled):not(.disabled):hover,
.dark-mode
  .dual-listbox
  .btn-dim.dual-listbox__button:not(:disabled):not(.disabled):hover,
.dual-listbox
  .dark-mode
  .btn-dim.dual-listbox__button:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #394659;
  border-color: #394659;
}
.dark-mode .btn-dim.btn-lighter {
  color: #b7c2d0;
  background-color: #2b3543;
  border-color: rgba(0, 0, 0, 0);
}
.dark-mode .btn-dim.btn-lighter:not(:disabled):not(.disabled):hover {
  color: #d6dce4;
  background-color: #394659;
  border-color: #394659;
}
.dark-mode .btn-dim.btn-outline-primary {
  color: #11c8fc;
  background-color: #28264f;
  border-color: #382a6f;
}
.dark-mode .btn-dim.btn-outline-primary:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #11c8fc;
  border-color: #11c8fc;
}
.dark-mode
  .btn-white.btn-outline-primary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dark-mode
  .dt-buttons
  .btn-outline-primary.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dt-buttons
  .dark-mode
  .btn-outline-primary.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dark-mode
  .btn-trans.btn-outline-primary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover {
  color: #11c8fc;
  background: #28264f;
}
.dark-mode .btn-dim.btn-outline-success {
  color: #0fca7a;
  background-color: #163f3b;
  border-color: #155c48;
}
.dark-mode .btn-dim.btn-outline-success:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #0fca7a;
  border-color: #0fca7a;
}
.dark-mode
  .btn-white.btn-outline-success:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dark-mode
  .dt-buttons
  .btn-outline-success.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dt-buttons
  .dark-mode
  .btn-outline-success.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dark-mode
  .btn-trans.btn-outline-success:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover {
  color: #0fca7a;
  background: #163f3b;
}
.dark-mode .btn-dim.btn-outline-warning {
  color: #fd9722;
  background-color: #41362b;
  border-color: #684a29;
}
.dark-mode .btn-dim.btn-outline-warning:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #fd9722;
  border-color: #fd9722;
}
.dark-mode
  .btn-white.btn-outline-warning:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dark-mode
  .dt-buttons
  .btn-outline-warning.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dt-buttons
  .dark-mode
  .btn-outline-warning.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dark-mode
  .btn-trans.btn-outline-warning:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover {
  color: #fd9722;
  background: #41362b;
}
.dark-mode .btn-dim.btn-outline-info {
  color: #058efc;
  background-color: #153552;
  border-color: #114775;
}
.dark-mode .btn-dim.btn-outline-info:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #058efc;
  border-color: #058efc;
}
.dark-mode
  .btn-white.btn-outline-info:not(.btn-dim):not(:disabled):not(.disabled):hover,
.dark-mode
  .dt-buttons
  .btn-outline-info.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dt-buttons
  .dark-mode
  .btn-outline-info.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dark-mode
  .btn-trans.btn-outline-info:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover {
  color: #058efc;
  background: #153552;
}
.dark-mode .btn-dim.btn-outline-danger {
  color: #f2426e;
  background-color: #3f2739;
  border-color: #642d44;
}
.dark-mode .btn-dim.btn-outline-danger:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #f2426e;
  border-color: #f2426e;
}
.dark-mode
  .btn-white.btn-outline-danger:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dark-mode
  .dt-buttons
  .btn-outline-danger.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dt-buttons
  .dark-mode
  .btn-outline-danger.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dark-mode
  .btn-trans.btn-outline-danger:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover {
  color: #f2426e;
  background: #3f2739;
}
.dark-mode .btn-dim.btn-outline-secondary {
  color: #6b89ae;
  background-color: #202b3b;
  border-color: #2d3e53;
}
.dark-mode .btn-dim.btn-outline-secondary:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #6b89ae;
  border-color: #6b89ae;
}
.dark-mode
  .btn-white.btn-outline-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dark-mode
  .dt-buttons
  .btn-outline-secondary.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dt-buttons
  .dark-mode
  .btn-outline-secondary.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dark-mode
  .btn-trans.btn-outline-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover {
  color: #6b89ae;
  background: #202b3b;
}
.dark-mode .btn-dim.btn-outline-gray {
  color: #b7c2d0;
  background-color: #2b3543;
  border-color: #3c4858;
}
.dark-mode .btn-dim.btn-outline-gray:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #b7c2d0;
  border-color: #b7c2d0;
}
.dark-mode
  .btn-white.btn-outline-gray:not(.btn-dim):not(:disabled):not(.disabled):hover,
.dark-mode
  .dt-buttons
  .btn-outline-gray.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dt-buttons
  .dark-mode
  .btn-outline-gray.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dark-mode
  .btn-trans.btn-outline-gray:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover {
  color: #b7c2d0;
  background: #2b3543;
}
.dark-mode .btn-dim.btn-outline-dark {
  color: #e5e9f2;
  background-color: #353e4a;
  border-color: #505966;
}
.dark-mode .btn-dim.btn-outline-dark:not(:disabled):not(.disabled):hover {
  color: #000;
  background-color: #e5e9f2;
  border-color: #e5e9f2;
}
.dark-mode
  .btn-white.btn-outline-dark:not(.btn-dim):not(:disabled):not(.disabled):hover,
.dark-mode
  .dt-buttons
  .btn-outline-dark.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dt-buttons
  .dark-mode
  .btn-outline-dark.btn-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dark-mode
  .btn-trans.btn-outline-dark:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover {
  color: #e5e9f2;
  background: #353e4a;
}
.dark-mode .btn-dim.btn-outline-light,
.dark-mode .dt-buttons .btn-dim.btn-secondary,
.dt-buttons .dark-mode .btn-dim.btn-secondary {
  color: #8091a7;
  background-color: #1e2937;
  border-color: #3c4858;
}
.dark-mode .btn-dim.btn-outline-light:not(:disabled):not(.disabled):hover,
.dark-mode
  .dt-buttons
  .btn-dim.btn-secondary:not(:disabled):not(.disabled):hover,
.dt-buttons
  .dark-mode
  .btn-dim.btn-secondary:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #8091a7;
  border-color: #8091a7;
}
.dark-mode
  .btn-white.btn-outline-light:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.dark-mode
  .dt-buttons
  .btn-secondary:not(.btn-dim):not(:disabled):not(.disabled):hover,
.dt-buttons
  .dark-mode
  .btn-secondary:not(.btn-dim):not(:disabled):not(.disabled):hover,
.dark-mode
  .btn-trans.btn-outline-light:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover {
  color: #8091a7;
  background: #1e2937;
}
.dark-mode .btn-dim.btn-outline-light:not(:disabled):not(.disabled):hover,
.dark-mode .btn-white.btn-outline-light:not(:disabled):not(.disabled):hover,
.dark-mode .dt-buttons .btn-secondary:not(:disabled):not(.disabled):hover,
.dt-buttons .dark-mode .btn-secondary:not(:disabled):not(.disabled):hover,
.dark-mode .btn-dim.btn-outline-light:not(:disabled):not(.disabled):active,
.dark-mode .btn-white.btn-outline-light:not(:disabled):not(.disabled):active,
.dark-mode .dt-buttons .btn-secondary:not(:disabled):not(.disabled):active,
.dt-buttons .dark-mode .btn-secondary:not(:disabled):not(.disabled):active,
.dark-mode .show > .btn-white.btn-outline-light.dropdown-toggle,
.dark-mode .dt-buttons .show > .dropdown-toggle.btn-secondary,
.dt-buttons .dark-mode .show > .dropdown-toggle.btn-secondary {
  background: #3c4d62;
  border-color: #3c4d62;
  color: #fff;
}
.dark-mode .btn-white,
.dark-mode .dt-buttons .btn-secondary,
.dt-buttons .dark-mode .btn-secondary,
.dark-mode .btn-white.btn-dim,
.dark-mode .btn-white.btn-outline-light {
  background: #18212d;
}
.dark-mode .btn-outline-light:focus,
.dark-mode .dt-buttons .btn-secondary:focus,
.dt-buttons .dark-mode .btn-secondary:focus,
.dark-mode .btn-outline-light.focus,
.dark-mode .dt-buttons .focus.btn-secondary,
.dt-buttons .dark-mode .focus.btn-secondary {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.07) !important;
}
.dark-mode .border,
.dark-mode .border-bottom,
.dark-mode .border-top,
.dark-mode .border-left,
.dark-mode .border-right,
.dark-mode .dropdown-inner + .dropdown-inner,
.dark-mode .dropdown-head,
.dark-mode .dropdown-foot,
.dark-mode .preview-hr,
.dark-mode .select2-container--default .select2-selection--single,
.dark-mode .ql-picker-options,
.dark-mode .dropzone,
.dark-mode .select2-container--default .select2-selection--multiple,
.dark-mode .select2-dropdown,
.dark-mode
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field,
.dark-mode .ui-timepicker-standard,
.dark-mode .ql-toolbar,
.dark-mode .ql-container,
.dark-mode .note-editor,
.dark-mode .note-toolbar,
.dark-mode .tox .tox-menubar,
.dark-mode .tox-toolbar-overlord,
.dark-mode .tox-tinymce,
.dark-mode .tox .tox-toolbar__group:not(:last-of-type),
.dark-mode .tox .tox-statusbar,
.dark-mode .tox .tox-menu,
.dark-mode .tox-toolbar,
.dark-mode .tox-editor-header,
.dark-mode .tox .tox-toolbar,
.dark-mode .tox .tox-toolbar__primary,
.dark-mode .tox .tox-toolbar__overflow,
.dark-mode .tox .tox-collection--list .tox-collection__group,
.dark-mode .note-btn-group .note-btn,
.dark-mode .note-statusbar,
.dark-mode .nk-code-preview-box,
.dark-mode .swal2-footer,
.dark-mode .example-border-all > div,
.dark-mode .btn-toolbar-sep,
.dark-mode .page-link,
.dark-mode .table th,
.dark-mode .table td,
.dark-mode .table-bordered,
.dark-mode .table-bordered th,
.dark-mode .table-bordered td,
.dark-mode .datatable-wrap,
.dark-mode .nk-tb-head .nk-tb-col,
.dark-mode .list-group-item,
.dark-mode .modal-header,
.dark-mode .modal-footer,
.dark-mode .popover,
.dark-mode .popover-header,
.dark-mode .tagify__tag > div::before,
.dark-mode .tagify__tag:hover:not([readonly]) div::before,
.dark-mode .nk-tb-item:not(:last-child) .nk-tb-col,
.dark-mode .nk-activity-item:not(:last-child),
.dark-mode .nk-support-item:not(:last-child),
.dark-mode .card-inner-group .card-inner:not(:last-child),
.dark-mode .card-aside,
.dark-mode .nav-tabs,
.dark-mode .divider,
.dark-mode .data-item:not(:last-child),
.dark-mode .nk-footer,
.dark-mode .nav-switch-s2 .nav-link,
.dark-mode .language-list li:not(:last-child) .language-item,
.dark-mode .user-avatar-multiple .user-avatar {
  border-color: #1d2d40 !important;
}
.dark-mode .select2-search--dropdown .select2-search__field {
  color: #b6c6e3;
}
.dark-mode .nk-header.has-fixed .nk-header-wrap,
.dark-mode .card,
.dark-mode .code-block,
.dark-mode .alert-pro,
.dark-mode .modal-content,
.dark-mode .toast,
.dark-mode .toastr {
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.3);
}
.dark-mode .toastr {
  background: #1f2b3a;
}
.dark-mode .toast-header {
  color: #8094ae;
}
.dark-mode .toast,
.dark-mode .toast-header {
  border-color: #203247;
  background: #233041;
}
.dark-mode .table-active,
.dark-mode .table-active > th,
.dark-mode .table-active > td,
.dark-mode .table-hover tbody tr:hover,
.dark-mode .code-block .prettyprint,
.dark-mode .prettyprint,
.dark-mode .list-group-item,
.dark-mode .example-spacing-pd > div > span,
.dark-mode .form-file-label::after,
.dark-mode .ql-picker-options,
.dark-mode .data-more:before,
.dark-mode .search-wrap,
.dark-mode .form-control,
.dark-mode div.dataTables_wrapper div.dataTables_filter input,
div.dataTables_wrapper div.dataTables_filter .dark-mode input,
.dark-mode .dual-listbox .dual-listbox__search,
.dual-listbox .dark-mode .dual-listbox__search,
.dark-mode .input-mail,
.dark-mode .dropzone,
.dark-mode .ui-state-hover,
.dark-mode .note-editor.note-frame .note-statusbar,
.dark-mode .note-editor.note-airframe .note-statusbar,
.dark-mode .swal2-popup,
.dark-mode
  .select2-container--default
  .select2-results__option--highlighted[aria-selected],
.dark-mode
  .select2-container--default
  .select2-results__option[aria-selected="true"],
.dark-mode
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__choice,
.dark-mode
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field,
.dark-mode .example-width > div,
.dark-mode .example-height > div,
.dark-mode .example-border > div,
.dark-mode .example-spacing > div {
  background: #101924;
}
.dark-mode .list-group-item {
  color: #b6c6e3;
}
.dark-mode .tox .tox-tbtn:hover,
.dark-mode .tox .tox-collection--list .tox-collection__item--active,
.dark-mode .tox .tox-mbtn--active,
.dark-mode .tox .tox-mbtn:focus:not(:disabled),
.dark-mode .tox .tox-mbtn:hover:not(:disabled):not(.tox-mbtn--active),
.dark-mode .tox .tox-collection--list .tox-collection__item--enabled {
  background: #0a0f16;
}
.dark-mode .icon-overlap li {
  border-color: #18212d;
}
.dark-mode .card,
.dark-mode .kanban-add-task,
.dark-mode .kanban-board-header,
.dark-mode .kanban-item,
.dark-mode .table > :not(caption) > * > * {
  background: #18212d;
}
.dark-mode .accordion,
.dark-mode .form-clip,
.dark-mode .form-text-hint,
.dark-mode .card-aside,
.dark-mode .code-block,
.dark-mode .alert-pro,
.dark-mode .modal-content,
.dark-mode .toggle-content,
.dark-mode .nav-switch-s2 .nav-link:hover,
.dark-mode .nav-switch-s2 .nav-link:focus,
.dark-mode .nav-switch-s2 .nav-link.active,
.dark-mode .select2-container--default .select2-selection--single,
.dark-mode .select2-container--default .select2-selection--multiple,
.dark-mode .select2-dropdown,
.dark-mode .form-file-label,
.dark-mode .form-select,
.dark-mode .popover,
.dark-mode .search-wrap,
.dark-mode .form-control,
.dark-mode div.dataTables_wrapper div.dataTables_filter input,
div.dataTables_wrapper div.dataTables_filter .dark-mode input,
.dark-mode .dual-listbox .dual-listbox__search,
.dual-listbox .dark-mode .dual-listbox__search,
.dark-mode .form-label-outlined,
.dark-mode .input-mail,
.dark-mode .page-link,
.dark-mode .ui-timepicker-standard,
.dark-mode .note-editor,
.dark-mode .note-toolbar,
.dark-mode .tox .tox-edit-area__iframe,
.dark-mode .tox .tox-menubar,
.dark-mode .tox-toolbar-overlord,
.dark-mode .tox .tox-toolbar,
.dark-mode .tox .tox-toolbar__primary,
.dark-mode .tox .tox-toolbar__overflow,
.dark-mode .tox .tox-statusbar,
.dark-mode .tox .tox-menu {
  background: #18212d !important;
}
.dark-mode .code-block + .code-block {
  border-top-color: #1d2d40;
}
.dark-mode .datepicker table tr td.today,
.dark-mode .datepicker table tr td.today:hover,
.dark-mode .datepicker table tr td.today.disabled,
.dark-mode .datepicker table tr td.today.disabled:hover,
.dark-mode .datepicker table tr td.day:hover,
.dark-mode .datepicker table tr td.day.focused,
.dark-mode .datepicker .datepicker-switch:hover,
.dark-mode .datepicker .prev:hover,
.dark-mode .datepicker .next:hover,
.dark-mode .datepicker tfoot tr th:hover {
  background: #101924 !important;
}
.dark-mode .search-wrap.search-wrap-extend,
.dark-mode .search-wrap.search-wrap-extend .form-control,
.dark-mode
  .search-wrap.search-wrap-extend
  div.dataTables_wrapper
  div.dataTables_filter
  input,
div.dataTables_wrapper
  div.dataTables_filter
  .dark-mode
  .search-wrap.search-wrap-extend
  input,
.dark-mode .search-wrap.search-wrap-extend .dual-listbox .dual-listbox__search,
.dual-listbox .dark-mode .search-wrap.search-wrap-extend .dual-listbox__search {
  background: #101924 !important;
}
.dark-mode .accordion-item {
  background: #18212d !important;
}
.dark-mode .clipboard-success-message {
  background: rgba(24, 33, 45, 0.9);
}
.dark-mode .tagify__tag > div::before,
.dark-mode .tagify__tag:hover:not([readonly]) div::before {
  box-shadow: 0 0 0 1.1em #101924 inset;
}
.dark-mode .popover-header {
  background-color: #1d2937;
}
.dark-mode .popover {
  box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.6);
}
.dark-mode .bs-popover-top > .popover-arrow::before,
.dark-mode
  .bs-popover-auto[data-popper-placement^="top"]
  > .popover-arrow::before {
  border-top-color: #1d2d40 !important;
}
.dark-mode .bs-popover-top > .popover-arrow::after,
.dark-mode
  .bs-popover-auto[data-popper-placement^="top"]
  > .popover-arrow::after {
  border-top-color: #18212d !important;
}
.dark-mode .bs-popover-bottom > .popover-arrow::before,
.dark-mode
  .bs-popover-auto[data-popper-placement^="bottom"]
  > .popover-arrow::before {
  border-bottom-color: #1d2d40 !important;
}
.dark-mode .bs-popover-bottom > .popover-arrow::after,
.dark-mode
  .bs-popover-auto[data-popper-placement^="bottom"]
  > .popover-arrow::after {
  border-bottom-color: #18212d !important;
}
.dark-mode .bs-popover-end > .popover-arrow::before,
.dark-mode
  .bs-popover-auto[data-popper-placement^="right"]
  > .popover-arrow::before {
  border-right-color: #1d2d40 !important;
}
.dark-mode .bs-popover-end > .popover-arrow::after,
.dark-mode
  .bs-popover-auto[data-popper-placement^="right"]
  > .popover-arrow::after {
  border-right-color: #18212d !important;
}
.dark-mode .bs-popover-start > .popover-arrow::before,
.dark-mode
  .bs-popover-auto[data-popper-placement^="left"]
  > .popover-arrow::before {
  border-left-color: #1d2d40 !important;
}
.dark-mode .bs-popover-start > .popover-arrow::after,
.dark-mode
  .bs-popover-auto[data-popper-placement^="left"]
  > .popover-arrow::after {
  border-left-color: #18212d !important;
}
.dark-mode .team-info li span:last-child,
.dark-mode .team-statistics li span:first-child,
.dark-mode .project-progress-percent,
.dark-mode .kanban-title-content .title,
.dark-mode .card-tools-nav li a:hover,
.dark-mode .card-tools-nav li a.active,
.dark-mode .card-tools-nav li.active a,
.dark-mode .card-amount .amount,
.dark-mode .accordion-head .title,
.dark-mode .form-file-label::after,
.dark-mode .input-mail,
.dark-mode .form-control,
.dark-mode div.dataTables_wrapper div.dataTables_filter input,
div.dataTables_wrapper div.dataTables_filter .dark-mode input,
.dark-mode .dual-listbox .dual-listbox__search,
.dual-listbox .dark-mode .dual-listbox__search,
.dark-mode .form-control:focus,
.dark-mode .form-label,
.dark-mode .form-label-outlined,
.dark-mode .input-group-text,
.dark-mode .table .table-light th,
.dark-mode .table-hover tbody tr:hover,
.dark-mode .close,
.dark-mode .close:hover,
.dark-mode .user-name,
.dark-mode .nk-news-text p,
.dark-mode .data-item:hover .icon,
.dark-mode .accordion-icon {
  color: #fff !important;
}
.dark-mode .close {
  text-shadow: 0 1px 0 #18212d;
}
.dark-mode .close:focus {
  outline: none !important;
}
.dark-mode .nk-block-des,
.dark-mode .card-title p,
.dark-mode .nk-sale-data .sub-title,
.dark-mode .link-check li span,
.dark-mode .data-value,
.dark-mode .form-file-label,
.dark-mode .form-select,
.dark-mode .form-control-select-multiple .form-select option,
.dark-mode
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered,
.dark-mode
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered,
.dark-mode .ui-timepicker-standard a,
.dark-mode .note-placeholder,
.dark-mode [class*="knob"],
.dark-mode .tox .tox-tbtn:hover,
.dark-mode .tox .tox-statusbar a,
.dark-mode .tox .tox-statusbar__path-item,
.dark-mode .tox .tox-statusbar__wordcount,
.dark-mode .tox-collection__item-label > *,
.dark-mode .nk-quick-nav-icon,
.dark-mode .dropzone .dz-message span,
.dark-mode .datepicker table tr td.today,
.dark-mode .datepicker table tr td.today:hover,
.dark-mode .datepicker table tr td.today.disabled,
.dark-mode .datepicker table tr td.today.disabled:hover,
.dark-mode .datepicker table tr td.day:hover,
.dark-mode .datepicker table tr td.day.focused,
.dark-mode .page-link,
.dark-mode .alert-pro,
.dark-mode .nk-news-text p span,
.dark-mode .language-item,
.dark-mode .nk-tb-list .tb-lead-sub,
.dark-mode .nk-tb-list .tb-amount-sub {
  color: #8094ae !important;
}
.dark-mode .tox .tox-collection__item-accessory {
  color: #526484;
}
.dark-mode .link-dark,
.dark-mode .link-list a {
  color: #8094ae;
}
.dark-mode .link-dark:hover,
.dark-mode .link-list a:hover {
  color: #11c8fc;
}
.dark-mode .nk-menu-badge {
  color: #dbdfea;
  background: #2d3f55;
}
.dark-mode .is-dark .nk-menu-badge {
  color: #11c8fc2e;
}
.dark-mode .is-dark .nk-menu-badge {
  color: #9c73f5;
}
.dark-mode .ql-picker.ql-expanded .ql-picker-label,
.dark-mode .page-link:hover {
  color: #11c8fc !important;
}
.dark-mode .nk-menu-link:hover .nk-menu-icon,
.dark-mode .nk-menu-item.active > .nk-menu-link .nk-menu-icon,
.dark-mode .nk-menu-item.current-menu > .nk-menu-link .nk-menu-icon,
.dark-mode .active > .nk-menu-link,
.dark-mode .is-theme .nk-menu-link:hover .nk-menu-icon,
.dark-mode .is-theme .nk-menu-item.active > .nk-menu-link .nk-menu-icon,
.dark-mode .is-theme .nk-menu-item.current-menu > .nk-menu-link .nk-menu-icon,
.dark-mode .is-theme .nk-menu-sub .nk-menu-link:hover,
.dark-mode .is-theme .nk-menu-sub .active > .nk-menu-link {
  color: #fff !important;
}
.dark-mode .nk-menu-link,
.dark-mode .nk-menu-main .nk-menu-sub .nk-menu-link {
  color: #8094ae;
}
.dark-mode .nk-menu-link:hover,
.dark-mode .nk-menu-main .nk-menu-sub .nk-menu-link:hover {
  color: #fff;
}
.dark-mode .nk-sidebar .nk-menu-sub .active > .nk-menu-link:before {
  background-color: #fff !important;
}
.dark-mode .nk-menu-hr,
.dark-mode .nk-menu-main .nk-menu-sub,
.dark-mode .nk-sidebar .nk-menu > li .nk-menu-sub .nk-menu-sub,
.dark-mode .user-avatar-group .user-avatar {
  border-color: #1d2d40;
}
.dark-mode .nk-menu-main .nk-menu-sub {
  background: #1d2937;
}
.dark-mode .nk-menu-main .nk-menu-sub .nk-menu-item:hover > a {
  background-color: #18212d;
}
.dark-mode .table .table-light th,
.dark-mode .page-item.disabled .page-link,
.dark-mode .page-link:hover,
.dark-mode .tb-odr-head,
.dark-mode .tb-tnx-head,
.dark-mode .custom-control-input[disabled] ~ .custom-control-label::before,
.dark-mode .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #2b3c51 !important;
}
.dark-mode .progress:not([class*="bg-"]),
.dark-mode .data-head,
.dark-mode .custom-control-label::before,
.dark-mode .input-group-text {
  background-color: #243244;
}
.dark-mode .nk-msg-profile-toggle {
  background-color: #0d141d !important;
}
.dark-mode .nk-msg-profile-toggle:hover {
  color: #fff;
}
.dark-mode .nk-msg-profile-toggle,
.dark-mode .custom-control-label::before,
.dark-mode .form-control,
.dark-mode div.dataTables_wrapper div.dataTables_filter input,
div.dataTables_wrapper div.dataTables_filter .dark-mode input,
.dark-mode .dual-listbox .dual-listbox__search,
.dual-listbox .dark-mode .dual-listbox__search,
.dark-mode .input-mail,
.dark-mode .form-file-label,
.dark-mode .input-group-text,
.dark-mode .form-select,
.dark-mode .swal2-input,
.dark-mode .swal2-file,
.dark-mode .swal2-textarea,
.dark-mode .select2-container--default .select2-selection--single,
.dark-mode .select2-container--default .select2-selection--multiple,
.dark-mode .select2-dropdown,
.dark-mode
  .note-popover
  .popover-content
  .dropdown-style
  > li[aria-label="pre"],
.dark-mode .note-editor .note-toolbar .dropdown-style > li[aria-label="pre"],
.dark-mode .link-bdr-t,
.link-tidy .dark-mode li + li,
.link-list-opt .dark-mode li + li,
.link-list-plain .dark-mode li + li,
.dark-mode .link-check + .link-check,
.dark-mode .link-tidy li + li,
.dark-mode .link-list + .link-list,
.dark-mode .link-list-opt li + li,
.dark-mode .link-list-plain li,
.dark-mode .link-list-plain li + li {
  border-color: #3b526f !important;
}
.dark-mode .custom-switch .custom-control-label::after {
  background-color: #869fbe !important;
}
.dark-mode
  .custom-switch
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-color: #fff !important;
}
.dark-mode .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #11c8fc !important;
  border-color: #11c8fc !important;
}
.dark-mode .btn-trigger:not(.on-dark):before,
.dark-mode .btn-trigger:not(.on-dark).active:hover:before,
.dark-mode .nk-quick-nav-icon:before {
  background-color: #070a0f;
}
.dark-mode .dropdown-menu {
  background-color: #1b2633;
  border-color: #2a3a4e;
  box-shadow: 0 3px 20px 1px rgba(0, 0, 0, 0.4);
}
.dark-mode .dropdown-menu-s1 {
  border-top-color: #11c8fc;
}
.dark-mode .note-btn {
  background-color: #141c26;
  color: #8094ae;
}
.dark-mode .note-dropdown-item[aria-label="pre"] {
  border-color: #2a3a4e;
}
.dark-mode .note-dropdown-menu {
  background-color: #1b2633;
  border-color: #2a3a4e;
}
.dark-mode .link-list-plain a,
.dark-mode .link-list-opt a,
.dark-mode .link-check li a {
  color: #b6c6e3;
}
.dark-mode .link-list-plain a:hover,
.dark-mode .link-list-opt a:hover,
.dark-mode .link-check li a:hover {
  background: #2d3f55;
}
.dark-mode .border-transparent {
  border-color: rgba(0, 0, 0, 0) !important;
}
.dark-mode .note-editor .btn,
.dark-mode .note-editor .dual-listbox .dual-listbox__button,
.dual-listbox .dark-mode .note-editor .dual-listbox__button,
.dark-mode .note-editor .actions ul li a,
.actions ul li .dark-mode .note-editor a,
.dark-mode .ql-picker,
.dark-mode .tox .tox-tbtn__select-label,
.dark-mode .tox .tox-mbtn__select-label,
.dark-mode .lead-text span,
.dark-mode .data-list-s2 .data-label,
.dark-mode .btn-trigger:not(.on-dark),
.dark-mode .dropdown .btn.btn-icon:not(.btn-light),
.dark-mode
  .dropdown
  .dual-listbox
  .btn-icon.dual-listbox__button:not(.btn-light),
.dual-listbox
  .dark-mode
  .dropdown
  .btn-icon.dual-listbox__button:not(.btn-light),
.dark-mode .dropdown .actions ul li a.btn-icon:not(.btn-light),
.actions ul li .dark-mode .dropdown a.btn-icon:not(.btn-light),
.dark-mode .dropdown .dt-buttons .btn.btn-secondary:not(.btn-light),
.dt-buttons .dark-mode .dropdown .btn.btn-secondary:not(.btn-light),
.dark-mode
  .dropdown
  .dual-listbox
  .dt-buttons
  .dual-listbox__button.btn-secondary:not(.btn-light),
.dual-listbox
  .dt-buttons
  .dark-mode
  .dropdown
  .dual-listbox__button.btn-secondary:not(.btn-light),
.dark-mode
  .dropdown
  .dt-buttons
  .dual-listbox
  .dual-listbox__button.btn-secondary:not(.btn-light),
.dt-buttons
  .dual-listbox
  .dark-mode
  .dropdown
  .dual-listbox__button.btn-secondary:not(.btn-light),
.dark-mode .dropdown .actions ul li .dt-buttons a.btn-secondary:not(.btn-light),
.actions ul li .dt-buttons .dark-mode .dropdown a.btn-secondary:not(.btn-light),
.dark-mode .dropdown .dt-buttons .actions ul li a.btn-secondary:not(.btn-light),
.dt-buttons .actions ul li .dark-mode .dropdown a.btn-secondary:not(.btn-light),
.dark-mode .list-step li.list-step-done {
  color: #8094ae;
}
.dark-mode .ql-fill,
.dark-mode .ql-stroke.ql-fill,
.dark-mode .tox .tox-tbtn svg {
  fill: #8094ae;
}
.dark-mode .ql-stroke {
  stroke: #8094ae;
}
.dark-mode .swal2-validation-message {
  background: #1d2d40;
}
.dark-mode .team-info li span:first-child,
.dark-mode .team-statistics li span:last-child,
.dark-mode .project-progress-task,
.dark-mode .table,
.dark-mode .nk-tb-list,
.dark-mode .nk-tb-list .tb-amount span,
.dark-mode .nk-tb-list .tb-amount .currency,
.dark-mode .tb-tnx-item .tb-tnx-total,
.dark-mode .tb-odr-item .tb-odr-total,
.dark-mode .user-balance-sub span,
.dark-mode .dropdown-menu,
.dark-mode .pln,
.dark-mode .overline-title,
.dark-mode .nk-block-des strong,
.dark-mode .data-item:hover .data-value,
.dark-mode .data-item:hover .data-label,
.dark-mode .nk-notification-text,
.dark-mode .profile-ud-value,
.dark-mode .bq-note-meta span > span,
.dark-mode .tagify__tag > div,
.dark-mode .popover-body,
.dark-mode .input-mail::placeholder,
.dark-mode .list-apps-title {
  color: #b6c6e3;
}
.dark-mode .nk-notification-text span,
.dark-mode .is-unread .chat-context .text {
  color: #dfe9fe;
}
.dark-mode .tb-tnx-item {
  border-color: #1d2d40 !important;
}
.dark-mode .nav-tabs .nav-link {
  color: #b6c6e3;
}
.dark-mode .nav-tabs .nav-link.active {
  color: #11c8fc;
}
.dark-mode .nav-tabs .nav-link:hover {
  color: #fff;
}
.dark-mode .link-list-menu a,
.dark-mode .list-plain a {
  color: #8094ae;
}
.dark-mode .link-list-menu a:hover,
.dark-mode .link-list-menu a.active,
.dark-mode .list-plain a:hover,
.dark-mode .list-plain a.active {
  color: #11c8fc;
}
.dark-mode .nav-switch .nav-link.active,
.dark-mode .list-apps a:hover .list-apps-title {
  color: #fff;
}
.dark-mode .nk-tb-item:not(.nk-tb-head):hover,
.dark-mode .nk-tb-item:not(.nk-tb-head).seleted {
  box-shadow: 0 2px 15px -4px rgba(0, 0, 0, 0.7);
}
.dark-mode .nk-tb-item:hover .nk-tb-action-hidden,
.dark-mode .bq-note-text,
.dark-mode .nk-tb-item:not(.nk-tb-head):hover,
.dark-mode .nk-tb-item:not(.nk-tb-head).seleted {
  background: #141c26;
}
.dark-mode .nk-apps-sidebar .dropdown-menu .lead-text,
.dark-mode .list-step li.list-step-current,
.dark-mode .nk-tb-list .tb-lead,
.dark-mode .nk-tb-list .tb-amount,
.dark-mode .nk-support-content .title,
.dark-mode .nk-sale-data .amount {
  color: #fff;
}
.dark-mode .nk-support-content .time,
.dark-mode .page-item.disabled .page-link,
.dark-mode .card-hint,
.dark-mode .list-step li {
  color: #526484;
}
.dark-mode .timeline-item:not(:last-child):before,
.dark-mode .timeline-status.is-outline:after {
  background-color: #2a3a4e;
}
.dark-mode .nk-order-ovwg-data .title .icon,
.dark-mode .nk-footer {
  background-color: #101924;
}
.dark-mode .nk-order-ovwg-data .info strong,
.dark-mode .user-activity .amount,
.dark-mode .invest-data-history .amount,
.dark-mode .analytic-data .amount,
.dark-mode .traffic-channel-data .amount,
.dark-mode .analytics-map-data .amount,
.dark-mode .device-status-data .amount,
.dark-mode .invest-ov .amount,
.dark-mode .profile-balance-amount .number {
  color: #fff;
}
.dark-mode .invest-ov:not(:last-child) {
  border-color: #3b526f;
}
.dark-mode .nk-wizard-simple .steps ul li:after {
  background: #1d2d40;
}
.dark-mode .nk-wizard-simple .steps ul li.done:after,
.dark-mode .nk-wizard-simple .steps ul li.current:after {
  background: #11c8fc;
}
.dark-mode .nk-header .dropdown-menu .lead-text,
.dark-mode .nk-sidebar-bar .dropdown-menu .lead-text {
  color: #fff;
}
.dark-mode .nk-sidebar,
.dark-mode .nk-sidebar.is-light,
.dark-mode .nk-sidebar-head {
  border-color: #2a3a4e !important;
}
.dark-mode .nk-header.has-fixed .nk-header-wrap,
.dark-mode .nk-sidebar,
.dark-mode .nk-sidebar.is-light {
  background: #18212d;
}
.dark-mode .nk-chat-aside-user .title,
.dark-mode .nk-file-name,
.dark-mode .nk-file-name-text a.title,
.dark-mode .is-unread .nk-ibx-context-text .heading,
.dark-mode .chat-profile-settings .custom-control-sm .custom-control-label,
.dark-mode .attach-info,
.dark-mode .profile-stats .amount,
.dark-mode .is-unread .chat-from .name {
  color: #fff;
}
.dark-mode .nk-fmg-menu-item .icon,
.dark-mode .nk-ibx-menu-item .icon {
  color: #8094ae;
}
.dark-mode .chat-upload-option {
  background-color: #18212d;
}
.dark-mode .nk-fmg-menu-text,
.dark-mode .nk-ibx-label-text,
.dark-mode .nk-ibx-menu-text,
.dark-mode .attach-item a,
.dark-mode .user-contacts li,
.dark-mode .nk-ibx-context-text .heading,
.dark-mode .chat-option-link:hover .lead-text,
.dark-mode .chat-from .name,
.dark-mode .user-plan-title {
  color: #b6c6e3;
}
.dark-mode .nk-file-name-text a.title:hover,
.dark-mode .active > .nk-fmg-menu-item .icon,
.dark-mode .active .nk-ibx-menu-text,
.dark-mode .active .nk-ibx-menu-item .icon {
  color: #11c8fc;
}
.dark-mode .nk-chat,
.dark-mode .nk-chat-aside,
.dark-mode .nk-chat-profile,
.dark-mode .nk-chat-head,
.dark-mode .chat-profile-group,
.dark-mode .nk-fmg,
.dark-mode .nk-fmg-aside,
.dark-mode .nk-fmg-body-head,
.dark-mode .nk-fmg-switch,
.dark-mode .nk-files-view-grid .nk-file,
.dark-mode .nk-files-view-group .nk-file,
.dark-mode .nk-ibx,
.dark-mode .nk-ibx-aside,
.dark-mode .nk-ibx-head,
.dark-mode .nk-ibx-item,
.dark-mode .nk-reply-form,
.dark-mode .nk-reply-form-header,
.dark-mode .nk-reply-form-tools,
.dark-mode .nk-reply-form-field:not(:last-child),
.dark-mode .nk-msg,
.dark-mode .nk-msg-head,
.dark-mode .nk-msg-profile,
.dark-mode .attach-files,
.dark-mode .attach-foot,
.dark-mode .nk-msg-item:not(:last-child),
.dark-mode .nk-msg-aside,
.dark-mode .nk-msg-nav,
.dark-mode .nk-msg-nav .search-wrap,
.dark-mode .nk-files-group > .title,
.dark-mode .nk-file-share-header,
.dark-mode .nk-files-view-list .nk-file > div {
  border-color: #1d2d40 !important;
}
.dark-mode .nk-block-subhead,
.dark-mode .data-list-s2 .data-head,
.dark-mode .nk-profile-toggle,
.dark-mode .nk-profile-content,
.dark-mode .user-account-links {
  border-color: #2b3748;
}
.dark-mode .nk-upload-item,
.dark-mode .dropzone {
  border-color: #354358 !important;
}
.dark-mode .data-list-s2 .data-head {
  background: rgba(0, 0, 0, 0) !important;
}
.dark-mode .chat-sap-meta:before,
.dark-mode .chat-sap-meta:after,
.dark-mode .active > .nk-fmg-menu-item,
.dark-mode .nk-fmg-menu-item:hover,
.dark-mode .nk-ibx-status,
.dark-mode .nk-ibx-reply-item:not(:last-child):after,
.dark-mode .nk-reply-meta-info:before,
.dark-mode .nk-reply-meta-info:after {
  background: #1d2d40;
}
.dark-mode .nk-chat,
.dark-mode .nk-chat-aside,
.dark-mode .nk-chat-profile,
.dark-mode .nk-chat-head,
.dark-mode .chat-msg,
.dark-mode .nk-chat-editor,
.dark-mode .nk-chat-blank,
.dark-mode .nk-fmg,
.dark-mode .nk-fmg-aside,
.dark-mode .nk-fmg-body-head,
.dark-mode .nk-files-view-grid .nk-file,
.dark-mode .nk-files-view-group .nk-file,
.dark-mode .nk-ibx,
.dark-mode .nk-ibx-aside,
.dark-mode .nk-ibx-item,
.dark-mode .nk-ibx-view,
.dark-mode .nk-msg,
.dark-mode .nk-msg-aside,
.dark-mode .nk-msg-head,
.dark-mode .nk-msg-nav,
.dark-mode .nk-msg-profile,
.dark-mode .nk-fmg-switch {
  background: #18212d;
}
.dark-mode .nk-ibx-status .progress {
  background: #18212d !important;
}
.dark-mode .nk-chat-panel,
.dark-mode .chat-item:hover,
.dark-mode .chat-item.current,
.dark-mode .chat-item:hover .chat-actions,
.dark-mode .chat-members .user-card:hover,
.dark-mode .chat-members .user-actions,
.dark-mode .nk-profile-content,
.dark-mode .nk-msg-item.active,
.dark-mode .nk-msg-item.current,
.dark-mode .channel-list a:hover,
.dark-mode .contacts-list .user-card:hover,
.dark-mode .contacts-list .user-actions,
.dark-mode .channel-list a.active {
  background: #121b28 !important;
}
.dark-mode .fav-list .user-avatar:after {
  background: #445f80;
}
.dark-mode .nk-files-view-list .nk-file.selected > div {
  background: rgba(0, 0, 0, 0);
}
.dark-mode .nk-files-view-list .nk-file.selected {
  background: #1d2d40;
}
.dark-mode .nk-ibx-item:not(.no-hover):hover,
.dark-mode .nk-ibx-item.active,
.dark-mode .nk-ibx-item:hover .ibx-actions-hidden,
.dark-mode .nk-ibx-item:hover .ibx-actions,
.dark-mode .nk-ibx-label > li:hover,
.dark-mode .nk-ibx-contact > li:hover,
.dark-mode .nk-ibx-menu li.active,
.dark-mode .nk-ibx-menu li:hover,
.dark-mode .dot-label:after,
.dark-mode .nk-reply-entry.note,
.dark-mode .nk-files-view-list .nk-file > div {
  background: #101924;
}
.dark-mode .nk-reply-from,
.dark-mode .attach-foot {
  background: #15202f;
}
.dark-mode .is-unread .nk-ibx-context-text,
.dark-mode .nk-upload-info {
  color: #dfe9fe;
}
.dark-mode .nk-reply-msg-excerpt,
.dark-mode .nk-ibx-context-text,
.dark-mode .user-contacts li .icon,
.dark-mode .nk-reply-tools .date {
  color: #8094ae;
}
.dark-mode .is-unread .nk-msg-sender .name {
  color: #fff;
}
.dark-mode .nk-reply-meta-info,
.dark-mode .nk-reply-from {
  color: #7a8faa;
}
.dark-mode .nk-reply-meta-info span,
.dark-mode .nk-reply-meta-info strong,
.dark-mode .nk-reply-meta-info .who,
.dark-mode .channel-list a {
  color: #b6c6e3;
}
.dark-mode .nk-reply-meta-info .whom {
  color: #11c8fc;
}
.dark-mode .fc table.fc-scrollgrid {
  border-color: #1d2d40;
}
.dark-mode .fc table td,
.dark-mode .fc table th {
  border-color: #1d2d40;
}
.dark-mode .fc .fc-list-event:hover td {
  background: #101924;
}
.dark-mode .fc.fc-theme-bootstrap5 .fc-list {
  border-color: #1d2d40;
}
.dark-mode .fc .fc-theme-bootstrap5-shaded {
  background-color: #1d2d40;
}
.dark-mode .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .btn,
.dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox
  .dual-listbox__button,
.dual-listbox
  .dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox__button,
.dark-mode .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .actions ul li a,
.actions
  ul
  li
  .dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  a {
  color: #b6c6e3;
  border-color: #1d2d40;
}
.dark-mode .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .btn:disabled,
.dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox
  .dual-listbox__button:disabled,
.dual-listbox
  .dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox__button:disabled,
.dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .actions
  ul
  li
  a:disabled,
.actions
  ul
  li
  .dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  a:disabled {
  color: #b6c6e3;
  border-color: #1d2d40;
}
.dark-mode .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .btn:hover,
.dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox
  .dual-listbox__button:hover,
.dual-listbox
  .dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox__button:hover,
.dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .actions
  ul
  li
  a:hover,
.actions
  ul
  li
  .dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  a:hover,
.dark-mode .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .btn:focus,
.dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox
  .dual-listbox__button:focus,
.dual-listbox
  .dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox__button:focus,
.dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .actions
  ul
  li
  a:focus,
.actions
  ul
  li
  .dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  a:focus {
  color: #fff;
  background: #101924;
  border-color: #1d2d40;
}
.dark-mode .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .btn:focus,
.dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox
  .dual-listbox__button:focus,
.dual-listbox
  .dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox__button:focus,
.dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .actions
  ul
  li
  a:focus,
.actions
  ul
  li
  .dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  a:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 45, 64, 0.5);
}
.dark-mode .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .btn.active,
.dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .dual-listbox
  .active.dual-listbox__button,
.dual-listbox
  .dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .active.dual-listbox__button,
.dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  .actions
  ul
  li
  a.active,
.actions
  ul
  li
  .dark-mode
  .fc
  .fc-toolbar.fc-header-toolbar
  .fc-toolbar-chunk
  a.active {
  color: #11c8fc;
  border-color: #1d2d40 !important;
  background: #101924;
  box-shadow: inset 0 0 4px -1px rgba(29, 45, 64, 0.25);
}
.dark-mode .fc-day {
  color: #b6c6e3;
}
.dark-mode .fc-day-other {
  color: #8094ae;
}
.dark-mode .fc-list-day,
.dark-mode .fc-col-header-cell {
  color: #fff;
}
.dark-mode .fc .fc-list-empty {
  background: #101924;
}
.dark-mode .fc-timegrid-event-harness-inset .fc-timegrid-event,
.dark-mode .fc-timegrid-event.fc-event-mirror {
  box-shadow: none;
}
.dark-mode a.fc-event-primary-dim {
  background-color: #262549 !important;
  border-color: #262549 !important;
}
.dark-mode .modal-header.fc-event-primary-dim {
  background-color: #262549 !important;
  border-color: #1d1c38 !important;
}
.dark-mode span.fc-event-primary-dim .dot {
  background-color: #262549 !important;
}
.dark-mode .fc-event-primary-dim:not(a) .fc-list-event-dot {
  background-color: #262549 !important;
}
.dark-mode a.fc-event-success-dim {
  background-color: #173a39 !important;
  border-color: #173a39 !important;
}
.dark-mode .modal-header.fc-event-success-dim {
  background-color: #173a39 !important;
  border-color: #102827 !important;
}
.dark-mode span.fc-event-success-dim .dot {
  background-color: #173a39 !important;
}
.dark-mode .fc-event-success-dim:not(a) .fc-list-event-dot {
  background-color: #173a39 !important;
}
.dark-mode a.fc-event-info-dim {
  background-color: #15314c !important;
  border-color: #15314c !important;
}
.dark-mode .modal-header.fc-event-info-dim {
  background-color: #15314c !important;
  border-color: #0f2438 !important;
}
.dark-mode span.fc-event-info-dim .dot {
  background-color: #15314c !important;
}
.dark-mode .fc-event-info-dim:not(a) .fc-list-event-dot {
  background-color: #15314c !important;
}
.dark-mode a.fc-event-warning-dim {
  background-color: #3a332b !important;
  border-color: #3a332b !important;
}
.dark-mode .modal-header.fc-event-warning-dim {
  background-color: #3a332b !important;
  border-color: #2b2620 !important;
}
.dark-mode span.fc-event-warning-dim .dot {
  background-color: #3a332b !important;
}
.dark-mode .fc-event-warning-dim:not(a) .fc-list-event-dot {
  background-color: #3a332b !important;
}
.dark-mode a.fc-event-danger-dim {
  background-color: #392637 !important;
  border-color: #392637 !important;
}
.dark-mode .modal-header.fc-event-danger-dim {
  background-color: #392637 !important;
  border-color: #2a1c28 !important;
}
.dark-mode span.fc-event-danger-dim .dot {
  background-color: #392637 !important;
}
.dark-mode .fc-event-danger-dim:not(a) .fc-list-event-dot {
  background-color: #392637 !important;
}
.dark-mode a.fc-event-secondary-dim {
  background-color: #1e2938 !important;
  border-color: #1e2938 !important;
}
.dark-mode .modal-header.fc-event-secondary-dim {
  background-color: #1e2938 !important;
  border-color: #151d27 !important;
}
.dark-mode span.fc-event-secondary-dim .dot {
  background-color: #1e2938 !important;
}
.dark-mode .fc-event-secondary-dim:not(a) .fc-list-event-dot {
  background-color: #1e2938 !important;
}
.dark-mode a.fc-event-dark-dim {
  background-color: #19232f !important;
  border-color: #19232f !important;
}
.dark-mode .modal-header.fc-event-dark-dim {
  background-color: #19232f !important;
  border-color: #10171e !important;
}
.dark-mode span.fc-event-dark-dim .dot {
  background-color: #19232f !important;
}
.dark-mode .fc-event-dark-dim:not(a) .fc-list-event-dot {
  background-color: #19232f !important;
}
.dark-mode a.fc-event-blue-dim {
  background-color: #21334c !important;
  border-color: #21334c !important;
}
.dark-mode .modal-header.fc-event-blue-dim {
  background-color: #21334c !important;
  border-color: #19273a !important;
}
.dark-mode span.fc-event-blue-dim .dot {
  background-color: #21334c !important;
}
.dark-mode .fc-event-blue-dim:not(a) .fc-list-event-dot {
  background-color: #21334c !important;
}
.dark-mode a.fc-event-azure-dim {
  background-color: #182e4c !important;
  border-color: #182e4c !important;
}
.dark-mode .modal-header.fc-event-azure-dim {
  background-color: #182e4c !important;
  border-color: #122239 !important;
}
.dark-mode span.fc-event-azure-dim .dot {
  background-color: #182e4c !important;
}
.dark-mode .fc-event-azure-dim:not(a) .fc-list-event-dot {
  background-color: #182e4c !important;
}
.dark-mode a.fc-event-indigo-dim {
  background-color: #1f2949 !important;
  border-color: #1f2949 !important;
}
.dark-mode .modal-header.fc-event-indigo-dim {
  background-color: #1f2949 !important;
  border-color: #171f37 !important;
}
.dark-mode span.fc-event-indigo-dim .dot {
  background-color: #1f2949 !important;
}
.dark-mode .fc-event-indigo-dim:not(a) .fc-list-event-dot {
  background-color: #1f2949 !important;
}
.dark-mode a.fc-event-purple-dim {
  background-color: #282c4d !important;
  border-color: #282c4d !important;
}
.dark-mode .modal-header.fc-event-purple-dim {
  background-color: #282c4d !important;
  border-color: #1f223c !important;
}
.dark-mode span.fc-event-purple-dim .dot {
  background-color: #282c4d !important;
}
.dark-mode .fc-event-purple-dim:not(a) .fc-list-event-dot {
  background-color: #282c4d !important;
}
.dark-mode a.fc-event-pink-dim {
  background-color: #3b2b3f !important;
  border-color: #3b2b3f !important;
}
.dark-mode .modal-header.fc-event-pink-dim {
  background-color: #3b2b3f !important;
  border-color: #2d2130 !important;
}
.dark-mode span.fc-event-pink-dim .dot {
  background-color: #3b2b3f !important;
}
.dark-mode .fc-event-pink-dim:not(a) .fc-list-event-dot {
  background-color: #3b2b3f !important;
}
.dark-mode a.fc-event-orange-dim {
  background-color: #3b3533 !important;
  border-color: #3b3533 !important;
}
.dark-mode .modal-header.fc-event-orange-dim {
  background-color: #3b3533 !important;
  border-color: #2d2927 !important;
}
.dark-mode span.fc-event-orange-dim .dot {
  background-color: #3b3533 !important;
}
.dark-mode .fc-event-orange-dim:not(a) .fc-list-event-dot {
  background-color: #3b3533 !important;
}
.dark-mode a.fc-event-teal-dim {
  background-color: #193a3d !important;
  border-color: #193a3d !important;
}
.dark-mode .modal-header.fc-event-teal-dim {
  background-color: #193a3d !important;
  border-color: #12292b !important;
}
.dark-mode span.fc-event-teal-dim .dot {
  background-color: #193a3d !important;
}
.dark-mode .fc-event-teal-dim:not(a) .fc-list-event-dot {
  background-color: #193a3d !important;
}
.dark-mode .card .card-inner + .table .tb-tnx-head,
.dark-mode .card-bordered,
.dark-mode .kanban-add-task,
.dark-mode .kanban-board-header,
.dark-mode .kanban-item,
.dark-mode .kanban-board .kanban-drag:empty,
.dark-mode .accordion-item:not(:last-child) .accordion-head,
.dark-mode .accordion-item:not(:last-child) .accordion-inner,
.dark-mode .accordion-item:last-child .accordion-inner,
.dark-mode .nk-download {
  border-color: #1d2d40;
}
.dark-mode .accordion:not(.accordion-s2):not(.accordion-s3) {
  border-color: #1d2d40 !important;
}
.dark-mode .card.bg-light .card-header,
.dark-mode .card.bg-lighter .card-header,
.dark-mode .dual-listbox .card.dual-listbox__item:hover .card-header,
.dual-listbox .dark-mode .card.dual-listbox__item:hover .card-header {
  background: rgba(0, 0, 0, 0.2);
}
.dark-mode .nk-tb-list.is-separate .nk-tb-item > .nk-tb-col {
  border-top-color: #1d2d40;
  border-bottom-color: #1d2d40;
}
.dark-mode .nk-tb-list.is-separate .nk-tb-item > .nk-tb-col:first-child {
  border-left-color: #1d2d40;
}
.dark-mode .nk-tb-list.is-separate .nk-tb-item > .nk-tb-col:last-child {
  border-right-color: #1d2d40;
}
.dark-mode .modal-footer.bg-light {
  background-color: #141c26 !important;
}
.dark-mode .kanban-primary {
  border-top-color: #11c8fc;
}
.dark-mode .kanban-success {
  border-top-color: #0fca7a;
}
.dark-mode .kanban-info {
  border-top-color: #058efc;
}
.dark-mode .kanban-warning {
  border-top-color: #fd9722;
}
.dark-mode .kanban-danger {
  border-top-color: #f2426e;
}
.dark-mode.ui-bordered .card:not([class*="bg-"]),
.dark-mode.ui-bordered .code-block,
.dark-mode.ui-bordered .accordion:not(.accordion-s2):not(.accordion-s3),
.dark-mode.ui-bordered .card .card-inner + .table .tb-tnx-head,
.dark-mode.ui-bordered .card-bordered,
.dark-mode.ui-bordered .has-fixed .nk-header-wrap,
.dark-mode.ui-bordered .nk-chat,
.dark-mode.ui-bordered .nk-ibx,
.dark-mode.ui-bordered .nk-msg,
.dark-mode.ui-bordered .nk-fmg,
.dark-mode.ui-bordered .nk-download {
  border-color: #1d2d40;
}
.dark-mode.ui-bordered .nk-tb-list.is-separate .nk-tb-item > .nk-tb-col {
  border-top-color: #1d2d40;
  border-bottom-color: #1d2d40;
}
.dark-mode.ui-bordered
  .nk-tb-list.is-separate
  .nk-tb-item
  > .nk-tb-col:first-child {
  border-left-color: #1d2d40;
}
.dark-mode.ui-bordered
  .nk-tb-list.is-separate
  .nk-tb-item
  > .nk-tb-col:last-child {
  border-right-color: #1d2d40;
}
.dark-mode .nk-order-ovwg-data.buy {
  border-color: #13865b;
}
.dark-mode .nk-order-ovwg-data.sell {
  border-color: #4f309e;
}
.dark-mode .nk-sidebar-overlay {
  background: rgba(0, 0, 0, 0.6);
}
.dark-mode .toggle-overlay,
.dark-mode .nk-chat-profile-overlay {
  background: rgba(7, 10, 15, 0.75);
}
.dark-mode .swal2-container.swal2-backdrop-show {
  background: rgba(0, 0, 0, 0.85);
}
.dark-mode .modal-backdrop {
  opacity: 0.9;
  background: rgba(0, 0, 0, 0.9);
}
.dark-mode .nk-sidebar .nk-menu > li:hover > a,
.dark-mode .nk-sidebar .nk-menu > li.active > a,
.dark-mode .nk-sidebar .nk-menu > li.current-menu > a,
.dark-mode .wallet-item a:before {
  background: #27254b;
}
.dark-mode .nk-sidebar .nk-menu-sub {
  background: #1d2338;
}
.dark-mode .nk-sidebar .nk-menu-sub:before {
  background: #27254b;
}
.dark-mode .nk-sidebar .nk-menu > li .nk-menu-sub .nk-menu-link:before {
  border-color: #1d2338;
  background: #27254b;
}
.dark-mode .buysell-cc-choosen,
.dark-mode .buysell-cc-chosen,
.dark-mode .buysell-pm-list {
  background: #18212d;
}
.dark-mode .nk-wgw-add a .add-icon:before {
  background: #101924;
}
.dark-mode .card.is-dark:not([class*="bg-"]) {
  background: #170c2f !important;
  border-color: #170c2f;
}
.dark-mode .tranx-item:not(:last-child),
.dark-mode .nk-refwg .nk-refwg-invite,
.dark-mode .nk-wgw-actions,
.dark-mode .card-custom-s1 .card-inner-group,
.dark-mode .nk-kyc-app-icon .icon,
.dark-mode .nk-kycfm-head:not(:last-child),
.dark-mode .nk-kycfm-content:not(:last-child),
.dark-mode .nk-kycfm-footer:not(:last-child) {
  border-color: #1d2d40;
}
.dark-mode .nk-wg4-switcher > li > .dropdown > a,
.dark-mode .buysell-cc-choosen,
.dark-mode .buysell-cc-chosen,
.dark-mode .nk-kycfm-label,
.dark-mode .buysell-cc-item:not(:last-child),
.dark-mode .buysell-cc-dropdown .dropdown-menu,
.dark-mode .buysell-pm-list,
.dark-mode .buysell-pm-item:not(:last-child),
.dark-mode .buysell-overview-list,
.dark-mode .buysell-overview-item:not(:last-of-type) {
  border-color: #3b526f;
}
.dark-mode .buysell-pm-label:before {
  border-color: #4d6b90;
}
.dark-mode .nk-kycfm-count {
  border-color: #7087a4;
  color: #8094ae;
}
.dark-mode .tranx-label,
.dark-mode .nk-wg1-title,
.dark-mode .lead-text-lg,
.dark-mode .is-dark .nk-wgw-balance .amount,
.dark-mode .coin-name,
.dark-mode .nk-wg7-stats .number-lg,
.dark-mode .nk-wg7-stats .number,
.dark-mode .buysell-pm-label .pm-name,
.dark-mode .nk-kyc-app-icon .icon,
.dark-mode .wallet-balance,
.dark-mode .nk-wg5-amount .amount {
  color: #fff !important;
}
.dark-mode .nk-wgw-balance .amount,
.dark-mode .tranx-amount .number,
.dark-mode .user-balance-alt,
.dark-mode .tranx-amount .number-sm,
.dark-mode .nk-wgw-actions a {
  color: #b6c6e3;
}
.dark-mode .nk-wgw-balance .amount-sm,
.dark-mode .tranx-amount .number span {
  color: #8094ae;
}
.dark-mode .is-dark .nk-wgw-balance .amount-sm {
  color: #9c73f5;
}
.dark-mode
  .ui-bordered
  .nk-tb-list.is-separate
  .nk-tb-item
  > .nk-tb-col:first-child,
.dark-mode .ui-bordered .nk-tb-list.is-separate .nk-tb-item > .nk-tb-col {
  border-color: #1d2d40 !important;
}
.dark-mode .nk-tb-list.is-separate .nk-tb-item > .nk-tb-col,
.dark-mode .nk-tb-list.is-separate .nk-tb-item:hover .nk-tb-action-hidden {
  background-color: #18212d;
}
.dark-mode .nk-tb-list .tb-product .title {
  color: #fff;
}
.dark-mode .dual-listbox .dual-listbox__available,
.dark-mode .dual-listbox .dual-listbox__selected {
  border-color: #3b526f;
}
.dark-mode .dual-listbox .dual-listbox__title {
  border-left-color: #3b526f;
  border-right-color: #3b526f;
  border-top-color: #3b526f;
}
.dark-mode .dual-listbox .dual-listbox__item {
  border-bottom-color: #3b526f;
}
.dark-mode .input-group-addon {
  background-color: #1f2b3a;
  border-color: #3b526f;
}
.dark-mode .border-light {
  border-color: #1d2d40 !important;
}
.dark-mode div.dt-button-info {
  background-color: #18212d;
}
.dark-mode div.dt-button-info h2,
.dark-mode div.dt-button-info .h2 {
  background-color: #131a23;
}
.dark-mode div.dt-button-info h2,
.dark-mode div.dt-button-info .h2 {
  border-color: #1d2d40;
}
.dark-mode .dropzone {
  background: #101924;
}
.dark-mode
  .image-control
  .custom-control-input:checked
  ~ .custom-control-label:before {
  border-color: #3b526f;
}
.dark-mode .image-control .custom-control-label {
  background-color: #1f2b3a;
}
.dark-mode .custom-control-pro .custom-control-label {
  background-color: #18212d;
  border-color: #3b526f;
}
.dark-mode .custom-control.color-control .custom-control-label::before {
  background: rgba(0, 0, 0, 0) !important;
}
.dark-mode .slick-dots li button {
  background-color: #1f2b3a;
}
.dark-mode .slick-dots li.slick-active button {
  background-color: #11c8fc;
}
.dark-mode .slick-next,
.dark-mode .slick-prev {
  color: #fff;
  background: #18212d;
  border-color: #1d2d40;
}
.dark-mode .slick-next:hover,
.dark-mode .slick-prev:hover {
  color: #fff;
  background: #11c8fc;
  border-color: #11c8fc;
}
.dark-mode .slick-disabled .slick-next,
.dark-mode .slick-disabled .slick-prev {
  color: #dbdfea;
}
.dark-mode .slick-disabled .slick-next:hover,
.dark-mode .slick-disabled .slick-prev:hover {
  color: #dbdfea;
  background: #18212d;
  border-color: #1d2d40;
}
.dark-mode .pricing-head {
  border-color: #1d2d40;
}
.dark-mode .pricing-amount .amount {
  color: #fff;
}
.dark-mode .product-title a {
  color: #fff;
}
.dark-mode .product-gallery {
  border-color: #1d2d40;
}
.dark-mode .product-gallery .slider-nav .slider-item .thumb {
  border-color: #1d2d40;
}
.dark-mode .product-gallery .slider-nav .slider-item.slick-current .thumb {
  border-color: #11c8fc;
}
.dark-mode .invoice-wrap {
  background-color: #18212d;
  border-color: #1d2d40;
}
.dark-mode .invoice-bills .table tfoot {
  border-color: #1d2d40;
}
.dark-mode .nk-add-product {
  background: #101924;
  box-shadow: -10px 15px 30px 5px rgba(0, 0, 0, 0.4);
}
.dark-mode .noUi-target {
  background: #33465f;
}
.dark-mode .noUi-handle:before,
.dark-mode .noUi-handle:after {
  background: #5a83b6;
}
.dark-mode .noUi-handle {
  border-color: #5a83b6;
  background: #080d12;
}
.dark-mode .noUi-active {
  box-shadow: inset 0 0 1px #000, inset 0 1px 7px #000, 0 3px 6px -3px #000;
}
.dark-mode input:-webkit-autofill,
.dark-mode input:-webkit-autofill:hover,
.dark-mode input:-webkit-autofill:focus,
.dark-mode textarea:-webkit-autofill,
.dark-mode textarea:-webkit-autofill:hover,
.dark-mode textarea:-webkit-autofill:focus,
.dark-mode select:-webkit-autofill,
.dark-mode select:-webkit-autofill:hover,
.dark-mode select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  transition: background-color 5000s ease-in-out 0s;
}
@media (max-width: 576px) {
  .dark-mode .nk-block-tools-toggle .toggle-expand-content {
    background-color: #243244;
  }
}
.dark-mode .pro-control .custom-control-label {
  border-color: #1d2d40;
}
.dark-mode .col-sep > * + * {
  border-color: #1d2d40;
}
.dark-mode .col-sep-vr > * + * {
  border-color: #1d2d40;
}
.dark-mode .page-item.active .page-link {
  color: #fff !important;
  background-color: #11c8fc !important;
  border-color: #11c8fc !important;
}
.dark-mode .list-item {
  color: #b6c6e3;
}
.dark-mode .list-item::before {
  color: #b6c6e3;
}
.dark-mode .nk-iv-wg4-sub:not(:last-child) {
  border-bottom-color: #2b3748;
}
.dark-mode .nk-iv-wg5-subtitle {
  color: #8094ae;
}
.dark-mode .nk-iv-wg5-ck-result .text-lead {
  color: #364a63;
}
.dark-mode .nk-iv-wg5-ck-result .text-sub {
  color: #8094ae;
}
.dark-mode .nk-iv-wg5-ck-minmax {
  color: #8094ae;
}
.dark-mode .featured-action {
  background: #101924;
  color: #dfe9fe;
}
.dark-mode .featured-action:hover {
  background-color: #11c8fc;
  color: #fff;
}
.dark-mode .nk-coin-ovwg6-legends li {
  color: #8094ae;
}
.dark-mode .plan-item-card {
  background: #18212d;
}
.dark-mode .plan-item-card:after {
  background: #11c8fc;
  color: #fff;
}
.dark-mode .plan-item-head {
  border-bottom-color: #2b3748;
}
.dark-mode .plan-control:checked + .plan-item-card {
  border-color: #11c8fc;
}
.dark-mode .plan-control:checked + .plan-item-card .plan-label {
  background: #11c8fc;
  border-color: #11c8fc;
  color: #fff;
}
.dark-mode .plan-label {
  border-color: #1d2d40;
  background: #18212d;
}
.dark-mode .invest-cc-dropdown .dropdown-indicator:after {
  color: #8094ae;
}
.dark-mode .invest-cc-item:not(:last-child) {
  border-bottom-color: #2b3748;
}
.dark-mode .invest-cc-choosen,
.dark-mode .invest-cc-chosen {
  border-color: #1d2d40;
  background: #18212d;
}
.dark-mode .invest-pm-list {
  border-color: #1d2d40;
  background: #18212d;
}
.dark-mode .invest-pm-item:not(:last-child) {
  border-bottom-color: #2b3748;
}
.dark-mode .invest-pm-label:before {
  border-color: #1d2d40;
}
.dark-mode .invest-pm-label:after {
  color: #fff;
  background: #11c8fc;
}
.dark-mode .invest-pm-label .pm-name {
  color: #364a63;
}
.dark-mode .invest-pm-label .pm-icon {
  color: #8094ae;
}
.dark-mode .invest-field .form-control-amount {
  color: #8094ae;
  border-color: #1d2d40;
}
.dark-mode .invest-field .form-control-amount ~ .form-range-slider.noUi-target {
  background: rgba(29, 45, 64, 0.6);
}
.dark-mode .invest-amount-label {
  border-color: #1d2d40;
  background: #18212d;
}
.dark-mode .invest-amount-control:checked ~ .invest-amount-label {
  border-color: #11c8fc;
  background-color: #11c8fc;
  color: #fff;
}
.dark-mode .tooltip-inner {
  background-color: #101924;
}
.dark-mode .bs-tooltip-top .tooltip-arrow::before,
.dark-mode
  .bs-tooltip-auto[data-popper-placement^="top"]
  .tooltip-arrow::before {
  border-top-color: #101924;
}
.dark-mode .bs-tooltip-end .tooltip-arrow::before,
.dark-mode
  .bs-tooltip-auto[data-popper-placement^="right"]
  .tooltip-arrow::before {
  border-right-color: #101924;
}
.dark-mode .bs-tooltip-bottom .tooltip-arrow::before,
.dark-mode
  .bs-tooltip-auto[data-popper-placement^="bottom"]
  .tooltip-arrow::before {
  border-bottom-color: #101924;
}
.dark-mode .bs-tooltip-start .tooltip-arrow::before,
.dark-mode
  .bs-tooltip-auto[data-popper-placement^="left"]
  .tooltip-arrow::before {
  border-left-color: #101924;
}
.dark-mode .nk-editor-base,
.dark-mode .nk-editor-header {
  border-color: #1d2d40;
}
.dark-mode .nk-history-item:not(:first-child):before {
  background: #1d2d40;
}
.dark-mode .nk-history-item:not(:last-child):after {
  background: #1d2d40;
}
.dark-mode .nk-history-symbol-button,
.dark-mode .nk-history-symbol-dot {
  background: #1d2d40;
  border-color: #1d2d40;
}
.dark-mode .nk-history-symbol-button {
  color: #dbdfea;
}
.dark-mode .filter-button {
  background: #1d2d40;
  color: #dbdfea;
}
.dark-mode .filter-button.active {
  background: #11c8fc;
  border-color: #11c8fc;
  color: #fff;
}
.nk-sticky-toolbar {
  position: fixed;
  border: 3px solid #fff;
  top: 50%;
  background: #fff;
  z-index: 600;
  right: 0;
  border-radius: 6px 0 0 6px;
  border-right: 0;
  box-shadow: -2px 0 24px -2px rgba(43, 55, 72, 0.15);
}
.nk-sticky-toolbar li:not(:last-child) {
  border-bottom: 2px solid #fff;
}
.nk-sticky-toolbar li a {
  display: flex;
  height: 36px;
  width: 36px;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: inherit;
}
.demo-layout {
  background-color: rgba(85, 155, 251, 0.1);
  color: #559bfb;
}
.demo-thumb {
  background-color: rgba(15, 202, 122, 0.1);
  color: #0fca7a;
}
.demo-settings {
  background-color: rgba(129, 107, 255, 0.1);
  color: #816bff;
}
.demo-purchase {
  background-color: rgba(255, 99, 165, 0.1);
  color: #ff63a5;
}
@media (min-width: 576px) {
  .nk-sticky-toolbar {
    top: 30%;
  }
  .nk-sticky-toolbar li a {
    font-size: 20px;
    height: 44px;
    width: 44px;
  }
  .nk-sticky-toolbar li.demo-thumb a {
    font-size: 22px;
  }
}
.nk-demo-panel {
  position: fixed;
  right: 0;
  top: 0;
  width: 320px;
  max-width: calc(100vw - 40px);
  max-height: 100vh;
  height: 100vh;
  z-index: 9999;
  background-color: #fff;
  box-shadow: 0 3px 12px 1px rgba(43, 55, 72, 0.15);
  padding: 0 0 1.5rem;
}
.nk-demo-panel + .toggle-overlay {
  z-index: 9000;
  background: rgba(16, 25, 36, 0.05);
}
.dark-mode .nk-demo-panel {
  box-shadow: 0 0 35px 5px rgba(0, 0, 0, 0.5);
}
.nk-demo-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1.5rem;
  text-transform: uppercase;
  border-bottom: 1px solid #e5e9f2;
}
.nk-demo-head h6,
.nk-demo-head .h6 {
  font-size: 13px;
  letter-spacing: 0.1em;
}
.nk-demo-list {
  overflow: auto;
  max-height: calc(100vh - 60px);
}
.nk-demo-title {
  display: block;
  font-size: 0.875rem;
  padding: 0.675rem 0;
  text-align: center;
  color: #526484;
  border-top: none;
  border-radius: 0 0 4px 4px;
}
.nk-demo-title .subtitle {
  display: block;
  color: #8094ae;
  font-size: 11px;
}
.nk-demo-item {
  padding: 2rem 1.5rem 1rem;
  border-bottom: 1px solid #e5e9f2;
}
.nk-demo-item a {
  display: block;
}
.nk-demo-item a:hover .nk-demo-title {
  color: #11c8fc;
}
.nk-demo-image {
  border: 1px solid #e5e9f2;
  border-radius: 4px 4px 0 0;
  padding: 1rem 1rem 0 1rem;
}
.nk-demo-image img {
  border-radius: inherit;
}
.nk-demo-note {
  background: #f5f6fa;
  padding-bottom: 2rem;
}
@media (min-width: 576px) {
  .nk-demo-item {
    padding: 2rem 2rem 1rem;
  }
  .nk-demo-head {
    padding: 15px 2rem 13px;
  }
  .nk-demo-note {
    padding-bottom: 2rem;
  }
}
@media (min-width: 768px) {
  .nk-demo-panel-2x {
    width: 640px;
  }
  .nk-demo-panel-2x .nk-demo-list .simplebar-content {
    display: flex;
    flex-wrap: wrap;
  }
  .nk-demo-panel-2x .nk-demo-list .simplebar-content .nk-demo-item {
    width: 50%;
  }
  .nk-demo-panel-2x
    .nk-demo-list
    .simplebar-content
    .nk-demo-item:not(:nth-child(2n)) {
    border-right: 1px solid #e5e9f2;
  }
}
.nk-opt-panel {
  overflow: auto;
  max-height: calc(100vh - 84px);
}
.nk-opt-reset {
  padding: 1.5rem 1.5rem 1.5rem;
}
.nk-opt-set {
  padding: 1.5rem 1.5rem 1.5rem;
}
.nk-opt-set:not(:last-child) {
  border-bottom: 1px solid #e5e9f2;
}
.nk-opt-set-title {
  padding: 0 0 0.5rem;
  text-transform: uppercase;
  color: #8094ae;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 11px;
}
.nk-opt-list {
  padding: 0;
  flex-wrap: wrap;
  margin: -0.5rem;
  display: flex;
}
.nk-opt-list + .nk-opt-set-title {
  margin-top: 1.25rem;
}
.nk-opt-item {
  width: 33%;
  text-align: center;
  padding: 0.5rem;
}
.nk-opt-item:not(.active):not(.disabled) {
  cursor: pointer;
}
.nk-opt-item.active {
  cursor: default;
}
.nk-opt-item.disabled {
  pointer-events: none;
}
.nk-opt-item.disabled > .nk-opt-item-name {
  opacity: 0.5;
}
.col-2x .nk-opt-item {
  width: 50%;
}
.col-4x .nk-opt-item {
  width: 25%;
}
.nk-opt-item-name {
  font-weight: 400;
  color: #526484;
  font-size: 12px;
  letter-spacing: 0.02em;
}
.nk-opt-item-name small,
.nk-opt-item-name .small {
  font-size: 11px;
}
.dark-mode .nk-opt-item-name {
  color: #8094ae;
}
.nk-opt-item-bg {
  background: #fff;
  width: 100%;
  height: 32px;
  border: 1px solid #dbdfea;
  padding: 3px;
  margin-bottom: 5px;
  position: relative;
  display: block;
  border-radius: 2px;
  transition: all 0.3s;
}
.dark-mode .nk-opt-item-bg {
  border-color: #3c4d62;
}
.nk-opt-item-bg > span:not(.nk-opt-item-name) {
  display: block;
  background: #526484;
  height: 100%;
  border-radius: 1px;
}
.nk-opt-item-bg > span:not(.nk-opt-item-name).bg-theme {
  background: #170c2f !important;
}
.nk-opt-item-bg > span:not(.nk-opt-item-name).bg-light {
  background: #ebeef2 !important;
}
.nk-opt-item-bg > span:not(.nk-opt-item-name).bg-lighter,
.dual-listbox
  .nk-opt-item-bg
  > span.dual-listbox__item:not(.nk-opt-item-name):hover {
  background: #f5f6fa !important;
}
.nk-opt-item-bg > span:not(.nk-opt-item-name).bg-dark {
  background: #101924 !important;
}
.nk-opt-item-bg > span:not(.nk-opt-item-name).theme-light {
  background: linear-gradient(
    90deg,
    #f5f6fa 0%,
    #f5f6fa 50%,
    #e5e9f2 50%,
    #e5e9f2 100%
  ) !important;
}
.nk-opt-item-bg > span:not(.nk-opt-item-name).theme-dark {
  background: linear-gradient(
    90deg,
    #1f2b3a 0%,
    #1f2b3a 50%,
    #101924 50%,
    #101924 100%
  ) !important;
}
.nk-opt-item-bg > span:not(.nk-opt-item-name).skin-default {
  background: linear-gradient(
    90deg,
    #170c2f 0%,
    #170c2f 28%,
    #11c8fc 28%,
    #11c8fc 72%,
    #ab89f2 72%,
    #ab89f2 100%
  ) !important;
}
.nk-opt-item-bg > span:not(.nk-opt-item-name).skin-purple {
  background: linear-gradient(
    90deg,
    #4700e8 0%,
    #4700e8 28%,
    #854fff 28%,
    #854fff 72%,
    #e7dcff 72%,
    #e7dcff 100%
  ) !important;
}
.nk-opt-item-bg > span:not(.nk-opt-item-name).skin-green {
  background: linear-gradient(
    90deg,
    #074e3b 0%,
    #074e3b 28%,
    #0fac81 28%,
    #0fac81 72%,
    #cfeee6 72%,
    #cfeee6 100%
  ) !important;
}
.nk-opt-item-bg > span:not(.nk-opt-item-name).skin-blue {
  background: linear-gradient(
    90deg,
    #0144a0 0%,
    #0144a0 30%,
    #0971fe 30%,
    #0971fe 72%,
    #cee3ff 72%,
    #cee3ff 100%
  ) !important;
}
.nk-opt-item-bg > span:not(.nk-opt-item-name).skin-egyptian {
  background: linear-gradient(
    90deg,
    #1a3767 0%,
    #1a3767 30%,
    #2e62b9 30%,
    #2e62b9 72%,
    #d5e0f1 72%,
    #d5e0f1 100%
  ) !important;
}
.nk-opt-item-bg > span:not(.nk-opt-item-name).skin-red {
  background: linear-gradient(
    90deg,
    #ab0e21 0%,
    #ab0e21 30%,
    #ee3148 30%,
    #ee3148 72%,
    #fcd6da 72%,
    #fcd6da 100%
  ) !important;
}
.nk-opt-item-bg > span:not(.nk-opt-item-name).skin-bluelite {
  background: linear-gradient(
    90deg,
    #001cfe 0%,
    #001cfe 28%,
    #6576ff 28%,
    #6576ff 72%,
    #e0e4ff 72%,
    #e0e4ff 100%
  ) !important;
}
.nk-opt-item-bg:hover,
.nk-opt-item-bg:focus {
  border-color: #b7c2d0;
}
.active > .nk-opt-item-bg {
  border-color: #11c8fc;
  box-shadow: 0 0 0 2px rgba(115, 58, 234, 0.2);
}
.active > .nk-opt-item-bg:after {
  font-family: "Nioicon";
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 0.875rem;
}
.active > .nk-opt-item-bg.is-light:after {
  color: #101924;
}
.only-text > .nk-opt-item-bg {
  margin-bottom: 0;
  height: 28px;
}
.only-text > .nk-opt-item-bg:after {
  display: none;
}
.disabled > .nk-opt-item-bg {
  border-color: #e5e9f2 !important;
}
.dark-mode .disabled > .nk-opt-item-bg {
  border-color: #3c4d62 !important;
}
.nk-opt-item-bg > .nk-opt-item-name {
  color: #364a63;
  font-size: 10px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.12em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.dark-mode .nk-opt-item-bg > .nk-opt-item-name {
  color: #b6c6e3;
}
.active > .nk-opt-item-bg > .nk-opt-item-name {
  color: #11c8fc;
  font-weight: 700;
}
@media (min-width: 576px) {
  .nk-opt-set,
  .nk-opt-reset {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.pmo-lv {
  position: fixed;
  left: 50%;
  bottom: -50px;
  max-width: 90%;
  width: 590px;
  transform: translate(-50%, 100%);
  transition: all 0.4s;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 0 40px -2px rgba(31, 43, 58, 0.25);
  z-index: 9999;
}
.pmo-lv.active {
  transform: translate(-50%, 0);
  bottom: 20px;
  transition-delay: 1s;
}
.pmo-lv.pmo-dark {
  background: #0564e5;
}
.pmo-close {
  color: #364a63;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  line-height: 38px;
  background: rgba(183, 194, 208, 0.6);
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -18px;
  right: 8px;
  font-size: 16px;
  z-index: 99;
}
.pmo-close:hover {
  color: #fff;
  background: #526484;
}
.pmo-dark .pmo-close {
  background: rgba(31, 43, 58, 0.3);
  color: #fff;
}
.pmo-dark .pmo-close:hover {
  background: rgba(31, 43, 58, 0.5);
}
.pmo-wrap {
  display: flex;
  padding: 0.875rem 3.5rem 0.875rem 1.5rem;
  align-items: center;
  border-radius: 50px;
  transition: all 300ms;
}
.pmo-wrap:hover {
  transform: translateX(5px);
}
.pmo-text {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #f2426e;
  transition: color 0.4s;
}
.pmo-text .ni {
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.125rem;
  margin-top: -3px;
}
.pmo-dark .pmo-text {
  color: #fff;
}
.pmo-st {
  position: fixed;
  right: 25px;
  bottom: 0;
  display: flex;
  align-items: center;
  transition: all 0.4s;
  border-radius: 30px;
  transform: translateY(100%);
  box-shadow: 0 5px 40px 0 rgba(16, 25, 36, 0.3);
  color: #fff;
  background: #f2426e;
  z-index: 99999;
}
.pmo-st:active,
.pmo-st:focus,
.pmo-st:hover {
  color: #fff;
}
.pmo-st.pmo-dark {
  background: #0564e5;
}
.pmo-st.active {
  bottom: 25px;
  transform: translateY(0);
  transition-delay: 0.4s;
}
.pmo-st-img {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  padding: 18px 18px;
  transition: all 0.4s;
}
.pmo-st-text {
  padding: 14px 0;
  height: 60px;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 600;
  transition: all 0.4s;
  visibility: hidden;
  font-size: 12px;
  line-height: 16px;
}
.pmo-st:hover .pmo-st-text {
  width: 220px;
  visibility: visible;
  padding: 14px 14px 14px 0;
}
@media (max-width: 575px) {
  .promo-cov-st {
    display: none;
  }
}
.dark-mode .nk-demo-panel {
  background-color: #18212d;
}
.dark-mode .nk-opt-item-bg {
  background-color: #101924;
}
.dark-mode .nk-demo-head,
.dark-mode .nk-opt-set:not(:last-child),
.dark-mode .nk-demo-item:not(:last-child) {
  border-color: #1d2d40 !important;
}
.dark-mode .nk-demo-title {
  color: #dfe9fe;
}
.dark-mode .nk-demo-image {
  border-color: #1d2d40;
}

.hashtag{
  background-color: #11c8fc;
    color: white;
    padding: 7px 11px;
    border-radius: 20px;
    font-weight: 900;
}


.btns-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  margin: 1em 0 1.5em 0;
}
input,
label,
button {
  border: none;
  outline: none;
}
input[type="file"] {
  display: none;
}
.label-cus,
.button-cus {
  display: block;
  font-size: 1.1em;
  background-color: #025bee;
  color: #ffffff;
  text-align: center;
  padding: 0.8em 0;
  border-radius: 0.3em;
  cursor: pointer;
}
#result {
  /* display: grid; */
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
}
#result div {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#result input {
  background-color: transparent;
  font-size: 1em;
  padding: 0.5em;
  width: 100%;
  color: #313b4c;
  border-bottom: 0.1em solid #021637;
}
#result button {
  position: absolute;
  right: 0.6em;
  background-color: transparent;
  color: #7c8696;
}
#picked-color-ref {
  grid-column: 2;
  grid-row: 1 / 3;
  border: 0.6em solid #d9e8ff;
  border-radius: 0.5em;
}
#custom-alert {
  transform: scale(0);
  transition: 0.5s;
  transform-origin: center;
  background-color: #d9e8ff;
  color: #025bee;
  text-align: center;
  padding: 0.5em;
  margin-top: 1.5em;
}
.hide {
  display: none;
}
#error {
  color: #ff725a;
  text-align: center;
}
.image-container{
  width: 200px;
    border-radius: 50%;
    border: 10px solid;
    height: 200px;
    display: flex;
    contain: paint
}

.height-cus{
  height: 100%;
}

.image-tag{
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.cus-tool{
  color:#9d0000
}
.cus-outer{
  /* display:none   !important; */
  opacity: 0;
}
.cus-parent:hover .cus-outer{
  /* display: flex !important; */
  opacity: 1;
  transition: all 0.3s ease-in-out;
}